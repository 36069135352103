// Angular
import { Component, Input, ViewChild, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { GoogleMap, MapGeocoder, MapGeocoderResponse, MapInfoWindow, MapMarker, MapMarkerClusterer } from '@angular/google-maps';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Predicate, Query, ReturnOption } from '@syncfusion/ej2-data';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { ListViewComponent, ListViewModule } from '@syncfusion/ej2-angular-lists';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Provider } from '@models/data-contracts';
import { MarkerInfo, ProviderType, PhoneNumber, NetworkStatus } from '@models/components/provider-map.model';
import { ApiService } from '@services/api/api.service';
import { Spacer } from '@ui/spacer/spacer.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { NetworkStatusService } from '@app/services/network-status.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Component({
  selector: 'provider-map',
  standalone: true,
  imports: [
    CommonModule,
    GoogleMap,
    MapMarker,
    MapInfoWindow,
    MapMarkerClusterer,
    ReactiveFormsModule,
    TextBoxModule,
    NumericTextBoxModule,
    MultiSelectModule, 
    ListViewModule,
    Spacer,
    ButtonModule
  ],
  templateUrl: './provider-map.component.html',
  styleUrl: './provider-map.component.scss'
})

export class ProviderMapComponent {
  
  constructor(
    private api: ApiService, 
    private router: Router, 
    private geocoder: MapGeocoder,
    private networkStatusService: NetworkStatusService,
    private toast: ToastMessageService) { }

  // Map variables
  center: google.maps.LatLngLiteral = { lat: 38.84, lng: -104.82 };
  display: google.maps.LatLngLiteral;
  zoom = 9;

  // Marker variables
  @Input() patientAddress: string = 'No Address';
  @Input() windowBtnText: string = 'Schedule Appointment';
  @Input() windowBtnFn: (params: any) => void = ($event) => { 
    this.router.navigate([`/scheduler`]);
  };
  markers: MarkerInfo[] = [];
  patientAddressLatLong = this.center;
  windowReference: MapInfoWindow[] = [];
  markerReference: MapMarker[] = [];
  phoneNumbers: PhoneNumber[] = [];

  // Cluster variables
  clusterMarkerIds: number[] = []
  clusterMarkerIndex = 0;
  placeHolderMapMarker: MapMarker;
  placeHolderPosition: google.maps.LatLngLiteral = { lat: 38.84, lng: -104.82 };
  isCluster = false;

  // Search variables
  @Input() hideWindowBtnFn: boolean = false;
  @ViewChild('providersListView', { static: false }) providersListView: ListViewComponent;
  fields: Object = { text: 'description', value: 'id' };
  clustererImagePath = '/assets/maps/images/m';
  providerTypes: ProviderType[] = [];
  providersListData: { [key: string]: Object; }[];
  searchFormGroup: FormGroup = new FormGroup({
    client_address: new FormControl<string | null>('Colorado Springs, CO', [FormValidators.required]),
    provider_type: new FormControl<number[] | null>(null, [FormValidators.required]),
    radius: new FormControl<number | null>(5, [FormValidators.required]),
  }, {});
  
  networkStatuses: NetworkStatus[] = [];
  isNetworkStatusesLoaded = false;
  
  @ViewChildren(MapInfoWindow) infoWindows!: QueryList<MapInfoWindow>;
  
  /**
   * Initialization
   */
  async ngOnInit() {
    try {
      await this.loadNetworkStatuses();
      this.getProviderTypes();
    } catch (error) {
      this.toast.showError('Failed to initialize map components');
    }
  }

  ngAfterViewInit() {
    // Store the references after view initialization
    this.windowReference = this.infoWindows.toArray();
    console.log('Windows initialized:', this.windowReference.length);

    // Subscribe to changes in case of dynamic updates
    this.infoWindows.changes.subscribe(() => {
        this.windowReference = this.infoWindows.toArray();
        console.log('Windows updated:', this.windowReference.length);
    });
  }

  private async loadNetworkStatuses() {
    try {
      const response = await firstValueFrom(this.networkStatusService.getNetworkStatuses());
      this.networkStatuses = Array.isArray(response) ? response : (response as any).result || [];
      this.isNetworkStatusesLoaded = true;
      
    } catch (error) {
      this.networkStatuses = [];
      this.toast.showError('Failed to load network statuses');
      throw error; // Re-throw to be caught by parent
    }
  }

  /**
   * Map markers and popup info
   */
  private autoLoadMapAndMarkers() {

    if (this.patientAddress === "No Address") {
      // Default search from current device's location
      navigator.geolocation.getCurrentPosition(async (position) => {
        let coder = await firstValueFrom(this.geocoder.geocode({ location: { lat: position.coords.latitude, lng: position.coords.longitude } }));
        this.getAddressFromGeocoder(coder);
      });

    } else {
      // If a value is possed in, use it instead
      firstValueFrom(this.geocoder.geocode({ address: this.patientAddress })).then((coder) => { this.getAddressFromGeocoder(coder); })
    }
  }

  // Used to set map position
  private getAddressFromGeocoder(coder: MapGeocoderResponse) {
    this.patientAddress = coder.results[0].formatted_address
    this.patientAddressLatLong = { lat: coder.results[0].geometry.location.lat(), lng: coder.results[0].geometry.location.lng() }
    this.center = this.patientAddressLatLong
    this.searchFormGroup.patchValue({ client_address: this.patientAddress, radius: 50 })
    this.getMarkers(new Query(), this.searchFormGroup.value.radius);
  }

  private getMarkers(query: Query, radius: number) {
    this.markers = [];
    this.windowReference = [];
    this.markerReference = [];

    this.api.getOdata(APIEndpoints.Providers)
      .executeQuery(this.filterQuery(query, radius).expand('XrefPhoneProviders($expand=Phone)'))
      .then((e: ReturnOption) => {
        console.log('API Response:', e.result);
        
        // For each provider, make a marker
        (e.result as object[]).forEach((provider: any, index) => {
          console.log('Processing provider:', provider);
          
          var distance = this.cosineDistance((provider.Latitude as number), (provider.Longitude as number), this.center.lat, this.center.lng);
          console.log('Provider distance:', distance);

          // Filters out null and bad values
          if (provider.Latitude != null && provider.Longitude != null && distance < radius) {
            console.log('Creating marker for:', provider.Name);
            
            const markerInfo: MarkerInfo = {
              id: provider.Id,
              name: provider.Name || 'Unknown',
              notes: provider.Notes || '',
              latling: { 
                lat: provider.Latitude, 
                lng: provider.Longitude 
              },
              distance: Math.floor(distance * 100) / 100,
              spanishSpeaking: provider.SpanishSpeaking || false,
              reimbursement: provider.ReimbursementRate || 0,
              inNetwork: provider.InNetwork || false,
              providerType: provider.ProviderType || 0,
              color: this.getMarkerColors(provider.PriorityId),
              priorityId: provider.PriorityId || 0,
              networkStatus: provider.NetworkStatus || '',
              notesImportant: provider.NotesImportant || false,
              signedPurchaseAgreement: provider.SignedPurchaseAgreement || false,
              XrefPhoneProviders: provider.XrefPhoneProviders || [],
              icon: {
                path: 'M10 0C4.478 0 0 4.478 0 10c0 5.523 10 22 10 22s10-16.477 10-22c0-5.522-4.478-10-10-10zm0 14c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z',
                fillColor: this.getMarkerColors(provider.PriorityId),
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#000000',
                scale: 1,
                anchor: new google.maps.Point(10, 32)
              }
            };
            
            console.log('Created marker:', markerInfo);
            this.markers.push(markerInfo);
          } else {
            console.log('Provider filtered out:', { 
              lat: provider.Latitude, 
              lng: provider.Longitude, 
              distance: distance,
              radius: radius 
            });
          }
        });
        
        this.providersListData = this.convertMarkersForSyncfusion(this.markers);
      });
  }

  // Converts the marker array into a syncfusion friendly format
  private convertMarkersForSyncfusion(markers: MarkerInfo[]): { [key: string]: any; }[] {
    return markers
      .sort((a, b) => a.distance - b.distance) // Sort by distance
      .map((element, index) => ({
        index: index,
        id: element.id,
        name: element.name,
        notes: element.notes,
        latling: { lat: element.latling.lat, lng: element.latling.lng },
        distance: element.distance,
        spanishSpeaking: element.spanishSpeaking,
        reimbursement: element.reimbursement,
        inNetwork: element.inNetwork,
        providerType: element.providerType,
        color: element.color,
        priorityId: element.priorityId,
        networkStatus: element.networkStatus,
        notesImportant: element.notesImportant,
        signedPurchaseAgreement: element.signedPurchaseAgreement,
        XrefPhoneProviders: element.XrefPhoneProviders
      }));
  }

  getLabel(input: string, color: string): google.maps.MarkerLabel {
    return {
      text: input,
      fontSize: '11px',
      fontWeight: 'bold',
    };
  }

  getMarkerColors(networkStatusId: number | undefined): string {  
    if (networkStatusId === undefined || networkStatusId === null) return '#FF0000';
    const status = this.networkStatuses.find(s => s.Id === networkStatusId);
    return status?.Color || '#FF0000';
  }

  // Creates a list of info windows whenever a cluster is clicked and opens up the first info window in the list
  clusterClick(c: any) {
    console.log('Starting cluster click...');
    
    if (!this.placeHolderMapMarker) {
        console.error('No placeholder marker');
        return;
    }

    this.isCluster = true;
    this.clusterMarkerIds = [];
    
    const clusterMarkers = c.getMarkers();
    
    // Get all unique markers at this position
    clusterMarkers.forEach((marker: any) => {
        const position = marker.getPosition();
        const indices = this.markers.reduce((acc: number[], m, index) => {
            if (Math.abs(m.latling.lat - position.lat()) < 0.0001 && 
                Math.abs(m.latling.lng - position.lng()) < 0.0001) {
                acc.push(index);
            }
            return acc;
        }, []);
        
        // Add all found indices if they're not already in the array
        indices.forEach(idx => {
            if (!this.clusterMarkerIds.includes(idx)) {
                this.clusterMarkerIds.push(idx);
            }
        });
    });

    console.log('Found marker IDs:', this.clusterMarkerIds);

    if (this.clusterMarkerIds.length > 0) {
        this.clusterMarkerIndex = 0;
        const center = c.getCenter();
        this.placeHolderPosition = {
            lat: center.lat(),
            lng: center.lng()
        };
        
        // Force close any open windows first
        this.windowReference.forEach(window => window.close());
        
        // Open the first window
        const windowToOpen = this.windowReference[this.clusterMarkerIds[0]];
        if (windowToOpen) {
            windowToOpen.open(this.placeHolderMapMarker);
        }
    }
  }

  // Creates an invisible placeholder marker for clicking on clusters
  setPlaceholderMarker(marker: MapMarker) {
    this.placeHolderMapMarker = marker
  }

  // Gets the next info window in the cluster
  getNextInfoWindow(currentWindow: MapInfoWindow) {
    if (this.clusterMarkerIndex < this.clusterMarkerIds.length - 1) {
      this.clusterMarkerIndex++;
      currentWindow.close();
      this.windowReference[this.clusterMarkerIds[this.clusterMarkerIndex]]?.open(this.placeHolderMapMarker);
    }
  }

  // Gets the previous info window in the cluster
  getPreviousInfoWindow(currentWindow: MapInfoWindow) {
    if (this.clusterMarkerIndex > 0) {
      this.clusterMarkerIndex--;
      currentWindow.close();
      this.windowReference[this.clusterMarkerIds[this.clusterMarkerIndex]]?.open(this.placeHolderMapMarker);
    }
  }


  // Gets a phone number from the map
  getNumber(providerId: number | undefined) {
    let res = this.phoneNumbers.find(x => x.id === providerId);

    if (res === undefined) {
      return "No Number Available"
    }
    else {
      return res.PhoneNumber;
    }
  }

  // Applies a small filter on the query so it doesn't need to get as much from the database
  filterQuery(query: Query, radius: number) {
    let a = (this.center.lat + (radius / 60));
    let b = (this.center.lat - (radius / 60));
    let c = (this.center.lng + (radius / 60));
    let d = (this.center.lng - (radius / 60));
    return query.where('latitude', 'lessthanorequal', a).where('latitude', 'greaterthanorequal', b)
      .where('longitude', 'lessthanorequal', c).where('longitude', 'greaterthanorequal', d)
      .where("Active", 'equal', true);
  }

  // Changes the page when the schedule appointment button is clicked
  changePage() { this.router.navigate([`/scheduler`]); }

  // When a marker is clicked open an info window
  OnMarkerclick(window: MapInfoWindow, marker: MapMarker) {
    this.isCluster = false;
    this.clusterMarkerIds = [];
    window.open(marker);
  }

  // Method for calculating distance in miles from the patient address
  cosineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    let R = 3958.8;
    let p1 = lat1 * Math.PI / 180;
    let p2 = lat2 * Math.PI / 180;
    let deltaP = p2 - p1;
    let deltaLon = lon2 - lon1;
    let deltaLambda = (deltaLon * Math.PI) / 180;
    let a = Math.sin(deltaP / 2) * Math.sin(deltaP / 2) + Math.cos(p1) * Math.cos(p2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
    let d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
    return (d);
  }

  /**
   * Search Query fns
   */

  getProviderTypes() {
    this.api.getOdata(APIEndpoints.ProviderTypes).executeQuery(new Query()).then((response: any) => {
      if (response && response.result) {
        this.providerTypes = response.result.map((res: any) => ({
          id: res.Id,
          description: res.Description
        }));
      }
    }).catch(error => {
      this.providerTypes = [];
      this.toast.showError('Failed to load provider types');
    });
  }

  async generateMarkersBasedOnFilter() {
    let coder = await firstValueFrom(this.geocoder.geocode({ address: this.searchFormGroup.value.client_address }))
    this.patientAddress = coder.results[0].formatted_address
    this.patientAddressLatLong = { lat: coder.results[0].geometry.location.lat(), lng: coder.results[0].geometry.location.lng() }
    this.center = { lat: this.patientAddressLatLong.lat, lng: this.patientAddressLatLong.lng };
    this.getMarkers(this.addProviderTypeOnSearchQuery(this.searchFormGroup.value.provider_type), this.searchFormGroup.value.radius);
  }

  private addProviderTypeOnSearchQuery(ids: number[]): Query {

    if (ids.length >= 1) {
      let pred: Predicate = new Predicate('ProviderType', 'equal', -1);

      ids.forEach((id) => {
        pred = pred.or(new Predicate('ProviderType', 'equal', id));
      });

      return new Query().where(pred);

    } else {
      return new Query();
    }
  }

  /**
   * Sidebar
   */
  @Output() providerSelected = new EventEmitter<any>();
  addReferenceToWindowMarkerPair(window: MapInfoWindow, marker: MapMarker) {
    this.windowReference.push(window)
    this.markerReference.push(marker)
  }

  recenterMap(index: any) {
    // First close any open windows
    this.windowReference.forEach(window => window.close());
    
    // Set zoom and center
    this.zoom = 18;
    this.center = { 
        lat: this.markers[index].latling.lat, 
        lng: this.markers[index].latling.lng 
    };

    // Ensure the marker reference exists before opening window
    if (this.markerReference[index] && this.windowReference[index]) {
        setTimeout(() => {
            this.windowReference[index].open(this.markerReference[index]);
        }, 100);
    }
  }

  applyStyleToChild(elem: any): void {
    elem.item.classList.remove('e-active');
    elem.item.querySelector('.e-list-item').classList.add('e-active');
  }

  onProvidersListSelect(): void {
    const selectedItems = this.providersListView.getSelectedItems() as any;
    this.providerSelected.emit(selectedItems as Provider);
    this.applyStyleToChild(selectedItems);
    this.recenterMap(selectedItems.data.index);
  }

  onProviderClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    const listItem = clickedElement.closest('li');
    // this.providersListView.selectItem()
    clickedElement.classList.add('e-active');

    if (listItem) {
      const childElement = listItem.querySelector('.e-list-wrapper');

      if (childElement) {
        childElement.classList.add('e-active')
      }

      this.providersListView.selectItem(listItem);
    }
  }

  getProviderName(providerId: number): string {
    let res = this.providerTypes.find(x => x.id === providerId)?.description ?? 'Type unkown';

    return res;
  }

  getPriorityLabel(distance: number): string {
    if (distance < 1) return 'Priority 1';
    if (distance < 4) return 'Priority 2';
    if (distance < 6) return 'Priority 3';
    if (distance < 25) return 'Priority 4';
    return 'Priority 5';
  }

  getNetworkStatusLabel(priorityId: number): string {
    if (!Array.isArray(this.networkStatuses)) return 'Unknown Status';
    const status = this.networkStatuses.find(s => s.Id === priorityId);
    return status?.Description || 'Unknown Status';
  }

  private createMarker(provider: any): google.maps.Marker {
    const marker = new google.maps.Marker({
      position: provider.latling,
      title: provider.name,
      icon: {
        path: 'M10 0C4.478 0 0 4.478 0 10c0 5.523 10 22 10 22s10-16.477 10-22c0-5.522-4.478-10-10-10zm0 14c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z',
        fillColor: provider.color,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#000000',
        scale: 0.07,
        anchor: new google.maps.Point(192, 512),
      }
    });

    return marker;
  }
}