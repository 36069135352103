// Angular
import { Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { CommandClickEventArgs, CommandModel, GridModel, RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { FeeSchedule } from '@models/data-contracts';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { GridTemplateModule } from '@modules/grid-template.module';
import { AddFeeScheduleForm } from '@root/src/app/components/forms/add-forms/add-fee-schedule-form/add-fee-schedule-form.component';

@Component({
  selector: 'fee-schedule-grid',
  standalone: true,
  imports: [
    DialogModule,
    GridTemplateModule,
    AddFeeScheduleForm
  ],
  templateUrl: './fee-schedule-grid.component.html',
  styleUrl: './fee-schedule-grid.component.scss'
})

export class FeeScheduleGridComponent implements OnInit {
  
  constructor(
    public globals: GlobalsService,
    private api: ApiService,
  ) {}

  // General variables

  // Grid variables
  @ViewChild('feeSchedulesGrid') public feeSchedulesGrid: GridTemplateComponent;

  public feeSchedulesGridSettings: GridModel;
  public providersEditorVisible: boolean = false;
  public assignedProvidersIds: any;
  public commands: CommandModel[] = [
    // { buttonOption: { iconCss: 'e-icons e-copy', cssClass: 'e-flat' }, title: 'Clone' }, 
    { buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }, title: 'Edit' }, 
  ];

  // Dialog variables
  @ViewChild('addFeeScheduleDialog', { static: true}) public addFeeScheduleDialog: DialogComponent;
  @ViewChild('addFeeScheduleForm') public addFeeScheduleForm: any;

  public isAddFeeScheduleDialogVisible: boolean = false;
  public existingFeeSchedule: WritableSignal<FeeSchedule | null> = signal(null);
  private getArrayLength(field: any, data: any): string {
    return Array.isArray(data[field]) ? data[field].length.toString() : '0';
  }
  

  ngOnInit(): void {
    this.feeSchedulesGridSettings = {
      dataSource: this.api.getOdata(`${APIEndpoints.FeeSchedules}`),
      query: new Query().expand(["XrefFeeScheduleProcedureCodes($expand=ProcedureCode)","Providers"]),
      allowFiltering: true,
      toolbar: this.globals.isResponsive ? 
        [{ text: 'Add Fee Schedule', tooltipText: 'Add Schedule', id: 'AddSchedule' }, 'Edit', 'Update', 'Cancel', 'Delete'] : 
        [{ text: 'Add Fee Schedule', tooltipText: 'Add Schedule', id: 'AddSchedule' }, 'Update', 'Cancel', 'Delete', 'ColumnChooser'],
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'Name' },
        { 
          field: 'XrefFeeScheduleProcedureCodes', 
          headerText: 'Total Procedure Codes',
          valueAccessor: (field: any, data: any) => this.getArrayLength('XrefFeeScheduleProcedureCodes', data)
        },
        { 
          field: 'Providers',
          headerText: 'Total Providers',
          valueAccessor: (field: any, data: any) => this.getArrayLength('Providers', data)
        },
        { type: 'commands', commands: this.commands, headerText: 'Actions', visible: this.globals.isResponsive ? false : true }
      ],
      toolbarClick: ($event: Event) => this.onToolbarClick($event),
      // rowSelected: ($event: RowSelectEventArgs) => this.feeSchedulesGridRowSelected($event),
      commandClick: ($event: CommandClickEventArgs) => this.commandClick($event)
    };
  }
  

  // Return total items as string to display in feeSchedulesGrid
  countFieldItems(field: string, data: object): string[] {
    // Ensure data exists
    if ((data as any)[field]) {
      const items = (data as any)[field];      
      return [items.length.toString()];
    } else {
      return ['0'];
    }
  }

  // Update height before modal opened
  beforeOpenAddFeeScheduleDialog(args: any) {
    args.maxHeight = '85vh';
  }

  // Add logic for custom Add Fee Schedule button
  onToolbarClick(args: any): void {
    if (args.item.id === 'AddSchedule') {
      this.addFeeScheduleDialog.show();
    } 
  }

   // Add logic for actions on individual rows
  commandClick(commandClickArgs: CommandClickEventArgs) {
    const rowData: FeeSchedule | undefined = (commandClickArgs?.rowData as FeeSchedule);
    if (rowData && rowData.Id) {
      const editFeeScheduleClicked: boolean | undefined = commandClickArgs.commandColumn?.buttonOption?.iconCss?.includes('e-edit');
      if (editFeeScheduleClicked) {
        this.existingFeeSchedule.set(rowData);
        this.addFeeScheduleDialog.show();
      }
    }
  }

  handleSubmitComplete(): void {
    // Handle invoice form
    if (this.isAddFeeScheduleDialogVisible) {
      this.existingFeeSchedule.set(null);
      this.addFeeScheduleDialog.hide();
      this.isAddFeeScheduleDialogVisible = false;
    }
  }
}
