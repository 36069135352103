// Angular(first)
import { Component, computed, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party(2nd)
import { Query } from '@syncfusion/ej2-data';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal last - Services
import { ApiService } from '@services/api/api.service';

// Internal(last) - Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Address, CaseFile, Patient } from '@models/data-contracts';

// Internal(last) - Components
import { PatientFormComponent } from '@forms/patient-form/patient-form.component';
import { CaseFileForm } from "../../components/forms/case-file-form/case-file-form.component";
import { CaseFileFormComponent } from "../../components/forms/case-file/case-file.component";

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    CommonModule,
    PatientFormComponent,
    CaseFileForm,
    CaseFileFormComponent
],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestPageComponent implements OnInit {
  
  /**
   * Constructor
   */
  constructor(
    private api: ApiService
  ) { }

  /**
   * Declare variables that require a decorator
   */
  @ViewChild('helloWorldElement', {static: true}) helloWorldElement!: string;

  /**
   * Declare public variables
   */
  helloWorldPublic: string;
  caseFileSignal = signal<CaseFile | undefined>(undefined);
  caseFileData = computed(() => this.caseFileSignal());

  /**
   * Declare private variables
   */
  private helloWorldPrivate: string;

  /**
   * OnInit hook
   */
  ngOnInit(): void {
    this.helloWorldPublic = 'Hello World';
    this.api.getOdata(APIEndpoints.Casefiles).executeQuery(new Query()).then((result: any) => {
      const caseFile = result.result[0] as CaseFile;
      this.caseFileSignal.set(caseFile);
    });
  }

  /**
   * AfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.helloWorldElement = 'Hello World';
  }
}
