export enum APIEndpoints {
    AccidentDetails = '/accidentdetails',
    AccidentInfo = '/accidentinfo',
    AddressTypes = '/addresstypes',
    Addresses = '/addresses',
    AgreementNotes = '/agreementNotes',
    AgreementPriorities = '/AgreementPriorities',
    Agreements = '/Agreements',
    AgreementStatuses = '/AgreementStatuses',
    Appointments = '/Appointments',
    AppointmentStatuses = '/AppointmentStatuses',
    AppointmentTypes = '/AppointmentTypes',
    Audits = '/Audits',
    AuthTypes = '/AuthTypes',
    BalanceStatements = '/BalanceStatements',
    BodyParts = '/BodyParts',
    Casefiles = '/Casefiles',
    CaseFilesTableData = '/CaseFiles/?$expand=LawFirmContactNavigation,AttorneyNavigation,CaseManagerNavigation,LawFirm,MarketManagerNavigation,PAndLNavigation,Patient($expand=Address)',
    CaseFileCompliances = '/CaseFileCompliances',
    CaseTypes = '/CaseTypes',
    ConfigAppointmentTypes = '/ConfigAppointmentTypes',
    ConfigInitialNotes = '/ConfigInitialNotes',
    ConfigNoteTypes = '/ConfigNoteTypes',
    ConfigPandLs = '/ConfigPandLs',
    ConfigVariables = '/ConfigVariables',
    Contacts = '/Contacts',
    DepositAllocations = '/DepositAllocations',
    Deposits = '/Deposits',
    FeeSchedules = '/FeeSchedules',
    Email = '/Email',
    FileGroups = '/FileGroups',
    FileRatings = '/FileRatings',
    InitialNotes = '/InitialNotes',
    InitialTimeFrames = '/InitialTimeFrames',
    InjuryTypes = '/InjuryTypes',
    Intakes = '/Intakes',
    Insurances = '/Insurances',
    IntakeStatuses = '/IntakeStatuses',
    IntakeSurveys = '/IntakeSurveys',
    InvoicePayments = '/InvoicePayments',
    InvoiceRows = '/InvoiceRows',
    Invoices = '/Invoices',
    Languages = '/Languages',
    Lawfirms = '/Lawfirms',
    LawfirmsBulk = '/Lawfirms/batch',
    LawfirmsGridData = '/Lawfirms?expand=Address($expand=StateNavigation),MarketManagerNavigation,CaseManagerNavigation',
    Locations = '/Locations',
    MedicareCptCodes = '/MedicareCptCodes',
    MedicareLocalities = '/MedicareLocalities',
    Modalities = '/Modalities',
    ModalityTypes = '/ModalityTypes',
    NextActivityTypes = '/NextActivityTypes',
    PandLs = '/PandLs',
    Patients = '/Patients',
    PatientsDataTable = '/Patients?$expand=Address',
    PaymentMethods = '/PaymentMethods',
    PaymentStatuses = '/PaymentStatuses',
    Phones = '/Phones',
    PhoneTypes = '/PhoneTypes',
    Priorities = '/Priorities',
    ProcedureCodes = '/ProcedureCodes',
    Providers = '/Providers',
    ProvidersBulk = '/Providers/batch',
    ProviderPriorities = '/ProviderPriorityStatuses',
    ProviderTypes = '/ProviderTypes',
    RecordStatuses = '/RecordStatuses',
    ReductionRequests = '/ReductionRequests',
    ReductionRequestApprovalTeams = '/ReductionRequestApprovalTeams',
    ReferralSources = '/ReferralSources',
    Rights = '/Rights',
    Roles = '/Roles',
    SchedulerLocations = '/SchedulerLocations',
    States = '/States',
    StatusingGroups = '/StatusingGroups',
    StatusOnBehalves = '/StatusOnBehalves',
    SignedAuthorizations = '/SignedAuthorizations',
    SurgeryTypes = '/SurgeryTypes',
    SurgicalInvoices = '/SurgicalInvoices',
    TypesofInsurances = '/TypesofInsurances',
    Users = '/Users',
    WorkItems = '/WorkItems',
    WorkItemTypes = '/ListWorkItemTypes',
    WorkItemCategories = '/ListWorkItemCategories',
    WorkItemStatus = '/ListWorkItemStatuses',
    WorkItemPriority = '/ListWorkItemPriorities',
    XrefFeeScheduleProcedureCodes = '/XrefFeeScheduleProcedureCodes',
    XrefUsersRoles = '/XrefUsersRoles',
    Remittances = '/Remittances',
    XrefIntakeAccidents = '/XrefIntakeAccidents',
    XrefIntakeInjuries = '/XrefIntakeInjuries',
    XrefAddressContacts = '/XrefAddressContacts',
    XrefLawFirmContacts = '/XrefLawFirmContacts',
    XrefProviderContacts = '/XrefProviderContacts',
    XrefPhoneContacts = '/XrefPhoneContacts',
    XrefAddressLawfirms = '/XrefAddressLawfirms',
    XrefPhoneUsers = '/XrefPhoneUsers',
    XrefAddressPatients = '/XrefAddressPatients',
    XrefPhonePatients = '/XrefPhonePatients',
    XrefLawFirmPatients = '/XrefLawFirmPatients',
    XrefProviderPatients = '/XrefProviderPatients',
    XrefAddressProviders = '/XrefAddressProviders',
    XrefPhoneProviders = '/XrefPhoneProviders',
    XrefPhoneLawfirms = '/XrefPhoneLawfirms',
    XrefWorkItemCaseFiles = '/XrefWorkItemCaseFiles',
    XrefWorkItemLawFirms = '/XrefWorkItemLawFirms',
    XrefWorkItemPatients = '/XrefWorkItemPatients',
    XrefWorkItemProviders = '/XrefWorkItemProviders',
    Surgeries = "/Surgerys",
    XrefSurgeriesSurgicalProgression = "/XrefSurgeriesSurgicalProgression",
    SurgicalProgression = "/SurgicalProgression"
}

export enum BoxEndpoints {
    SignRequests = 'api/box/sign/requests',
    Templates = 'api/box/sign/templates',
    TokenGenerator = 'api/box/token/picker',
}
