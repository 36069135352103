// Angular
import { Component, Output, EventEmitter, Input, ViewChild, WritableSignal, signal } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { createSpinner } from '@syncfusion/ej2-angular-popups';
import { showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { ChangeEventArgs, DropDownListModule, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, TextBoxComponent, MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorAllModule, ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';

// Internal
import { environment } from '@environments/environment';
import { states } from '@models/global-vars';
import { BoxSignTags } from '@models/components/box-sign.model';
import { APIEndpoints, BoxEndpoints } from '@models/api/Endpoints';
import { BoxService } from '@services/box/box.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { LoadingModule } from '@modules/loading.module';
import { RichTextEditorComponent } from '../../../ui/custom-rich-text-editor/rich-text-editor.component';

@Component({
  selector: 'box-sign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    RichTextEditorAllModule,
    DatePickerModule,
    MaskedTextBoxModule,
    NumericTextBoxModule,
    ButtonModule,
    GridTemplateModule,
    LoadingModule,
    CommonModule,
    RichTextEditorComponent,
],
  templateUrl: './box-sign.component.html',
  styleUrl: './box-sign.component.scss',
  providers: [
    ToolbarService,
    LinkService, 
    ImageService, 
    HtmlEditorService,
  ]
})
export class BoxSignComponent {

  /**
   * ~~ NOTICE ~~
   *  Connection can be tested temporarily by creating a developer token 
   *  here -> https://app.box.com/developers/console/app/2268127/configuration
   *  @see {@link environment}
   *  Copy generated token above and paste into BoxDeveloperToken value 
   *  in both of the files found in @environments/ folder
   */

  constructor (
    private toast: ToastMessageService, // TO DO: Add toaster to signify fetch results
    private box: BoxService,
    private api: ApiService
  ) {}
  
  @Input() caseFileId: any = 2;
  @Input() caseNumber?: string | null;
  @Output() folder = new EventEmitter<any>();
  @Output() template = new EventEmitter<any>();
  @Output() formValues = new EventEmitter<any>();
  @ViewChild('signTemplates') signTemplates: DropDownListComponent;
  @ViewChild('emailSubject') emailSubject: TextBoxComponent;
  @ViewChild('emailMessage') emailMessage: RichTextEditorComponent;

  selectedTemplate: WritableSignal<any> = signal({});
  selectedTemplateInfo: WritableSignal<any> = signal({});
  loadingData: WritableSignal<any> = signal(false);
  folders: any;
  templatesList: any;
  requestsList: any;
  providersList: any;
  contactsList: any;
  caseManagersList: any;
  statesList: any;
  requestsGridLoading: boolean = true;
  requestsGrid: GridModel;
  selectedTemplateSignRequest: any;
  boxSignForm: FormGroup = new FormGroup({});
  richTextEditorTools: object = {
    type: 'MultiRow',
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ]
  };

  ngOnInit() {
    this.getTemplates();
    this.loadSignRequests();
  }

  logData(event: any, args: any) {
    console.log(event, args);
  }

  getTemplates() {
    this.api.fetchRequest(`${BoxEndpoints.Templates}(${this.caseFileId})`, undefined, undefined, undefined, false).then(templates => this.templatesList = templates );
  }

  getProviders() {
    this.providersList = this.api.getOdata(APIEndpoints.Providers);
  }

  getContacts() {
    this.contactsList = this.api.getOdata(APIEndpoints.Contacts);
  }

  getStates() {
    this.statesList = states;
  }

  // Emit changes when Sign Template selected
  emitDropDownSelect(args: ChangeEventArgs) {
    this.boxSignForm.reset();
    this.template.emit(args.itemData);
    this.selectedTemplate.set(args.itemData);
    this.api.fetchRequest(`${BoxEndpoints.Templates}/info/${this.selectedTemplate().id}`).then(templateInfo => {
      this.selectedTemplateInfo.set(templateInfo);
      this.boxSignForm = this.buildForm((templateInfo as any).boxSignTags);
    });
    
  }

  initializeSpinner(elementId: string) {

    createSpinner({
      target: document.getElementById(elementId) as any
    });

    showSpinner((document as any).getElementById(elementId));
  }

  endSpiner(elementId: string) {
    hideSpinner((document as any).getElementById(elementId));
  }

  noRecordSpinner(args: any) {

    if (args.name === 'beforeOpen') {

      // Check for populated values and set timeout to ensure #noRecordsTemplate visible
      if (!this.templatesList) {
        setTimeout(() => {
          this.initializeSpinner('no-record');
        }, 1);
      } else {
        setTimeout(() => {
          this.endSpiner('no-record');
        }, 1)
      }
    }
  }

  async submitForm(args: SubmitEvent) {
    this.loadingData.set(true);
    this.boxSignForm.value.boxSignTemplateId = this.signTemplates.value;
    this.boxSignForm.value.emailSubject = this.emailSubject.value;
    this.boxSignForm.value.emailMessage = this.selectedTemplate().emailBody; 
    this.boxSignForm.value.caseNumber = this.caseNumber;
    this.formValues.emit(this.boxSignForm.value);
    console.log(this.signTemplates.value);
    if (this.signTemplates.value === 35) {
      // await this.box.postNewSignRequest(this.boxSignForm.value);
      this.loadingData.set(false);
    } else {
      const errorMsg = `
        <strong>SIGN TEMPLATE ERROR</strong>
        <p>While testing, we may only submit Sign Requests using the \"<i>(Case Compass Test)</i>\" template. Please click dropdown and select test template before re-submitting.</p>
      `;
      this.toast.showError(errorMsg);
      this.loadingData.set(false);
    }
  }

  clearForm() {
    this.boxSignForm.reset();
  }

  removeUnderscoreFromString(sourceString: string) {
    return sourceString.replace(/_/g, ' ');
  }
  
  buildForm(dataArray: BoxSignTags[]) {
    const boxSignForm: any = {};
    const requestData: any = {};

    for (const item of dataArray) {
      requestData[item.tagId] = new FormControl('', this.getValidatorsForField(item.tagFormRequired));
    }

    boxSignForm['requestData'] = new FormGroup(requestData);

    return new FormGroup(boxSignForm);
  }

  getValidatorsForField(required: boolean) {
    return required === true ? [Validators.required] : null;
  }

  loadSignRequests() {
    this.requestsGridLoading = true;
    this.api.fetchRequest(`${BoxEndpoints.SignRequests}/${this.caseNumber}`, undefined, undefined, undefined, false)
      .then(response => {
        if (response) {  // Make sure we have data before setting grid
          this.requestsGrid = { 
            dataSource: response,
            columns: [
              { field: 'Id' },
              { field: 'TemplateName', headerText: 'Sign Template' },
              { field: 'Signers', headerText: 'Signer Statuses' },
              { field: 'CreatedAt', headerText: 'Created' },
              { field: 'Status', headerText: 'Status' }
            ]
          };
        }
      })
      .catch(error => {
        console.error('Error loading sign requests:', error);
        this.toast.showError('Failed to load previous sign requests');
      })
      .finally(() => {
        this.requestsGridLoading = false;
      });
  }
}
