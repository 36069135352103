import { Injectable, WritableSignal } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { FeeSchedule } from "@root/src/app/shared/models/data-contracts";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { ToastMessageService } from "@root/src/app/shared/services/toast-message/toast-message.service";
import { Query, ReturnOption } from "@syncfusion/ej2-data";

interface medicareLocality { id: number; localityName: string; workRate: number; practiceRate: number; malpracticeRate: number; conversionFactor: number; }
interface medicareCptCode { id: number; code: string; facilityRvu: number; malpracticeRvu: number; nonFacilityRvu: number; physicianWorkRvu: number }


@Injectable({
    providedIn: 'root'
  })


  // Complex Because of the many to many relationship between FeeSchedule and Providers
  // 1 form is basically touching 4 different tables 
  // FeeSchedule, Providers, XrefFeeScheduleProcedureCodes, ProcedureCodes
  // there can be multiple providers per fee schedule and 1 fee schedule for provider
  // there can be multiple procedure codes per fee schedule
  // there is many to many relationship between FeeSchedule and ProcedureCodes for the XrefFeeScheduleProcedureCodes
  export class AddFeeScheduleService  {
    constructor(private api: ApiService, private toast: ToastMessageService) {}
  
    cptCodesData: object[] = [];

    medicareCptCodesData: medicareCptCode[] = [];
    medicareLocalitiesData: medicareLocality[] = [];

    async addFeeScheduleToProviders(feeScheduleId: number, providers: any[]): Promise<void> {
      const updatePromises = providers.map(provider => {
        const providerUpdate = {
          Id: provider.Id,
          FeeScheduleId: feeScheduleId
        };
        return this.api.fetchRequest(APIEndpoints.Providers + `(${provider.Id})`, 'PATCH', providerUpdate, undefined, true);
      });
    
      await Promise.all(updatePromises);
    }
  
    async addNewFeeScheduleToProviders(feeScheduleId: number, assignedProviders: any[], existingProviders: any[]): Promise<void> {
      const providersToAdd = assignedProviders.filter(assigned => 
        !existingProviders.some(existing => existing.Id === assigned.Id)
      );
    
      const updatePromises = providersToAdd.map(provider => {
        const providerUpdate = {
          Id: provider.Id,
          FeeScheduleId: feeScheduleId
        };
        return this.api.fetchRequest(APIEndpoints.Providers + `(${provider.Id})`, 'PATCH', providerUpdate, undefined, true);
      });
    
      await Promise.all(updatePromises);
    }
    
    async removeProvidersFromFeeSchedule(assignedProviders: any[], existingProviders: any[]): Promise<void> {
      const providersToRemove = existingProviders.filter(existing => 
        !assignedProviders.some(assigned => assigned.Id === existing.Id)
      );
    
      const updatePromises = providersToRemove.map(provider => {
        const providerUpdate = {
          Id: provider.Id,
          FeeScheduleId: null
        };
        return this.api.fetchRequest(APIEndpoints.Providers + `(${provider.Id})`, 'PATCH', providerUpdate, undefined, true);
      });
    
      await Promise.all(updatePromises);
    }
    async addNewProcedureCodes(feeScheduleId: number, newEntries: any[], existingXrefs: any[]): Promise<void> {
      const xrefsToAdd = newEntries.filter(entry => 
        !existingXrefs.some(existing => existing.ProcedureCodeId === entry.Code)
      ).map(entry => ({
        FeeScheduleId: feeScheduleId,
        ProcedureCodeId: entry.Code,
        BilledAmount: entry.BilledAmount || 0,
        SplitInvoice: entry.SplitInvoice || false,
        FrontEndReimbursmentRate: entry.FrontEndReimbursmentRate || 0,
        BackEndReimbursmentRate: entry.BackEndReimbursmentRate || 0
      }));
    
      if (xrefsToAdd.length > 0) {
        const createPromises = xrefsToAdd.map(xref => 
          this.api.fetchRequest(APIEndpoints.XrefFeeScheduleProcedureCodes, 'POST', xref, undefined, true)
        );
        await Promise.all(createPromises);
      }
    }
    
    async updateExistingProcedureCodesXref(feeScheduleId: number, newEntries: any[], existingXrefs: any[]): Promise<void> {
      const xrefsToUpdate = existingXrefs.filter(existing =>
        newEntries.some(entry => entry.Code === existing.ProcedureCodeId)
      ).map(existing => {
        const newEntry = newEntries.find(entry => entry.Code === existing.ProcedureCodeId);
        return {
          Id: existing.Id,
          FeeScheduleId: feeScheduleId,
          ProcedureCodeId: existing.ProcedureCodeId,
          BilledAmount: newEntry.BilledAmount || 0,
          SplitInvoice: newEntry.SplitInvoice || false,
          FrontEndReimbursmentRate: newEntry.FrontEndReimbursmentRate || 0,
          BackEndReimbursmentRate: newEntry.BackEndReimbursmentRate || 0
        };
      });
    
      const updatePromises = xrefsToUpdate.map(xref =>
        this.api.fetchRequest(`${APIEndpoints.XrefFeeScheduleProcedureCodes}(${xref.Id})`, 'PATCH', xref, undefined, true)
      );
      await Promise.all(updatePromises);
    }
    
    async removeProcedureCodesXref(newEntries: any[], existingXrefs: any[]): Promise<void> {
      const xrefsToRemove = existingXrefs.filter(existing =>
        !newEntries.some(entry => entry.Code === existing.ProcedureCodeId)
      );
      const deletePromises = xrefsToRemove.map(xref =>
        this.api.fetchRequest(`${APIEndpoints.XrefFeeScheduleProcedureCodes}(${xref.Id})`, 'DELETE', undefined, undefined, true)
      );
      await Promise.all(deletePromises);
    }

    async updateProcedureCodesXref(feeScheduleId: number, procedureCodesForm: FormGroup<any>, existingXrefs: any[] = []): Promise<void> {
      const newEntries = procedureCodesForm.value.entries || [];
      
      await this.addNewProcedureCodes(feeScheduleId, newEntries, existingXrefs);
      await this.updateExistingProcedureCodesXref(feeScheduleId, newEntries, existingXrefs);
      await this.removeProcedureCodesXref(newEntries, existingXrefs);
    }

    async patchFeeSchedule(feeSchedule: Partial<FeeSchedule>): Promise<void> {
      const endpoint = `${APIEndpoints.FeeSchedules}(${feeSchedule.Id})`;
      await this.api.fetchRequest(endpoint, 'PATCH', feeSchedule, undefined, true).then(async (response) => {
        if (response?.ok || response?.status === 204) {
          this.toast.showSuccess('Fee Schedule and related items successfully updated');
        }
      })
      .catch((error) => {
        console.error('Error updating fee schedule:', error);
        this.toast.showError('There was a problem updating the fee schedule');
      });
    }

    async createFeeSchedule(feeSchedule: Partial<FeeSchedule>, procedureCodesForm: FormGroup<any>, assignedProviders: any[]): Promise<void> {
      this.api.fetchRequest(APIEndpoints.FeeSchedules, 'POST', feeSchedule, undefined, true,)
        .then(async (response) => {
          const createdFeeSchedule = response;
          try {
            await this.addNewFeeScheduleToProviders(createdFeeSchedule.Id!, assignedProviders, []);
            await this.removeProvidersFromFeeSchedule(assignedProviders, []);
            await this.updateProcedureCodesXref(createdFeeSchedule.Id!, procedureCodesForm, []);

          } catch (error) {
            console.error('Error processing fee schedule creation:', error);
            this.toast.showError('Error processing fee schedule creation');
          }
        });
    }

    async updateFeeSchedule(
      feeSchedule: Partial<FeeSchedule>,
      assignedProviders: any[], 
      existingProviders: any[],
      procedureCodesForm: FormGroup<any>, 
      existingXrefs: any[]
    ): Promise<void> {

      await this.addNewFeeScheduleToProviders(feeSchedule.Id!, assignedProviders, existingProviders);
      await this.removeProvidersFromFeeSchedule(assignedProviders, existingProviders);
      await this.updateProcedureCodesXref(feeSchedule.Id!, procedureCodesForm, existingXrefs);
      await this.patchFeeSchedule(feeSchedule);

    }

    getProcedureCodes() {
      this.api.getOdata(APIEndpoints.ProcedureCodes)
        .executeQuery(new Query())
        .then((e: ReturnOption) => {
          this.cptCodesData = e.result as object[];
          // going to be added later
          // this.getMedicareProcedureCodes();
        });
    }

    getMedicareProcedureCodes() {
      this.api.getOdata(APIEndpoints.MedicareCptCodes)
        .executeQuery(new Query())
        .then((e: ReturnOption) => {
          {
            let res = e.result as object[];
            res.forEach(element => {
              let elem = element as any;
              let code = (this.cptCodesData.find(x => (x as any).Id === elem.CptCode) as any).ProcedureCodeName ?? 'Error';
              this.medicareCptCodesData.push(
                {
                  id: elem.Id,
                  code: code,
                  facilityRvu: elem.FacilityRvu,
                  malpracticeRvu: elem.MalpracticeRvu,
                  nonFacilityRvu: elem.NonFacilityRvu,
                  physicianWorkRvu: elem.PhysicianWorkRvu,
                }
              )
            });
          }
        });
    }

    getMedicareLocalities() {
      this.api.getOdata(APIEndpoints.MedicareLocalities)
        .executeQuery(new Query())
        .then((e: ReturnOption) => {
          {
            let res = e.result as object[];
            res.forEach(element => {
              let elem = element as any;
              this.medicareLocalitiesData.push(
                {
                  id: elem.Id,
                  localityName: elem.LocalityName,
                  workRate: elem.WorkRate,
                  practiceRate: elem.PracticeRate,
                  malpracticeRate: elem.MalpracticeRate,
                  conversionFactor: elem.ConversionFactor
                }
              )
            });
          }
        });
    }
    
  }