<!-- Address Form Start -->
<div id="case-file-form" [class]="formClasses.formContainer" #caseFileFormContainer>
    <h2 class="form-title"><fa-icon [icon]="caseFileIcons.form" /> Case File</h2>

    <form [formGroup]="caseFileForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' case-file-section'">
            
            <div [class]="formClasses.group">

                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('FileOpened')?.errors && caseFileForm.get('FileOpened')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileOpened" />
                        <ejs-tooltip *ngIf="caseFileForm.get('FileOpened')?.errors && caseFileForm.get('FileOpened')?.touched" [content]="getErrorMessage('FileOpened')" />
                    </span>
                    <ejs-datepicker 
                        [class]="formClasses.input"
                        placeholder="File Opened" 
                        [format]="'yyyy-MM-dd'"
                        [value]="caseFileForm.get('FileOpened')?.value"
                        (focus)="onDatePickerFocus($event)"
                        (change)="onDateOnlyChange($event, 'FileOpened')" />
                    <ng-container *ngIf="caseFileForm.get('FileOpened')?.invalid && caseFileForm.get('FileOpened')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('FileOpened')}}</span>
                    </ng-container>
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('FileNumber')?.errors && caseFileForm.get('FileNumber')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileNumber" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="FileNumber" placeholder="File Number" [disabled]="caseFileForm.get('FileNumber')?.value !== undefined" />
                    <ng-container *ngIf="caseFileForm.get('FileNumber')?.invalid && caseFileForm.get('FileNumber')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('FileNumber')}}</span>
                    </ng-container>
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('RecordStatusId')?.errors && caseFileForm.get('RecordStatusId')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.recordStatus" />
                        <ejs-tooltip *ngIf="caseFileForm.get('RecordStatusId')?.errors && caseFileForm.get('RecordStatusId')?.touched" [content]="getErrorMessage('RecordStatusId')" />
                    </span>
                    <ejs-dropdownlist formControlName="RecordStatusId" 
                        [placeholder]="'Record Status'" 
                        [class]="formClasses.input"
                        [dataSource]="recordStatuses"
                        [query]="recordStatusesQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('RecordStatusId')?.value"
                        (created)="onRecordStatusCreated($event)" />
                    <ng-container *ngIf="caseFileForm.get('RecordStatusId')?.invalid && caseFileForm.get('RecordStatusId')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('RecordStatusId')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('CaseManager')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.caseManager" />
                    </span>
                    <ejs-dropdownlist formControlName="CaseManager" 
                        [placeholder]="'Case Manager'" 
                        [class]="formClasses.input"
                        [dataSource]="caseManagers"
                        [query]="caseManagersQuery"
                        [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('CaseManager')?.value"
                        (created)="onCaseManagerCreated($event)" />
                </div>

                <div *ngIf="displayFields.includes('MarketManager')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.marketManager" />
                    </span>
                    <ejs-dropdownlist formControlName="MarketManager" 
                        [placeholder]="'Market Manager'" 
                        [class]="formClasses.input"
                        [dataSource]="marketManagers"
                        [query]="marketManagersQuery"
                        [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('MarketManager')?.value"
                        (created)="onMarketManagerCreated($event)" />
                </div>
            
                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('PAndL')?.errors && caseFileForm.get('PAndL')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.pAndL" />
                        <ejs-tooltip *ngIf="caseFileForm.get('PAndL')?.errors && caseFileForm.get('PAndL')?.touched" [content]="getErrorMessage('PAndL')" />
                    </span>
                    <ejs-dropdownlist formControlName="PAndL" 
                        [placeholder]="'P & L *'" 
                        [class]="formClasses.input"
                        [dataSource]="pAndL"
                        [query]="pAndLQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('PAndL')?.value"
                        (created)="onPAndLCreated($event)" />
                    <ng-container *ngIf="caseFileForm.get('PAndL')?.invalid && caseFileForm.get('PAndL')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('PAndL')}}</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('ReferringPhysician')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.referringPhysician" />
                    </span>
                    <ejs-dropdownlist formControlName="ReferringPhysician" 
                        [placeholder]="'Referring Physician'" 
                        [class]="formClasses.input"
                        [dataSource]="referringPhysicians"
                        [query]="referringPhysiciansQuery"
                        [fields]="{ text: 'ContactName', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('ReferringPhysician')?.value"
                        (created)="onReferringPhysicianCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('ReferralSource')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.referralSource" />
                    </span>
                    <ejs-dropdownlist formControlName="ReferralSource" 
                        [placeholder]="'Referral Source'" 
                        [class]="formClasses.input"
                        [dataSource]="referralSources"
                        [query]="referralSourcesQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('ReferralSource')?.value"
                        (created)="onReferralSourceCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('InitialTimeFrame')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.initialTimeFrame" />
                    </span>
                    <ejs-dropdownlist formControlName="InitialTimeFrame" 
                        [placeholder]="'Initial Time Frame'" 
                        [class]="formClasses.input"
                        [dataSource]="initialTimeFrames"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('InitialTimeFrame')?.value"
                        (created)="onInitialTimeFrameCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('Statuser')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.statuser" />
                    </span>
                    <ejs-dropdownlist formControlName="Statuser" 
                        [placeholder]="'Statuser'" 
                        [class]="formClasses.input"
                        [dataSource]="statusers"
                        [query]="statusersQuery"
                        [fields]="{ text: 'ContactName', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('Statuser')?.value"
                        (created)="onStatuserCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('ResponsibleParty')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.responsibleParty" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="ResponsibleParty" placeholder="Responsible Party" />
                </div>
            
                <div *ngIf="displayFields.includes('CaseTypeId')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.caseType" />
                    </span>
                    <ejs-dropdownlist formControlName="CaseTypeId" 
                        [placeholder]="'Case Type'" 
                        [class]="formClasses.input"
                        [dataSource]="caseTypes"
                        [query]="caseTypesQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('CaseTypeId')?.value"
                        (created)="onCaseTypeCreated($event)" />
                </div>
            
                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('DateOfLoss')?.errors && caseFileForm.get('DateOfLoss')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.dateOfLoss" />
                        <ejs-tooltip *ngIf="caseFileForm.get('DateOfLoss')?.errors && caseFileForm.get('DateOfLoss')?.touched" [content]="getErrorMessage('DateOfLoss')" />
                    </span>
                    <ejs-datepicker formControlName="DateOfLoss"
                        [class]="formClasses.input"  
                        [placeholder]="'Date Of Loss'"
                        [format]="'yyyy-MM-dd'"
                        [value]="caseFileForm.get('DateOfLoss')?.value"
                        (focus)="onDatePickerFocus($event)"
                        (change)="onDateOnlyChange($event, 'DateOfLoss')" />
                    <ng-container *ngIf="caseFileForm.get('DateOfLoss')?.invalid && caseFileForm.get('DateOfLoss')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('DateOfLoss')}}</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('FileSecuredBy')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileSecuredBy" />
                    </span>
                    <ejs-dropdownlist formControlName="FileSecuredBy" 
                        [placeholder]="'File Secured By'" 
                        [class]="formClasses.input"
                        [dataSource]="users"
                        [query]="usersQuery"
                        [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('FileSecuredBy')?.value"
                        (created)="onFileSecuredByCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('FileGroupId')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileGroupId" />
                    </span>
                    <ejs-dropdownlist formControlName="FileGroupId" 
                        [placeholder]="'File Group'" 
                        [class]="formClasses.input"
                        [dataSource]="fileGroups"
                        [query]="fileGroupsQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('FileGroupId')?.value"
                        (created)="onFileGroupCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('CompanionCases')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.companionCases" />
                    </span>
                    <ejs-multiselect formControlName="CompanionCases" 
                        [placeholder]="'Companion Cases'" 
                        [class]="formClasses.input"
                        [dataSource]="companionCases"
                        [query]="companionCasesQuery"
                        [fields]="{ text: 'FileNumber', value: 'FileNumber', Id: 'Id' }"
                        [value]="caseFileForm.get('CompanionCases')?.value"
                        (created)="onCompanionCasesCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('ExcludeFromBalanceDue')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.excludeFromBalanceDue" />
                    </span>
                    <ejs-checkbox formControlName="ExcludeFromBalanceDue" label="Exclude From Balance Due?" [checked]="caseFileForm.get('ExcludeFromBalanceDue')?.value" />
                </div>
            
                <div *ngIf="displayFields.includes('IsSurgical')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.isSurgical" />
                    </span>
                    <ejs-checkbox formControlName="IsSurgical" label="Surgical?" [checked]="caseFileForm.get('IsSurgical')?.value" />
                </div>
            
                <div *ngIf="displayFields.includes('InTreatment')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.inTreatment" />
                    </span>
                    <ejs-checkbox formControlName="InTreatment" label="In Treatment?" [checked]="caseFileForm.get('InTreatment')?.value" />
                </div>
            
                <div *ngIf="displayFields.includes('ClientHasCheckpointLimitations')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.clientHasCheckpointLimitations" />
                    </span>
                    <ejs-checkbox formControlName="ClientHasCheckpointLimitations" label="Client Has Checkpoint Limitations?" [checked]="caseFileForm.get('ClientHasCheckpointLimitations')?.value" />
                </div>
            
                <div *ngIf="displayFields.includes('Comments')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.comments" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Comments" placeholder="Comments" multiline="true" />
                </div>

            </div>
    
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' patient-section w-100'">
            <patient-form-component #patientFormComp [patient]="patientData" />
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' law-firm-section'">
            <h2 class="form-title"><fa-icon [icon]="caseFileIcons.form" /> Law Firm</h2>
            <div [class]="formClasses.group">
                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('LawFirmId')?.errors && caseFileForm.get('LawFirmId')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.lawFirmId" />
                        <ejs-tooltip *ngIf="caseFileForm.get('LawFirmId')?.errors && caseFileForm.get('LawFirmId')?.touched" [content]="getErrorMessage('LawFirmId')" />
                    </span>
                    <ejs-dropdownlist formControlName="LawFirmId" 
                        [placeholder]="'Law Firm'"
                        [class]="formClasses.input"
                        [dataSource]="lawFirms"
                        [query]="lawFirmsQuery"
                        [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('LawFirmId')?.value"
                        (created)="onLawFirmCreated($event)" />
                    <ng-container *ngIf="caseFileForm.get('LawFirmId')?.invalid && caseFileForm.get('LawFirmId')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('LawFirmId')}}</span>
                    </ng-container>
                </div>
        
                <div *ngIf="displayFields.includes('LawFirmContact')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.lawFirmContact" />
                    </span>
                    <ejs-dropdownlist formControlName="LawFirmContact" 
                        [placeholder]="'Law Firm Contact'" 
                        [class]="formClasses.input"
                        [dataSource]="lawFirmContacts"
                        [query]="lawFirmContactsQuery"
                        [fields]="{ text: 'ContactName', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('LawFirmContact')?.value"
                        (created)="onLawFirmContactCreated($event)"
                        (beforeOpen)="beforeLawFirmContactOpened($event)" />
                </div>
        
                <div *ngIf="displayFields.includes('Attorney')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.attorney" />
                    </span>
                    <ejs-dropdownlist formControlName="Attorney" 
                        [placeholder]="'Attorney'" 
                        [class]="formClasses.input"
                        [dataSource]="attorneys"
                        [query]="attorneysQuery"
                        [fields]="{ text: 'ContactName', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('Attorney')?.value"
                        (created)="onAttorneyCreated($event)" />
                </div>
        
                <div *ngIf="displayFields.includes('LawFirmFileNumber')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.lawFirmFileNumber" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="LawFirmFileNumber" placeholder="Law Firm File #" />
                </div>
        
                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="caseFileForm.get('StatuteOfLimitations')?.errors && caseFileForm.get('StatuteOfLimitations')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.statuteOfLimitations" />
                    </span>
                    <ejs-datepicker formControlName="StatuteOfLimitations"
                        [class]="formClasses.input"  
                        [placeholder]="'Statute Of Limitations'" 
                        [format]="'yyyy-MM-dd'"
                        [value]="caseFileForm.get('StatuteOfLimitations')?.value"
                        (focus)="onDatePickerFocus($event)"
                        (change)="onDateOnlyChange($event, 'StatuteOfLimitations')" />
                    <ng-container *ngIf="caseFileForm.get('StatuteOfLimitations')?.invalid && caseFileForm.get('StatuteOfLimitations')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('StatuteOfLimitations')}}</span>
                    </ng-container>
                </div>
            </div>
        </section>
        
        <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
            </div>
        </section>

    </form>

</div>
<!-- Address Form End -->