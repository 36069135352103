import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridAllModule, AggregateService, GroupService, SortService, EditService, ToolbarService, PageService, FilterService, DetailRowService, ColumnChooserService, ForeignKeyService, ExcelExportService, PdfExportService } from '@syncfusion/ej2-angular-grids';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { TextAreaAllModule, TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { TabCardComponent } from "@components/file-hub/file-hub-tabs/tab-card/tab-card.component";
@NgModule({
  imports: [
    CommonModule,
    GridAllModule,
    FontAwesomeModule,
    DropDownListAllModule,
    TextAreaAllModule,
    TextBoxAllModule,
    CheckBoxAllModule,
    TabCardComponent,
    DialogAllModule
],
  declarations: [
    GridTemplateComponent
  ],
  providers: [
    AggregateService,
    GroupService,
    SortService,
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    DetailRowService,
    ColumnChooserService,
    ForeignKeyService,
    ExcelExportService,
    PdfExportService
  ],
  exports: [
    GridTemplateComponent
  ]
})
export class GridTemplateModule {}