<div id="remittance-grid">
    <grid-template #remittanceGridComponent [settings]="remittanceGridSettings" [name]="'Invoice Processing'" [useRoundedEdges]="true" />
    <ejs-dialog #bulkEditRemittancesDialog 
        header="Bulk Edit Remittances" 
        [isModal]="true" 
        [showCloseIcon]='true'
        [visible]="false"
        [buttons]="dialogButtons">
        <bulk-edit-remittances-form #bulkEditRemittancesForm [remittances]="remittancesSignal" />
    </ejs-dialog>
</div>