<div id="injury-tab">
    <tab-card [heading]="'Injury'" [loadingContent]="loadingIntake">
        <ng-container *ngIf="caseFileId">
            <casefile-intake-form 
                [caseFileId]="caseFileId"
                [existingIntake]="existingIntake()"
                [existingInsurances]="existingInsurances()"
                [existingAccidentDetails]="existingAccidentDetails()"
                [existingInjuries]="existingInjuries()"
            />
        </ng-container>
    </tab-card>
</div>  