import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TabCardComponent } from "../../../../file-hub/file-hub-tabs/tab-card/tab-card.component";
import { GridTemplateModule } from '@root/src/app/shared/modules/grid-template.module';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { RichTextEditorComponent } from '../../../../ui/custom-rich-text-editor/rich-text-editor.component';
import { SpecialInstructionsService } from './special-instructions.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';


@Component({
  selector: 'app-special-instructions-tab',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TabCardComponent,
    DatePickerModule,
    ButtonModule,
    GridTemplateModule,
    RichTextEditorComponent,
  ],
  templateUrl: './special-instructions-tab.component.html',
  styleUrls: ['./special-instructions-tab.component.scss']
})
export class SpecialInstructionsTabComponent {
  specialInstructionsContent: string = '';
  isSaving: boolean = false;
  @Input() entityType: APIEndpoints;
  @Input() entityId!: number;
  @Input() payload: any;

  constructor(
    private specialInstructionsService: SpecialInstructionsService,
    private toast: ToastMessageService,
  ) {}

  ngOnInit() {
    this.specialInstructionsContent = this.payload.Notes;
  }
  

  saveSpecialInstructions() {
    this.isSaving = true;

    // Filter out lowercase keys from payload
    const filteredPayload = Object.fromEntries(
      Object.entries(this.payload).filter(([key]) => /^[A-Z]/.test(key))
    );

    const updatedPayload = {
      ...filteredPayload,
      Notes: this.specialInstructionsContent
    };

    this.specialInstructionsService
    .saveInstructions(this.entityId, updatedPayload, this.entityType)
    .then((response) => {
      if (response?.ok) {
        this.toast.showSuccess(`${this.entityType} special instructions successfully updated`);
      } else {
        this.toast.showError('There was a problem saving the special instructions');
      }
    })
    .catch((error) => {
      console.error(error);
      this.toast.showError('There was a problem saving the special instructions');
    })
    .finally(() => {
      this.isSaving = false;
    });
  }

}