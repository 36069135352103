// Angular
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';


// 3rd Party
import { CommandClickEventArgs, GridModel } from '@syncfusion/ej2-grids';
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Patient } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';

// Modules
import { GridTemplateModule } from '@modules/grid-template.module';

// Components
import { GridTemplateComponent } from '@components/grids/grid-template/grid-template.component';

@Component({
  selector: 'patients-grid',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    GridTemplateModule,
    DialogAllModule
    
  ],
  templateUrl: './patients-grid.component.html',
  styleUrl: './patients-grid.component.scss'
})
export class PatientsGridComponent {

  constructor(
    private api: ApiService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('patientDialog') patientDialog!: DialogComponent;
  @ViewChild('patientsGridComponent') patientsGridComponent!: GridTemplateComponent;
  APIEndpoints = APIEndpoints;
  patients: GridModel;
  patientsGrid: GridTemplateComponent;
  patientForm: WritableSignal<any> = signal(undefined);
  patientEndpoint: string;

  ngOnInit() {
    this.patientForm = signal(this.fb.group({
      Id: [''],
      Firstname: [''],
      Lastname: [''],
      Dob: [''],
      Gender: [''],
      Language: [''],
      Email: [''],
      Minor: [false]
    }));
    this.patientEndpoint = `${APIEndpoints.Patients}(${this.patientForm().value.Id})`;
    this.patients = {
      dataSource: this.api.getOdata(APIEndpoints.Patients),
      query: new Query().expand('Address($expand=StateNavigation)'),
      columns: [
        { field: 'Id' },
        { field: 'Firstname', headerText: 'Patient Name', template: this.nameTemplate  },
        { field: 'Address.Address1', headerText: 'Address' },
        { field: 'Address.City', headerText: 'City' },
        { field: 'Address.StateNavigation.Name', headerText: 'State' },
        { field: 'Address.Zip', headerText: 'Zip' },
        { field: 'Dob', headerText: 'Date Of Birth' },
        { field: 'Gender' },
        { field: 'Language' },
        { field: 'Email' },
        { field: 'Minor' },
        { type: 'commands', headerText: 'Actions' }
      ]
    }
  }

  onPatientNameClick(data: any) {
    this.router.navigate([`/patient-detail/${data.Id}`]);
  }
}
