<div id="settings-tab">

    <tab-card [heading]="'Tab Settings'">
        <ng-container *ngFor="let tab of fileHub.tabs; let $index = index">
            <li *ngIf="tab.HeaderText !== 'Settings' && tab.HeaderText !== 'Home'">
                <label>{{ tab.HeaderText }}</label>
            </li>
        </ng-container>
    </tab-card>
    
</div>