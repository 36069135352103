// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBuilding, faCity, faHouseUser, faHashtag, faBuildingUser, faFlagUsa, faTableList, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Type & Interface setup
type AddressFormComponentControls = {
  [K in keyof Address]: FormControl<Address[K] | null>;
};

export type AddressFormResult = 'success' | 'warning' | 'error' | 'no-change' | undefined;

@Component({
  selector: 'address-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule
  ],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss'
})
export class AddressFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService
  ) { 

     effect((onCleanup) => {
      const { currentValue } = this.formState();
      if (currentValue) this.addressForm.patchValue(currentValue, { emitEvent: false });
      this.loadingForm = false;

      // Cleanup previous subscriptions
      onCleanup(() => this.addressForm.reset());
    });
  }

  // Decorator variabls
  @Input() address!: Signal<Address | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() title: string = 'Address';
  @Input() displayFields: string[] = ['Address1', 'Address2', 'City', 'State', 'Zip', 'AddressType'];
  @Input() displayButtons: boolean = true;
  @Input() displayTitle: boolean = true;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formResult = new EventEmitter<AddressFormResult>();

  // Form state
  protected readonly formState = computed(() => {
    const currentAddress = this.address();
    return {
      isValid: this.addressForm.valid,
      hasChanges: currentAddress !== this.addressForm.value,
      currentValue: currentAddress
    };
  });

  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.addressForm.controls).forEach(key => {
      const control = this.addressForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  addressHTMLElement: Element | null = null;
  addressTypes: any;
  addressTypesQuery: Query;
  states: any;
  statesQuery: Query;
  addressForm = new FormGroup({
    Id: new FormControl<Address['Id']>(undefined),
    Address1: new FormControl<Address['Address1']>(undefined, [Validators.required]),
    Address2: new FormControl<Address['Address2']>(undefined),
    AddressType: new FormControl<Address['AddressType']>(undefined),
    City: new FormControl<Address['City']>(undefined),
    State: new FormControl<Address['State']>(undefined),
    Zip: new FormControl<Address['Zip']>(undefined, [Validators.required]),
    County: new FormControl<Address['County']>(undefined)
  });
  addressIcons = {
    form: faMapLocationDot,
    address1: faHouseUser,
    address2: faBuildingUser,
    addressType: faTableList,
    city: faCity,
    state: faFlagUsa,
    zip: faHashtag,
    county: faBuilding,
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions'
  };

  ngAfterViewInit() {
    this.addressHTMLElement = document.querySelector('address-form');
    this.updateWidth(this.addressHTMLElement?.clientWidth || window.innerWidth);
    setTimeout(() => this.watchInputElements(), 500);
  }

  // On form submit
  async onSubmit(): Promise<any> {
    let formResult: AddressFormResult;
    this.markAllFieldsAsTouched();
    const submitType = this.submitType ? this.submitType : this.addressForm.get('Id')?.value ? 'PATCH' : 'POST';
    const endpoint = this.addressForm.get('Id')?.value ? `${APIEndpoints.Addresses}/${this.addressForm.get('Id')?.value}` : `${APIEndpoints.Addresses}`;

    // Check for valid form first
    if (this.addressForm.invalid) {
      formResult = 'error';
      this.loadingForm = false;
      this.formResult.emit(formResult);
      return formResult;
    }

    // Check if form has changes
    if (this.addressForm.pristine) {
      formResult = 'no-change';
      this.loadingForm = false;
      this.formResult.emit(formResult);
      return formResult;
    }

    return await this.formCRUD.submitForm(this.addressForm, `odata${endpoint}`, submitType);
  }

  // Remove null/undefined values from form
  private cleanFormValues<T extends object>(formValue: T): Partial<T> {
    const cleanedValues: Partial<T> = {};
    Object.entries(formValue).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        cleanedValues[key as keyof T] = value;
      }
    });
    return cleanedValues;
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.addressForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
    }  else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.addressHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.addressForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Get Address Types
  async onAddressTypesCreated(args: any) {
    this.addressTypes = this.api.getOdata(APIEndpoints.AddressTypes);
    this.addressTypesQuery = new Query().select('Id,Description');
  }

  // Get States
  async onStatesCreated(args: any) {
    this.states = this.api.getOdata(APIEndpoints.States);
    this.statesQuery = new Query().select('Id,Code,Name,StatuteOfLimitationsOffset');
  }
}