import { Component, Input, ViewChild, viewChild } from '@angular/core';
import { DropdownType } from '@models/components/providers-grid.model';
import { ApiService } from '@services/api/api.service';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown-multi',
  standalone: true,
  imports: [MultiSelectModule, ReactiveFormsModule],
  templateUrl: './dropdown-multi.component.html',
  styleUrl: './dropdown-multi.component.scss'
})
export class DropdownMultiComponent {
  constructor(private api: ApiService) { }
  //Textbox 
  @Input() id = "Id"
  @Input() placeholder: string = "";
  @Input() name = "";
  @Input() public form: FormGroup;

  //Textbox data
  @Input() endpoint: string;
  @Input() idName: string = "Id";
  @Input() descriptionName: string = "Description";
  @Input() allowCustomValue: boolean = false;
  @ViewChild('Select') select: MultiSelectModule;
  @Input() defaultValue: string;

  //Dropdown float label type
  @Input() floatLabelType: string = "Auto";

  source: DropdownType[];
  fields: object = { text: 'description', value: 'id' };

  ngOnInit(): void {
    if (this.endpoint != null) {
      this.source = this.getDropdown(this.endpoint, this.idName, this.descriptionName)
    }
  }

  getDropdown(endpoint: string, idName: string, descriptionName: string): DropdownType[] {
    let result: DropdownType[] = [];
    this.api.getOdata(endpoint).executeQuery(new Query())
      .then((e: ReturnOption) => {
        (e.result as object[]).forEach((element) => {
          let res = element as any
          let newTable: DropdownType = {
            id: res[idName],
            description: res[descriptionName],
          }
          result.push(newTable);
        });
      });
    return result;
  }

  onChange() {

  }
}
