// Angular
import {
  Component,
  OnInit,
  WritableSignal,
  signal,
  ViewChild,
  ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager, Query, ReturnOption, Predicate } from '@syncfusion/ej2-data';
import { GridModel, RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ProcedureCode } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AddProcedureCodeFormComponent } from '../../components/forms/add-forms/add-procedure-code-form/add-procedure-code-form.component';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';
import { EditProcedureCodeFormComponent } from '@components/forms/edit-procedure-code/edit-procedure-code-form.component';

@Component({
  selector: 'app-procedure-codes',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateModule,
    AddProcedureCodeFormComponent,
    EditProcedureCodeFormComponent,
    LoadingModule
  ],
  templateUrl: './procedure-codes.component.html',
  styleUrl: './procedure-codes.component.scss'
})
export class ProcedureCodesComponent {

  constructor(
    public globals: GlobalsService,
    private api: ApiService,
    private cdr: ChangeDetectorRef
  ) {}

  // Signals
  loadingData: WritableSignal<boolean> = signal(true);

  // Grid variables
  @ViewChild('procedureCodesGrid') procedureCodesGrid: GridTemplateComponent;
  procedureCodesGridSettings: GridModel;
  defaultToolbar: any[];
  isAddProcCodeDialogVisible: boolean = false;
  isEditProcCodeDialogVisible: boolean = false;
  selectedProcedureCode: any;

  // Dialog variables
  @ViewChild('addProcCodeDialog', { static: true}) addProcCodeDialog: DialogComponent;
  @ViewChild('addFeeScheduleForm') addFeeScheduleForm: any;
  modalityTypes: DataManager;
  
  ngOnInit(): void {
    const addScheduleBtn = { text: 'Add Procedure Codes', tooltipText: 'Add Codes', id: 'AddCodes' };
    this.getData().then(() => {
      this.procedureCodesGridSettings = {
        dataSource: this.api.getOdata(APIEndpoints.ProcedureCodes),
        query: new Query().expand('ModalityType'),
        allowFiltering: true,
        height: '100%',
        pageSettings: { pageSize: 18 },
        editSettings: { allowEditing: false, allowAdding: true},
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'ProcedureCodeName', headerText: 'Procedure Code' },
          { field: 'ModalityTypeId', headerText: 'Modality Type', foreignKeyField: 'Id', foreignKeyValue: 'Description', dataSource: this.modalityTypes },
          { field: 'Description'},
          { field: 'CreatedAt', headerText: 'Created At', format: 'yMd', type: 'date' },
          { 
            headerText: 'Actions',
            width: 80,
            commands: [
              { 
                type: 'Edit',
                buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
              }
            ]
          }
        ],
        commandClick: (args: any) => {
          if (args.commandColumn.type === 'Edit') {
            this.handleEditClick(args);
          }
        },
        created: () => this.onCreated(),
        rowSelected: ($event: RowSelectEventArgs) => this.feeSchedulesGridRowSelected($event),
        toolbarClick: ($event: Event) => { return this.onToolbarClick($event); },
      };
    });
  }

  onCreated() {
    this.defaultToolbar = this.procedureCodesGrid.settings.toolbar as any[];
    this.procedureCodesGrid.grid.toolbar = this.defaultToolbar;
    this.procedureCodesGrid.insertToolbarItem({ text: 'Add Multiple', id: 'AddCodes' });
  }

  async getData() {
    const response = await this.api
      .getOdata(APIEndpoints.ModalityTypes)
      .executeQuery(new Query().where('Active', 'equal', true))
      .then((response: any) => response.result);

    this.modalityTypes = response;
    this.loadingData.set(false);
  }

  // Update grids for custom editing
  feeSchedulesGridRowSelected (rowArgs: RowSelectEventArgs) {
    const rowData = rowArgs?.data as any;
  }

  // Add logic for custom Add Fee Schedule button
  onToolbarClick(args: any): void {

    if (args.item.text === 'Add') {
      args.cancel = true;
      this.isAddProcCodeDialogVisible = true;
      if (this.addProcCodeDialog) this.addProcCodeDialog.show();
    } 
  }

  // Return total items as string to display in feeSchedulesGrid
  countFieldItems(field: string, data: object): string[] {

    // Ensure data exists
    if ((data as any)[field]) {
      const items = (data as any)[field];      
      return [items.length.toString()];
    } else {
      return ['0'];
    }
  }

  beforeOpenAddProcCodeDialog(args: any) {
    args.maxHeight = '85vh';
    args.width = '50%';
  }

  handleEditClick(args: any) {
    this.selectedProcedureCode = args.rowData;
    this.isEditProcCodeDialogVisible = true;
    return false;
  }

  beforeOpenEditProcCodeDialog(args: any) {
    args.maxHeight = '85vh';
    args.width = '50%';
  }

  refreshGrid() {
    
    setTimeout(() => {
      this.cdr.detectChanges();
      
      if (this.procedureCodesGrid?.grid) {
        const query = this.procedureCodesGrid.grid.query;
        this.procedureCodesGrid.grid.query = new Query().expand('ModalityType');
        this.procedureCodesGrid.grid.refresh();
      } else {
        this.getData().then(() => {
          this.procedureCodesGridSettings = {
            ...this.procedureCodesGridSettings,
            dataSource: this.api.getOdata(APIEndpoints.ProcedureCodes)
          };
        });
      }
    }, 100);
  }
}
