import { Component, Input, Output, EventEmitter } from '@angular/core';
import { 
  HtmlEditorService, 
  ImageService, 
  LinkService, 
  RichTextEditorAllModule, 
  ToolbarService, 
} from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-rich-text-editor',
  standalone: true,
  imports: [RichTextEditorAllModule],
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
  ]
})
export class RichTextEditorComponent {
  @Input() content: string = '';
  // Allows the user to provide custom toolbar settings;
  @Input() customToolbarSettings: object | null = null;
  @Output() contentChange = new EventEmitter<string>();

  defaultToolbarSettings: object = {
    type: 'MultiRow',
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|', 'ClearFormat', 'Print', 'EmojiPicker',
      '|', 'Undo', 'Redo'
    ]
  };

    // If the user does not provide customToolbarSettings, default to using default
    get editorTools() {
      return this.customToolbarSettings || this.defaultToolbarSettings;
    }

  onContentChange(event: any) {
    this.content = event.value; 
    this.contentChange.emit(this.content);
    console.log('Content changed:', this.content);
  }
}