<div class="work-items-monday">
  <div class="board-header" *ngIf="boardName">
    <h2>{{boardName}}</h2>
    <div class="column-settings">
      <button type="button" [matMenuTriggerFor]="columnMenu">
        <i class="fas fa-columns"></i> Columns
      </button>
      <mat-menu #columnMenu="matMenu">
        <div *ngFor="let col of columns" class="column-toggle">
          <mat-checkbox [(ngModel)]="col.visible" (change)="toggleColumnVisibility(col)">
            {{col.header}}
          </mat-checkbox>
        </div>
      </mat-menu>
    </div>
  </div>
  <div class="tasks-table">
    <div class="table-header" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="onColumnDrop($event)">
      <div *ngFor="let col of visibleColumns" 
           class="header-cell" 
           [style.width.px]="col.width" 
           cdkDrag 
           [cdkDragDisabled]="!!resizingColumn">
        <div class="header-content" 
             (click)="sort(col.field)"
             cdkDragHandle>
          {{col.header}}
          <span class="sort-indicator" *ngIf="sortState.column === col.field">
            {{ sortState.direction === 'asc' ? '↑' : '↓' }}
          </span>
        </div>
        <div class="resize-handle" 
             (mousedown)="startResize($event, col)" 
             (click)="$event.stopPropagation()"></div>
      </div>
    </div>
    <div *ngFor="let workItem of sortedWorkItems" class="table-row">
      <div *ngFor="let col of visibleColumns" 
           [class]="'col-' + col.field" 
           [style.width.px]="col.width"
           (click)="startEditing(workItem, col.field)">
        <ng-container [ngSwitch]="col.field">
          <!-- Status Column -->
          <ng-container *ngSwitchCase="'status'">
            <span *ngIf="workItem['status'] && !workItem.editing['status']" 
                  [class]="'status ' + workItem['status'].toLowerCase().replace(' ', '-')">
              {{workItem['status']}}
            </span>
            <select *ngIf="workItem.editing['status']" 
                    [(ngModel)]="workItem['status']"
                    (change)="saveField(workItem, 'status')"
                    (blur)="saveField(workItem, 'status')">
              <option value="Working on it">Working on it</option>
              <option value="Done">Done</option>
              <option value="Stuck">Stuck</option>
              <option value="Not Started">Not Started</option>
            </select>
          </ng-container>
          
          <!-- Priority Column -->
          <ng-container *ngSwitchCase="'priority'">
            <span *ngIf="!workItem.editing['priority']" 
                  [class]="'priority ' + (workItem['priority']?.toLowerCase() || '')"
                  (click)="startEditing(workItem, 'priority')">
              {{workItem['priority']}}
            </span>
            <select *ngIf="workItem.editing['priority']" 
                    [(ngModel)]="workItem['priority']"
                    (change)="saveField(workItem, 'priority')"
                    (blur)="saveField(workItem, 'priority')"
                    (keyup.enter)="saveField(workItem, 'priority')"
                    (keyup.escape)="cancelEdit(workItem, 'priority')">
              <option *ngFor="let label of priorityLabels" [value]="label">{{label}}</option>
            </select>
          </ng-container>
          
          <!-- Case File Column -->
          <ng-container *ngSwitchCase="'caseFile'">
            <span *ngIf="!workItem.editing['caseFile']">{{workItem['caseFile']}}</span>
            <select *ngIf="workItem.editing['caseFile']" 
                    [(ngModel)]="workItem['caseFile']"
                    (change)="saveField(workItem, 'caseFile')"
                    (blur)="saveField(workItem, 'caseFile')">
              <option [value]="null">None</option>
              <option *ngFor="let cf of caseFiles" [value]="cf.id">
                {{cf.caseFileNumber}} - {{cf.patientName}}
              </option>
            </select>
          </ng-container>
          
          <!-- Due Date Column -->
          <ng-container *ngSwitchCase="'dueDate'">
            <span *ngIf="!workItem.editing['dueDate']">{{workItem['dueDate']}}</span>
            <input *ngIf="workItem.editing['dueDate']" 
                   type="date"
                   [(ngModel)]="workItem['dueDate']"
                   (change)="saveField(workItem, 'dueDate')"
                   (blur)="saveField(workItem, 'dueDate')">
          </ng-container>
          
          <!-- Notes Column -->
          <ng-container *ngSwitchCase="'notes'">
            <span *ngIf="!workItem.editing['notes']">{{workItem['notes']}}</span>
            <textarea *ngIf="workItem.editing['notes']" 
                      [(ngModel)]="workItem['notes']"
                      (keyup.enter)="saveField(workItem, 'notes')"
                      (blur)="saveField(workItem, 'notes')"
                      (keyup.escape)="cancelEdit(workItem, 'notes')"></textarea>
          </ng-container>
          
          <!-- Default Column (text input) -->
          <ng-container *ngSwitchDefault>
            <span *ngIf="!workItem.editing[col.field]">{{workItem[col.field]}}</span>
            <input *ngIf="workItem.editing[col.field]" 
                   [(ngModel)]="workItem[col.field]"
                   (keyup.enter)="saveField(workItem, col.field)"
                   (blur)="saveField(workItem, col.field)"
                   (keyup.escape)="cancelEdit(workItem, col.field)">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div> 