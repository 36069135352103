<!-- Modal to open on custom add function -->
<ejs-dialog #Modal width="1000px" header="Bulk Edit Providers" [isModal]="true" [showCloseIcon]='true' [visible]="false"
    (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <form [formGroup]="editForm" class="d-flex flex-column search-form">
            <div class="row">
                <div class="col-6">
                    <ejs-numerictextbox id="reimbursement_rate" formControlName="ReimbursementRate"
                        placeholder="Reimbursement Rate" format="n2" decimals="2" min="0"></ejs-numerictextbox>
                </div>
                <div class="col-6">
                    <ejs-datepicker id="reimbursement_rate_effective_date" formControlName="ReimbursementRateEffectiveDate"
                        placeholder="Reimbursement Rate Effective Date" format="MM/dd/yyyy"></ejs-datepicker>
                </div>
                <div class="col-6">
                    <app-dropdown-single id="provider_type" name="ProviderType" placeholder="Provider Type" [endpoint]=providerTypes
                        [form]="editForm"></app-dropdown-single>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <ejs-checkbox id="signed_purchase_agreement" formControlName="SignedPurchaseAgreement" 
                            label="Signed Purchase Agreement"></ejs-checkbox>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <ejs-checkbox id="signed_hipaa" formControlName="SignedHipaa" 
                            label="Signed HIPAA"></ejs-checkbox>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-check">
                        <ejs-checkbox id="signed_w9" formControlName="SignedW9" 
                            label="Signed W9"></ejs-checkbox>
                    </div>
                </div>
                <div class="col-10"></div>
                <div class="col-2 form-submit">
                    <button ejs-button cssClass="e-primary" (click)="onSubmit()" type="button">Submit</button>
                </div>
            </div>
        </form>
    </ng-template>
</ejs-dialog> 