// Angular
import { Component, Output, EventEmitter, Input, ViewChild, WritableSignal, signal } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Pary
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';

export interface BulkEditDeposits {
  DepositDate?: string,
  DepositAmount?: number,
  DepositToAccount?: number,
  FinalCheck?: boolean,
  Notes?: string
}

@Component({
  selector: 'bulk-edit-deposits-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerModule,
    TextBoxModule
  ],
  templateUrl: './bulk-edit-deposits.component.html',
  styleUrl: './bulk-edit-deposits.component.scss'
})
export class BulkEditDepositsForm {

  @Input() endpoint: string;
  @Output() formValues = new EventEmitter<any>();

  bulkEditDepositsForm: WritableSignal<FormGroup> = signal(new FormGroup({
    DepositDate: new FormControl<BulkEditDeposits['DepositDate'] | null>(undefined),
    Notes: new FormControl<BulkEditDeposits['Notes'] | null>(undefined),
    DepositAmount: new FormControl<BulkEditDeposits['DepositAmount'] | null>(null),
    DepositToAccount: new FormControl<BulkEditDeposits['DepositToAccount'] | null>(null),
    FinalCheck: new FormControl<BulkEditDeposits['FinalCheck'] | null>(null)
  }));

  onSubmit() {
    this.formValues.emit(this.bulkEditDepositsForm().value);
    this.bulkEditDepositsForm().reset();
  }
}
