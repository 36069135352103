// Angular
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

// 3rd Party - Syncfusion
import { GridModel, CommandClickEventArgs } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { LoadingModule } from '@modules/loading.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { toggleActive } from '@grids/helpers';
import { providerForm } from '@root/src/app/shared/constants/form-groups/provider';
import { ProviderFormComponent } from '../../forms/provider-form/provider-form.component';
import { BulkEditProvidersComponent } from '../../forms/bulk-edit-forms/bulk-edit-providers/bulk-edit-providers.component';
import { Provider } from '../../../shared/models/data-contracts';

@Component({
  selector: 'providers-grid',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateModule,
    DialogAllModule,
    LoadingModule,
    AuditLogsComponent,
    ProviderFormComponent,
    BulkEditProvidersComponent
  ],
  templateUrl: './providers-grid.component.html',
  styleUrl: './providers-grid.component.scss'
})
export class ProvidersGridComponent {
  // Class constants
  private readonly dialogMaxHeight = '85vh';
  private readonly dialogWidth = '90vw';

  // Grid related properties
  @ViewChild('providersGrid') providersGrid!: GridTemplateComponent;
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  public providers: GridModel;
  showActive = true;
  providersGridToolbar: any[];

  // Provider form related properties
  @ViewChild('addProviderDialog') addProviderDialog: DialogComponent;
  providerForm: WritableSignal<any> = signal(providerForm);
  addProviderDialogVisibility = false;

  // Bulk edit component reference
  @ViewChild('bulkEditProvidersComponent') bulkEditProvidersComponent: BulkEditProvidersComponent;

  // Audit logs related properties
  @ViewChild('logsDialog') logsDialog: any;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility = false;
  
  // Track selected rows count for toolbar
  private selectedRowCount: number = 0;

  readonly logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  constructor(
    private api: ApiService,
    private auditLogService: AuditLogService,
    private toast: ToastMessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.providers = {
      dataSource: this.api.getOdata(APIEndpoints.Providers),
      query: new Query().where('Active', 'equal', this.showActive),
      editSettings: { allowEditing: false }, 
      toolbar: this.getToolbarItems(),
      columns: [
        { type: 'checkbox' },
        { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
        { field: 'Name', headerText: 'Name', template: this.nameTemplate },
        { field: 'Notes', headerText: 'Notes' },
        { field: 'SpanishSpeaking', headerText: 'Spanish Speaking' },
        { field: 'ReimbursementRate', headerText: 'Reimbursement Rate', template: '<div>${ReimbursementRate}%</div>' },
        { field: 'Active', headerText: 'Active' },
        {
          type: 'commands',
          headerText: 'Actions',
          commands: [
            { type: 'Delete', title: 'Toggle Active', buttonOption: { cssClass: 'e-flat', iconCss: 'e-icons e-circle-check' } },
            { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } }
          ]
        }
      ],
      allowFiltering: true,
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
      rowSelected: () => this.onRowSelected(),
      rowDeselected: () => this.onRowDeselected(),
    };
  }

  private getToolbarItems(): any[] {
    const items = [
      { text: 'Show Inactive', id: 'showActive' },
      { text: 'Add Provider', id: 'addProvider' }
    ];

    // Only add bulk edit button if more than one row is selected
    if (this.selectedRowCount > 1) {
      items.push({ text: 'Bulk Edit', id: 'bulkEditProviders' });
    }

    return items;
  }

  onRowSelected(): void {
    this.selectedRowCount = this.providersGrid.getSelectedRows().length;
    const currentToolbar = this.providersGrid.grid.toolbar || [];
    
    // Remove bulk edit button if it exists
    const toolbarWithoutBulkEdit = currentToolbar.filter((item: any) => item.id !== 'bulkEditProviders');
    
    // Add bulk edit button if more than one row is selected
    if (this.selectedRowCount > 1) {
      toolbarWithoutBulkEdit.push({ text: 'Bulk Edit', id: 'bulkEditProviders' });
    }
    
    this.providersGrid.grid.toolbar = toolbarWithoutBulkEdit;
  }

  onRowDeselected(): void {
    this.selectedRowCount = this.providersGrid.getSelectedRows().length;
    const currentToolbar = this.providersGrid.grid.toolbar || [];
    
    // Remove bulk edit button if it exists
    const toolbarWithoutBulkEdit = currentToolbar.filter((item: any) => item.id !== 'bulkEditProviders');
    
    // Add bulk edit button if more than one row is selected
    if (this.selectedRowCount > 1) {
      toolbarWithoutBulkEdit.push({ text: 'Bulk Edit', id: 'bulkEditProviders' });
    }
    
    this.providersGrid.grid.toolbar = toolbarWithoutBulkEdit;
  }

  async onCommandClick(args: CommandClickEventArgs): Promise<void> {
    const provider = args.rowData as any;

    if (provider?.Id && args.commandColumn?.title === 'Logs') {
      try {
        this.auditLogsLoading = true;
        this.showLogsDialog();
        const logs = await this.auditLogService.getAuditLogs(provider.Id, 'Provider');

        if (!logs) {
          throw new Error('No audit logs returned from service');
        }

        this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(logs);
      } catch (error) {
        this.closeLogsDialog();
        this.toast.showError('Failed to load audit logs. Please try again.');
        console.error('Error loading audit logs:', error);
      } finally {
        this.auditLogsLoading = false;
      }
    } 
  }

  showLogsDialog(): void {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        throw new Error('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog(): void {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = [];
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  closeDialog(): void {
    this.addProviderDialogVisibility = false;
  }

  customToolbarClick(args: any): void {
    console.log('customToolbarClick', args.item.id);
    if (args.item.id === 'showActive') {
      this.showActive = toggleActive(this.providersGrid, args.item.id, this.showActive, new Query());
    }
    if (args.item.id === 'addProvider') {
      this.addProviderDialogVisibility = true;
    }
    if (args.item.id === 'bulkEditProviders') {
      this.getSelected();
    }
  }
  onProviderClicked(data: any): void {
    this.router.navigate(['/provider-detail', data.Id]);
  }

  beforeOpening(args: any): void {
    args.maxHeight = this.dialogMaxHeight;
    args.width = this.dialogWidth;
  }

  getSelected() {
    const selectedRecords = this.providersGrid.getSelectedRows() as Provider[];
    if (selectedRecords && selectedRecords.length > 1) {
      this.bulkEditProvidersComponent.selectedRows = selectedRecords;
      this.bulkEditProvidersComponent.showModal();
    } else {
      this.toast.showWarning('Please select at least two providers to bulk edit.');
    }
  }
}
