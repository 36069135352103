<div id="intakes-grid">

    <div *ngIf="gridSettings">
        <grid-template #intakesGrid [settings]="gridSettings" [name]="'Intake Processing'" (rowDblClick)="onRecordDoubleClick($event)" [useRoundedEdges]="true">
            <ng-template #lawFirmsDrpDwn let-data>
                <ejs-dropdownlist [dataSource]="lawFirms" [fields]="{value: 'Id', text: 'Name'}" (created)="getLawFirmsData()"></ejs-dropdownlist>
            </ng-template>
        </grid-template>
    </div>
    
    <ejs-dialog id="intake-survey-modal" #intakeSurveyDialog
        header="Intake Survey Details"    
        [showCloseIcon]="true"
        [target]="'body'"
        [isModal]="true"
        [closeOnEscape]="true"
        [visible]="false"
        [buttons]="intakeSurveyDialogButtons"
        (close)="intakeSurveyDialog.hide()"
        (beforeOpen)="onBeforeOpen($event)">
        <div class="border">
            <div class="d-flex justify-content-center align-items-center">
                <div *ngIf="intakeSurveyDialogVisibility">
                    <intake-survey-form #intakeSurveyForm [intakeId]="dataObservable$" (values)="getFormResult($event)" />
                </div>
            </div>
        </div>
        <div class="dialog-footer">
            <div class="form-actions">
              <!-- Primary form buttons rendered by [buttons] -->
            </div>
            <div class="next-step" *ngIf="intakeStatus">
              <button ejs-button [isPrimary]="false" 
                      [cssClass]="nextStepButton.buttonModel.cssClass"
                      [iconCss]="nextStepButton.buttonModel.iconCss"
                      (click)="nextStepButton.click($event)">
                {{nextStepButton.buttonModel.content}}
              </button>
            </div>
        </div>
    </ejs-dialog>
    
</div>