<!-- Main container for the add user form -->
<div id="edit-user">
    <!-- Reactive form with submit handler -->
    <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()" class="edit-user-form">
      <!-- Two-column layout container -->
      <div class="form-row">
        <!-- Left column - Primary user information -->
        <div class="form-col">
          <!-- Username field with validation -->
          <div class="form-group required">
            <span class="input-icon" [class.error]="editUserForm.get('userName')?.errors && editUserForm.get('userName')?.touched">
              <ejs-tooltip *ngIf="editUserForm.get('userName')?.errors && editUserForm.get('userName')?.touched" 
                          [content]="getErrorMessage('userName')">
                <i class="fas fa-user"></i>
              </ejs-tooltip>
              <i *ngIf="!(editUserForm.get('userName')?.errors && editUserForm.get('userName')?.touched)" 
                 class="fas fa-user"></i>
            </span>
            <ejs-textbox 
              formControlName="userName"
              [readonly]="true"
              placeholder="Username"
              title="Username cannot be edited">
            </ejs-textbox>
            <div *ngIf="editUserForm.get('userName')?.errors?.['required'] && editUserForm.get('userName')?.touched" class="e-error">
              <span>This field is required.</span>
            </div>
          </div>
          
          <!-- Email field with validation -->
          <div class="form-group required">
            <span class="input-icon" [class.error]="editUserForm.get('email')?.errors && editUserForm.get('email')?.touched">
              <ejs-tooltip *ngIf="editUserForm.get('email')?.errors && editUserForm.get('email')?.touched" 
                          [content]="getErrorMessage('email')">
                <i class="fas fa-envelope"></i>
              </ejs-tooltip>
              <i *ngIf="!(editUserForm.get('email')?.errors && editUserForm.get('email')?.touched)" 
                 class="fas fa-envelope"></i>
            </span>
            <ejs-textbox 
              formControlName="email"
              [readonly]="true"
              placeholder="Email address"
              title="Email cannot be edited">
            </ejs-textbox>
            <div *ngIf="editUserForm.get('email')?.errors?.['required'] && editUserForm.get('email')?.touched" class="e-error">
              <span>This field is required.</span>
            </div>
          </div>
  
          <!-- Phone number field with validation -->
          <div class="form-group required">
            <span class="input-icon" [class.error]="editUserForm.get('phoneNumber')?.errors && editUserForm.get('phoneNumber')?.touched">
              <ejs-tooltip *ngIf="editUserForm.get('phoneNumber')?.errors && editUserForm.get('phoneNumber')?.touched" 
                          [content]="getErrorMessage('phoneNumber')">
                <i class="fas fa-phone"></i>
              </ejs-tooltip>
              <i *ngIf="!(editUserForm.get('phoneNumber')?.errors && editUserForm.get('phoneNumber')?.touched)" 
                 class="fas fa-phone"></i>
            </span>
            <ejs-textbox 
              formControlName="phoneNumber"
              [readonly]="true"
              placeholder="Phone number"
              title="Phone number cannot be edited">
            </ejs-textbox>
            <div *ngIf="editUserForm.get('phoneNumber')?.errors?.['required'] && editUserForm.get('phoneNumber')?.touched" class="e-error">
              <span>This field is required.</span>
            </div>
          </div>
  
          <!-- Full name field with validation -->
          <div class="form-group required">
            <span class="input-icon" [class.error]="editUserForm.get('name')?.errors && editUserForm.get('name')?.touched">
              <ejs-tooltip *ngIf="editUserForm.get('name')?.errors && editUserForm.get('name')?.touched" 
                          [content]="getErrorMessage('name')">
                <i class="fas fa-id-card"></i>
              </ejs-tooltip>
              <i *ngIf="!(editUserForm.get('name')?.errors && editUserForm.get('name')?.touched)" 
                 class="fas fa-id-card"></i>
            </span>
            <ejs-textbox formControlName="name" placeholder="Full name"></ejs-textbox>
            <div *ngIf="editUserForm.get('name')?.errors?.['required'] && editUserForm.get('name')?.touched" class="e-error">
              <span>This field is required.</span>
            </div>
          </div>
  
          <!-- Job title field (optional) -->
          <div class="form-group">
            <span class="input-icon">
              <i class="fas fa-briefcase"></i>
            </span>
            <ejs-textbox formControlName="title" placeholder="Job title"></ejs-textbox>
          </div>
        </div>
  
        <!-- Right column - Secondary user information -->
        <div class="form-col">
          <!-- Replace the role and rights form groups with this container -->
          <div class="security-section">
            <div class="security-header">
              <i class="fas fa-shield-alt"></i>
              <span>Security Settings</span>
            </div>
            
            <!-- Role selection with validation -->
            <div class="form-group required">
              <span class="input-icon" [class.error]="editUserForm.get('roleId')?.errors && editUserForm.get('roleId')?.touched">
                <i class="fas fa-users"></i>
              </span>
              <ejs-multiselect formControlName="roleId" 
                              [dataSource]="rolesData" 
                              [fields]="{ text: 'RoleName', value: 'Id' }" 
                              [showDropDownIcon]="true" 
                              placeholder="Select roles">
              </ejs-multiselect>
              <div *ngIf="editUserForm.get('roleId')?.errors?.['required'] && editUserForm.get('roleId')?.touched" class="e-error">
                <span>This field is required.</span>
              </div>
            </div>
  
            <!-- Rights selection -->
            <div class="form-group">
              <span class="input-icon">
                <i class="fas fa-shield-alt"></i>
              </span>
              <ejs-multiselect formControlName="rightIds" 
                              [dataSource]="rightsData" 
                              [fields]="{ text: 'FriendlyName', value: 'Id' }" 
                              [showDropDownIcon]="true" 
                              placeholder="Additional rights">
              </ejs-multiselect>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Error message display -->
      <div *ngIf="errorMessage" class="error-message">
        <i class="fas fa-exclamation-circle"></i>
        {{ errorMessage }}
      </div>
  
      <!-- Form actions -->
      <div class="form-actions">
        <button ejs-button [isPrimary]="true" type="submit">
          Edit User
        </button>
        <button ejs-button type="button" cssClass="e-outline" (click)="cancel()">
          Cancel
        </button>
      </div>
    </form>
  </div>