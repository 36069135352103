<div #itemView id="item-view" class="item-view">

    <div class="header">
        <ng-container *ngIf="!hasMultiple && openFileData()">
            <h1>{{title()}}</h1>
        </ng-container>

        <ng-container *ngIf="!hasMultiple && openFileData()?.FileRating">
            <div class="file-status">
                <span>File Status: <status [status]="openFileData()?.FileRating"></status></span>
            </div>
        </ng-container>
    </div>

    <div *ngIf="openFileData()">
        <item-render [isEditing]="isEditing" [data]="openFileData()"></item-render>
        <div *ngIf="!openFileData()">Loading...</div>
    </div>

    <ng-container *ngIf="!hasMultiple && isMobile()">
        <bottom-nav [items]="bottomNavItems"></bottom-nav>
    </ng-container>

</div>