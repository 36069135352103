// Angular
import { Component, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// Internal
import { CaseFile } from '@models/data-contracts';
import { BoxComponentsType } from '@models/components/box-ui.model';
import { BoxUiComponent } from '../../../features/box/box-ui/box-ui.component';
import { TabCardComponent } from '@fileHub-tabs/tab-card/tab-card.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GlobalsService } from '@services/globals/globals.service';
import { LoadingModule } from '@modules/loading.module';
import { APIEndpoints } from '@models/api/Endpoints';
import { Query } from '@syncfusion/ej2-data';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@services/file-hub/file-hub.service';

@Component({
  selector: 'documents-tab',
  standalone: true,
  imports: [
    BoxUiComponent,
    TabCardComponent,
    BoxUiComponent,
    LoadingModule,
    CommonModule,
  ],
  templateUrl: './documents-tab.component.html',
  styleUrl: './documents-tab.component.scss'
})
export class DocumentsTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor (
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService,
    private fileHub: FileHubService
  ) {}

  loadingDocs: WritableSignal<boolean> = signal(true);
  boxPrivateFolderId: string;
  contentExplorer: any;

  ngAfterViewInit() {
    this.checkData();
  }

  // Verifies data is available
  async checkData() {
    const activeFileExists: boolean = this.fileHub.caseFile || this.fileHub.caseFileId ? true : false;

    if (!activeFileExists) {
      this.toast.showError(`Error: Case File missing. Unable to display associated Documents.`);
    } else {
      await this.addBoxFolderIdToCaseFile();
      this.setContentExplorer();
      this.loadingDocs.set(false);
    }
  }

  async addBoxFolderIdToCaseFile() {
    const boxPrivateFolderExists = this.fileHub.caseFile ? this.globals.objHasKey(this.fileHub.caseFile, 'BoxPrivateFolder') : false;
    
    if (!boxPrivateFolderExists) {
      const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
      const query = new Query().select('BoxPrivateFolder')
      return await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        const file = res.result[0] as CaseFile;
        this.boxPrivateFolderId = file.BoxPrivateFolder as string;
        this.fileHub.updateCaseFile('BoxPrivateFolder', file.BoxPrivateFolder);
      });
    }
  }

  setContentExplorer() {
    this.contentExplorer = {
      folderId: this.boxPrivateFolderId,
      boxCdnJS: 'https://cdn01.boxcdn.net/platform/elements/20.0.0/en-US/explorer.js',
      boxCdnCss: 'https://cdn01.boxcdn.net/platform/elements/20.0.0/en-US/explorer.css',
      name: BoxComponentsType.ContentExplorer
    };
  }
}
