<div id="scheduler">

  <div class="row">

    <ng-container *ngIf="instanceType === 'Hybrid' || instanceType === undefined">
      <div class="col-12 col-md-2 filters">
        <div class="e-card categories">
    
          <ejs-accordion #scheduleFilter>
            <e-accordionitems>
              <e-accordionitem [expanded]="true" header="Locations">
                <ng-template #content>
                  <scheduler-locations #locations />
                </ng-template>
              </e-accordionitem>
    
              <e-accordionitem [expanded]="true" header="Modality Types">
                <ng-template #content>
                  <scheduler-modalities #modalities />
                </ng-template>
              </e-accordionitem>
            </e-accordionitems>
          </ejs-accordion>
    
        </div>
      </div>
    </ng-container>

    <div [class]="schedulerClass">
      <scheduler-calendar #schedulerCalendar [componentContextId]="'main-appointment-calendar'" [caseFile]="caseFile"/>
    </div>

  </div>
  
</div>