// Organized Angular imports
import { Component, Input, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';

// Third-party imports
import { CommandModel, GridModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';


// Application imports
import { GridTemplateModule } from '@modules/grid-template.module';
import { Contact } from '@root/src/app/shared/models/data-contracts';
import { capitalizeFirstLetter } from '@app/utils';
import { GridTemplateComponent } from '@root/src/app/components/grids/grid-template/grid-template.component';
import { ContactFormComponent } from '@root/src/app/components/forms/contact-form/contact-form.component';

@Component({
  selector: 'contacts-tab',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateModule,
    ContactFormComponent  
  ],
  templateUrl: './contacts-tab.component.html',
  styleUrl: './contacts-tab.component.scss'
})
export class ContactsTabComponent {
  // ViewChild declarations
  @ViewChild('contactsGrid') contactsGrid!: GridTemplateComponent;
  @ViewChild('addContactDialog', { static: true }) addContactDialog!: DialogComponent;
  @ViewChild('editContactDialog', { static: true }) editContactDialog!: DialogComponent;
  @ViewChild(ContactFormComponent) contactFormComponent!: ContactFormComponent;
  selectedAccountId?: number;
  isLawFirm?: boolean;
 
  // Input properties
  @Input() payload: any;
  selectedContact: WritableSignal<Contact | undefined> = signal(undefined);

  // Grid-related properties
  public contactsGridSettings!: GridModel;
 
  readonly contactsGridActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }
  ];
  
  // Dialog-related properties
  addContactDialogVisibility = false;
  editContactDialogVisibility = false;
  editContactDialogHeader = 'Edit Contact';
  editContact?: Contact

  ngOnInit() {
    const contactsData = this.payload?.XrefLawFirmContacts || this.payload?.XrefProviderContacts || [];
    this.selectedAccountId = this.payload?.Id;
    this.isLawFirm = this.payload?.XrefLawFirmContacts ? true : false;
    this.contactsGridSettings = {
      dataSource: contactsData.map((x: { Contact: Contact }) => {
        const phone = x.Contact?.XrefPhoneContacts?.[0]?.Phone?.PhoneNumber;
        const address = x.Contact?.XrefAddressContacts?.[0]?.Address;
        console.log(x)
        return {
          Id: x.Contact.Id,
          ContactType: x.Contact.ContactType,
          ContactName: capitalizeFirstLetter(x.Contact.ContactName ?? ''),
          ContactTitle: x.Contact.ContactTitle,
          ContactEmail: x.Contact.ContactEmail,
          Phone: phone,
          Address: address?.Address1,
          City: address?.City,
          State: address?.StateNavigation?.Name,
          Zip: address?.Zip,
          Notes: x.Contact.Notes,
        };
      }) || [],
      toolbar: [{text: '+ Add Contact', tooltipText: 'Add Contact', id: 'AddContact', align: 'Left'}],
      columns: [
        { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
        { field: 'ContactName', headerText: 'Name' },
        { field: 'ContactTitle', headerText: 'Title' },
        { field: 'Phone', headerText: 'Phone' },
        { field: 'ContactEmail', headerText: 'Email' },
        { field: 'Address', headerText: 'Address' },
        { field: 'Suite', headerText: 'Suite' },
        { field: 'city', headerText: 'City' },
        { field: 'state', headerText: 'State' },
        { field: 'zip', headerText: 'Zip' },
        { type: 'commands', commands: this.contactsGridActionsColumnCommands, headerText: 'Actions' }
      ],
      toolbarClick: (args: any) => this.onToolbarClick(args),
      commandClick: (args: any) => this.onCommandClick(args), 
      editSettings: { allowEditing: false }
    };
  }

 onToolbarClick(args: any): void {
    if (args.item.id === 'AddContact') {
      this.addContactDialogVisibility = true;
    }
  }

  onCommandClick(args: any): void {
    if (args.commandColumn.type === 'Edit') {
      const selectedContact = args.rowData;
      this.selectedContact.set(selectedContact); 
      this.editContact = selectedContact;
      this.editContactDialogVisibility = true;
    }
  }

  refreshGrid() {
    window.location.reload();
  }

  beforeOpening(args: any): void {
    args.maxHeight = '100%';
  }
}