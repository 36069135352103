<div id="deposits-grid" class="deposits-grid">
    
    <div class="grid-container" *ngIf="depositsGridSettings">
        <grid-template #depositsGridTemplateComponent [settings]="depositsGridSettings" [name]="'Deposits'" /> 
    </div>
    
    <div class="dialog-container">
        <ejs-dialog id="add-new-deposit-dialog" #addDepositDialog 
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="false"
            [buttons]="addDepositButtons"
            header="Add Deposit"
            (close)="addDepositDialog.hide()">
            <add-deposit-form #newDepositForm />
        </ejs-dialog>
    </div>

    <!-- Bulk Edit -->
    <div class="dialog-container">
        <ejs-dialog id="bulk-edit-deposit-dialog" #bulkEditDepositsDialog
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="false"
            [buttons]="bulkUpdateDepositsButtons"
            header="Bulk Edit"
            (close)="bulkEditDepositsDialog.hide()">
            <bulk-edit-deposits-form #bulkEditForm [endpoint]="depositsEndpoint" />
        </ejs-dialog>
    </div>
</div>