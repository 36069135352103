<div id="main-nav">
    <ejs-menu #mainNav id="main-nav" [cssClass]="" [items]="mainNavItems" [hamburgerMode]="globals.isResponsive"
        [animationSettings]='animationSettings' (created)="onCreated()" (beforeItemRender)="beforeItemRender($event)"
        (select)="select($event)">
        <ng-template #template let-data>
            <div *ngIf="isSideBarSmall && data.top === true; else sidebarOpen">
                <!-- Collapsed menu includes tooltip text -->
                <ejs-tooltip id='tooltip' content={{data.text}} position="RightCenter" [animation]='TooltipAnimation'>
                    <div class="custom-menu-item" #item *ngIf="!data.separator && data.faIcon">
                        <fa-icon class="custom-menu-item-icon" [icon]="data.faIcon" *ngIf="data.faIcon" />
                        <span class="custom-menu-item-text e-text">{{data.text}}</span>
                    </div>
                </ejs-tooltip>
            </div>
            <ng-template #sidebarOpen>
                <div class="custom-menu-item" #item>
                    <fa-icon class="custom-menu-item-icon" [icon]="data.faIcon" *ngIf="data.faIcon" />
                    <span class="custom-menu-item-text e-text">{{data.text}}</span>
                </div>
            </ng-template>
        </ng-template>
    </ejs-menu>
</div>