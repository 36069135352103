// Angular
import { Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommandClickEventArgs, CommandModel, GridModel, RowDeselectEventArgs, RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { BehaviorSubject, Observable } from 'rxjs';

// 3rd Party
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { BeforeOpenEventArgs, DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { IntakeSurvey } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { GridTemplateModule } from '@modules/grid-template.module';
import { IntakeSurveyFormComponent } from '@forms/intake-survey-form/intake-survey-form.component';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-richtexteditor';
import { CommonModule } from '@angular/common';
import { USDateformat, USDateTimeformat } from '@models/global-vars';

interface ButtonModel {
  buttonModel: {
    cssClass: string;
    iconCss: string;
    content: string;
  };
  click: (event: any) => void;
}

@Component({
  selector: 'intakes-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    DropDownListModule,
    GridTemplateModule,
    IntakeSurveyFormComponent,
    ButtonModule
  ],
  templateUrl: './intakes-grid.component.html',
  styleUrl: './intakes-grid.component.scss'
})
export class IntakesGridComponent implements OnInit {

  constructor (
    private toast: ToastMessageService,
    private api: ApiService,
    private globals: GlobalsService,
    private user: UserPreferencesService
  ) {}

  @ViewChild('intakesGrid') intakesGrid: GridTemplateComponent;
  @ViewChild('intakeSurveyDialog') intakeSurveyDialog: DialogComponent;
  @ViewChild('intakeSurveyForm') intakeSurveyForm: IntakeSurveyFormComponent;

  private dataSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private variableSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  dataObservable$ = this.dataSubject.asObservable();
  variable$: Observable<number> = this.variableSubject.asObservable();
  intakeModel: IntakeSurvey;
  gridSettings: GridModel;
  lawFirms: any[];
  intakeId: number;
  intakeStatus: string;
  intakeSurveyFormResults: any;
  intakeSurveyDialogVisibility: boolean = false;
  intakesGridActionsColumnCommands: CommandModel[] = [{ type: 'Edit', title: 'Edit Intake', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }];
  intakeSurveyDialogButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-flat' } },
    { click: this.submitIntakeSurveyForm.bind(this), buttonModel: { 
        content: 'Update Intake', 
        cssClass: 'e-primary', 
        isPrimary: true 
      } 
    }
  ];
  nextStepButton: ButtonModel = {
    click: this.findMatchingCases.bind(this),
    buttonModel: { 
      content: 'Find Matching Cases →', 
      cssClass: 'e-success',
      iconCss: 'e-icons e-plus'
    }
  };
  patients: any[];
  layoutMode: WritableSignal<string> = signal('');
  rowsAreSelected: boolean = false;

  ngOnInit(): void {
    this.user.appLayout$.subscribe((layoutMode) => {
      this.layoutMode.set(layoutMode);
    });
    this.gridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.IntakeSurveys),
      query: new Query()
        .expand('Patient($expand=Address),Lawfirm,LawfirmContact')
        .select('Id,FileNumber,Patient,Lawfirm,ClaimDateOfLoss,CreatedAt,CreatedBy,Status'),
      allowRowDragAndDrop: false,
      allowSelection: false,
      allowSorting: true,
      columns: [
        { field: 'Id' },
        { field: 'FileNumber' },
        { field: 'Patient', headerText: 'Client Name', valueAccessor: (field: string, data: Object): string => this.getPatientFullName(field, data) },
        { field: 'Patient.Address.StateNavigation.Name', headerText: 'Client State' },
        { field: 'Lawfirm.Name', headerText: 'Law Firm' },
        { field: 'Patient.Dob', headerText: 'DOB' },
        { field: 'ClaimDateOfLoss', headerTemplate: 'DOL' },
        { field: 'Status', headerText: 'Status' },
        { field: 'CreatedAt', headerText: 'Created At' },
        { field: 'CreatedBy', headerText: 'Created By' },
        { type: 'commands', headerText: 'Actions', commands: this.intakesGridActionsColumnCommands }
      ],
      recordDoubleClick: ($event: any) => this.onRecordDoubleClick($event),
      toolbarClick: ($event: ToolbarClickEventArgs) => this.onToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    },
    this.api.getOdata(APIEndpoints.Patients).executeQuery(new Query().select(['Id', 'Firstname', 'Lastname'])).then((e: ReturnOption) => {
      this.patients = (e.result as any[]);
    });
  }

  // Update display of roles by retrieving the RoleName from the data
  getPatientFullName(field: string, data: object): string {
    let returnString;

    // Check if data[field] is defined and is an array
    if ((data as any)[field]) {
      returnString = `${(data as any)[field].Firstname} ${(data as any)[field].Lastname}`;
    } else {
      returnString = '';
    }

    return returnString;
  }

  // Get data for law firms dropdown
  getLawFirmsData() {
    this.api.getOdata(APIEndpoints.Lawfirms).executeQuery(new Query().select(['Id, Name']).where('Active', 'equals', true)).then((e: ReturnOption) => {
      this.lawFirms = (e.result as any[]);
    })
  }

  onToolbarClick(toolbarClickArgs: ToolbarClickEventArgs) {

    if (toolbarClickArgs.item.text === 'Add') {
      toolbarClickArgs.cancel = true;

      // Request a new ID from the server instead of calculating it client-side
      this.api.getOdata(APIEndpoints.IntakeSurveys + '/nextId').executeQuery(new Query()).then((response: any) => {
        this.intakeId = response.id;
        this.dataSubject.next(this.intakeId);
        
        // Initialize rest of the form...
        this.intakeStatus = '';
        this.intakeSurveyDialogButtons = [/* ... existing button config ... */];
        this.intakeModel = {
          Id: this.intakeId,
          CreatedAt: new Date(),
          CreatedBy: this.user.userId()
        };
        this.intakeSurveyDialog.show();
      }).catch((error) => {
        this.toast.showError('Failed to generate new intake ID');
        console.error('Error getting next ID:', error);
      });

    } else if (toolbarClickArgs.item.text === 'Edit') {
      toolbarClickArgs.cancel = true;
      const selectedRow = this.intakesGrid.grid.getSelectedRecords()[0] as any;

      if (!selectedRow) {
        this.toast.showError('No row selected');
        return;
      } else {
        this.intakeSurveyDialogVisibility = true;
        this.intakeId = selectedRow.Id;
        this.dataSubject.next(this.intakeId);
        this.intakeStatus = selectedRow.Status;
      }
    }
  }

  // Opens intake form modal and set observables
  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const type = commandClickArgs.commandColumn?.type;
    const data = commandClickArgs.rowData as any;

    if (type === 'Edit' && data) {
      commandClickArgs.cancel = true;
      
      if (data.Status !== 'File Created') {
        this.intakeSurveyDialogVisibility = true;
        this.intakeId = data.Id;
        this.dataSubject.next(this.intakeId);
        this.intakeStatus = data.Status;
        this.intakeSurveyDialog.show();
      } else {
        this.toast.showWarning('Cannot edit intake form after case file has been created.');
      }
    }
  }

  // Closes intake survey modal
  closeDialog() {
    if (this.intakeSurveyForm) {
      this.intakeSurveyForm.intakeSurveyForm().reset();
    }
    this.intakeSurveyDialog.hide();
    this.intakeSurveyDialogVisibility = false;
    this.intakeId = 0;
    this.dataSubject.next(0);
    this.intakeStatus = '';
  }

  // Gets intake form values
  getFormResult(result: any) {
    this.intakeSurveyFormResults = result;
  }

  // Add new intake to list of intakes
  submitIntakeSurveyForm(args: any) {
    const form = this.intakeSurveyForm.intakeSurveyForm();
    this.intakeSurveyForm.emitValues();
    this.globals.markControlsTouched(form);
  
    if (form.valid) {
      // Pass the actual number value
      this.intakeSurveyForm.onSubmit(this.intakeId).then(() => {
        this.intakeSurveyDialog.hide();
        this.intakeSurveyDialogVisibility = false;
        
        // Refresh grid to show new/updated data
        this.intakesGrid.grid.refresh();
      }).catch(error => {
        console.error('Error submitting form:', error);
        this.toast.showError('Failed to save intake survey. Please try again.');
      });
    } else {
      console.log('Form invalid: ', form);
      this.toast.showError('Form Invalid.');
    }
  }

  // Set max height of modal and add margin to accomodate for nav bar
  onBeforeOpen(args: BeforeOpenEventArgs) {
    args.maxHeight = this.layoutMode() === 'on' ? '100%' : '90vh' ;
    (args.element as HTMLElement).style.marginTop = this.layoutMode() === 'on' ? '0' : '65px';
  }

  // Add new method for handling the next step
  findMatchingCases(args: any) {
    // First save the intake form
    this.submitIntakeSurveyForm(args);
    
    // Then proceed with finding matching cases
    // Add your matching cases logic here
  }

  // Add new method to handle double click
  onRecordDoubleClick(args: any) {
    const data = args.rowData;
    console.log('Data: ', data);
    if (data?.Status !== 'File Created') {
      this.intakeId = data.Id;
      this.dataSubject.next(this.intakeId);
      this.intakeStatus = data.Status;
      this.intakeSurveyDialogVisibility = true;
    } else {
      this.toast.showWarning('Cannot edit intake form after case file has been created.');
    }
  }

}