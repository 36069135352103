<div class="audit-logs">
  <loading-overlay *ngIf="loading" [loading]="loadingMessage"></loading-overlay>

  <ng-container *ngIf="!loading">
    <ng-container *ngIf="isSectionedLogs(logs)">
      <ng-container *ngFor="let section of logs">
        <div class="section-header clickable" (click)="toggleSection(section)">
          <h3>
            <span class="e-icons collapse-icon">
              {{isSectionCollapsed(section) ? '+' : '-'}}
            </span>
            {{section.sectionHeader}}
          </h3>
        </div>
        
        <table class="e-table" [class.collapsed]="isSectionCollapsed(section)">
          <thead>
            <tr>
              <th>Action</th>
              <th>Type</th>
              <th>User</th>
              <th>Date/Time</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let log of section.logs; let i = index">
              <tr class="log-row" 
                  [class.expanded]="isRowExpanded(i)"
                  (click)="toggleRow(i)">
                <td>
                  <span class="e-icons">{{isRowExpanded(i) ? '-' : '+'}}</span>
                </td>
                <td>{{log.type}}</td>
                <td>{{log.user}}</td>
                <td>{{log.dateTime | date:'MM-dd-yyyy h:mm a'}}</td>
              </tr>
              <tr *ngIf="isRowExpanded(i)">
                <td colspan="4">
                  <table class="changes-table">
                    <thead>
                      <tr>
                        <th>Property</th>
                        <th>Old Value</th>
                        <th>New Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let change of getChangedProperties(log.oldValues, log.newValues)">
                        <td>{{change.property}}</td>
                        <td><pre>{{formatValue(change.oldValue)}}</pre></td>
                        <td><pre>{{formatValue(change.newValue)}}</pre></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>

    <table class="e-table" *ngIf="!isSectionedLogs(logs)">
      <thead>
        <tr>
          <th>Action</th>
          <th>Type</th>
          <th>User</th>
          <th>Date/Time</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let log of logs; let i = index">
          <tr class="log-row" 
              [class.expanded]="isRowExpanded(i)"
              (click)="toggleRow(i)">
            <td>
              <span class="e-icons">{{isRowExpanded(i) ? '-' : '+'}}</span>
            </td>
            <td>{{log.type}}</td>
            <td>{{log.user}}</td>
            <td>{{log.dateTime | date:'MM-dd-yyyy h:mm a'}}</td>
          </tr>
          <tr *ngIf="isRowExpanded(i)">
            <td colspan="4">
              <table class="changes-table">
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let change of getChangedProperties(log.oldValues, log.newValues)">
                    <td>{{change.property}}</td>
                    <td><pre>{{formatValue(change.oldValue)}}</pre></td>
                    <td><pre>{{formatValue(change.newValue)}}</pre></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>