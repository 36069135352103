// Angular
import { Component, computed, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Syncfusion
import { GridComponent, CommandModel, GridModel, CommandClickEventArgs, PdfExportProperties, PdfExportService, PageService, ToolbarService, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { BulkEditCaseFilesForm } from '@root/src/app/components/forms/bulk-edit-forms/bulk-edit-case-file/bulk-edit-case-file.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { AuditLogService } from '@root/src/app/shared/services/audit-logs/audit-log-service';
import { Router } from '@angular/router';
import { CaseFileFormComponent } from '@forms/case-file/case-file.component';

@Component({
  selector: 'case-files-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateModule,
    BulkEditCaseFilesForm,
    CaseFileFormComponent,
    AuditLogsComponent
  ],
  providers: [
    PdfExportService,
    PageService,
    ToolbarService
  ],
  templateUrl: './case-files-grid.component.html',
  styleUrl: './case-files-grid.component.scss'
})
export class CaseFilesGridComponent {

  constructor (
    private api: ApiService,
    private globals: GlobalsService,
    private toast: ToastMessageService,
    private user: UserPreferencesService,
    private auditLogService: AuditLogService,
    private router: Router
  ) { }  

  // Initialize variables for caseFilesGrid
  @ViewChild('caseFilesGrid') caseFilesGrid: GridComponent;
  @ViewChild('caseFiles') caseFiles: GridTemplateComponent;
  @ViewChild('fileNumberDisplayTemplate', { static: true }) fileNumberDisplayTemplate!: string;
  @ViewChild('lawFirmDisplayTemplate', { static: true }) lawFirmDisplayTemplate!: string;
  caseFilesGridSettings: GridModel;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  caseFilesGridDefaultToolbar: any[] = ["Add"];
  caseFileSignal = signal<CaseFile | undefined>(undefined);
  caseFileData = computed(() => this.caseFileSignal());
  caseFilesGridActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
    { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
  ];

  // Adding a new case file
  @ViewChild('addCaseFileDialog', { static: true}) addCaseFileDialog: DialogComponent;
  @ViewChild('newFileForm') newFileForm: CaseFileFormComponent;
  emptyCaseFileObj: object;
  addCaseFileDialogVisibility: Boolean = false;
  selectedRecords: any[] = [];
  addCaseFileButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearCaseFileForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Custom dialog for bulk editing files
  @ViewChild('bulkEditCaseFilesDialog') bulkEditCaseFilesDialog: DialogComponent;
  @ViewChild('bulkEditForm') bulkEditForm: BulkEditCaseFilesForm;
  bulkEditCaseFilesDialogVisibility: Boolean = false;
  bulkUpdateCaseFilesButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Editing a single case file
  @ViewChild('editCaseFileDialog', { static: true}) editCaseFileDialog: DialogComponent;
  @ViewChild('editFileForm') editFileForm: CaseFileFormComponent;
  editCaseFileDialogVisibility: Boolean = false;
  editCaseFile: CaseFile;
  editCaseFileDialogHeader: string = 'Editing Case File';
  editCaseFileButtons: object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearCaseFileForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onEditCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  @ViewChild('logsDialog', { static: true }) logsDialog: DialogComponent;
  logsDialogVisibility: Boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  ngOnInit() {
    this.caseFilesGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Casefiles),
      pageSettings: { pageSize: 16 },
      toolbar: this.caseFilesGridDefaultToolbar,
      query: new Query().expand('LawFirmContactNavigation,AttorneyNavigation,CaseManagerNavigation,LawFirm,MarketManagerNavigation,PAndLNavigation,ReferringPhysicianNavigation,ReferralSourceNavigation,Patient($expand=Address($expand=StateNavigation))'),
      columns: [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'FileNumber', headerText: 'File Number', allowEditing: false, template: this.fileNumberDisplayTemplate },
        { field: 'Patient', headerText: 'Client Name', valueAccessor: (field: string, data: Object): string[] => this.getPatientName(field, data) },
        { field: 'Patient.Dob', headerText: 'DOB', format: 'yMd' },
        { field: 'Patient.Minor', headerText: 'Minor', visible: false },
        { field: 'ResponsibleParty', headerText: 'Responsible Party', visible: false },
        { field: 'Patient.Address.StateNavigation.Description', headerText: 'State' },
        { field: 'Patient.Address.County', headerText: 'County', visible: false },
        { field: 'LawFirm.Name', headerText: 'Law Firm', template: this.lawFirmDisplayTemplate },
        { field: 'LawFirmContactNavigation.ContactName', headerText: 'Law Firm Contact', visible: false },
        { field: 'AttorneyNavigation.ContactName', headerText: 'Attorney', visible: false },
        { field: 'FileOpened', headerText: 'File Opened', allowEditing: false, format: 'yMd' },
        { field: 'InTreatment', headerText: 'In Treatment' },
        { field: 'IsSurgical', headerText: 'Surgical', visible: false },
        { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
        { field: 'Status', headerText: 'Record Status', visible: false },
        { field: 'ReferralSourceNavigation.Description', headerText: 'Referral Source', visible: false },
        { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false },
        { field: 'AdditionalDatesOfLoss', headerText: 'Additional Dates of Loss', visible: false },
        { field: 'MarketManagerNavigation.Name', headerText: 'Market Manager', visible: false },
        { field: 'Patient.Email', headerText: 'Client Email', visible: false },
        { field: 'Patient.Phone', headerText: 'Client Phone', visible: false },
        { field: 'Patient.Language', headerText: 'Client Language', visible: false },
        { field: 'LawFirmContactNavigation.Email', headerText: 'Law Firm Contact Email', visible: false },
        { field: 'Comments', headerText: 'Comments', allowFiltering: false, allowSorting: false, valueAccessor: (field: string, data: object): string[] => this.getCommentValues(field, data), visible: false },
        { field: 'PAndLNavigation.Description', headerText: 'P&L', visible: false },
        { field: 'LawFirmFileNumber', headerText: 'Law Firm File Number', visible: false },
        { field: 'BalanceDue', headerText: 'Balance Due', visible: false },
        { field: 'Statuser', headerText: 'Statuser', visible: false },
        { field: 'SelectFiles', headerText: 'Select Files', visible: false },
        { type: 'commands', commands: this.caseFilesGridActionsColumnCommands, headerText: 'Actions' }
      ],
      created: () => this.onCreated(),
      dataBound: ($event: any) => this.onDataBound($event),
      toolbarClick: ($event: any) => this.onCustomToolbarClick($event),
      rowSelected: ($event: any) => this.onRowSelected($event),
      rowSelecting: ($event: any) => this.onRowSelecting($event),
      rowDeselected: ($event: any) => this.onRowDeselected($event),
      rowDeselecting: ($event: any) => this.onRowDeselecting($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    }
  }

  // Ensures foreign key fields have data ready for display
 

  getPatientName(field: string, data: object): string[] {
    const patient = (data as any)[field];
    const patientFullName = `${patient.Firstname} ${patient.Lastname}`;
    return [patientFullName];
  }

  onLawFirmClick(lawFirm: any) {
    this.router.navigate(['/law-firm-detail', lawFirm.Id])
      .then(() => {
        window.location.reload();
      });
  }

  // Value accessor for displaying strings in comments column
  getCommentValues(field: string, data: object): string[] {
    if ((data as any)[field]) {
      return ((data as any)[field as string]);
    } else {
      return [''];
    }
  }

  onCreated() {
    this.caseFilesGridDefaultToolbar = this.caseFiles.settings.toolbar as any[];
  }

  onDataBound(args: any) {
    this.caseFilesGrid = this.caseFiles.grid;
  }

  // Add logic for rendering modals
  onCustomToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addCaseFileDialogVisibility = true;
      this.clearBulkEditForm();
      if (this.addCaseFileDialog) this.addCaseFileDialog.show();
    } 
    
    if (args.item.id === 'BulkEdit') {
      this.bulkEditCaseFilesDialogVisibility = true;
      if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.show();
    }
  }

  // Add logic for editing individual case files
  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const file: CaseFile = (commandClickArgs.rowData as CaseFile);

    if (!file) {
      this.toast.showError('Unable to open Case File.<br />No Case Number found.');
      return;
      
    } else if (commandClickArgs.commandColumn?.type === 'Edit') {
      commandClickArgs.cancel = true;
      this.editCaseFileDialogVisibility = true;
      this.editCaseFile = commandClickArgs.rowData as CaseFile;
      this.caseFileSignal.set(this.editCaseFile);
      if (file.FileNumber && this.caseFileData()) this.editCaseFileDialog.show();
      return;

    } else if (commandClickArgs.commandColumn?.title === 'Logs') {
      commandClickArgs.cancel = true;
      
      if (file.Id) {
        this.auditLogsLoading = true;
        this.showLogsDialog();

        return this.auditLogService.getAuditLogs(file.Id, 'CaseFile').then((result: any) => {

          if (!result || !result.length || result.length === 0) {
            this.toast.showError('No audit logs found');
            return;
          }

          this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(result);
          this.auditLogsLoading = false;
          return;
        });
      }

      return;

    } else {
      commandClickArgs.cancel = true;
      this.caseFiles.onCommandClick(commandClickArgs);
      return;
    }
  }

  // New method to show audit logs dialog
  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.toast.showError('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  // Add logic to open case file when case number clicked
  caseNumberClick(data: any) {
    const openFiles = this.user.getLocalUserPreference('openFiles') ?? [];

    if (data.FileNumber) {
      const foundFile = openFiles.find((obj: any) => obj['id'] === data.FileNumber) as any;
      
      if (!foundFile) {
        openFiles.push({id: data.FileNumber, name: `${data.Patient.Firstname} ${data.Patient.Lastname}`});
        this.user.saveLocalUserPreference('openFiles', openFiles);
      }
      
      this.router.navigate([`/case-files/hub`], { 
        queryParams: { fileNumber: data.FileNumber }
      });
    } else {
      this.toast.showError('No File Number found.')
    }
  }

  onRowSelected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Detect selection of multiple rows
  onRowSelecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  onRowDeselected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Occurs as rows are deselected
  onRowDeselecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Set toolbar according to number of selected rows
  updateToolbar() {

    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;

      if (length === 1) {
        this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
      } else if (length > 1) {
        const bulkEditButton = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit', prefixIcon: 'e-properties-1' };
        const buttonExists = this.caseFilesGrid.toolbar.some(button => (button as any).id === bulkEditButton.id);
        if (!buttonExists) this.caseFiles.insertToolbarItem(bulkEditButton); // make sure button is not already in toolbar
      }

    } else {
      this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
    }
  }

  // Close both modal windows
  closeDialog() {
    if (this.addCaseFileDialog) this.addCaseFileDialog.hide(); this.addCaseFileDialogVisibility = false;
    if (this.editCaseFileDialog) this.editCaseFileDialog.hide(); this.editCaseFileDialogVisibility = false; 
    if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.hide(); this.bulkEditCaseFilesDialogVisibility = false;
    if (this.logsDialog) this.logsDialog.hide(); this.logsDialogVisibility = false;
  }

  // Set height of modals
  beforeOpening(args: any) {
    try {
      args.maxHeight = '100%';
    } catch (error) {
      this.toast.showError('Error configuring dialog');
      console.error('Error in beforeOpening:', error);
    }
  }

  // Set fields to blank
  clearCaseFileForm() {
    if (this.newFileForm) this.newFileForm.caseFileForm.reset();
    if (this.editFileForm) this.editFileForm.caseFileForm.reset();
  }

  // Add new file to grid
  onNewCaseFileSubmit(args: any) {
    this.newFileForm.onSubmit().then((result: any) => {
      if (result && (result.ok || result.Id)) {
        this.closeDialog();
        this.caseFilesGrid.refresh();
      }
    });
  }

  onEditCaseFileSubmit(args: any) {
    this.editFileForm.onSubmit().then((result: any) => {
      if (result && (result.ok || result.Id)) {
        this.closeDialog();
        this.caseFilesGrid.refresh();
      }
    }); 
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    if (this.bulkEditForm) (this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).reset();
  }

  // Apply bulk changes to data
  onBulkEditSubmit(args: any) {
    const comp = this;
    let form = this.bulkEditForm.bulkEditCaseFilesForm;

    if (form.valid) {

      this.selectedRecords.forEach((record) => {
        for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
          const updatedValue: any = value;
          if (value !== null) {
            this.caseFilesGrid.updateCell(record, key, updatedValue);
          }
        }
      })
      this.closeDialog();
      this.mapBulkEditChanges();
    } else {
      console.log((this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).errors);
      alert('Please enter a valid form.');
    }
  }

  // Ensure data is properly formatted before batchSave()
  mapBulkEditChanges() {
    let changedRecords: any[] = []

    for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
      if (value !== null) {
        let newObj = { [key]: value };
        changedRecords.push(newObj);
      }
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = []; // Clear the logs when closing
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }
}
