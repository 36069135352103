export const environment = {
    cognitoUserPoolId: "us-west-2_kD3g0xRL6",
    cognitoAppClientId: "6qoseebakm4rot6ved4m5kicr3",
    cognitoIdendityPoolId: "us-west-2:584e93b1-beca-40fb-9b1c-f501ff78f4e3",
    production: false,
    BoxDeveloperToken: 'ca0pY44Lhfg2WXsZnTZyHcEeCtDioCjM', // Generate a temporary deveoper token here -> https://app.box.com/developers/console/app/2268127/configuration
    mondayApiToken: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ1NjAyMTAwMywiYWFpIjoxMSwidWlkIjo3MDU5OTc4MCwiaWFkIjoiMjAyNS0wMS0wOVQyMzozMDoxMS4xOTZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MjczNTU1MjYsInJnbiI6InVzZTEifQ.ISF8D2-SoWC0FV5pb3w-pCASznq_0YT9Pe97M11df7k', // Generate a temporary API token here -> https://developer.monday.com/apps/new
    // mondayBoardId: '8206260134' // Get the board ID from the URL of the board you want to use -> https://monday.com/boards/8206260134/,
    mondayBoardId: '8218021211' //
};

export const BACKENDURL = 'https://localhost:63094/';