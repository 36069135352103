// Angular
import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons'; 

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { CasefileInsurancePolicyFormComponent } from '@forms/casefile-insurance-policy-form/casefile-insurance-policy-form.component';
import { caseFileTimeFrame } from '@models/global-vars';
import { GlobalsService } from '@services/globals/globals.service';
import { Intake } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { BodyPartSelectorComponent } from '@ui/body-part-selector/body-part-selector.component';

@Component({
  selector: 'casefile-intake-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    SwitchModule,
    DropDownListModule,
    MultiSelectModule,
    TextBoxModule,
    NumericTextBoxModule,
    BodyPartSelectorComponent,
    CasefileInsurancePolicyFormComponent,
    DialogAllModule,
  ],
  templateUrl: './casefile-intake-form.component.html',
  styleUrl: './casefile-intake-form.component.scss'
})
export class CasefileIntakeFormComponent {

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService
  ) {}

  // Inputs 
  @Input() caseFileId: number;
  @Input() existingIntake: Intake | null = null;
  @Input() existingInsurances: any[] = [];
  @Input() existingAccidentDetails: any[] = [];
  @Input() existingInjuries: any[] = [];

  // Data
  accidentInfo: DataManager;
  accidentInfoQuery: Query = new Query().select(['Id,Description']);
  accidentDetails: DataManager;
  accidentDetailsQuery: Query = new Query().select(['Id,Description']);
  settlementTimeframe: any[] = caseFileTimeFrame;
  intakeForm: FormGroup;
  insuranceFormResults: any;
  insurancePolicyFormVisible: boolean = false;
  toggleInsurancePolicyBtnText: string = 'Add Insurance Policy';
  toggleInsurancePolicyBtnIconClass: string = 'e-icons e-circle-add';


  ngOnInit () {
    this.getAccidentInfo();
    this.getAccidentDetails();
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['existingIntake'] && this.existingIntake) ||
      (changes['existingInsurances'] && this.existingInsurances) ||
      (changes['existingAccidentDetails'] && this.existingAccidentDetails)
    ) {
      this.initializeForm();
    }
  }

  initializeForm() {
    this.intakeForm = new FormGroup({
      CaseFileId: new FormControl<Intake['CaseFileId']>(
        this.caseFileId, 
        [Validators.required]
      ),
      AccidentInfo: new FormControl<Intake['AccidentInfo']>(
        this.existingIntake?.AccidentInfo || undefined
      ),
      AccidentInfoId: new FormControl<Intake['AccidentInfoId']>(
        this.existingIntake?.AccidentInfoId || undefined, 
        [Validators.required]
      ),
      AccidentDetails: new FormControl<any[]>(
        this.existingAccidentDetails.map((x: any) => x.AccidentDetailId) || undefined
      ),
      AnticipatedSettlement: new FormControl<Intake['AnticipatedSettlement']>(
        this.existingIntake?.AnticipatedSettlement || 'undefined', 
        [Validators.required]
      ),
      IntakeComments: new FormControl<Intake['IntakeComments']>(
        this.existingIntake?.IntakeComments || 'undefined', 
        [Validators.required]
      ),
      MedicalCosts: new FormControl<Intake['MedicalCosts']>(
        this.existingIntake?.MedicalCosts || undefined, 
        [Validators.required]
      ),
      PropertyDamage: new FormControl<Intake['PropertyDamage']>(
        this.existingIntake?.PropertyDamage || undefined, 
        [Validators.required]
      ),
      PreviousAssistance: new FormControl<Intake['PreviousAssistance']>(
        this.existingIntake?.PreviousAssistance || false, 
        [Validators.required]
      ),
      GapInTreatment: new FormControl<Intake['GapInTreatment']>(
        this.existingIntake?.GapInTreatment || false, 
        [Validators.required]
      ),
      ClearLiability: new FormControl<Intake['ClearLiability']>(
        this.existingIntake?.ClearLiability || false, 
        [Validators.required]
      ),
      PriorRepresentation: new FormControl<Intake['PriorRepresentation']>(
        this.existingIntake?.PriorRepresentation || false, 
        [Validators.required]
      ),
      LimitsSearch: new FormControl<Intake['LimitsSearch']>(
        this.existingIntake?.LimitsSearch || false, 
        [Validators.required]
      ),
      Insurance: new FormArray([])
    });
  }

  // Form Data
  getAccidentInfo() {
    this.api.getOdata(APIEndpoints.AccidentInfo)
      .executeQuery(new Query().select(['Id', 'Description']))
      .then((response: any) => {
      this.accidentInfo = response.result;
    });
  }

  // Form Data
  getAccidentDetails() {
    this.api.getOdata(APIEndpoints.AccidentDetails)
      .executeQuery(new Query().select(['Id', 'Description']))
      .then((response: any) => {
      this.accidentDetails = response.result;
    });
  }

  // Insurance Form Visibility
  toggleInsurancePolicy(event: any) {
    this.insurancePolicyFormVisible = !this.insurancePolicyFormVisible;

    if (this.insurancePolicyFormVisible === true) {
      this.toggleInsurancePolicyBtnText = 'Hide Insurance Policy Form';
      this.toggleInsurancePolicyBtnIconClass = 'e-icons e-circle-remove';
    } else {
      this.toggleInsurancePolicyBtnText = 'Add Insurance Policy';
      this.toggleInsurancePolicyBtnIconClass = 'e-icons e-circle-add';
    }
  }
  

  getInsuranceFormResults(result: any) {
    console.log(result)
    // this.intakeForm.value.Insurance.push(result);
    // this.toggleInsurancePolicyBtnText = 'Add Insurance Policy';
    // this.toggleInsurancePolicyBtnIconClass = 'e-icons e-circle-add';
  }

  // Accident Details XRefs
  private createNewAccidentXRefs(intakeId: number, detailsToAdd: number[]): Promise<any>[] {
    return detailsToAdd.map((detailId: number) => {
      const payload = {
        IntakeId: intakeId,
        AccidentDetailId: detailId
      };
      return this.api.basicPost(APIEndpoints.XrefIntakeAccidents, payload);
    });
  }

  private deleteRemovedAccidentXRefs(xrefsToRemove: any[]): Promise<any>[] {
    return xrefsToRemove.map((xref: any) => {
      return this.api.deleteOdata(`${APIEndpoints.XrefIntakeAccidents}(${xref.Id})`);
    });
  }

  // Manage Accident Details XRefs
  private async manageAccidentDetailsXRefs(intakeId: number) {
    const existingXRefIds = this.existingAccidentDetails.map(x => x.AccidentDetailId);
    const selectedDetailIds = this.intakeForm.value.AccidentDetails || [];
    
    const detailsToAdd = selectedDetailIds.filter((id: number) => !existingXRefIds.includes(id));
    const xrefsToRemove = this.existingAccidentDetails.filter(
      xref => !selectedDetailIds.includes(xref.AccidentDetailId)
    );


    const promises = [
      ...this.createNewAccidentXRefs(intakeId, detailsToAdd),
      ...this.deleteRemovedAccidentXRefs(xrefsToRemove)
    ];

    if (promises.length) {
      await Promise.all(promises);
      this.toast.showSuccess('Successfully saved intake and accident details');
    }
  }

  getInjuryData(injuries: any) {
    const injuryArray: any[] = injuries.map((injury: any) => ({
      CaseFileId: this.caseFileId,
      BodyPartId: injury.bodyPartId,
      Severity: injury.severity
    }));
  
    this.intakeForm.patchValue({ Injury: injuryArray });
  }

  // Injury XRefs
  private createNewInjuryXRefs(intakeId: number, injuriesToAdd: any[]): Promise<any>[] {
    return injuriesToAdd.map((injury: any) => {
      const payload = {
        IntakeId: intakeId,
        BodyPartId: injury.BodyPartId,
        Severity: injury.Severity
      };
      return this.api.basicPost(APIEndpoints.XrefIntakeInjuries, payload);
    });
  }

  private deleteRemovedInjuryXRefs(xrefsToRemove: any[]): Promise<any>[] {
    return xrefsToRemove.map((xref: any) => {
      return this.api.deleteOdata(`${APIEndpoints.XrefIntakeInjuries}(${xref.Id})`);
    });
  }

  private updateExistingInjuryXRefs(injuriesToUpdate: any[]): Promise<any>[] {
    return injuriesToUpdate.map((injury: any) => {
      const existingXRef = this.existingInjuries.find(x => x.BodyPartId === injury.BodyPartId);
      return this.api.patchOdata(
        `${APIEndpoints.XrefIntakeInjuries}(${existingXRef.Id})`,
        { Severity: injury.Severity }
      );
    });
  }

  // Manage Injury XRefs
  private async manageInjuryXRefs(intakeId: number) {
    const existingXRefIds = this.existingInjuries.map(x => x.BodyPartId);
    const selectedInjuries = this.intakeForm.value.Injury || [];
    
    const injuriesToAdd = selectedInjuries.filter((injury: any) => 
      !existingXRefIds.includes(injury.BodyPartId)
    );
    const xrefsToRemove = this.existingInjuries.filter(xref => 
      !selectedInjuries.some((injury: any) => injury.BodyPartId === xref.BodyPartId)
    );
    const xrefsToUpdate = selectedInjuries.filter((injury: any) => {
      const existingInjury = this.existingInjuries.find(x => x.BodyPartId === injury.BodyPartId);
      return existingInjury && existingInjury.Severity !== injury.Severity;
    });

    const promises = [
      ...this.createNewInjuryXRefs(intakeId, injuriesToAdd),
      ...this.deleteRemovedInjuryXRefs(xrefsToRemove),
      ...this.updateExistingInjuryXRefs(xrefsToUpdate)
    ];

    if (promises.length) {
      await Promise.all(promises);
      this.toast.showSuccess('Successfully saved intake and injuries');
    }
  }

  // Insurance Policy Results
  handleInsuranceResult(result: any) {
    if (result) {
      this.existingInsurances.push(result);
      this.toggleInsurancePolicy(null);  // Close the dialog after successful submission
    }
  }

  // Transform Form Data for API
  private getFormData(): any {
    return {
      Id: this.existingIntake?.Id,
      CaseFileId: this.caseFileId,
      AccidentInfoId: this.intakeForm.value.AccidentInfoId,
      MedicalCosts: Number(this.intakeForm.value.MedicalCosts),
      PropertyDamage: Number(this.intakeForm.value.PropertyDamage),
      ClearLiability: Boolean(this.intakeForm.value.ClearLiability),
      PriorRepresentation: Boolean(this.intakeForm.value.PriorRepresentation),
      LimitsSearch: Boolean(this.intakeForm.value.LimitsSearch),
      IntakeComments: this.intakeForm.value.IntakeComments || '',
      AnticipatedSettlement: this.intakeForm.value.AnticipatedSettlement || '',
      PreviousAssistance: Boolean(this.intakeForm.value.PreviousAssistance),
      GapInTreatment: Boolean(this.intakeForm.value.GapInTreatment)
    };
  }

  // Intake CRUD
  private async saveIntake(formData: any): Promise<any> {
    if (this.existingIntake?.Id) {
      return this.api.patchOdata(
        `${APIEndpoints.Intakes}(${this.existingIntake.Id})`, 
        formData
      ).then((response: any) => response);
    }
    return this.api.basicPost(APIEndpoints.Intakes, formData)
      .then((response: any) => response);
  }

  // Submit Form
  async onSubmit(event: any) {
    event.preventDefault();
    this.globals.markControlsTouched(this.intakeForm);

    if (!this.intakeForm.valid) {
      this.toast.showError('Please fill in all required fields');
      return;
    }

    try {
      const formData = this.getFormData();
      const intakeResponse = await this.saveIntake(formData);
      const intakeId = this.existingIntake?.Id || intakeResponse.result?.[0]?.Id;

      if (intakeId) {
        try {
          await this.manageAccidentDetailsXRefs(intakeId);
          await this.manageInjuryXRefs(intakeId);
        } catch (xrefError) {
          console.error('Error managing XRefs:', xrefError);
          this.toast.showError('Failed to save intake');
        }
      }
    } catch (error) {
      console.error('Error saving intake:', error);
      this.toast.showError('Failed to save intake. Please check the console for details.');
    }
  }
}
