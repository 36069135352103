<div id="edit-case-file">

    <form id="edit-case-file-form" [formGroup]="caseFileForm()" #formDir="ngForm" class="row d-flex flex-row justify-content-center">

        <div class="info-box">
            <h3>File Info</h3>

            <div class="row">
                <div class="form-group col-12 col-sm-6 col-xl-4 required">
                    <label>File Opened</label>
                    <ejs-datepicker formControlName="FileOpened" />
                    <div *ngIf="caseFileForm().get('FileOpened')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('FileOpened')?.errors?.['required'] && caseFileForm().get('FileOpened')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Current Status(Rating)</label>
                    <ejs-dropdownlist 
                        formControlName="Status"
                        placeholder="Select Current Status" 
                        [dataSource]="currentStatus" 
                        [fields]="keyValueFields"
                        [allowFiltering]="true"
                        (filtering)="globals.onDropdownFiltering($event, currentStatus)" />
                </div> 
    
                <div class="form-group col-12 col-sm-6 col-xl-4 required">
                    <label>Case Manager</label>
                    <ejs-dropdownlist formControlName="CaseManager" 
                        [dataSource]="users" 
                        [fields]="usersFields"
                        [allowFiltering]="true"
                        (filtering)="globals.onDropdownFiltering($event, users, 'Name')" />
                    <div *ngIf="caseFileForm().get('CaseManager')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('CaseManager')?.errors?.['required'] && caseFileForm().get('CaseManager')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4 required">
                    <label>Market Manager</label>
                    <ejs-dropdownlist formControlName="MarketManager" 
                        [dataSource]="users" 
                        [fields]="usersFields"
                        [allowFiltering]="true"
                        (filtering)="globals.onDropdownFiltering($event, users, 'Name')" />
                    <div *ngIf="caseFileForm().get('MarketManager')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('MarketManager')?.errors?.['required'] && caseFileForm().get('MarketManager')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4 required">
                    <label>P & L</label>
                    <ejs-dropdownlist formControlName="PAndL" [dataSource]="pAndL" [fields]="keyValueFields" />
                    <div *ngIf="caseFileForm().get('PAndL')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('PAndL')?.errors?.['required'] && caseFileForm().get('PAndL')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
                
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Referring Physician</label>
                    <ejs-dropdownlist formControlName="ReferringPhysician" [dataSource]="contacts" [fields]="contactsFields" />
                    <div *ngIf="caseFileForm().get('ReferringPhysician')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('ReferringPhysician')?.errors?.['required'] && caseFileForm().get('ReferringPhysician')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Referral Source</label>
                    <ejs-dropdownlist formControlName="ReferralSource"  [dataSource]="referrals" [fields]="referralsFields" />
                    <div *ngIf="caseFileForm().get('ReferralSource')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('ReferralSource')?.errors?.['required'] && caseFileForm().get('ReferralSource')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Initial Time Frame</label>
                    <ejs-dropdownlist formControlName="InitialTimeFrame" [dataSource]="initialTimeFrame" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Statuser</label>
                    <ejs-dropdownlist formControlName="Statuser"  [dataSource]="users" [fields]="usersFields" [query]="statusersQuery" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Responsible Party</label>
                    <ejs-textbox formControlName="ResponsibleParty" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Case Type</label>
                    <ejs-dropdownlist formControlName="CaseType" [dataSource]="caseTypes" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Date Of Loss</label>
                    <ejs-datepicker formControlName="DateOfLoss" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>File Secured By</label>
                    <ejs-dropdownlist formControlName="FileSecuredBy" [dataSource]="users" [fields]="usersFields" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>File Group</label>
                    <ejs-dropdownlist formControlName="FileGroupId" [dataSource]="fileGroups" [fields]="keyValueFields" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Companion Cases</label>
                    <ejs-multiselect formControlName="CompanionCases" [dataSource]="caseFiles" [fields]="caseFilesFields" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Exclude From Balance Due?</label>
                    <ejs-checkbox formControlName="ExcludeFromBalanceDue" [label]="caseFileForm().value.ExcludeFromBalanceDue== true ? 'Yes' : 'No'" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Surgical?</label>
                    <ejs-checkbox formControlName="IsSurgical" [label]="caseFileForm().value.IsSurgical == true ? 'Yes' : 'No'" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>In Treatment?</label>
                    <ejs-checkbox formControlName="InTreatment" [label]="caseFileForm().value.InTreatment == true ? 'Yes' : 'No'" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Client Has Checkpoint Limitations?</label>
                    <ejs-checkbox formControlName="ClientHasCheckpointLimitations" [label]="caseFileForm().value.ClientHasCheckpointLimitations == true ? 'Yes' : 'No'" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Comments</label>
                    <ejs-textbox [multiline]="true" formControlName="Comments" />
                </div>
            </div>
        </div>

        <div class="info-box">
            <h3>Client Info</h3>

            <form [formGroup]="caseFilePatientFormComponent()" #formDir="ngForm">

                <div class="row flex-row">
                    
                    <div class="form-group col-12 col-sm-6 col-xl-4">
                        <label>Choose Existing Client</label>
                        <ejs-dropdownlist #clientList formControlName="Id"
                            [dataSource]="clients" 
                            [fields]="clientFields"
                            [placeholder]="'Select Client'"
                            [allowFiltering]="true"
                            (created)="onClientListCreated($event)"
                            (change)="onClientSelect($event)"
                            (filtering)="globals.onDropdownFiltering($event, clients, 'Text')" />
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4 required">
                        <label>First Name</label>
                        <ejs-textbox formControlName="Firstname" />
                        <div *ngIf="caseFileForm().get('Firstname')?.errors" class="error-container">
                            <div *ngIf="caseFileForm().get('Firstname')?.errors?.['required'] && caseFileForm().get('Firstname')?.touched"
                                class="e-error">
                                <span>This field is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4 required">
                        <label>Last Name</label>
                        <ejs-textbox formControlName="Lastname" />
                        <div *ngIf="caseFileForm().get('Lastname')?.errors" class="error-container">
                            <div *ngIf="caseFileForm().get('Lastname')?.errors?.['required'] && caseFileForm().get('Lastname')?.touched"
                                class="e-error">
                                <span>This field is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4 required">
                        <label>Email</label>
                        <ejs-textbox formControlName="Email" />
                        <div *ngIf="caseFileForm().get('Email')?.errors" class="error-container">
                            <div *ngIf="caseFileForm().get('Email')?.errors?.['required'] && caseFileForm().get('Email')?.touched"
                                class="e-error">
                                <span>This field is required.</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4">
                        <label>Gender</label>
                        <ejs-dropdownlist [dataSource]="genders" formControlName="Gender" />
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4">
                        <label>D.O.B.</label>
                        <ejs-datepicker formControlName="Dob" />
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4">
                        <label>Language</label>
                        <ejs-dropdownlist [dataSource]="languages" formControlName="Language" />
                    </div>

                    <div class="form-group col-12 col-sm-6 col-xl-4">
                        <label>Minor?</label>
                        <ejs-checkbox formControlName="Minor"
                            [label]="caseFileForm().value.Minor == true ? 'Yes' : 'No'" />
                    </div>

                    <div class="col-12">

                        <form [formGroup]="caseFilePatientAddressFormComponent()" #formDir="ngForm" class="row flex-row">
                            <div class="form-group col-12 col-sm-6 col-xl-4 required">
                                <label>Street Address</label>
                                <ejs-textbox formControlName="Address1" />
                                <div *ngIf="caseFilePatientAddressFormComponent().get('Zip')?.errors" class="error-container">
                                    <div *ngIf="caseFilePatientAddressFormComponent().get('Zip')?.errors?.['required'] && caseFilePatientAddressFormComponent().get('Zip')?.touched" class="e-error">
                                        <span>This field is required.</span>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group col-12 col-sm-6 col-xl-4">
                                <label>Apt #</label>
                                <ejs-textbox formControlName="Address2" />
                            </div>
                    
                            <div class="form-group col-12 col-sm-6 col-xl-4">
                                <label>City</label>
                                <ejs-textbox formControlName="City" />
                            </div>
                    
                            <div class="form-group col-12 col-sm-6 col-xl-4">
                                <label>State</label>
                                <ejs-dropdownlist 
                                    [dataSource]="states" 
                                    [fields]="{ text: 'key', value: 'id' }" 
                                    placeholder="Select a State" 
                                    formControlName="State"
                                    [allowFiltering]="true"
                                    (filtering)="globals.onDropdownFiltering($event, states)" />
                            </div>
                    
                            <div class="form-group col-12 col-sm-6 col-xl-4 required">
                                <label>Zip</label>
                                <ejs-textbox formControlName="Zip" />
                                <div *ngIf="caseFilePatientAddressFormComponent().get('Zip')?.errors" class="error-container">
                                    <div *ngIf="caseFilePatientAddressFormComponent().get('Zip')?.errors?.['required'] && caseFilePatientAddressFormComponent().get('Zip')?.touched" class="e-error">
                                        <span>This field is required.</span>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </form>
        </div>

        <div class="info-box">
            <h3>Law Firm Info</h3>
        
            <div class="row">
                <div class="form-group col-12 col-md-6 col-xl-4">
                    <label>Law Firm</label>
                    <ejs-dropdownlist formControlName="LawFirmId" 
                        [dataSource]="lawFirms" 
                        [fields]="lawFirmsFields"
                        [allowFiltering]="true"
                        (filtering)="globals.onDropdownFiltering($event, lawFirms, 'Text')" />
                    <div *ngIf="caseFileForm().get('LawFirmId')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('LawFirmId')?.errors?.['required'] && caseFileForm().get('LawFirmId')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Law Firm Contact</label>
                    <ejs-dropdownlist formControlName="LawFirmContact" 
                        [dataSource]="contacts"
                        [fields]="contactsFields"
                        [allowFiltering]="true"
                        (filtering)="globals.onDropdownFiltering($event, contacts, 'ContactName')" />
                    <div *ngIf="caseFileForm().get('LawFirmContact')?.errors" class="error-container">
                        <div *ngIf="caseFileForm().get('LawFirmContact')?.errors?.['required'] && caseFileForm().get('LawFirmContact')?.touched"
                            class="e-error">
                            <span>This field is required.</span>
                        </div>
                    </div>
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Attorney</label>
                    <ejs-dropdownlist formControlName="Attorney" 
                        [dataSource]="contacts"
                        [fields]="contactsFields"
                        [allowFiltering]="true"
                        (filtering)="globals.onDropdownFiltering($event, contacts, 'ContactName')" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Law Firm File #</label>
                    <ejs-textbox formControlName="LawFirmFileNumber" />
                </div>
    
                <div class="form-group col-12 col-sm-6 col-xl-4">
                    <label>Statute Of Limitations</label>
                    <ejs-datepicker formControlName="StatuteOfLimitations" />
                </div>
            </div>
        </div>

        <div class="form-group col-12" *ngIf="showFormButtons === true">
            <div class="d-flex flex-row justify-content-end col-12">
                <button ejs-button isPrimary="false" cssClass="e-outline" (click)="formReset()">Reset</button>
                <button ejs-button type="submit" isPrimary="true">Submit</button>
            </div>
        </div>
    </form>

</div>