// ANgular
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Internal
import { Theme, ThemeColors } from '../../models/services/user-preferences.model';
import { UserPreferencesService } from '../user/user-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    private user: UserPreferencesService
  ) { }

  themes: Theme[] = [
    { Id: 'default', Title: 'Case Compass Default', PrimaryColor: '', SecondaryColor: '', TertiaryColor: '' },
    { Id: 'basic', Title: 'Purple Gray', PrimaryColor: '#7e57c2', SecondaryColor: '#8F90BB', TertiaryColor: '#515F79' },
    { Id: 'ocean', Title: 'Ocean Breeze', PrimaryColor: '#0288d1', SecondaryColor: '#26a69a', TertiaryColor: '#29b6f6' },
    { Id: 'orange', Title: 'Orange Dream', PrimaryColor: '#d14b02', SecondaryColor: '#fda035', TertiaryColor: '#ef7550' },
    { Id: 'glamour', Title: 'Bright Glamour', PrimaryColor: '#e53935', SecondaryColor: '#ec4043', TertiaryColor: '#bc4783' },
    { Id: 'rainforest', Title: 'Rainforest Green', PrimaryColor: '#00897b', SecondaryColor: '#97dd80', TertiaryColor: '#4db6ac' },
    { Id: 'purple', Title: 'Royal Purple', PrimaryColor: '#ba68c8', SecondaryColor: '#5e35b1', TertiaryColor: '#bc4783' },
    { Id: 'modern', Title: 'Modernly Slick', PrimaryColor: '#1fcd9e', SecondaryColor: '#718096', TertiaryColor: '#858b9d' },
  ];

  setTheme(primary: string, secondary: string, tertiary: string) {
    document.body.style.setProperty('--color-sf-primary', this.hexToRgb(primary));
    document.body.style.setProperty('--color-sf-secondary', this.hexToRgb(secondary));
    document.body.style.setProperty('--color-sf-tertiary', this.hexToRgb(tertiary));
    document.body.style.setProperty('--color-sf-button-color', this.getContrastColor(primary));
  }

  resetTheme() {
    document.body.removeAttribute('style');
    const userPreferences = JSON.parse(localStorage.getItem('userPreferences') as string);
    userPreferences['theme'] = 'default';
    userPreferences['customThemeColors'] = {};
    localStorage.setItem('userPreferences', JSON.stringify(userPreferences));
  }

  getThemeColors(themeId: string) {
    const theme = this.themes.find(x => x.Id === themeId) ?? this.themes[0];
    const colors: ThemeColors = {
      PrimaryColor: theme.PrimaryColor,
      SecondaryColor: theme.SecondaryColor,
      TertiaryColor: theme.TertiaryColor
    }

    return colors;
  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.substring(0, 7));
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : "0, 0, 0";
  }

  rgbToHex(input: string | boolean) {
    if (input === false) {
      return '#FF0000'
    } else {
      let res = (input as string).replace(/\s/g, '').split(',');
      return this.rgbToHexHelper(res[0], res[1], res[2])
    }
  }

  rgbToHexHelper(r: any, g: any, b: any) {
    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
  }

  hexToRgbArray(hex: string): [number, number, number] {
    // Remove '#' if present
    hex = hex.replace(/^#/, '');
  
    // Convert 3-digit hex to 6-digit hex
    if (hex.length === 3) {
      hex = hex.split('').map(function (hexPart) {
        return hexPart + hexPart;
      }).join('');
    }
  
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    return [r, g, b];
  }

  luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v: any) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  contrastRatio(l1: number, l2: number): number {
    return (l1 + 0.05) / (l2 + 0.05);
  }

  getContrastColor(hexColor: string): string {
    const rgb = this.hexToRgbArray(hexColor);
    const luminanceColor = this.luminance(rgb[0], rgb[1], rgb[2]);
    const luminanceWhite = this.luminance(255, 255, 255); // Luminance for white
    const ratio = this.contrastRatio(luminanceWhite, luminanceColor);

    // If contrast ratio is >= 4.5, return white, else return black
    return ratio >= 3.5 ? '#FFFFFF' : '#000000';
  }
}
