<div *ngIf="keys" class="row">
    <div class="col-md-6" *ngFor="let key of keys">
        <div class="column-container">
            <label>{{ formatKey(key) }}: </label>
            <ng-container [ngSwitch]="getDataType(data[key], key)">
                <ng-container *ngSwitchCase="'status'">
                    <status [status]="data[key]"></status>
                </ng-container>
                <ng-container *ngSwitchCase="'array'">
                    <div *ngFor="let obj of data[key]; let i = index">
                        <button ejs-button (click)="toggleAppointment(key, i)">
                            {{ expanded[key + '_' + i] ? 'Close' :'Expand'}}</button>
                        <div *ngIf="expanded[key + '_' + i]">
                            <pre>{{ obj | json }}</pre>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'empty'">
                    {{'null'}}
                </ng-container>
                <ng-container *ngSwitchCase="'object'">
                    <ng-container *ngIf="isNestedObject(data[key]); else nonNestedObject">
                        <button ejs-button (click)="toggleNested(key)">{{ expanded[key] ? 'Close' : 'Expand'}}</button>
                        <!-- Handle nested objects with modal -->
                        <ejs-dialog [showCloseIcon]="true" [isModal]="true" [closeOnEscape]="true" [visible]="expanded[key]"
                            [header]="getName(data[key])" (open)="show(key)" (close)="hide(key)">
                            <ng-template #content>
                                <!-- Content inside the dialog should be enclosed within this ng-template tag -->
                                <div *ngFor="let nestedKey of getObjKeys(data[key])" class="nested-object">
                                    <label>{{ this.formatKey(nestedKey) }}: </label>
                                    <item-view-helper [isEditing]="isEditing" [data]="data[key][nestedKey]"
                                        [isBoolean]="isBoolean(nestedKey, data[key][nestedKey])"></item-view-helper>
                                </div>
                            </ng-template>
                        </ejs-dialog>
                    </ng-container>
                    <ng-template #nonNestedObject>
                        <item-view-helper [isEditing]="isEditing" [data]="data[key]" [isBoolean]="isBoolean(key, data[key])"></item-view-helper>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <item-view-helper [isEditing]="isEditing" [data]="data[key]" [isBoolean]="isBoolean(key, data[key])"></item-view-helper>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>