
<div id="bulk-edit-remittance-form" [class]="formClasses.formContainer">

    <form [formGroup]="bulkEditRemittancesForm" [class]="formClasses.form" (ngSubmit)="submit()">

        <section [class]="formClasses.section + ' remittances-section'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('PaymentMethod')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="bulkEditRemittancesForm.get('PaymentMethod')?.errors && bulkEditRemittancesForm.get('PaymentMethod')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="remittanceIcons.paymentMethod" />
                        <ejs-tooltip *ngIf="bulkEditRemittancesForm.get('PaymentMethod')?.errors && bulkEditRemittancesForm.get('PaymentMethod')?.touched" [content]="getErrorMessage('PaymentMethod')" />
                    </span>
                    <ejs-dropdownlist formControlName="PaymentMethod" 
                        [placeholder]="'Payment Method'" 
                        [class]="formClasses.input"
                        [dataSource]="paymentMethodData"
                        [query]="paymentMethodQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="bulkEditRemittancesForm.get('PaymentMethod')?.value"
                        (created)="onBeforeOpenPaymentMethod($event)" />
                    <ng-container *ngIf="bulkEditRemittancesForm.get('PaymentMethod')?.invalid && bulkEditRemittancesForm.get('PaymentMethod')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('PaymentStatus')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="bulkEditRemittancesForm.get('PaymentStatus')?.errors && bulkEditRemittancesForm.get('PaymentStatus')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="remittanceIcons.paymentStatus" />
                        <ejs-tooltip *ngIf="bulkEditRemittancesForm.get('PaymentStatus')?.errors && bulkEditRemittancesForm.get('PaymentStatus')?.touched" [content]="getErrorMessage('PaymentStatus')" />
                    </span>
                    <ejs-dropdownlist formControlName="PaymentStatus" 
                        [placeholder]="'Payment Status'" 
                        [class]="formClasses.input"
                        [dataSource]="paymentStatusData"
                        [query]="paymentStatusQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="bulkEditRemittancesForm.get('PaymentStatus')?.value"
                        (created)="onBeforeOpenPaymentStatus($event)" />
                    <ng-container *ngIf="bulkEditRemittancesForm.get('PaymentStatus')?.invalid && bulkEditRemittancesForm.get('PaymentStatus')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('DatePaid')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="bulkEditRemittancesForm.get('DatePaid')?.errors && bulkEditRemittancesForm.get('DatePaid')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="remittanceIcons.datePaid" />
                        <ejs-tooltip *ngIf="bulkEditRemittancesForm.get('DatePaid')?.errors && bulkEditRemittancesForm.get('DatePaid')?.touched" [content]="getErrorMessage('DatePaid')" />
                    </span>
                    <ejs-datepicker formControlName="DatePaid" placeholder="Date Paid" [class]="formClasses.input" />
                    <ng-container *ngIf="bulkEditRemittancesForm.get('DatePaid')?.invalid && bulkEditRemittancesForm.get('DatePaid')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('TransactionNumber')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="bulkEditRemittancesForm.get('TransactionNumber')?.errors && bulkEditRemittancesForm.get('TransactionNumber')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="remittanceIcons.transactionNumber" />
                        <ejs-tooltip *ngIf="bulkEditRemittancesForm.get('TransactionNumber')?.errors && bulkEditRemittancesForm.get('TransactionNumber')?.touched" [content]="getErrorMessage('TransactionNumber')" />
                    </span>
                    <ejs-textbox formControlName="TransactionNumber" placeholder="Transaction Number" [class]="formClasses.input" />
                    <ng-container *ngIf="bulkEditRemittancesForm.get('TransactionNumber')?.invalid && bulkEditRemittancesForm.get('TransactionNumber')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('InvoiceNotes')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="remittanceIcons.invoiceNotes" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="InvoiceNotes" placeholder="Invoice Notes" [type]="'textarea'" [multiline]="true" />
                </div>

            </div>
    
        </section>

        <section [class]="formClasses.section + ' col-12'" *ngIf="displaySubmit">
            <div [class]="formClasses.actions">
                <button ejs-button type="button">Cancel</button>
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
            </div>
        </section>

    </form>

</div>