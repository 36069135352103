// Angular
import { Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';

// 3rd Party
import { Dialog } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { DropdownSingleComponent } from "../../../ui/dropdown-single/dropdown-single.component";
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { LawFirm, LawFirmUpdateDTO } from '@models/data-contracts';

@Component({
  selector: 'app-bulk-edit-lawfirms',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DialogModule,
    ButtonModule,
    CheckBoxModule,
    DropdownSingleComponent,
    ComboBoxModule
  ],
  templateUrl: './bulk-edit-lawfirms.component.html',
  styleUrl: './bulk-edit-lawfirms.component.scss'
})
export class BulkEditLawFirmsComponent {
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
  ) { }

  @ViewChild('Modal') modal: Dialog;
  selectedRows: LawFirm[] = [];
  marketManagersDataSource: any;
  caseManagersDataSource: any;
  marketManagersQuery: Query;
  caseManagersQuery: Query;

  // Make APIEndpoints available to template
  protected APIEndpoints = APIEndpoints;

  editForm = this.fb.group({
    MarketManager: [null],
    CaseManager: [null],
    Active: [false]
  });

  showModal() {
    this.modal.show();
  }

  ngOnInit() {
    // Market Managers
    this.marketManagersDataSource = this.api.getOdata(APIEndpoints.Users);
    this.marketManagersQuery = new Query().select('Id,Name')
      .expand('XrefUsersRoles($expand=Role($select=RoleName))')
      .where('XrefUsersRoles/any(x: x/Role/RoleName eq \'Market Manager\')', 'equal', true);

    // Case Managers
    this.caseManagersDataSource = this.api.getOdata(APIEndpoints.Users);
    this.caseManagersQuery = new Query().select('Id,Name')
      .expand('XrefUsersRoles($expand=Role($select=RoleName))')
      .where('XrefUsersRoles/any(x: x/Role/RoleName eq \'Case Manager\')', 'equal', true);

  }

  beforeModalOpened(args: any) {
    args.maxHeight = '85vh';
  }

  submit = async () => {
    try {
      const changedLawFirms: LawFirmUpdateDTO[] = this.selectedRows.map(lawFirm => ({
        Id: lawFirm.Id,
        ...this.getChangedValues()
      }));

      const syncFusionDTO = {
        Action: "Update",
        Added: [],
        Changed: changedLawFirms,
        Deleted: []
      };

      const response = await this.api.basicPost(APIEndpoints.LawfirmsBulk, syncFusionDTO);
      
      this.modal.hide();
      window.location.reload();
    } catch (error) {
      console.error('Error in submit:', error);
    }
  }

  private getChangedValues(): Partial<LawFirmUpdateDTO> {
    const changes: Partial<LawFirmUpdateDTO> = {};
    const formValues = this.editForm.value;

    // Only include fields that have been changed
    if (formValues.MarketManager !== null) {
      changes.MarketManager = formValues.MarketManager;
    }
    if (formValues.CaseManager !== null) {
      changes.CaseManager = formValues.CaseManager;
    }
    if (formValues.Active !== null) {
      changes.Active = formValues.Active;  // Let the API handle the boolean conversion
    }

    return changes;
  }

  onSubmit() {
    this.submit();
  }
} 
