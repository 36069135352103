// Angular
import { Injectable, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
// 3rd Party
import { Query } from '@syncfusion/ej2-data';
// Internal
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

export interface OpenFile {
  name: string;
  id: string;
}

export interface Tab {
  text: string;
  iconCss?: string;
  active: boolean;
  onClick?: () => any;
}

@Injectable({
  providedIn: 'root',
})
export class FileViewService {
  constructor(
    private router: Router,
    private user: UserPreferencesService,
    private location: Location,
    private globals: GlobalsService,
    private api: ApiService,
    private toast: ToastMessageService
  ) {
    this.screenWidth = window.innerWidth;
    this.loadTabSettings();
  }
  screenWidth: any;
  isMobileMode = (): boolean => this.screenWidth <= 1033;

  // remove after sub components are getting all data
  private readonly caseFileExpands = [
    'Agreements',
    'AttorneyNavigation',
    // 'Appointments',
    'CaseManagerNavigation',
    'CaseTypeNavigation',
    'FileGroup',
    'Invoices',
    'Lawfirm',
    'LawFirmContactNavigation',
    'MarketManagerNavigation',
    'PAndLNavigation',
    'Patient(expand=Address)',
    'ReferralSourceNavigation',
    'ReferringPhysicianNavigation',
    'StatusingGroupNavigation',
    'ReferralSourceNavigation',
    'ReferringPhysicianNavigation',
    'CaseFileCompliance',
    'XrefWorkItemCaseFile($expand=WorkItem)',
    // corresponds Nexus.Models.CaseFile virtual refrences
  ];

  // grabs url and removes dashes
  urlParts = () => this.router.url.replace(/-/g, '').split(/[\/#]/).splice(1);

  // formats url
  getUrlInfo() {
    return {
      beginning: this.urlParts()[0] || null,
      middle: this.urlParts()[1] || null,
      end: this.urlParts()[2] || null,
    };
  }

  private query: Query;

  // checks if url is casefile
  isCaseFile = () =>
    this.getUrlInfo().beginning === 'casefiles' &&
    isNaN(Number(this.getUrlInfo().middle));

  // creates query based on url
  getQuery = () => {
    const queryField = this.isCaseFile() ? 'CaseNumber' : 'Id';
    const queryValue = this.isCaseFile()
      ? this.getUrlInfo().middle
      : Number(this.getUrlInfo().middle);
    this.query = new Query().where(queryField, 'equal', queryValue);

    // todo add other cases if they need expands
    switch (this.getUrlInfo().beginning) {
      case 'casefiles':
        this.query.expand(this.caseFileExpands);
        break;
      case 'patients':
        this.query.expand(['Address']);
        break;
      default:
    }
  };

  // redurect case file and keeps hash if case file is number
  redirectCaseFile(caseNumber: string): void {
    if (!isNaN(Number(this.getUrlInfo().middle))) {
      this.location.replaceState(`case-files/${caseNumber}`);
      const hash = this.getUrlInfo()?.end || 'Home';
      window.location.hash = hash;
    }
  }

  // saves open file to user settings
  saveOpenFile(title: string, caseNumber: string) {
    const openFiles = this.user.getLocalUserPreference('openFiles') || [];
    if (title && !openFiles.find((file: OpenFile) => file.id === caseNumber)) {
      openFiles.push({
        id: caseNumber,
        name: title,
      });
      // this.user.saveUserSetting('openFiles', openFiles);
      // this.user.patchUserPreferencesJustin();
    }
  }

  // sets file data from query
  queryCallback = (
    data: any,
    fileInfo: WritableSignal<any>,
    title: WritableSignal<string>
  ) => {
    if (!data.result.length) this.router.navigate(['/not-found']);
    fileInfo.set(data.result[0] || {});
    title.set(this.globals.getName(data.result[0]));

    if (this.getUrlInfo().beginning === 'casefiles') {
      this.redirectCaseFile(fileInfo().CaseNumber);
      this.saveOpenFile(title(), fileInfo().CaseNumber);
    }
  };

  // gets data and rusns callback
  async fetchData(fileInfo: WritableSignal<any>, title: any) {
    this.getQuery();
    return await this.api.getOdata(`/${this.getUrlInfo().beginning}`).executeQuery(this.query).then((data: any) => {
      return this.queryCallback(data, fileInfo, title);
    })
    .catch((error) => {
      this.toast.showError(
        `Error fetching data from /${
          this.getUrlInfo().beginning
        } : ${error.toString()}`
      );
    });
  }
  showLeftColumn: WritableSignal<boolean> = signal(false); // Initially hide the left column
  toggleLeftColumn = () => this.showLeftColumn.set(!this.showLeftColumn());

  changeHash(newHash: string): void {
    this.showLeftColumn.set(false);
    window.location.hash = newHash;
  }

  private tabSettings: { [key: string]: boolean } = {};
  public tabSettingsSubject: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject(this.loadTabSettings());

  private loadTabSettings(): { [key: string]: boolean } {
    const savedSettings = this.user.getLocalUserPreference('caseFileTabs');
    if (!savedSettings) {
      const defaultSettings = this.createDefaultTabSettings();
      // this.user.saveUserSetting('caseFileTabs', defaultSettings);
      return defaultSettings;
    }
    this.tabSettings = savedSettings;
    return this.tabSettings;
  }

  private createDefaultTabSettings(): { [key: string]: boolean } {
    const defaultSettings: any = {};
    const tabs: Tab[] = [
      {
        text: 'My Files',
        iconCss: 'e-drop-down',
        active: true,
        onClick: this.toggleLeftColumn,
      },
      {
        text: 'Home',
        iconCss: 'e-calculated-member',
        active: true,
        onClick: () => this.changeHash('Home'),
      },
      {
        text: 'Appointments',
        iconCss: 'e-timeline-today',
        active: true,
        onClick: () => this.changeHash('Appointments'),
      },
      {
        text: 'Work Items',
        iconCss: 'e-list-ordered',
        active: true,
        onClick: () => this.changeHash('WorkItems'),
      },
      {
        text: 'Financial',
        iconCss: 'e-sum',
        active: true,
        onClick: () => this.changeHash('Financial'),
      },
      {
        text: 'Documents',
        iconCss: 'e-folder-open',
        active: true,
        onClick: () => this.changeHash('Documents'),
      },
      {
        text: 'Authorizations',
        iconCss: 'e-check-box',
        active: true,
        onClick: () => this.changeHash('Authorizations'),
      },
      {
        text: 'Injury',
        iconCss: 'e-download',
        active: true,
        onClick: () => this.changeHash('Injury'),
      },
      {
        text: 'Box',
        iconCss: 'e-print-3',
        active: true,
        onClick: () => this.changeHash('Box'),
      },
      {
        text: 'Compliance',
        iconCss: 'e-description',
        active: true,
        onClick: () => this.changeHash('Compliance'),
      },
      {
        text: 'Settings',
        iconCss: 'e-settings',
        active: true,
        onClick: () => this.changeHash('Settings'),
      },
    ];
    for (const tab of tabs) {
      defaultSettings[tab.text] = true; // All tabs visible by default
    }
    return defaultSettings;
  }

  getTabSettings(): Observable<{ [key: string]: boolean }> {
    return this.tabSettingsSubject.asObservable();
  }

  tabs: Tab[] = [
    {
      text: 'My Files',
      iconCss: 'e-drop-down',
      active: true,
      onClick: this.toggleLeftColumn,
    },
    {
      text: 'Home',
      iconCss: 'e-calculated-member',
      active: true,
      onClick: () => this.changeHash('Home'),
    },
    {
      text: 'Appointments',
      iconCss: 'e-timeline-today',
      active: this.tabSettingsSubject.value['Appointments'],
      onClick: () => this.changeHash('Appointments'),
    },
    {
      text: 'Work Items',
      iconCss: 'e-list-ordered',
      active: this.tabSettingsSubject.value['WorkItems'],
      onClick: () => this.changeHash('WorkItems'),
    },
    {
      text: 'Financial',
      iconCss: 'e-sum',
      active: this.tabSettingsSubject.value['Financial'],
      onClick: () => this.changeHash('Financial'),
    },
    {
      text: 'Documents',
      iconCss: 'e-folder-open',
      active: this.tabSettingsSubject.value['Documents'],
      onClick: () => this.changeHash('Documents'),
    },
    {
      text: 'Authorizations',
      iconCss: 'e-check-box',
      active: this.tabSettingsSubject.value['Authorizations'],
      onClick: () => this.changeHash('Authorizations'),
    },
    {
      text: 'Injury',
      iconCss: 'e-download',
      active: this.tabSettingsSubject.value['Injury'],
      onClick: () => this.changeHash('Injury'),
    },
    {
      text: 'Box',
      iconCss: 'e-print-3',
      active: this.tabSettingsSubject.value['Box'],
      onClick: () => this.changeHash('Box'),
    },
    {
      text: 'Compliance',
      iconCss: 'e-description',
      active: this.tabSettingsSubject.value['Compliance'],
      onClick: () => this.changeHash('Compliance'),
    },
    {
      text: 'Settings',
      iconCss: 'e-settings',
      active: true,
      onClick: () => this.changeHash('Settings'),
    },
  ];

  getAppointments() {
    
  }
}
