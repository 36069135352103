<div id="fee-schedules-grid" class="fee-schedules-grid">

    <grid-template #feeSchedulesGrid [settings]="feeSchedulesGridSettings" [name]="'Fee Schedules'" [useRoundedEdges]="true" />
<!-- 
    <custom-assignments-editor #customProvidersEditor 
        [availableGridSettings]="availableProvidersGridSettings"
        [assignmentsGridSettings]="assignedProvidersGridSettings"
        [assignmentsGridPredicate]="attachedProvidersPredicate"
        [assignmentsGridPredicateField]="'Name'"
        [assignmentsIds]="assignedProvidersIds" /> -->

    <ejs-dialog #addFeeScheduleDialog
        [showCloseIcon]="true"
        [target]="'#fee-schedules-grid'"
        [isModal]="true"
        [closeOnEscape]="true"
        [visible]="isAddFeeScheduleDialogVisible"
        [header]="'Add Fee Schedule'"
        (beforeOpen)="beforeOpenAddFeeScheduleDialog($event)"
        (close)="handleSubmitComplete()">
            <add-fee-schedule-form 
                #addFeeScheduleForm 
                [existingFeeSchedule]="existingFeeSchedule()" 
                (submit)="handleSubmitComplete()"
                (close)="addFeeScheduleDialog.hide()"
            />
    </ejs-dialog>

</div>