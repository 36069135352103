import { Query } from '@syncfusion/ej2-data';

export const lawFirmQuery = new Query().expand([
    `XrefLawFirmContacts(
      $expand=Contact(
        $expand=XrefPhoneContacts($expand=Phone),
        XrefAddressContacts($expand=Address($expand=StateNavigation))
      )
    )`,
    'CaseManagerNavigation,MarketManagerNavigation',
    'XrefAddressLawfirms($expand=Address($expand=StateNavigation))',
    'XrefPhoneLawfirms($expand=Phone)'
])