import { formatDate } from "@angular/common";

export function formatDateToMMDDYYYY(dateString: string | null | undefined): string {
  if (!dateString) return 'N/A';
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'N/A';
    return formatDate(date, 'MM/dd/yyyy', 'en-US');
  } catch {
    return 'N/A';
  }
}

export function capitalizeFirstLetter(text: string): string {
  return text
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
}

export function formatBoolean(value: number | boolean): string {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value === 1 ? 'Yes' : 'No';
}

