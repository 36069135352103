<div id="procedure-codes-grid" class="procedure-codes-grid">

    <div class="loading-container" *ngIf="loadingData(); else procedureCodesGrid">
        <loading-overlay [loading]="loadingData" />
    </div>

    <ng-template #procedureCodesGrid>
        <grid-template #procedureCodesGrid [settings]="procedureCodesGridSettings" [name]="'Procedure Codes'" [useRoundedEdges]="true"></grid-template>

        <div *ngIf="isAddProcCodeDialogVisible">
            <ejs-dialog #addProcCodeDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="isAddProcCodeDialogVisible"
                [width]="'50%'"
                [header]="'Add Procedure Codes'"
                (close)="isAddProcCodeDialogVisible = false"
                (beforeOpen)="beforeOpenAddProcCodeDialog($event)">
                <add-procedure-code-form #addProcedureCodeForm (closeDialog)="isAddProcCodeDialogVisible = false"></add-procedure-code-form>
            </ejs-dialog>
        </div>

        <!-- Add Edit Dialog -->
        <div *ngIf="isEditProcCodeDialogVisible">
            <ejs-dialog #editProcCodeDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="isEditProcCodeDialogVisible"
                [width]="'33%'"
                [header]="'Edit Procedure Code'"
                (close)="isEditProcCodeDialogVisible = false">
                <edit-procedure-code-form 
                    [procedureCode]="selectedProcedureCode"
                    (closeDialog)="isEditProcCodeDialogVisible = false"
                    (refreshGrid)="refreshGrid()">
                </edit-procedure-code-form>
            </ejs-dialog>
        </div>
    </ng-template>
</div>