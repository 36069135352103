// Angular
import { Component, signal, ViewChild, WritableSignal, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AccordionAllModule } from '@syncfusion/ej2-angular-navigations';
import { GridModel, RowSelectEventArgs, CommandClickEventArgs, DetailDataBoundEventArgs } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, Deposit, Invoice, ReductionRequest } from '@models/data-contracts';
import { PerformanceData } from '@models/components/financial-performance.model';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { FileHubService } from '@services/file-hub/file-hub.service';
import { TabCardComponent } from '@fileHub-tabs/tab-card/tab-card.component';
import { LoadingModule } from '@modules/loading.module';
import { StatusComponent } from '@ui/status/status.component';
import { GridTemplateModule } from "@modules/grid-template.module";
import { ViewBalanceStatementComponent } from '../../../features/balance-statement/view-balance-statement/view-balance-statement.component';
import { GenerateBalanceStatementComponent } from '../../../features/balance-statement/generate-balance-statement/generate-balance-statement.component';
import { createGridDiv, GridDetailsConfig, onGridDetailDataBound } from '../../../grids/helpers';
import { TransferInvoiceComponent } from '../../../forms/transfer-invoice/transfer-invoice.component';
import { AddDepositForm } from "../../../forms/add-forms/add-deposit/add-deposit.component";
import { AddInvoiceForm } from '../../../forms/add-forms/add-invoice/add-invoice.component';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';


@Component({
  selector: 'financial-tab',
  standalone: true,
  imports: [
    CommonModule,
    AccordionAllModule,
    DialogAllModule,
    LoadingModule,
    TabCardComponent,
    StatusComponent,
    GridTemplateModule,
    ViewBalanceStatementComponent,
    GenerateBalanceStatementComponent,
    TransferInvoiceComponent,
    AddDepositForm,
    AddInvoiceForm,
    AuditLogsComponent
],
  templateUrl: './financial-tab.component.html',
  styleUrl: './financial-tab.component.scss'
})
export class FinancialTab implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private api: ApiService,
    private globals: GlobalsService,
    public fileHub: FileHubService,
    private auditLogService: AuditLogService,
    private toast: ToastMessageService
  ) {}

  private caseFile: CaseFile;
  private invoices: Invoice[];
  private deposits: Deposit[];
  loadingFinancialPerformanceData: WritableSignal<boolean> = signal(true);
  editingInvoice: WritableSignal<Invoice | null> = signal(null);
  performanceData?: PerformanceData;
  reductionsData: ReductionRequest[];
  depositsGrid: GridModel;
  invoicesGrid: GridModel;

  @ViewChild('generateBalanceStatement') public generateBalanceStatement: DialogComponent;

  @ViewChild('viewBalanceStatement') public viewBalanceStatement: DialogComponent;
  public viewBalanceStatementVisibility: boolean = false;

  @ViewChild('transferInvoice') public transferInvoice: DialogComponent;
  public transferInvoiceVisibility: boolean = false ;

  @ViewChild('addNewInvoice') public addNewInvoice: DialogComponent;
  public addNewInvoiceVisibility: boolean = false;

  @ViewChild('addDeposit') public addDeposit: DialogComponent;
  public addDepositVisibility: boolean = false;

  @ViewChild('logsDialog') logsDialog: any;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility: boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  showFinancialSection: boolean = false; // Default to showing invoices

  ngOnInit() {
    // Subscribe to changes in the fileHub service
    this.fileHub.reload$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((sub) => {
      this.checkData();
    });
  }

  ngAfterViewInit() {
    this.checkData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Verifies data is available
  async checkData() {
    if (!this.fileHub.caseFile) return; // Exit if no case file
    await this.addFinancialDataToCaseFile();
  }

  async addFinancialDataToCaseFile() {
    let expanded: string[] = [];
    let selected: string[] = [];
    const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFile?.Id})`;
    const query = new Query().expand('Invoices($expand=InvoiceRows,InvoicePayments,Provider),Deposits,ReductionRequests').select('Invoices,Deposits,ReductionRequests');

    if (!this.globals.objHasKey(this.fileHub.caseFile, 'Invoices')) {
      expanded.push('Invoices');
      selected.push('Invoices');
    }

    if (!this.globals.objHasKey(this.fileHub.caseFile, 'Deposits')) {
      expanded.push('Deposits');
      selected.push('Deposits');
    } 

    if (!this.globals.objHasKey(this.fileHub.caseFile, 'ReductionRequests')) {
      expanded.push('ReductionRequests');
      selected.push('ReductionRequests');
    }

    try {

      const financialDataSetup = this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        if (res.result.length > 0) {
          return res.result;
        } else {
          console.error('No results found.');
          return undefined;
        }
      });

      await financialDataSetup.then((result) => {
        const financialData = result[0] as CaseFile;
        this.fileHub.updateCaseFile('Invoices', financialData.Invoices);
        this.fileHub.updateCaseFile('Deposits', financialData.Deposits);
        this.fileHub.updateCaseFile('ReductionRequests', financialData.ReductionRequests);
        this.reductionsData = financialData.ReductionRequests ?? [];
        this.setInvoicesGrid();
        this.setDepositsGrid();
        this.getPerformanceData();
      });

      this.loadingFinancialPerformanceData.set(false);
      return financialDataSetup;

    } catch (error) {
      console.error('Error loading financial data:', error);
      this.loadingFinancialPerformanceData.set(false);
      return undefined;
    }
  }

  async getPerformanceData() {
    try {
      if (!this.fileHub.performanceData) await this.fileHub.setPerformanceData(this.invoices, this.deposits); 
      this.performanceData = this.fileHub.performanceData;
      this.loadingFinancialPerformanceData.set(false);
    } catch (error) {
      console.error('Error loading performance data:', error);
      this.loadingFinancialPerformanceData.set(false);
    }
  }

  // Deposits grid
  setDepositsGrid() {
    if (!this.fileHub.caseFile?.Id) return;

    this.depositsGrid = {
      dataSource: this.api.getOdata(APIEndpoints.Deposits),
      toolbar: [{text: 'Add Deposit', tooltipText: 'Add Deposit', id: 'AddDeposit', align: 'Left'}],
      query: new Query().where('CaseFileId', 'equal', this.fileHub.caseFile.Id),
      columns: [
        { field: 'Id' },
        { field: 'DepositDate', headerText: 'Date' },
        { field: 'FinalCheck', headerText: 'Final Check'},
        { field: 'Notes', headerText: 'Notes', visible: false },
        { field: 'DepositAmount', headerText: 'Amount', format: 'C2' },
        { field: 'DepositToAccount', headerText: 'P&L', visible: false },
        { field: 'CourtesyReduction', headerText: 'Courtesy Reduction', visible: false },
      ],
      toolbarClick: ($event: any) => this.onDepositsGridCustomToolbarClick($event),
    }

    return;
  }

  onDepositsGridCustomToolbarClick(args: any) {

    if (args.item.id === 'AddDeposit') {
      args.cancel = true;
      this.addDepositVisibility = true;
      if (this.addDeposit) this.addDeposit.show();
    }
  }

  // Invoices grid
  invoiceRowColumns = [
    { field: 'Id', isPrimaryKey: true, visible: false },
    { field: 'DateOfService', headerText: 'Service Date', editType: 'datepickeredit', type: 'date', format: 'yyyy-MM-dd' },
    { field: 'ProcedureCode.Description', headerText: 'Procedure', editType: 'dropdownedit' },
    { field: 'ReimbursementRate', headerText: 'Reimbursement Rate', editType: 'numericedit', format: 'P' },
    { field: 'AmountBilled', headerText: 'Billed Amount', format: 'C2', editType: 'numericEdit' },
    { field: 'TotalDueProvider', headerText: 'Provider Amount', format: 'C2', editType: 'numericEdit' },
    { field: 'SettlementValue', headerText: 'Settlement', format: 'C2', editType: 'numericEdit' },
  ];
  invoicePaymentsColumns = [
    { field: 'Id', isPrimaryKey: true, visible: false },
    { field: 'DatePaid', headerText: 'Payment Date', editType: 'datepickeredit', type: 'date', format: 'yyyy-MM-dd' },
    { field: 'BalanceDue', headerText: 'Balance', format: 'C2', editType: 'numericEdit' },
    { field: 'AmountPaid', headerText: 'Amount Paid', format: 'C2', editType: 'numericEdit' },
    { field: 'PaymentMethodNavigation.Description', headerText: 'Method', editType: 'dropdownedit' },
    { field: 'PaymentStatusNavigation.Description', headerText: 'Status', editType: 'dropdownedit' }
  ];
  invoiceDetailsSettings = [
    {header: 'Invoice Rows', columns: this.invoiceRowColumns, dataKey: "InvoiceRows", renderMode: ("Vertical" as any)},
    {header: 'Invoice Payments', columns: this.invoicePaymentsColumns, dataKey: "InvoicePayments", renderMode: ("Vertical" as any)}
  ]
  selectedInvoices: WritableSignal<Invoice[] | null> = signal(null); // transfer invoice setup

  setInvoicesGrid() {
    const endpoint = APIEndpoints.Invoices;
    const query = new Query().where('CaseFileId', 'equal', this.fileHub.caseFile?.Id).expand('CaseFile($expand=Patient),InvoiceRows($expand=ProcedureCode),InvoicePayments($expand=PaymentStatusNavigation,PaymentMethodNavigation),Provider');
    this.invoicesGrid = {
      dataSource: this.api.getOdata(endpoint),
      pageSettings: { pageSize: 6},
      query: query,
      toolbar: [
        {text: 'Add Invoice', tooltipText: 'Add Invoice', id: 'AddInvoice', align: 'Left'},
        {text: 'Transfer Invoice', tooltipText: 'Transfer', id: 'TransferInvoice', align: 'Left'},
      ],
      columns:  [
        { type: 'checkbox', headerText: 'Select', allowFiltering: false, visible: true },
        { field: 'Id', visible: false },
        { field: 'ProviderId', headerText: 'Provider Id', visible: false },
        { field: 'ProviderInvoiceNumber', headerText: 'Provider Invoice' },
        { field: 'InternalInvoiceNumber', headerText: 'Internal Invoice' },
        { field: 'InvoiceDate', headerText: 'Date' },
        { field: 'LockInvoice', headerText: 'Lock' },
        { field: 'Notes', headerText: 'Notes' },
        { field: 'SplitInvoice', headerText: 'Split', visible: false },
        { field: 'SplitInvoiceId', headerText: 'Split Id', visible: false },
        { field: 'InvoiceTransferred', headerText: 'Transferred', visible: false },
        { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
        { type: 'commands', headerText: 'Actions', commands: [
          { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
          { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
        ] }
      ],
      selectionSettings: { checkboxOnly: true },
      rowSelecting: ($event: any) => this.onInvoicesGridRowSelected($event),
      rowDeselecting: ($event: any) => this.onInvoicesGridRowDeselected($event),
      toolbarClick: ($event: any) => this.onInvoicesGridCustomToolbarClick($event),
      detailDataBound: ($event: any) => this.onInvoicesGridDetailDataBound($event, this.invoiceDetailsSettings),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
    }

    return;
  }

  onInvoicesGridCustomToolbarClick(args: any) {

    if (args.item.id === 'TransferInvoice') {
      this.transferInvoiceVisibility = true;
      if (this.transferInvoice) this.transferInvoice.show();
    }

    if (args.item.id === 'AddInvoice') {
      this.addNewInvoiceVisibility = true;
      if (this.addNewInvoice) this.addNewInvoice.show();
    }
  }

  // transfer invoice setup
  onInvoicesGridRowSelected(args: RowSelectEventArgs): void {
    const selectedRow = (args.data as Invoice);
    if (selectedRow) {
      const currentSelections = this.selectedInvoices() ?? [];
      this.selectedInvoices.set([selectedRow, ...currentSelections]);
    }
  }

  // transfer invoice setup
  onInvoicesGridRowDeselected(args: RowSelectEventArgs): void {
    const selectedRowId = (args.data as Invoice).Id;
    if(selectedRowId) {
      const currentSelections = this.selectedInvoices() ?? [];
      this.selectedInvoices.set(currentSelections.filter((row) => row.Id !== selectedRowId))
    }
  }

  // Build additional grid for each invoice row
  onInvoicesGridDetailDataBound(e: DetailDataBoundEventArgs, gridsConfig: GridDetailsConfig[]) {
    return onGridDetailDataBound(e, gridsConfig);
  }

  setInfoClass() {
    let classString: string = 'col';
    const reductionInfoContainer = document.getElementById('reductions-data');

    if (reductionInfoContainer) {
      const containerWidth = reductionInfoContainer.offsetWidth;

      if (containerWidth < 390) {
        classString += ' col-12';
      } else if (containerWidth >= 390 && containerWidth < 1040) {
        classString += ' col-6';
      } else {
        classString += ' col-4';
      }
    }

    return classString;
  }
  
  toggleSections() {
    this.showFinancialSection = !this.showFinancialSection;
  }

  handleSubmitComplete(): void {
    // Handle invoice form
    if (this.addNewInvoiceVisibility) {
      this.editingInvoice.set(null);
      this.addNewInvoice.hide();
      this.addNewInvoiceVisibility = false;
    }

    // handleTransferInvoice
    if(this.transferInvoiceVisibility) {
      this.transferInvoice.hide();
      this.transferInvoiceVisibility = false;
      this.selectedInvoices.set(null);
    }

    // Handle deposit form
    if (this.addDepositVisibility) {
      this.addDeposit.hide();
      this.addDepositVisibility = false;
    }

    // Refresh the data
    this.checkData();
  }

  onDialogClose(): void {
    this.editingInvoice.set(null);
    this.addNewInvoiceVisibility = false;
    this.checkData();
  }

  async onCommandClick(args: CommandClickEventArgs) {
    const invoice = args.rowData as any;
    
    if (invoice && invoice.Id && args.commandColumn?.title === 'Edit') {
      args.cancel = true;
      this.editingInvoice.set(invoice);
      this.addNewInvoiceVisibility = true;
      if (this.addNewInvoice) this.addNewInvoice.show();
    }

    if (invoice && invoice.Id && args.commandColumn?.title === 'Logs') {
      try {
        this.auditLogsLoading = true;
        this.showLogsDialog();

        // Get all logs
        try {
          const invoiceLogs = await this.auditLogService.getAuditLogs(invoice.Id, 'Invoice');
          const rowLogs = await this.auditLogService.getAuditLogs(invoice.InvoiceRows[0].Id, 'InvoiceRow');
          const paymentLogs = invoice.InvoicePayments.length > 0 ? 
            await this.auditLogService.getAuditLogs(invoice.InvoicePayments[0].Id, 'InvoicePayment') : 
            [];

          // Format logs with clear section headers
          try {
            this.auditLogs = [
              { 
                sectionHeader: 'Invoice Header Logs', 
                logs: this.auditLogService.mapAuditDataToLogFormat(invoiceLogs) 
              },
              { 
                sectionHeader: 'Invoice Row Logs', 
                logs: this.auditLogService.mapAuditDataToLogFormat(rowLogs) 
              },
              { 
                sectionHeader: 'Invoice Payment Logs', 
                logs: this.auditLogService.mapAuditDataToLogFormat(paymentLogs) 
              }
            ].filter(section => section.logs.length > 0); // Only show sections that have logs
          } catch (formatError) {
            this.closeLogsDialog();
            this.toast.showError('Error formatting audit log data');
            console.error('Error formatting audit logs:', formatError);
            return;
          }
        } catch (error) {
          this.closeLogsDialog();
          this.toast.showError('Failed to load audit logs');
          console.error('Error loading audit logs:', error);
        }
      } catch (error) {
        this.closeLogsDialog();
        this.toast.showError('Error processing audit logs request');
        console.error('Error in onCommandClick:', error);
      } finally {
        this.auditLogsLoading = false;
      }
    }
  }

  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.toast.showError('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = []; // Clear the logs when closing
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  beforeOpening(args: any) {
    args.maxHeight = '85vh';
  }

  // Add property to track warning state
  invoiceFormHasWarnings: boolean = false;

  // Add method to handle warning changes
  onInvoiceWarningsChanged(hasWarnings: boolean) {
    this.invoiceFormHasWarnings = hasWarnings;
    if (this.addNewInvoice) {
      // Adjust dialog width based on warnings
      this.addNewInvoice.width = hasWarnings ? '500px' : '90%';
    }
  }

}
