// Angular
import { Component, ViewChild, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { AccordionAllModule } from '@syncfusion/ej2-angular-navigations';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { IconDefinition, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCopy, faEnvelope, faChevronDown } from '@fortawesome/free-solid-svg-icons';

// Internal
import { FileViewService } from '@root/src/app/shared/services/file-view/file-view.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { StatusComponent } from '@ui/status/status.component';
import { PatientInfoComponent } from '@ui/patient-info/patient-info.component';
import { BottomNavComponent } from '@ui/bottom-nav/bottom-nav.component';
import { ItemViewComponent } from '@features/case-file/item-view/item-view.component';
import { LoadingModule } from '@modules/loading.module';
import { GlobalsService } from '@services/globals/globals.service';

@Component({
  selector: 'multi-file',
  standalone: true,
  imports: [
    CommonModule,
    StatusComponent,
    PatientInfoComponent,
    ItemViewComponent,
    // BottomNavComponent,
    ListViewModule,
    AccordionAllModule,
    ButtonAllModule,
    TooltipModule,
    FontAwesomeModule,
    LoadingModule
  ],
  templateUrl: './multi-file.component.html',
  styleUrl: './multi-file.component.scss',
})
export class MultiFileComponent {
  constructor(
    private user: UserPreferencesService,
    private fileView: FileViewService,
    private toast: ToastMessageService,
    public globals: GlobalsService
  ) {}

  @ViewChild(PatientInfoComponent) patientInfo: any;
  faCopy: IconDefinition = faCopy;
  faEnvelope: IconDefinition = faEnvelope;
  faChevronDown: IconDefinition = faChevronDown;
  loadingFileData: WritableSignal<boolean> = signal(true);

  // needs to be signals so the data changes when url changes
  openFileData: WritableSignal<any> = signal(null);
  title: WritableSignal<string> = signal('');
  openFiles = this.user.getLocalUserPreference('openFiles') || [];
  urlInfo = this.fileView.getUrlInfo();
  showColumn = this.fileView.showLeftColumn; // Initially show the left column
  bottomNavItems = this.fileView.tabs;
  isPatientInfoOpen = false;
  toggleLeftColumn = this.fileView.toggleLeftColumn;
  isMobile = this.fileView.isMobileMode;
  chevronClick(e: Event): void {
    this.patientInfo.handleClick(e);
    this.isPatientInfoOpen = !this.isPatientInfoOpen;
  }

  // data fetch and selecting open file
  ngOnInit() {
    if (this.openFiles.length > 1) {
      this.fileView.fetchData(this.openFileData, this.title);
      this.loadingFileData.set(false);
    }
  }

  handleCopyPatientDemographics(args: any) {
    const data = this.openFileData();
    const patient = data.Patient;
    const formattedText = `Patient Information:
Name: ${patient.Firstname} ${patient.Lastname}
Date of Birth: ${new Date(patient.Dob).toLocaleDateString()}
Gender: ${patient.Gender}
Language: ${patient.Language}
Email: ${patient.Email || 'Not provided'}
Minor: ${patient.Minor ? 'Yes' : 'No'}
Phone: ${patient.Phone?.length ? 'On file' : 'Not provided'}

File Details:
File #: ${data.FileNumber}
Law Firm: ${data.LawFirm.Name}
Liability: ${data.Liability}
Notes: ${data.Comments || 'None'}`;

    navigator.clipboard.writeText(formattedText).then(() => {
      this.toast.showSuccess(`Copied patient demographics to clipboard.`);
    })
    .catch((err) => {
      this.toast.showError(`Error copying patient demographics to clipboard.`);
      console.error(err);
    });
  }

  emailAuthRequest() {
    const email = 'fileauth@wshcgroup.com';
    const subject = this.openFileData().FileNumber;
    const body = `File #: ${this.openFileData().FileNumber} 
      URL: ${window.location.href}
      Name: ${this.openFileData().Patient.Firstname} ${this.openFileData().Patient.Lastname}
      Law Firm ${this.openFileData().LawFirm.Name}
      Liability:
      MVA or Premise:
      UM/UIM:
      Specials:
      Auth Amount Requested:
      Note Regarding Request: `;
    const href = `${email}?subject=${subject}&body=${body}`;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }
}
