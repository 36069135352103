import { Component, Input, signal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { LoadingModule } from '@modules/loading.module';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Add interface for sectioned logs
interface LogSection {
  sectionHeader: string;
  logs: any[];
}

@Component({
  selector: 'app-audit-logs',
  standalone: true,
  imports: [CommonModule, ButtonModule, LoadingModule],
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {
  @Input() set logs(value: LogSection[] | any[]) {
    this._logs = value;
    // Collapse sections whenever new logs are set
    if (this.isSectionedLogs(value)) {
      this.collapsedSections.clear(); // Clear existing collapsed sections
      value.forEach(section => {
        this.collapsedSections.add(section.sectionHeader);
      });
    }
  }
  get logs(): LogSection[] | any[] {
    return this._logs;
  }
  private _logs: LogSection[] | any[] = [];

  @Input() loading = false;
  loadingMessage = signal<boolean>(true);
  expandedRows: { [key: number]: boolean } = {};
  collapsedSections: Set<string> = new Set(); // Track collapsed sections by header

  ngOnInit() {
    // Also handle initial collapse state
    if (this.isSectionedLogs(this._logs)) {
      this._logs.forEach(section => {
        this.collapsedSections.add(section.sectionHeader);
      });
    }
  }

  toggleSection(section: LogSection) {
    if (this.collapsedSections.has(section.sectionHeader)) {
      this.collapsedSections.delete(section.sectionHeader);
    } else {
      this.collapsedSections.add(section.sectionHeader);
    }
  }

  isSectionCollapsed(section: LogSection): boolean {
    return this.collapsedSections.has(section.sectionHeader);
  }

  constructor(private toast: ToastMessageService) {}

  toggleRow(index: number) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  isRowExpanded(index: number): boolean {
    return this.expandedRows[index] === true;
  }

  getChangedProperties(oldValues: any, newValues: any): any[] {
    if (!oldValues || !newValues) return [];

    // Get all unique keys from both objects
    const allKeys = Array.from(new Set([
      ...Object.keys(oldValues),
      ...Object.keys(newValues)
    ]));

    // Create an array of changes for each property
    return allKeys.map(key => ({
      property: this.formatPropertyName(key),
      oldValue: oldValues[key] ?? 'N/A',
      newValue: newValues[key] ?? 'N/A'
    }));
  }

  formatPropertyName(name: string): string {
    // Convert camelCase to Title Case with spaces
    return name
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()); // Capitalize first letter
  }

  formatValue(value: any): string {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'object') return JSON.stringify(value);
    return String(value);
  }

  // Add helper method
  isSectionedLogs(logs: any[]): logs is LogSection[] {
    return logs.length > 0 && 'sectionHeader' in logs[0];
  }
}
