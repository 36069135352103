<!-- Address Form Start -->
<div id="address-form" [class]="formClasses.formContainer">
    <h2 class="form-title" *ngIf="displayTitle"><fa-icon [icon]="addressIcons.form" /> {{title}}</h2>
    
    <form [formGroup]="addressForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' w-100'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('Address1')" [class]="formClasses.inputContainer" [class.error]="addressForm.get('Address1')?.errors && addressForm.get('Address1')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.address1" />
                        <ejs-tooltip *ngIf="addressForm.get('Address1')?.errors && addressForm.get('Address1')?.touched" [content]="getErrorMessage('Address1')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Address1" placeholder="Address 1" />
                    <ng-container *ngIf="addressForm.get('Address1')?.invalid && addressForm.get('Address1')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('Address2')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.address2" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Address2" placeholder="Address 2" />
                </div>
            
                <div *ngIf="displayFields.includes('City')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.city" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="City" placeholder="City" />
                </div>
            
                <div *ngIf="displayFields.includes('State')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.state" />
                    </span>
                    <ejs-dropdownlist formControlName="State" 
                        [placeholder]="'State'" 
                        [class]="formClasses.input"
                        [dataSource]="states"
                        [query]="statesQuery"
                        [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="addressForm.get('State')?.value"
                        (created)="onStatesCreated($event)" />
                </div>
            
                <div *ngIf="displayFields.includes('Zip')" [class]="formClasses.inputContainer" [class.error]="addressForm.get('Zip')?.errors && addressForm.get('Zip')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.zip" />
                    </span>
                    <ejs-maskedtextbox formControlName="Zip" 
                        [class]="formClasses.input"
                        placeholder="Zip"
                        [mask]="'99999'" />
                    <ng-container *ngIf="addressForm.get('Zip')?.invalid && addressForm.get('Zip')?.touched">
                        <span [class]="formClasses.error">Required</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('County')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.county" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="County" placeholder="County" />
                </div>

                <div *ngIf="displayFields.includes('AddressType')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.addressType" />
                        <ejs-tooltip *ngIf="addressForm.get('AddressType')?.errors && addressForm.get('AddressType')?.touched" [content]="getErrorMessage('AddressType')" />
                    </span>
                    <ejs-dropdownlist formControlName="AddressType" 
                        [placeholder]="'Address Type'" 
                        [class]="formClasses.input"
                        [dataSource]="addressTypes"
                        [query]="addressTypesQuery"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="addressForm.get('AddressType')?.value"
                        (created)="onAddressTypesCreated($event)" />
                </div>
            </div>
    
        </section>

        <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
            <div [class]="formClasses.actions">
                <button ejs-button type="button">Cancel</button>
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
            </div>
        </section>

    </form>

</div>
<!-- Address Form End -->