// Angular
import { Component, EventEmitter, Input, Output, signal, SimpleChanges, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

// 3rd Party
import { GridModel} from '@syncfusion/ej2-grids';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule, CheckBoxModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { FeeSchedule, ProcedureCode, XrefFeeScheduleProcedureCode } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { CustomAssignmentsEditorComponent } from '@grids/custom-assignments-editor/custom-assignments-editor.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AddFeeScheduleService } from './data/add-fee-schedule.service';

@Component({
  selector: 'add-fee-schedule-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckBoxModule,
    SwitchModule,  
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    CustomAssignmentsEditorComponent
  ],
  templateUrl: './add-fee-schedule-form.component.html',
  styleUrl: './add-fee-schedule-form.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddFeeScheduleForm {

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private toast: ToastMessageService,
    public service: AddFeeScheduleService
  ) { }

  @Input() existingFeeSchedule: FeeSchedule | null = null; 
  
  @Output() submit = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  @ViewChild('customProvidersEditor') customProvidersEditor: CustomAssignmentsEditorComponent;


  //Fee Schedule
  newFeeScheduleForm: FormGroup;
  attachedProcedureCodesForm: FormGroup;
  cptCodeBtnText: string = 'Add Procedure Codes';
  assignedProviders: WritableSignal<any[]> = signal([]); 
  availableProviders: any[] = [];

  //Medicare
  medicareParticipating = [{ name: "Participating" }, { name: "Non Participating" }, { name: "Limiting" }]
  medicareFormGroup: FormGroup = new FormGroup({
    localityId: new FormControl<number | null>(null, [Validators.required]),
    cptCodeId: new FormControl<number | null>(null, [Validators.required]),
    multiplier: new FormControl<number | null>(null, [Validators.required]),
    facility: new FormControl<boolean | null>(null, [Validators.required]),
    participating: new FormControl<string | null>(null, [Validators.required]),
  }, {});


  ngOnInit(): void {
    this.service.getProcedureCodes();
    // this.service.getMedicareLocalities();
    this.initializeForms();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['existingFeeSchedule']) {
      this.populateExistingData();
    }
  }

  private getAvailableProviders(): void {
    this.api.getOdata(APIEndpoints.Providers).executeQuery(new Query().select(['Id', 'Name'])).then((response: any) => {
      this.availableProviders = response.result || [];
    });
  } 

  private initializeForms(): void {
    this.newFeeScheduleForm = new FormGroup({
      Name: new FormControl<FeeSchedule['Name']>('', [Validators.required]),
      Providers: new FormControl<FeeSchedule['Providers']>([]),
      XrefFeeScheduleProcedureCodes: new FormControl<FeeSchedule['XrefFeeScheduleProcedureCodes']>([]),
    });

    this.attachedProcedureCodesForm = this.fb.group({
      entries: this.fb.array([])
    });

    this.getAvailableProviders();

    // If no existing data, add one empty entry
    if (!this.existingFeeSchedule) {
      this.addEntry();
    }
  }

  private createProcedureCodeFormGroup(code: XrefFeeScheduleProcedureCode): FormGroup {
    return this.fb.group({
      Code: new FormControl(code.ProcedureCodeId),
      Description: new FormControl(code.ProcedureCode?.Description || ''),
      IsMedicare: new FormControl(code.ProcedureCode?.McptId ? true : false),
      BilledAmount: new FormControl(code.BilledAmount),
      SplitInvoice: new FormControl(code.SplitInvoice),
      FrontEndReimbursmentRate: new FormControl(code.FrontEndReimbursmentRate),
      BackEndReimbursmentRate: new FormControl(code.BackEndReimbursmentRate)
    });
  }

  private mapProviderToGridData(provider: any) {
    return {
      Id: provider.Id,
      Name: provider.Name
    };
  }

  private populateExistingData(): void {
    if (!this.existingFeeSchedule) return;

    if (this.existingFeeSchedule.Providers) {
      this.assignedProviders.set(this.existingFeeSchedule.Providers.map(this.mapProviderToGridData));
    }

    // Populate main form
    this.newFeeScheduleForm.patchValue({
      Name: this.existingFeeSchedule.Name,
      Providers: this.existingFeeSchedule.Providers,
      XrefFeeScheduleProcedureCodes: this.existingFeeSchedule.XrefFeeScheduleProcedureCodes
    });

    this.entries.clear();

    // Populate procedure codes
    if (this.existingFeeSchedule.XrefFeeScheduleProcedureCodes?.length) {
      this.existingFeeSchedule.XrefFeeScheduleProcedureCodes.forEach(code => {
        const entryForm = this.createProcedureCodeFormGroup(code);
        this.entries.push(entryForm);
      });
    } else {
     this.toggleProcedureCodeRepeater();
    }
  }

  // Add Fee Schedule form functions
  toggleProcedureCodeRepeater() {
    this.addEntry();
  }

  // Add CTP form functions
  get entries(): FormArray {
    return this.attachedProcedureCodesForm.get('entries') as FormArray;
  }

  addEntry(): void {
    const entryForm = this.fb.group({
      Code: new FormControl<ProcedureCode['ProcedureCodeName']>(''),
      Description: new FormControl<ProcedureCode['Description']>(''),
      BilledAmount: new FormControl<XrefFeeScheduleProcedureCode['BilledAmount']>(0),
      SplitInvoice: new FormControl<XrefFeeScheduleProcedureCode['SplitInvoice']>(false),
      FrontEndReimbursmentRate: new FormControl<XrefFeeScheduleProcedureCode['FrontEndReimbursmentRate']>(0),
      BackEndReimbursmentRate: new FormControl<XrefFeeScheduleProcedureCode['BackEndReimbursmentRate']>(0),
      IsMedicare: new FormControl<any>(false),
    });
    this.entries.push(entryForm);
  }

  removeEntry(): void {
    this.entries.removeAt(this.entries.length - 1);
  }

  changedProcedureCode(args: any) {
    this.newFeeScheduleForm.value.XrefFeeScheduleMasterCptcode = this.attachedProcedureCodesForm.value.entries as ProcedureCode[];
  }

  //Submits the medicare billing rates
  submitMedicare(index: number) {
    let locality = this.service.medicareLocalitiesData.find((x) => (x as any).id === this.medicareFormGroup.value.localityId) as any;
    let cptCode = this.service.medicareCptCodesData.find((x) => (x as any).id === this.medicareFormGroup.value.cptCodeId) as any;
    let practiceRvu = 0
    if (this.medicareFormGroup.value.facility) {
      practiceRvu = cptCode.facilityRvu;
    }
    else {
      practiceRvu = cptCode.nonFacilityRvu;
    }
    this.entries.controls[index].patchValue({ BilledAmount: this.calculateFee(cptCode.physicianWorkRvu, practiceRvu, cptCode.malpracticeRvu, locality.workRate, locality.practiceRate, locality.malpracticeRate, locality.conversionFactor, this.medicareFormGroup.value.multiplier) })
  }

  //Gets the billing amount based on medicare rates
  calculateFee(workRvu: number, practiceRvu: number, malpracticeRvu: number, workModifier: number, practiceModifier: number, malpracticeModifier: number, conversionFactor: number, multiplier: number) {
    let baseFee = ((workRvu * workModifier) + (practiceRvu * practiceModifier) + (malpracticeRvu * malpracticeModifier)) * conversionFactor * multiplier
    switch (this.medicareFormGroup.value.participating) {
      case "Participating":
        return baseFee * .95;
      case "Limiting":
        return baseFee * .95 * 1.15;
      case "Non Participating":
        return baseFee;
      default:
        return baseFee;
    }
  }

  mapProcedureCodesToXref(procedureCodes: ProcedureCode[]): XrefFeeScheduleProcedureCode[] {
    return this.existingFeeSchedule?.Id ? this.attachedProcedureCodesForm.value.entries.map((entry: any) => ({
      FeeScheduleId: this.existingFeeSchedule?.Id,
      ...entry
    })) : [];
  } 

  // Add handler for assigned records changes
  onAssignedProvidersChange(event: any): void {
    if (event.eventName === "moveTo") {
      this.assignedProviders.set([...this.assignedProviders(), ...event.items]);
    } 
    else if (event.eventName === "moveFrom" && this.assignedProviders().length > 0) {
      this.assignedProviders.set(this.assignedProviders().filter((provider: any) => !event.items.includes(provider)));
    }
  }

  resetForm(): void {
    this.newFeeScheduleForm.reset();
    this.attachedProcedureCodesForm.reset();
    this.assignedProviders.set([]);
    this.submit.emit();
    this.close.emit();
  }

  onSubmit() {
    if (this.newFeeScheduleForm.valid && this.attachedProcedureCodesForm.valid) {
      // Format the base fee schedule data
      const formData: Partial<FeeSchedule> = {
        Name: this.newFeeScheduleForm.value.Name,
        // Add any other direct fee schedule properties here
      };
      if (this.existingFeeSchedule) {
        this.service.updateFeeSchedule(
          {Id: this.existingFeeSchedule.Id,...formData },
          this.assignedProviders(),
          this.existingFeeSchedule.Providers ??[],
          this.attachedProcedureCodesForm,
          this.existingFeeSchedule.XrefFeeScheduleProcedureCodes ??[]
        ).then(() => {
          this.resetForm();
        });
      } else {
        this.service.createFeeSchedule(formData, this.attachedProcedureCodesForm, this.assignedProviders()).then(() => {
          this.resetForm();
        });
      }
    } else {
      this.toast.showError('Please fill in all required fields');
    } 

  }
}
