<ejs-toolbar #toolbar id="bottom-nav">
    <e-items>
        <e-item *ngFor="let item of items" [tooltipText]="item.text" [visible]="item.active">
            <ng-template #template>
                <button ejs-button cssClass="e-flat" [iconCss]="'e-icons ' + item.iconCss" (click)="item.onClick()">
                    <p>{{item.text}}</p>
                </button>
            </ng-template>
        </e-item>
    </e-items>
</ejs-toolbar>