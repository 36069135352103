// Angular
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

// Syncfusion
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { GridComponent, GridModel, EditService, RowSelectEventArgs, GridAllModule } from '@syncfusion/ej2-angular-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Right, Role, XrefRolesRight } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AddRoleComponent } from '@root/src/app/components/forms/add-forms/add-role/add-role.component';
import { TabCardComponent } from '@components/file-hub/file-hub-tabs/tab-card/tab-card.component';

@Component({
  selector: 'roles-grid',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule, 
    DialogModule,
    GridAllModule,
    GridTemplateModule,
    AddRoleComponent,
    TabCardComponent
  ],
  templateUrl: './roles-grid.component.html',
  styleUrls: ['./roles-grid.component.scss'],
  providers: [
    EditService
  ],
})
export class RolesGridComponent implements OnInit, AfterViewInit {

  constructor(
    private globals: GlobalsService,
    private api: ApiService
  ) {}

  // Decorator Variables
  @Input() showRoleEditor: boolean;
  @Input() showRoleAdder: boolean = false;
  @ViewChild('usersRolesGridComponent') usersRolesGridComponent: GridTemplateComponent;
  @ViewChild('availableRightsGrid') availableRightsGrid: GridComponent;
  @ViewChild('assignedRightsGrid') assignedRightsGrid: GridComponent;
  @ViewChild('emptyRightsTemplate', { static: true }) emptyRightsTemplate: string;
  @ViewChild('editRoleDialog') editRoleDialog: DialogComponent;

  // General variables
  isResponsive: boolean = this.globals.isResponsive;

  // Variables for roles
  roles: GridComponent;
  rolesSettings: GridModel;
  selectedRole: Role;
  editRoleDialogHeader: string = 'Edit Rights';

  // Variables for availableRightsGrid
  availableRightsGridSettings: GridModel;
  availableRightsGridCurrentSelection: object[];
  
  // Variables for assignedRightsGrid
  assignedRights: any;
  assignedRightsGridSettings: GridModel;
  assignedRightsGridCurrentSelection: object[];
  
  ngOnInit(): void {
    this.showRoleAdder = window.location.pathname === '/users-roles' ? true : false;
    this.rolesSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Roles),
      pageSettings: { pageSize: 25 },
      toolbar: [{ text: 'Edit Rights', tooltipText: 'Edit Rights', id: 'EditRights', disabled: true }],
      selectionSettings: { mode: 'Row',  type: 'Single' },
      query: new Query() 
        .expand('XrefRolesRights($expand=Right($select=FriendlyName))')
        .select('Id, RoleName, Description, CreatedAt, XrefRolesRights'),
      columns: [
        { field: 'Id', isPrimaryKey: true, showInColumnChooser: false, visible: false },
        { field: 'RoleName', headerText: 'Role' },
        { field: 'Description' },
        { field: 'XrefRolesRights', headerText: 'Rights', allowSorting: false, allowFiltering: false, allowEditing: false,
            valueAccessor:  (field: string, data: Object): string[] => this.getRightsNames(field, data) },
        { field: 'CreatedAt', headerText: 'Created', type: 'datetime', format: 'MMM dd, yyyy hh:mm a' },
      ],
      toolbarClick: ($event: any): void => this.toolbarClickHandler($event),
      rowSelected: ($event: RowSelectEventArgs): void => this.onRowSelected($event)
    }

    this.availableRightsGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Rights),
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { type: 'checkbox', width: 50, textAlign: 'Center', allowFiltering: false },
        { field: 'FriendlyName', headerText: 'Available Rights', allowFiltering: true},
      ],
      rowDataBound: ($event: any): void => this.customizeAvailableRightsBackgroundColor($event)
    };

    this.assignedRightsGridSettings = {
      dataSource: undefined,
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        mode: 'Batch'
      },
      columns: [
        { field: 'RightId', isPrimaryKey: true, visible: false },
        { type: 'checkbox', width: 50, textAlign: 'Center', allowFiltering: false },
        { field: 'Friendly_name', headerText: 'Assigned Rights', allowFiltering: true},
      ]
    }
  }
  
  ngAfterViewInit(): void {
    // Set reference to grid after HTML has fully been rendered
    this.roles = this.usersRolesGridComponent.grid;
  }
  
  // Custom function needed to retrieve string values from nested arrays in fetched data
  getRightsNames(field: string, data: object): string[] {

    // Ensure data exists
    if ((data as any)[field]) {
      return (data as any)[field as string].map((XrefRolesRights: { Right: { FriendlyName: string } }) => {
        return XrefRolesRights.Right.FriendlyName;
      });
    } else {
      return [];
    }
  }

  toolbarClickHandler(args: any): void {
    if (args.item.id === 'EditRights') this.editRoleDialog.show();
  }

  // Apply a background color to any rows in this.assignedRights
  customizeAvailableRightsBackgroundColor(args: any): void {
    if (!args.row || !args.data || !this.assignedRights) return;
    
    // Remove existing class if present
    args.row.classList.remove('assigned-right');
    
    // Add class if right is assigned
    const rightsSet = new Set(this.assignedRights);
    if (rightsSet.has(args.data.Id)) {
      args.row.classList.add('assigned-right');
    }
  }

  dialogClose(): void {
    this.editRoleDialog.hide();
  }

  // Update grids so new data is reflected
  refreshGrids(): void {
    this.roles.refresh();
    this.availableRightsGrid.refresh();
    this.assignedRightsGrid.refresh();
  }

  // Editing rights for individual roles - appears below roles roles
  onRowSelected(rowArgs: RowSelectEventArgs): void {
    const rowData = rowArgs?.data as Role;

    // Ensure necessary data exists for clicked role
    if (rowData?.Id) {

      // Enable Rights editor button and set data for assigned rights grid
      const assignedRightsData = this.api.getOdata(APIEndpoints.Roles + `(${rowData.Id})/Rights`);
      this.roles.toolbarModule.enableItems(['EditRights'], true);
      this.assignedRightsGridSettings.dataSource = assignedRightsData
      this.selectedRole = rowData as Role;
      this.editRoleDialogHeader = `Edit Rights for: ${rowData.RoleName}`;

      // Update public variable this.assignedRights
      assignedRightsData.executeQuery(new Query()).then((e: ReturnOption) => { 
        this.assignedRights = (e.result as object[]).map((right) => (right as any).RightId);
        
        // Apply background colors to all rows after data is loaded
        if (this.availableRightsGrid) {
          this.availableRightsGrid.getRows().forEach(row => {
            const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
            this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
          });
        }
      });

      // Refresh available rights grid so asssigned rights are visible
      this.availableRightsGrid.getRows().forEach(row => {
        const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
        this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
      });
      this.availableRightsGrid.refresh();
    }
  }

  // Update height before modal opened
  beforeOpenEditRightsDialog(args: any) {
    args.maxHeight = '85vh';
    this.editRoleDialog.width = '75vw';
  }

  addSelectedRoleToAssignedRightsGrid() {
    // Get currently selected records from available rights grid
    this.availableRightsGridCurrentSelection = this.availableRightsGrid.getSelectedRecords();
    
    // Get the current role Id and data
    const selectedRoleId = this.selectedRole.Id;
    if (!selectedRoleId) throw new Error('Missing selected role ID to add assigned rights.');
    const currentData = this.assignedRightsGrid.getCurrentViewRecords();
    
    // Create new records
    const newRecords = this.availableRightsGridCurrentSelection.map((selected: any) => ({
      RoleId: selectedRoleId,
      RightId: selected.Id,
      Friendly_name: selected.FriendlyName
    }));

    // Update the grid's data source with combined data
    this.assignedRightsGrid.setProperties({
      dataSource: [...currentData, ...newRecords]
    });

    // Clear selections from available rights grid
    this.availableRightsGrid.clearSelection();
    this.availableRightsGrid.getRows().forEach(row => {
      const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
      this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
    });
    this.assignedRightsGrid.refresh();
    this.availableRightsGrid.refresh();
  }
  
  // Remove selected role to assignedRightsGrid
  removeSelectedRoleToAssignedRightsGrid() {
    // Get currently selected records to remove
    this.assignedRightsGridCurrentSelection = this.assignedRightsGrid.getSelectedRecords();
    const currentData = this.assignedRightsGrid.getCurrentViewRecords();
    
    // Create a Set of IDs to remove for efficient lookup
    const idsToRemove = new Set(this.assignedRightsGridCurrentSelection.map((right: any) => right.RightId));
    const updatedData = currentData.filter((right: any) => !idsToRemove.has(right.RightId));
    
    // Update the grid's data source with filtered data
    this.assignedRightsGrid.setProperties({
      dataSource: updatedData
    });

    // Update background colors for available rights grid
    this.assignedRightsGrid.clearSelection();
    this.availableRightsGrid.getRows().forEach(row => {
      const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
      this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
    });
    this.assignedRightsGrid.refresh();
    this.availableRightsGrid.refresh();
  }
  
  // Finalize changed settings
  async submitRoleChanges() {
    const roleId = this.selectedRole.Id;
    if (!roleId) throw new Error('Missing selected role ID to update rights.');
    
    // Create the payload with just the RightIds
    const payload: XrefRolesRight[] = this.assignedRightsGrid.getCurrentViewRecords().map((right: any) => ({ RightId: right.RightId }));

    try {
      const endpoint = `odata${APIEndpoints.Roles}\(${roleId}\)/Rights`;
      await this.api.fetchRequest(endpoint, 'POST', payload);

    } catch (error) {
      console.error('Error updating role rights:', error);

    } finally {
      this.refreshGrids();
      this.editRoleDialog.hide();
    }
  }
}
