// Angular
import { Component, signal, Signal, ViewChild } from '@angular/core';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

// Models
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';

// Services
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Modules
import { GridTemplateModule } from '@root/src/app/shared/modules/grid-template.module';

// Components
import { BulkEditRemittancesForm } from '../../forms/bulk-edit-forms/bulk-edit-remittances/bulk-edit-remittances.component';
import { GridTemplateComponent } from '../grid-template/grid-template.component';

// Models
import { EditRemittance } from '@models/data-contracts';

@Component({
  selector: 'app-remittance-grid',
  standalone: true,
  imports: [
    DialogAllModule,
    GridTemplateModule,
    BulkEditRemittancesForm
  ],
  templateUrl: './remittance-grid.component.html',
  styleUrl: './remittance-grid.component.scss'
})
export class RemittanceGridComponent {

  // Constructor
  constructor(
    private api: ApiService,
    private toast: ToastMessageService
  ) { }

  // Decorator variables
  @ViewChild('remittanceGridComponent') remittanceGridComponent: GridTemplateComponent;
  @ViewChild('bulkEditModal') bulkEditModal: BulkEditRemittancesForm;
  @ViewChild('bulkEditRemittancesDialog') bulkEditRemittancesDialog: DialogComponent;
  @ViewChild('bulkEditRemittancesForm') bulkEditRemittancesForm: BulkEditRemittancesForm;

  // Public variables
  grid: any;
  remittanceGridSettings: GridModel;
  remittanceGridDefaultToolbar: any[] = ["Add"];
  selectedRecords: any[] = [];
  remittancesSignal = signal<EditRemittance[] | undefined>(undefined);
  dialogButtons: object[]

  ngOnInit() {
    this.remittanceGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Remittances),
      toolbar: this.remittanceGridDefaultToolbar,
      columns: [
        { type: 'checkbox' },
        { field: 'Id', headerText: 'Id', isPrimaryKey: true, visible: false },
        { field: 'FileNumber', headerText: 'File Number' },
        { field: 'PatientName', headerText: 'Patient Name' },
        { field: 'InternalInvoiceNumber', headerText: 'Invoice Number', },
        { field: 'DateOfService', headerText: 'Date Of Service' },
        { field: 'CptCode', headerText: 'CPT Code' },
        { field: 'AmountBilled', headerText: 'Amount Billed' },
        { field: 'ReimbursementRate', headerText: 'Reimbursement Rate' },
        { field: 'TotalDueProvider', headerText: 'Total Due Provider' },
        { field: 'PaymentMethod', headerText: 'Payment Method' },
        { field: 'PaymentStatus', headerText: 'Payment Status' },
        { field: 'AmountPaid', headerText: 'Amount Paid' },
        { field: 'DatePaid', headerText: 'Date Paid' },
        { field: 'TransactionNumber', headerText: 'Transaction Number' },
        { field: 'InvoiceNotes', headerText: 'Invoice Notes' },
        { field: 'InvoicePaymentId' },
        { type: 'commands' }
      ],
      created: () => this.onCreated(),
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      rowSelected: ($event: any) => this.onRowSelected($event),
      rowSelecting: ($event: any) => this.onRowSelecting($event),
      rowDeselected: ($event: any) => this.onRowDeselected($event),
      rowDeselecting: ($event: any) => this.onRowDeselecting($event)
    }

    this.dialogButtons = [
      { click: () => this.cancelBulkEditChanges(), buttonModel: { content: 'Cancel', isPrimary: false, type: 'button' } },
      { click: () => this.bulkEditRemittancesForm.clearForm(), buttonModel: { content: 'Reset', isPrimary: false, type: 'button' } },
      { click: () => this.bulkEditRemittancesForm.submit(), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary', type: 'submit' } }
    ];
  }

  // Occurs after grid createdbulk
  onCreated() {
    this.grid = this.remittanceGridComponent.grid;
    this.remittanceGridDefaultToolbar = this.remittanceGridComponent.settings.toolbar as any[];
  }

  // Custom toolbar click
  customToolbarClick(args: any) {
    if (args.item.id === 'BulkEdit') {
      this.bulkEditRemittancesDialog.show();
    }
  }

  // Detect selection of a single row
  onRowSelected(args: any) {
    this.selectedRecords = this.grid.getSelectedRecords();
    this.updateToolbar();
  }

  // Detect selection of multiple rows
  onRowSelecting(args: any) {
    this.selectedRecords = this.grid.getSelectedRows();
    this.updateToolbar();
  }

  onRowDeselected(args: any) {
    this.selectedRecords = this.grid.getSelectedRows();
    this.updateToolbar();
  }

  // Occurs as rows are deselected
  onRowDeselecting(args: any) {
    this.selectedRecords = this.grid.getSelectedRows();
    this.updateToolbar();
  }

  // Set toolbar according to number of selected rows
  updateToolbar() {

    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;
      this.remittancesSignal.set(this.selectedRecords);
      if (length === 1) {
        this.grid.toolbar = this.remittanceGridDefaultToolbar;
      } else if (length > 1) {
        const bulkEditButton = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit', prefixIcon: 'e-properties-1' };
        const buttonExists = this.grid.toolbar.some((button: any) => button.id === bulkEditButton.id);
        if (!buttonExists) this.remittanceGridComponent.insertToolbarItem(bulkEditButton); // make sure button is not already in toolbar
      }

    } else {
      this.grid.toolbar = this.remittanceGridDefaultToolbar;
    }
  }

  // Cancel bulk edit changes
  cancelBulkEditChanges() {
    this.bulkEditRemittancesForm.clearForm();
    this.bulkEditRemittancesDialog.hide();
  }

  async submitBulkEditChanges() {
    const result = await this.bulkEditRemittancesForm.submit();
    if (result) {
      this.toast.showSuccess('Remittances updated successfully');
      this.grid.refresh();
      this.bulkEditRemittancesDialog.hide();
    } else {
      this.toast.showError('Failed to update remittances');
    }
  }
}
