<div id="multi-file">
    <ng-container *ngIf="openFiles?.length > 1; else singleFile">
        <div class="row">
            <ng-container *ngIf="openFileData()">
                <div class="col-12 header-info">
                    <ng-container *ngIf="openFileData().CaseNumber && openFileData().Comments">
                        <div class="d-flex">
                            <h1>{{openFileData()?.CaseNumber}} / {{title()}}</h1>
                            <span class="h4">{{openFileData()?.Comments}}</span>
                        </div>
                    </ng-container>
                </div>
            
                <div class="col-12 file-info">
                    <div class="e-card">
                        <ng-container *ngIf="loadingFileData() === false; else loading">
                            <div class="row">
                                <div class="col-12 col-md-1">
                                    <div class="d-flex flex-column patient-info">
                                        <span><label>File Status:</label><status [status]="openFileData()?.FileRating" /></span>
                                        <div class="d-flex justify-content-between patient-info-buttons">
                                            <ejs-tooltip content="Copy Patient Demographics">
                                                <button ejs-button cssClass="action-button" (click)="handleCopyPatientDemographics($event)"><fa-icon [icon]="faCopy"></fa-icon></button>
                                            </ejs-tooltip>
                                            <ejs-tooltip content="Send Auth Request">
                                                <button ejs-button cssClass="action-button" (click)="emailAuthRequest()"><fa-icon [icon]="faEnvelope"></fa-icon></button>
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-12 col-md-11">
                                    <ng-container *ngIf="openFileData()?.Patient">
                                        <patient-info [inputData]="openFileData()"></patient-info>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #loading>
                            <loading-overlay [loading]="loadingFileData"></loading-overlay>
                        </ng-template>
                    </div>
                </div>
            
                <div class="col-12">
                    <section class="multi-file-container">
                        <ng-container *ngIf="openFileData()">
                            <item-view [data]="openFileData()" [hasMultiple]="true"></item-view>
                        </ng-container>
                    </section>
                </div>
            </ng-container>
        </div>
    </ng-container>
    
    <ng-template #singleFile>
        <item-view></item-view>
    </ng-template>
</div>