<div id="authorizations-tab">
    <tab-card [heading]="'Authorizations'" [loadingContent]="loadingAuthorizationsData">
        <ng-container *ngIf="authorizationsGrid">
            <grid-template #grid [settings]="authorizationsGrid" />
        </ng-container>
    </tab-card>
</div>

<ejs-dialog id="addAuthorizations" #addAuthorizations
    header="Add Authorization"
    [showCloseIcon]="true"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="addAuthorizationsVisibility"
    (close)="addAuthorizations.hide()">
    <add-authorizations
        [caseId]="this.fileHub.caseFile?.Id ?? 0"     
        (close)="addAuthorizations.hide()"
        (submit)="onSubmit()"
        [selectedAuthorization]="selectedAuthorization()"
    ></add-authorizations>  
</ejs-dialog>