<div id="add-deposit-form">
  
  <form [formGroup]="depositForm()" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-4">
        <ejs-datepicker formControlName="DepositDate" placeholder="Date Recieved"
          floatLabelType="Auto"></ejs-datepicker>
      </div>
      <div class="col-4">
        <ejs-numerictextbox min="0" formControlName="DepositAmount" placeholder="Deposit Amount"
          floatLabelType="Auto"></ejs-numerictextbox>
      </div>
      <div class="col-4">
        <ejs-dropdownlist [dataSource]="listPandL" [query]="pAndLQuery" [fields]="pAndLFields"
          formControlName="DepositToAccount" placeholder="Deposit to Account"
          floatLabelType="Auto"></ejs-dropdownlist>
      </div>
      <!-- <div class="form-item">
          Lock Deposit: <ejs-switch onLabel="Yes" offLabel="No" formControlName="lockDeposit"></ejs-switch>
      </div> -->
      <div class="col-12">
        <ejs-textbox multiline="true" formControlName="Notes" placeholder="Notes"
          floatLabelType="Auto"></ejs-textbox>
      </div>
      <div class="form-item">
        Final Check: <ejs-switch onLabel="Yes" offLabel="No" formControlName="FinalCheck"></ejs-switch>
      </div>
      <!-- <div class="form-item">
          Total Amount Due: <ejs-textbox formControlName="totalAmountDue"></ejs-textbox>
      </div>
      <div class="form-item">
        Amount of Payment: <ejs-textbox formControlName="amountOfPayment"></ejs-textbox>
      </div> -->
      <div class="col-10"> </div>
      <div class="col-2 form-submit">
        <button ejs-button class="e-primary e-flat" ejs-button type="submit" isPrimary="true"
          (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </form>
  
</div>