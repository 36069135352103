<ejs-dialog #Modal width="1200px" header="Send Email" [isModal]="true" [showCloseIcon]='true' [visible]="false">

    <form [formGroup]="formGroup" class="row search-form">
        <div class="col-12">
            <app-dropdown-multi id="To" name="To" placeholder="To" [form]="formGroup"
                [allowCustomValue]=true></app-dropdown-multi>
        </div>
        <div class="col-12">
            <ejs-textbox required id="Subject" formControlName="Subject" floatLabelType="Auto"
                placeholder="Subject Line">
            </ejs-textbox>
        </div>
        <div class="col-12">Body</div>
        <div class="col-12">
            <app-rich-text-editor [(content)]="EmailTemplate"></app-rich-text-editor>
        </div>
        <div class="col-10"> </div>
        <div class="col-2 form-submit">
            <button ejs-button class="e-primary e-flat" ejs-button type="submit" isPrimary="true"
                (click)="onSubmit()">Submit</button>
        </div>
    </form>

    <div *ngIf="sendingEmail" class="loading-overlay">
        <div class="spinner"></div>
        <div class="loading-text">Sending Email...</div>
    </div>

</ejs-dialog>