// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Internal
import { AuthGuard } from '@services/auth/auth-guard.guard';
// Internal - @pages
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ProfileComponent } from '@root/src/app/components/features/profile/profile.component';
import { LayoutWrapper } from '@layout/layout-wrapper.component';
import { RootPageComponent } from '@pages/root/root-page.component';
import { TestPageComponent } from '@pages/test/test.component';
import { Test2Component } from '@pages/test2/test2.component';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { EditUsersComponent } from '@pages/edit-users/edit-users.component';
import { ItemViewComponent } from '@root/src/app/components/features/case-file/item-view/item-view.component';
import { MultiFileComponent } from '@root/src/app/components/features/case-file/multi-file/multi-file.component';
import { ProcedureCodesComponent } from '@pages/procedure-codes/procedure-codes.component';
import { WorkItemsMondayComponent } from '../../modules/monday-integration/components/work-items-monday/work-items-monday.component';
import { WorkItemsListComponent } from '../../modules/work-items/pages/work-items-list/work-items-list.component';
// Internal - @components
import { SchedulerComponent } from '@features/scheduler/scheduler.component'; 
import { UsersGridComponent } from '@grids/users-grid/users-grid.component';
import { RolesGridComponent } from '@grids/roles-grid/roles-grid.component';
import { CaseFilesGridComponent } from '@grids/case-files-grid/case-files-grid.component';
import { LawFirmsGridComponent } from '@grids/law-firms-grid/law-firms-grid.component';
import { PatientsGridComponent } from '@grids/patients-grid/patients-grid.component';
import { ProvidersGridComponent } from '@grids/providers-grid/providers-grid.component';
import { FeeScheduleGridComponent } from '@grids/fee-schedule-grid/fee-schedule-grid.component';
import { ReductionRequestsGridComponent } from '@grids/reduction-requests-grid/reduction-requests-grid.component';
import { IntakesGridComponent } from '@grids/intakes-grid/intakes-grid.component';
import { AccountingComponent } from '../../components/features/accounting/accounting.component';
import { RemittanceReportComponent } from '../../components/features/remittance-report/remittance-report.component';
import { FileHubComponent } from '../../components/file-hub/file-hub.component';
import { ProviderMapPageComponent } from '../../pages/provider-map-page/provider-map-page.component';
import { LawFirmDetailComponent } from '../../components/features/law-firm/ui/law-firm-detail/law-firm-detail.component';
import { ProviderDetailsComponent } from '../../components/features/providers/provider-details/provider-details.component';
import { PatientDetailComponent } from '../../components/features/patients/patient-detail/patient-detail.component';

const routes: Routes = [
  { path: '', component: LayoutWrapper, children: [
    { title: 'Case Compass', path: '', component: RootPageComponent },
    { title: 'Dashboard', path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    { title: 'Remittance', path: 'remittance', component: RemittanceReportComponent },
    { title: 'Scheduler', path: 'scheduler', component: SchedulerComponent, canActivate: [AuthGuard] },
    { title: 'Provider Map', path: 'provider-map', component: ProviderMapPageComponent, canActivate: [AuthGuard] },
    { title: 'Reduction Requests', path: 'reduction-requests', component: ReductionRequestsGridComponent , canActivate: [AuthGuard] },
    { title: 'Intakes', path: 'file-intake-report', component: IntakesGridComponent , canActivate: [AuthGuard] },
    { title: 'Procedure Codes', path: 'procedure-codes', component: ProcedureCodesComponent, canActivate: [AuthGuard] },
    {
      title: 'Monday Integration',
      path: 'work-items-monday',
      component: WorkItemsMondayComponent,
      canActivate: [AuthGuard]
    },
    {
      title: 'Work Items',
      path: 'work-items',
      component: WorkItemsListComponent,
      canActivate: [AuthGuard]
    },
    { title: 'Test', path: 'test', component: TestPageComponent, canActivate: [AuthGuard] },
    { title: 'Test 2', path: 'test2', component: Test2Component, canActivate: [AuthGuard] },
    { title: 'Roles', path: 'users-roles', component: RolesGridComponent, canActivate: [AuthGuard] },
    { title: 'Profile', path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
    { title: 'Edit User', path: 'edit-user/:id', component: EditUsersComponent, canActivate: [AuthGuard] },
    { title: 'Users', path: 'users', canActivate: [AuthGuard], children: [
      { path: '', component: UsersGridComponent },
      { path: ':id', component: ItemViewComponent }
    ]},
    { title: 'Case Files', path: 'case-files', canActivate: [AuthGuard], children: [
      { path: '', component: CaseFilesGridComponent },
      { path: 'hub', component: FileHubComponent },
      { path: ':FileNumber', component: MultiFileComponent },
    ]},
    { title: 'Law Firms', path: 'law-firms', canActivate: [AuthGuard], children: [
      { path: '', component: LawFirmsGridComponent },
      { path: ':id', component: ItemViewComponent }
    ]},
    { path: 'law-firm-detail/:id', component: LawFirmDetailComponent },
    { title: 'Patients', path: 'patients', canActivate: [AuthGuard], children: [
      { path: '', component: PatientsGridComponent },
      { path: ':id', component: ItemViewComponent }
    ]},
    { path: 'patient-detail/:id', component: PatientDetailComponent },
    { title: 'Providers', path: 'providers', component: ProvidersGridComponent, canActivate: [AuthGuard], children: [
      { path: '', component: PatientsGridComponent },
      { path: ':id', component: ItemViewComponent }
    ]},
    { title: 'Providers', path: 'providers/:id', component: ItemViewComponent, canActivate: [AuthGuard], children: [{ title: 'Provider', path: 'providers' }] },
    { path: 'provider-detail/:id', component: ProviderDetailsComponent },
    { title: 'Fee Schedules', path: 'fee-schedules', component: FeeScheduleGridComponent, canActivate: [AuthGuard], children: [
      { path: '', component: PatientsGridComponent },
      { path: ':id', component: ItemViewComponent }
    ]},
    { title: 'Accounting', path: 'accounting', component: AccountingComponent, canActivate: [AuthGuard] },
  ]},
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
  { path: 'logout', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
