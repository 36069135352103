// Angular
import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { from } from 'rxjs';

// 3rd Party
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { SwitchAllModule, ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { CaseFileCompliance } from '@root/src/app/shared/models/data-contracts';
import { FileHubService } from '@root/src/app/shared/services/file-hub/file-hub.service';
import { FormField } from '../ui/field/field.component';
import { FormRequiredField } from '../ui/required-field/required-field.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Component({
  selector: 'compliance',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    SwitchAllModule,
    DatePickerModule,
    DropDownListModule,
    TextBoxModule,
    ButtonModule
  ],
  templateUrl: './compliance.component.html',
  styleUrl: './compliance.component.scss',
})
export class ComplianceComponent {

  constructor(
    private api: ApiService,
    public fileHub: FileHubService,
    private toast: ToastMessageService
  ) {
    console.log('Compliance Component Constructor Called');
  }

  @Input() caseFile: any;
  @ViewChild('complianceDialog') complianceDialog: DialogComponent;
  signedLeinSource = ['Yes', 'No', 'Client Only'];
  hipaaSignedSource = ['Yes', 'No', 'Provider BAA Only', 'N/A'];
  intakeSource = ['Yes', 'No', 'Manager Override'];
  underWritingSource = ['Pending', 'Approved', 'Denied'];
  mriSource = ['Positive', 'Negative'];
  complianceDialogButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitProviderSelection.bind(this), buttonModel: { content: 'Select This Provider', isPrimary: true, cssClass: 'e-outline' } },
  ];

  @Output() formValues = new EventEmitter<any>();
  updatedComplianceForm: FormGroup = new FormGroup({
    Id: new FormControl<number | null>(null),
    CaseFileId: new FormControl<number | null>(null),
    UccFiled: new FormControl<boolean | null>(null),
    UccFiledDate: new FormControl<string | null>(null),
    UccTerminated: new FormControl<boolean | null>(null),
    UccTerminatedDate: new FormControl<string | null>(null),
    MedicalLienFiled: new FormControl<boolean | null>(null),
    MedicalLienFiledDate: new FormControl<string | null>(null),
    SignedMedicalLien: new FormControl<string | null | undefined>(null),
    SignedHipaa: new FormControl<string | null | undefined>(null),
    IntakeForm: new FormControl<string | null | undefined>(null),
    DocumentLink: new FormControl<string | null | undefined>(null),
    PublicLink: new FormControl<string | null | undefined>(null),
    MriResults: new FormControl<boolean | null>(null),
    UnderwritingApproved: new FormControl<string | null | undefined>(null),
    LopObtained: new FormControl<boolean | null>(null),
    DocketNum: new FormControl<string | null | undefined>(null),
    County: new FormControl<string | null | undefined>(null),
    Notes: new FormControl<string | null | undefined>(null),
    NosaCompliance: new FormControl<boolean | null>(null),
    NosaMailedDate: new FormControl<string | null>(null),
    NosaCertifiedMailTrackingNumber: new FormControl<string | null | undefined>(null),
    NosaDeliveredDate: new FormControl<string | null>(null),
    NosaReceivedBy: new FormControl<string | null | undefined>(null),
  });

  //form data
  formData: CaseFileComplianceI = {
    UccFiled: false,
    UccFiledDate: null,
    UccTerminated: false,
    UccTerminatedDate: null,
    MedicalLienFiled: false,
    MedicalLienFiledDate: null,
    SignedMedicalLien: '',
    SignedHipaa: '',
    LopObtained: false,
    IntakeForm: '',
    NosaCompliance: false,
    NosaMailedDate: null,
    NosaCertifiedMailTrackingNumber: '',
    NosaDeliveredDate: null,
    NosaReceivedBy: '',
    UnderwritingApproved: '',
    MriResults: false,
    PublicLink: '',
    DocumentLink: '',
    Notes: '',
    DocketNum: '',
    County: '',
  };

  formChanged = false;

  ngOnInit(){
    console.log('Compliance Data:', this.compliance);
    if(this.compliance) {
      console.log('Setting form data...');
      this.formData = this.compliance;
      this.updatedComplianceForm.patchValue(this.compliance);
      
      // Subscribe to form changes and log the state for debugging
      this.updatedComplianceForm.valueChanges.subscribe(() => {
        this.formChanged = true;
        console.log('Form changed:', this.formChanged);
      });
    }
  }

  onSubmit() {
    try {
      // Format any dates in the form values
      const formValues = this.formatDates({ ...this.updatedComplianceForm.value });
      
      console.log('Submitting form values:', formValues);

      from(this.api.patchOdata(`${APIEndpoints.CaseFileCompliances}(${formValues.Id})`, formValues))
        .subscribe({
          next: (response: Response) => {
            this.toast.showSuccess('Successfully updated compliance data!');
            this.updatedComplianceForm.markAsPristine();
            this.formChanged = false;
          },
          error: (error: HttpErrorResponse) => {
            this.toast.showError('Failed to update compliance data');
            console.error('Error updating compliance:', error);
          }
        });
    } catch (error) {
      console.error('Error processing compliance data:', error);
      this.toast.showError('Error processing compliance data');
    }
  }

  formatDates(formObj: any){
    // Iterate through each key in the object
    for (const key in formObj) {
        if (formObj.hasOwnProperty(key)) {
            const value = formObj[key];
            // Check if the value is a Date object
            if (value instanceof Date) {
                // Convert Date object to string format (assuming DateOnly format)
                formObj[key] = value.toISOString().slice(0, 10); // YYYY-MM-DD format
            }
        }
    }
    return formObj;
  }

  // Set height of modals
  beforeOpeningModal(args: any) {
    args.maxHeight = '85vh';
  }

  openDialog() {
    this.complianceDialog.show();
  }

  closeDialog() {
    this.complianceDialog.hide();
  }

  submitProviderSelection() {
    console.log(this.complianceDialog);
    this.complianceDialog.hide();
  }

  getFormValues() {
    this.formatDates(this.updatedComplianceForm.value);
    this.formValues.emit(this.updatedComplianceForm.value);
    return this.updatedComplianceForm.value;
  }

  @Input() set compliance(value: any) {
    console.log('Compliance setter called with:', value);
    if (value) {
      // Store the original values for cancel functionality
      this.formData = { ...value };
      value.CaseFileId = this.caseFile.Id;
      this.updatedComplianceForm.patchValue(value);
      
      // Reset form state after setting initial values
      this.updatedComplianceForm.markAsPristine();
      this.formChanged = false;

      // Subscribe to form changes
      this.updatedComplianceForm.valueChanges.subscribe(() => {
        this.formChanged = this.updatedComplianceForm.dirty;
      });
    }
  }

  onCancel() {
    if (this.formData) {
      // Reset to original values
      this.updatedComplianceForm.patchValue(this.formData);
      this.updatedComplianceForm.markAsPristine();
      this.formChanged = false;
      this.toast.showSuccess('Changes reverted');
    }
  }
}

interface CaseFileComplianceI {
  [key: string]: any;
  Id?: number;
  UccFiled?: boolean | null;
  UccFiledDate?: string | null; // Assuming DateOnly is converted to string format
  UccTerminated?: boolean | null;
  UccTerminatedDate?: string | null; // Assuming DateOnly is converted to string format
  MedicalLienFiled?: boolean | null;
  MedicalLienFiledDate?: string | null; // Assuming DateOnly is converted to string format
  SignedMedicalLien?: string | null | undefined;
  SignedHipaa?: string | null | undefined;
  IntakeForm?: string | null | undefined;
  DocumentLink?: string | null | undefined;
  PublicLink?: string | null | undefined;
  MriResults?: boolean | null;
  UnderwritingApproved?: string | null | undefined;
  LopObtained?: boolean | null;
  DocketNum?: string | null | undefined;
  County?: string | null | undefined;
  Notes?: string | null | undefined;
  NosaCompliance?: boolean | null;
  NosaMailedDate?: string | null; // Assuming DateOnly is converted to string format
  NosaCertifiedMailTrackingNumber?: string | null | undefined;
  NosaDeliveredDate?: string | null; // Assuming DateOnly is converted to string format
  NosaReceivedBy?: string | null | undefined;
  CreatedBy?: number | null;
  UpdatedBy?: number | null;
  CreatedAt?: string | null; // Assuming DateOnly is converted to string format
  UpdatedAt?: string | null; // Assuming DateOnly is converted to string format
  IdNavigation?: any; // Replace 'any' with the type of 'CaseFile' if known
}
