<ng-container *ngIf="status; else unknown">
    <span *ngIf="status.includes('Good')" class="statustxt goodcolor">Good</span>
    <span *ngIf="status.includes('Active')" class="statustxt goodcolor">Active</span>
    <span *ngIf="status.includes('Bad')" class="statustxt badcolor">Bad</span>
    <span *ngIf="status.includes('Collections')" class="statustxt badcolor">Collections</span>
    <span *ngIf="status.includes('Neutral')" class="statustxt neutralcolor">Neutral</span>
    <span *ngIf="status.includes('Standard')" class="statustxt neutralcolor">Standard</span>
    <span *ngIf="status.includes('Archived')" class="statustxt neutralcolor">Archived</span>
    <span *ngIf="status.includes('Delinquent')" class="statustxt badcolor">Payment Past Due</span>
    <span *ngIf="status.includes('Pending')" class="statustxt neutralcolor">Pending</span>
    <span *ngIf="status.includes('Test')" class="statustxt neutralcolor">Test</span>
    <span *ngIf="status.includes('Approved')" class="statustxt goodcolor">Approved</span>
    <span *ngIf="status.includes('Denied')" class="statustxt badcolor">Denied</span>
</ng-container>
<ng-template #unknown>
    <span class="statustxt neutralcolor">Unknown</span>
</ng-template>