<div class="add-invoice">
  <div class="loading-overlay" *ngIf="isLoading() && !isFormHidden()">
    <loading-overlay [loading]="isLoading"></loading-overlay>
  </div>
  <!-- Show warnings if any exist -->
  <ng-container *ngIf="isFormHidden(); else showForm">
    <div [ngSwitch]="true">
      <div *ngSwitchCase="warnings[WarningType.Closed]" class="warning-container">
        <h3>Warning!</h3>
        <p>You are entering an invoice on a closed file, or a file where client is not currently in
        treatment. Please verify this is correct before proceeding.</p>
        <div class="warning-actions">
          <button class="close-button" type="button" ejs-button [isPrimary]="false" (click)="closeForm()">Close</button>
          <button type="button" ejs-button [isPrimary]="true" (click)="hideWarning(WarningType.Closed)">Continue</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.Important]" class="warning-container">
        <h3>Important Notes</h3>
        <p>{{selectedProvider?.Notes}}</p>
        <div class="warning-actions">
          <button class="close-button" type="button" ejs-button [isPrimary]="false" (click)="closeForm()">Close</button>
          <button type="button" ejs-button [isPrimary]="true" (click)="hideWarning(WarningType.Important)">Continue</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.SingleAgreement]" class="warning-container">
        <h3>Warning!</h3>
        <p>This provider is not in network. Do you have a Single Case Agreement for this file?</p>
        <div class="warning-actions">
          <button  class="close-button" type="button" ejs-button [isPrimary]="false" (click)="closeForm()">No</button>
          <button type="button" ejs-button [isPrimary]="true" (click)="hideWarning(WarningType.SingleAgreement)">Yes</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.SignedLien]" class="warning-container">
        <h3>Warning!</h3>
        <p>This case file does not have a signed Lien or LOP</p>
        <div class="warning-actions">
          <button type="button" ejs-button [isPrimary]="false" (click)="hideWarning(WarningType.SignedLien)">Close</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.NoProcedure]" class="warning-container">
        <h3>Warning!</h3>
        <p>This Provider is not part of any Fee Schedule</p>
        <div class="warning-actions">
          <button type="button" ejs-button [isPrimary]="false" (click)="hideWarning(WarningType.NoProcedure)">Close</button>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Show form when no warnings -->
  <ng-template #showForm>
    <ng-container *ngIf="!isLoading()">
      <form [formGroup]="invoiceForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div *ngIf="!hideHeader">
            <h1>Invoice</h1>
          </div>
          <div class="col-12 col-sm-6">
            <ng-container *ngIf="!invoice; else showProviderName">
              <ejs-dropdownlist #providerDropdown 
                  id='providerDD' 
                  allowFiltering="true"
                  placeholder="Select Provider" 
                  [fields]="{
                      text: 'Name',
                      value: 'Id'
                  }"
                  [dataSource]='providerList'
                  formControlName="ProviderId"
                  (change)='onProviderChange($event)'
                  floatLabelType="Auto"
              ></ejs-dropdownlist>
          </ng-container>
          
          <!-- Show provider name for editing -->
          <ng-template #showProviderName>
              <ejs-textbox 
                  [value]="selectedProvider?.Name"
                  placeholder="Provider"
                  floatLabelType="Auto"
                  [readonly]="true"
                  [enabled]="false"
              ></ejs-textbox>
          </ng-template>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-textbox 
              placeholder="Provider Invoice Number"
              formControlName="ProviderInvoiceNumber"
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-textbox>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-textbox 
              placeholder="Internal Invoice Number"
              formControlName="InternalInvoiceNumber"
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-textbox>
          </div>
          <div class="col-12 col-sm-6">
            Provider In Network : 
            <ejs-switch 
              [checked]="selectedProvider?.InNetwork"
              onLabel="Yes" 
              offLabel="No" 
              disabled="true"
            ></ejs-switch>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-datepicker 
              formControlName="InvoiceDate" 
              placeholder="Invoice Date" 
              floatLabelType="Auto"  
              format="yyyy-MM-dd"
              [enabled]="!isFieldDisabled()"
            ></ejs-datepicker>
          </div>
          <div class="col-12 col-sm-6">
            Lock Invoice :
            <ejs-switch 
              formControlName="LockInvoice"
              onLabel="Yes" 
              offLabel="No"
            ></ejs-switch>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-dropdownlist 
              formControlName="PaymentStatus"
              allowFiltering="true"
              placeholder="Select Payment Status" 
              [fields]="{
                value: 'Id',
                text: 'Description'
              }" 
              [dataSource]='paymentStatuses'
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-dropdownlist>
          </div>
          <div class="col-12 col-sm-6" *ngIf="showSurgicalFields">
            <ejs-dropdownlist 
              formControlName="SurgicalInvoiceId"
              placeholder="Select Surgical Type" 
              [fields]="{
                value: 'Id',
                text: 'Description'
              }"
              [dataSource]="surgicalProviderTypes"
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-dropdownlist>
          </div>
          <div class="col-12">
            <ejs-textbox 
              [multiline]='true' 
              placeholder="Notes"
              formControlName="Notes"
              [enabled]="!isFieldDisabled()"
            ></ejs-textbox>
          </div>

          <div  *ngIf="procedureCodes && procedureCodes.length > 0" class="form-item add-row-buttons">
            <button type="button" [isPrimary]="true" ejs-button (click)="addInvoiceRow()" [disabled]="isFieldDisabled()">Add Invoice Row</button>
            <button type="button" [isPrimary]="true" ejs-button (click)="addInvoicePayment()" [disabled]="isFieldDisabled()">Add Payment Row</button>
          </div>

          <!-- Invoice Rows -->
          <div *ngIf="procedureCodes && procedureCodes.length > 0">
            <div formArrayName="InvoiceRows">
              <div *ngFor="let row of invoiceRows.controls; let i = index" [formGroupName]="i">
                <div *ngIf="i>=1">
                  <hr />
                </div>
                <div class="invoice-row">
                  <div class="header">
                    <h4>Invoice Row {{i + 1}}</h4>
                    <button type="button" (click)="deleteInvoiceRow(i)" class="e-icons e-close row-button" [disabled]="isFieldDisabled()"></button>
                  </div>
                  <div class="row">
                    <div class="col-12 col-sm-2">
                      <ejs-datepicker 
                        formControlName="DateOfService"
                        placeholder="Date of Service" 
                        floatLabelType="Auto" 
                        format="yyyy-MM-dd"
                        [enabled]="!isFieldDisabled()"
                      ></ejs-datepicker>
                    </div>
                    <div class="col-12 col-sm-2">
                      <ejs-dropdownlist 
                        allowFiltering="true"
                        placeholder="Select a Procedure Code"
                        [fields]="{
                          value: 'Id',
                          text: 'ProcedureCodeName'
                        }" 
                        [dataSource]='procedureCodes'
                        formControlName="ProcedureCodeId"
                        (change)="onProcedureCodeChange($event, i)"
                        floatLabelType="Auto"
                        [enabled]="!isFieldDisabled()"
                      ></ejs-dropdownlist>
                    </div>
                    <div class="col-12 col-sm-2">
                      <ejs-numerictextbox 
                        formControlName="AmountBilled"
                        min="0" 
                        placeholder="Amount Billed ($)"  
                        floatLabelType="Auto"
                        [enabled]="!isFieldDisabled()"
                      ></ejs-numerictextbox>
                    </div>
                    <div class="col-12 col-sm-2">
                      <ejs-numerictextbox 
                      formControlName="ReimbursementRate"
                      min="0" 
                      max="100"
                      placeholder="Reimbursement Rate (%)"
                      floatLabelType="Auto"
                      format="n2"
                      decimals="2"
                      showSpinButton="false"
                      [enabled]="!isFieldDisabled()"
                      ></ejs-numerictextbox>
                    </div>
                    <div class="col-12 col-sm-2">
                      <ejs-numerictextbox 
                        formControlName="TotalDueProvider"
                        min="0" 
                        placeholder="T.D.Provider ($)" 
                        floatLabelType="Auto"
                        [enabled]="!isFieldDisabled()"
                      ></ejs-numerictextbox>
                    </div>
                    <div class="col-12 col-sm-2">
                      <ejs-numerictextbox 
                        formControlName="SettlementValue"
                        min="0" 
                        placeholder="Settlement Value ($)" 
                        floatLabelType="Auto"
                        [enabled]="!isFieldDisabled('settlement')"
                      ></ejs-numerictextbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="invoiceRows.length > 0">
              <hr />
            </div>

            <!-- Invoice Payments -->
            <div formArrayName="InvoicePayments">
              <div *ngFor="let payment of invoicePayments.controls; let i = index" [formGroupName]="i">
                <div *ngIf="i>=1">
                  <hr />
                </div>
            <div class="payment-row row">
              <div class="header">
                <h4>Payment {{i + 1}} Details</h4>
                <button type="button" (click)="deleteInvoicePayment(i)" class="e-icons e-close row-button" [disabled]="isFieldDisabled()"></button>
              </div>
              <div class="form-item col-12 col-sm-2">
                <ejs-datepicker 
                  formControlName="DatePaid"
                  placeholder="Date Paid" 
                  floatLabelType="Auto"  
                  format="yyyy-MM-dd"
                  [enabled]="!isFieldDisabled()"
                ></ejs-datepicker>
              </div>
              <div class="form-item col-12 col-sm-2">
                <ejs-numerictextbox 
                  formControlName="BalanceDue"
                  placeholder="Balance Due ($)" 
                  floatLabelType="Auto"
                  [enabled]="!isFieldDisabled('balance')"
                ></ejs-numerictextbox>
              </div>
              <div class="form-item col-12 col-sm-2">
                <ejs-numerictextbox 
                  formControlName="AmountPaid"
                  placeholder="Amount Paid ($)" 
                  floatLabelType="Auto"
                  [enabled]="!isFieldDisabled()"
                ></ejs-numerictextbox>
              </div>
              <div class="form-item col-12 col-sm-2">
                <ejs-dropdownlist 
                  formControlName="PaymentMethod"
                  allowFiltering="true"
                  placeholder="Select Payment Method" 
                  [fields]="{
                    value: 'Id',
                    text: 'Description'
                  }" 
                  [dataSource]='paymentMethods'
                  floatLabelType="Auto"
                  [enabled]="!isFieldDisabled()"
                ></ejs-dropdownlist>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="invoicePayments.length > 0">
          <hr />
        </div>
      </div>
      <div class="form-item form-buttons" [ngClass]="{'hidden': isFormHidden()}">
        <button type="submit" [isPrimary]="true" ejs-button>Submit</button>
      </div>
      </div>
      </form>
    </ng-container>
  </ng-template>
</div>