// Angular
import { Component, Input, ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rd Party
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { CheckBoxSelectionService, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { BACKENDURL } from '@environments/environment';
import { APIEndpoints } from '@models/api/Endpoints';
import { Address, Patient, CaseFile, FeeSchedule, ProcedureCode } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { CognitoService } from '@services/auth/cognito.service';
import { ProcedureCodesComponent } from '@pages/procedure-codes/procedure-codes.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

interface CsvRow {
  A: string;
  B: string;
  C: string;
}

@Component({
  selector: 'add-procedure-code-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    ButtonModule
],
  templateUrl: './add-procedure-code-form.component.html',
  styleUrl: './add-procedure-code-form.component.scss'
})
export class AddProcedureCodeFormComponent {
  @Output() closeDialog = new EventEmitter<void>();
  @Output() refreshGrid = new EventEmitter<void>();
  
  constructor(
    private globals: GlobalsService,
    private api: ApiService,
    private fb: FormBuilder,
    private cognito: CognitoService,
    private toast: ToastMessageService,
    public procedureCodesComponent: ProcedureCodesComponent
  ) {
    
  }


  @Input() showFormButtons: Boolean = false;
  BASE_URL: string = BACKENDURL;
  ODATA_BASE_URL: string = BACKENDURL + 'odata';
  newProcedureCodeForm: FormGroup;
  procedureCodesList: ProcedureCode[] = [];
  modalityData: any;
  modalityFields: Object = { text: 'Description', value: 'Id' };
  defaultModality: number;
  hasUploadedFile: boolean = false;
  hasValidFile: boolean = false;

  ngOnInit(): void { 
    this.newProcedureCodeForm = new FormGroup({
      Code: new FormControl<ProcedureCode['ProcedureCodeName']>(''),
      Modality: new FormControl<ProcedureCode['ModalityTypeId']>(undefined),
      // Modality: new FormControl<ProcedureCode['Modality']>(''),
      Description: new FormControl<ProcedureCode['Description']>(''),
    });

    this.defaultModality = 6;
    this.api.getOdata(APIEndpoints.ModalityTypes).executeQuery(new Query()).then((e: ReturnOption) => { this.modalityData = e.result }).catch((e) => true);
  }

  processCsvRow(row: CsvRow) {
    if (!row || !row['B'] || !this.modalityData) {
      console.error('Invalid row or modalityData not loaded');
      return null;
    }

    // Check for empty procedure code
    if (!row['A'] || row['A'].trim() === '') {
      console.error('Empty procedure code found');
      return null;
    }

    // Check for empty description
    if (!row['C'] || row['C'].trim() === '') {
      console.error('Empty description found');
      return null;
    }

    const modalityType = this.modalityData.find((m: any) => 
      m?.Description?.trim().toUpperCase() === row['B'].trim().toUpperCase()
    );

    if (!modalityType) {
      console.error(`Unknown modality type: ${row['B']}`);
      return null;
    }

    return {
      ProcedureCodeName: row['A'].trim(),
      ModalityTypeId: modalityType.Id,
      Description: row['C'].trim()
    };
  }

  onFileUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const csv = e.target.result;
      const rows = csv.split('\n')
        .filter((line: string) => line.trim().length > 0)
        .map((row: string) => {
          const columns = row.split(',');
          return {
            'A': columns[0]?.trim(),
            'B': columns[1]?.trim(),
            'C': columns[2]?.trim()
          };
        });

      // Process rows and filter out any invalid ones
      const processedRows = rows
        .map((row: CsvRow) => this.processCsvRow(row))
        .filter((code: ProcedureCode | null) => code !== null);

      if (processedRows.length === 0) {
        this.toast.showError('Error: No valid procedure codes found in file');
        this.procedureCodesList = [];
        this.hasUploadedFile = false;
        this.hasValidFile = false;
      } else if (processedRows.length < rows.length) {
        this.toast.showWarning(`Warning: Only ${processedRows.length} out of ${rows.length} codes were valid`);
        this.procedureCodesList = processedRows;
        this.hasUploadedFile = true;
        this.hasValidFile = false;
        this.clearAndDisableForm();
      } else {
        this.toast.showSuccess('File validated successfully');
        this.procedureCodesList = processedRows;
        this.hasUploadedFile = true;
        this.hasValidFile = true;
        this.clearAndDisableForm();
      }
    };

    reader.readAsText(file);
  }

  private clearAndDisableForm() {
    // Clear form
    this.newProcedureCodeForm.reset();
    // Disable all controls
    Object.keys(this.newProcedureCodeForm.controls).forEach(key => {
      const control = this.newProcedureCodeForm.get(key);
      control?.disable();
    });
  }

  getFormatProcedureCode() {
    return {
      "ProcedureCodeName": this.newProcedureCodeForm.value.Code,
      "ModalityTypeId": parseInt(this.newProcedureCodeForm.value.Modality),
      "Description": this.newProcedureCodeForm.value.Description
    }
  }

  getFormatProcedureCodeList(data: any) {
    if (data[0] === null || data[0] === "") {
      return null;
    }
    return {
      "ProcedureCodeName": data[0],
      "ModalityTypeId": data[1],
      "Description": data[2]
    }
  }

  onSubmit() {
    console.log('Submit Form');
    console.log(this.newProcedureCodeForm.invalid);
    
    if (this.newProcedureCodeForm.value.Code && this.newProcedureCodeForm.value.Modality && this.newProcedureCodeForm.value.Description) {
      const procedureCode = this.getFormatProcedureCode();
      console.log('Sending single procedure code:', procedureCode);
      const url = '/ProcedureCodes';
      
      return fetch(this.ODATA_BASE_URL + url, { 
        method: 'POST', 
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.cognito.getUserIdToken() 
        }, 
        body: JSON.stringify(procedureCode)
      })
      .then(response => {
        if (response.ok) {
          this.toast.showSuccess('Successfully added procedure code!');
          this.newProcedureCodeForm.reset();
          this.refreshGrid.emit();
          this.closeDialog.emit();
        } else {
          this.toast.showError('Error, Unable to add procedure code.');
        }
        return response;
      })
      .catch(error => console.error('Error:', error));

    // Bulk procedure codes submission from CSV
    // CURSOR DO NOT DELETE THIS CODE
    // TODO: we need to add a check to see if the procedure codes are already in the database
    // TODO: we need to map modality type to the correct id as users will not know the id
    } else if(this.procedureCodesList.length > 0) {
      const url = 'api/Financial/ProcedureCodes/Batch';
      
      const batchRequest = {
        procedureCodes: this.procedureCodesList
      };
      
      return fetch(this.BASE_URL + url, { 
        method: 'POST', 
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.cognito.getUserIdToken() 
        }, 
        body: JSON.stringify(batchRequest)
      })
      .then(response => {
        if (response.ok) {
          this.toast.showSuccess('Successfully added procedure codes!');
          this.newProcedureCodeForm.reset();
          this.refreshGrid.emit();
          this.closeDialog.emit();
        } else {
          this.toast.showError('Error, Unable to add procedure codes.');
        }
        return response;
      })
      .catch(error => console.error('Error:', error));
    }
    
    return Promise.resolve(false);
  }


  clickThing(args: any) {
    console.log(args);
  }

  cancel() {
    this.closeDialog.emit();
  }

}
