// Angular
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'form-required-field',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './required-field.component.html',
  styleUrl: './required-field.component.scss'
})
export class FormRequiredField {

  constructor () {}

  @Input() form: FormGroup;
  @Input() field: string;
}
