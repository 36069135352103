<form [formGroup]="taskForm" class="task-form">
  <div class="form-group">
    <label for="taskName">Task Name *</label>
    <ejs-textbox
      id="taskName"
      formControlName="TaskName"
      placeholder="Enter task name">
    </ejs-textbox>
  </div>

  <div class="form-group">
    <label for="taskDescription">Description</label>
    <ejs-textbox
      id="taskDescription"
      formControlName="TaskDescription"
      placeholder="Enter task description"
      multiline="true">
    </ejs-textbox>
  </div>

  <div class="form-group">
    <label for="taskType">Type *</label>
    <ejs-dropdownlist
      id="taskType"
      formControlName="TaskTypeId"
      [dataSource]="taskTypes"
      [fields]="{ text: 'Description', value: 'Id' }"
      placeholder="Select task type">
    </ejs-dropdownlist>
  </div>

  <div class="form-group">
    <label for="priority">Priority</label>
    <ejs-dropdownlist
      id="priority"
      formControlName="Priority"
      [dataSource]="[1,2,3,4,5]"
      placeholder="Select priority">
    </ejs-dropdownlist>
  </div>

  <div class="form-group">
    <label for="provider">Provider</label>
    <ejs-dropdownlist
      id="provider"
      formControlName="ProviderId"
      [dataSource]="providers"
      [fields]="{ text: 'Name', value: 'Id' }"
      placeholder="Select provider">
    </ejs-dropdownlist>
  </div>

  <div class="form-group">
    <label for="nextDateOfActivity">Next Activity Date</label>
    <ejs-datepicker
      id="nextDateOfActivity"
      formControlName="NextDateOfActivity"
      placeholder="Select next activity date">
    </ejs-datepicker>
  </div>

  <div class="form-group">
    <label for="comments">Comments</label>
    <ejs-textbox
      id="comments"
      formControlName="Comments"
      placeholder="Enter comments"
      multiline="true">
    </ejs-textbox>
  </div>

  <div class="form-actions">
    <button ejs-button type="button" (click)="onCancelClick()" [cssClass]="'e-outline e-flat'">Cancel</button>
    <button ejs-button [isPrimary]="true" type="submit" (click)="onSubmit()" [disabled]="!taskForm.valid">Save</button>
  </div>
</form> 