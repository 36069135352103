import { CompactEncrypt } from '../jwe/compact/encrypt.js';
import { encoder } from '../lib/buffer_utils.js';
import { ProduceJWT } from './produce.js';
export class EncryptJWT extends ProduceJWT {
  setProtectedHeader(protectedHeader) {
    if (this._protectedHeader) {
      throw new TypeError('setProtectedHeader can only be called once');
    }
    this._protectedHeader = protectedHeader;
    return this;
  }
  setKeyManagementParameters(parameters) {
    if (this._keyManagementParameters) {
      throw new TypeError('setKeyManagementParameters can only be called once');
    }
    this._keyManagementParameters = parameters;
    return this;
  }
  setContentEncryptionKey(cek) {
    if (this._cek) {
      throw new TypeError('setContentEncryptionKey can only be called once');
    }
    this._cek = cek;
    return this;
  }
  setInitializationVector(iv) {
    if (this._iv) {
      throw new TypeError('setInitializationVector can only be called once');
    }
    this._iv = iv;
    return this;
  }
  replicateIssuerAsHeader() {
    this._replicateIssuerAsHeader = true;
    return this;
  }
  replicateSubjectAsHeader() {
    this._replicateSubjectAsHeader = true;
    return this;
  }
  replicateAudienceAsHeader() {
    this._replicateAudienceAsHeader = true;
    return this;
  }
  async encrypt(key, options) {
    const enc = new CompactEncrypt(encoder.encode(JSON.stringify(this._payload)));
    if (this._replicateIssuerAsHeader) {
      this._protectedHeader = {
        ...this._protectedHeader,
        iss: this._payload.iss
      };
    }
    if (this._replicateSubjectAsHeader) {
      this._protectedHeader = {
        ...this._protectedHeader,
        sub: this._payload.sub
      };
    }
    if (this._replicateAudienceAsHeader) {
      this._protectedHeader = {
        ...this._protectedHeader,
        aud: this._payload.aud
      };
    }
    enc.setProtectedHeader(this._protectedHeader);
    if (this._iv) {
      enc.setInitializationVector(this._iv);
    }
    if (this._cek) {
      enc.setContentEncryptionKey(this._cek);
    }
    if (this._keyManagementParameters) {
      enc.setKeyManagementParameters(this._keyManagementParameters);
    }
    return enc.encrypt(key, options);
  }
}