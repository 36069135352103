<!-- Contact Form Start -->
<div id="contact-form" [class]="formClasses.formContainer">
    <h2 class="form-title"><fa-icon [icon]="contactIcons.faUsersRectangle" /> Contact Information</h2>

    <form [formGroup]="contactForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' contact-section'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('ContactName')" [class]="formClasses.inputContainer" [class.error]="contactForm.get('ContactName')?.errors && contactForm.get('ContactName')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="contactIcons.faUser" />
                        <ejs-tooltip *ngIf="contactForm.get('ContactName')?.errors && contactForm.get('ContactName')?.touched" [content]="getErrorMessage('ContactName')" />
                    </span>
                    <label>{{ fieldLabels['ContactName'] }}</label>
                    <ejs-textbox [class]="formClasses.input" formControlName="ContactName" placeholder="Contact Name" />
                    <ng-container *ngIf="contactForm.get('ContactName')?.invalid && contactForm.get('ContactName')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('ContactEmail')" [class]="formClasses.inputContainer" [class.error]="contactForm.get('ContactEmail')?.errors && contactForm.get('ContactEmail')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="contactIcons.faEnvelope" />
                        <ejs-tooltip *ngIf="contactForm.get('ContactEmail')?.errors && contactForm.get('ContactEmail')?.touched" [content]="getErrorMessage('ContactEmail')" />
                    </span>
                    <label>{{ fieldLabels['ContactEmail'] }}</label>
                    <ejs-textbox [class]="formClasses.input" formControlName="ContactEmail" placeholder="Contact Email" [type]="'email'" />
                    <ng-container *ngIf="contactForm.get('ContactEmail')?.invalid && contactForm.get('ContactEmail')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('ContactTitle')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="contactIcons.faIdCard" />
                    </span>
                    <label>{{ fieldLabels['ContactTitle'] }}</label>
                    <ejs-textbox [class]="formClasses.input" formControlName="ContactTitle" placeholder="Contact Title" />
                </div>

                <div *ngIf="displayFields.includes('ContactType')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="contactIcons.faUserTag" />
                        <ejs-tooltip *ngIf="contactForm.get('ContactType')?.errors && contactForm.get('ContactType')?.touched" [content]="getErrorMessage('ContactType')" />
                    </span>
                    <label>{{ fieldLabels['ContactType'] }}</label>
                    <ejs-dropdownlist formControlName="ContactType" 
                        [placeholder]="'Contact Type'" 
                        [class]="formClasses.input"
                        [dataSource]="contactTypes"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="contactForm.get('ContactType')?.value" />
                </div>

                <div *ngIf="displayFields.includes('Notes')" [class]="formClasses.inputContainerFullWidth">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="contactIcons.faComments" />
                    </span>
                    <label>{{ fieldLabels['Notes'] }}</label>
                    <ejs-textbox [class]="formClasses.input" formControlName="Notes" placeholder="Notes" [type]="'textarea'" [multiline]="true" />
                </div>
                <div *ngIf="displayFields.includes('XrefPhoneContacts')" [class]="formClasses.inputContainerFullWidth">
                    <label>{{ fieldLabels['ContactPhone'] }}</label>
                    <phone-form #phoneFormComp [phone]="contactPhone" [displayFields]="['PhoneNumber', 'PhoneType']" [displayButtons]="false"></phone-form>
                </div>

                <!-- Add fax form -->
                <div *ngIf="displayFields.includes('ContactFax')" [class]="formClasses.inputContainerFullWidth">
                    <label>{{ fieldLabels['ContactFax'] }}</label>
                    <phone-form #faxFormComp [phone]="contactFax" [displayFields]="['PhoneNumber', 'PhoneType']" [displayButtons]="false"></phone-form>
                </div>

            </div>
    
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' ' + formClasses.subForm + ' address-section'">
            <p class="section-title"><fa-icon [icon]="contactIcons.faMapLocationDot" /> Contact Address</p>
            <!-- Address Form Start -->
            <address-form #addressFormComp [address]="contactAddress" [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" [displayButtons]="false" />
            <!-- Address Form End -->
        </section>
        
        <section [class]="formClasses.section + ' col-12'" *ngIf="showSubmitButton">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
            </div>
        </section>

    </form>

</div>
<!-- Contact Form End -->