// Angular
import { Component, Input, Output, EventEmitter, ViewChild, ViewEncapsulation, signal, computed, WritableSignal } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
// 3rd Party
import { DropDownList,  } from '@syncfusion/ej2-dropdowns';
import { DataManager } from '@syncfusion/ej2-data';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { firstValueFrom } from 'rxjs';
import { faBuilding, faHashtag, faMoneyBill, faNoteSticky, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Internal
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { insuranceCompanies, countryCodes, insuranceTypes, policyLimits, states } from '@models/global-vars';
import { Insurance, Contact, Address } from '@models/data-contracts';
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form/phone-number-form.component';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Component({
  selector: 'casefile-insurance-policy-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    ButtonModule,
    MaskedTextBoxModule,
    CommonModule,
    // AddressFormComponent,
    // PhoneFormComponent,
    ContactFormComponent,
    FontAwesomeModule
  ],
  templateUrl: './casefile-insurance-policy-form.component.html',
  styleUrl: './casefile-insurance-policy-form.component.scss'
})
export class CasefileInsurancePolicyFormComponent {

  constructor(
    private toast: ToastMessageService,
    private fb: FormBuilder,
    private api: ApiService
  ) {}

  @Input() includeSubmit: boolean = false;
  @Output() result = new EventEmitter<any>();
  @ViewChild('faxCountryCode') faxCountryCode: DropDownList;
  @ViewChild('phoneCountryCode') phoneCountryCode: DropDownList;
  @ViewChild('addressForm') addressForm: AddressFormComponent;
  @ViewChild('adjusterPhoneForm') adjusterPhoneForm: PhoneFormComponent;
  @ViewChild('adjusterFaxForm') adjusterFaxForm: PhoneFormComponent;
  @ViewChild('adjusterContact') adjusterContact: ContactFormComponent;
  @Input() caseFileId: number;

  // Main insurance form
  insuranceForm = this.fb.group({
    InsuranceCompany: [null as number | null],
    ClaimNumber: [null as string | null],
    PolicyLimits: [null as number | null],
    ClaimAdjusterId: [null as number | null],
    ClaimAdjusterName: [null as string | null],
    Note: [null as string | null],
  });

  // Phone forms
  phoneFormGroup = this.fb.group({
    Id: [null as number | null],
    PhoneType: [null as number | null],
    PhoneNumber: [null as string | null],
    Extension: [null as string | null],
    CreatedBy: [null as number | null],
    CreatedDate: [null as Date | null],
    UpdatedBy: [null as number | null],
    UpdatedDate: [null as Date | null],
    Active: [true as boolean | null]
  });

  faxFormGroup = this.fb.group({
    Id: [null as number | null],
    PhoneType: [null as number | null],
    PhoneNumber: [null as string | null],
    Extension: [null as string | null],
    CreatedBy: [null as number | null],
    CreatedDate: [null as Date | null],
    UpdatedBy: [null as number | null],
    UpdatedDate: [null as Date | null],
    Active: [true as boolean | null]
  });

  insuranceCompanies: DataManager | any[] = insuranceCompanies;
  insuranceTypes: DataManager | any[] = insuranceTypes;
  insuraceTypesFields: any = { text: 'description', value: 'id' };
  countryCodes: DataManager | any[] = countryCodes;
  countryCodesFields: any = { text: 'description', value: 'code', iconCSS: 'icon' };
  policyLimits: DataManager | any[] = policyLimits;

  @ViewChild('statesDrpDwn') statesDrpDwn: DropDownList;
  states: DataManager | any[] = states;

  // Create computed signals that watch the form controls
  readonly adjusterPhoneSignal: WritableSignal<any> = signal(() => this.insuranceForm.get('AdjusterPhone')?.value);
  readonly adjusterFaxSignal: WritableSignal<any> = signal(() => this.insuranceForm.get('ClaimAdjusterFaxNumber')?.value);
  readonly addressSignal: WritableSignal<any> = signal(() => this.insuranceForm.get('Address')?.value);

  // Create a signal for the contact
  adjusterContactSignal = signal<Contact | undefined>(undefined);

  icons = {
    faBuilding,
    faHashtag,
    faMoneyBill,
    faNotes: faNoteSticky,
    faUser
  };

  formClasses = {
    inputContainer: 'cc-input-container',
    icon: 'cc-input-icon',
    input: 'cc-input'
  };

  ngOnInit() {
    this.addressSignal.set(this.insuranceForm.get('Address')?.value);
    this.adjusterPhoneSignal.set(this.insuranceForm.get('AdjusterPhone')?.value);
    this.adjusterFaxSignal.set(this.insuranceForm.get('ClaimAdjusterFaxNumber')?.value);
  }


  async onSubmit(event: any) {
    event.stopPropagation();
    
    try {
      const contactResponse = await this.adjusterContact.onSubmit();
      if (!contactResponse || !contactResponse.Id) {
        this.toast.showError('Failed to create contact');
        return;
      }

      this.insuranceForm.patchValue({ 
        ClaimAdjusterId: contactResponse.Id,
        ClaimAdjusterName: contactResponse.ContactName 
      });

      const insurancePayload = {
        CaseFileId: this.caseFileId,
        InsuranceCompany: this.insuranceForm.value.InsuranceCompany || '',
        ClaimNumber: this.insuranceForm.value.ClaimNumber || '',
        TypesOfInsurance: 'Primary',
        PolicyLimits: this.insuranceForm.value.PolicyLimits || '',
        ClaimAdjusterContactId: contactResponse.Id,
        ClaimAdjusterName: contactResponse.ContactName || '',
        AddressId: contactResponse.AddressId,
        Note: this.insuranceForm.value.Note || ''
      };

      const insuranceRes = await this.api.basicPost(APIEndpoints.Insurances, insurancePayload);
      const insuranceData = insuranceRes;
      
      this.toast.showSuccess('Insurance policy created successfully');
      this.result.emit(insuranceData);
      return insuranceData;

    } catch (error) {
      this.toast.showError('Failed to save form data');
      return undefined;
    }
  }

  handleAddressFormResult(result: any) {
    if (result === 'error') {
      this.toast.showError('Failed to save address');
    }
  }

  handlePhoneFormResult(result: any) {
    if (result === 'error') {
      this.toast.showError('Failed to save phone number');
    }
  }

  getFormControl(path: string) {
    return this.insuranceForm.get(path)?.value;
  }
}