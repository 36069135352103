<div id="add-fee-schedule" class="content">

    <form class="add-fee-schedule-form" [formGroup]="newFeeScheduleForm" (ngSubmit)="onSubmit()">

        <div class="row">

            <div class="col-6">
                <ejs-textbox formControlName="Name" placeholder="Name" floatLabelType="Auto"></ejs-textbox>
                <div *ngIf="newFeeScheduleForm.get('Name')?.errors" class="error-container">
                    <div *ngIf="newFeeScheduleForm.get('Name')?.errors?.['required'] && newFeeScheduleForm.get('Name')?.touched"
                        class="e-error">
                        <span>This field is required.</span>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <label>CPT Codes</label>

                <div class="d-flex flex-column" formControlName="Description">
                    <!-- <ng-container *ngIf="entries.controls.length > 0 && cptCodeRepeaterVisibile"> -->
                    <ng-container>
                        <form [formGroup]="attachedProcedureCodesForm">
                            <div formArrayName="entries">
                                <ng-container *ngFor="let entry of entries.controls; let $index = index">
                                    <div [formGroupName]="$index" class="d-flex flex-row justify-content-between align-items-center mt-1">
                                        <!-- Medicare Billing will be added in the future -->

                                        <!-- <ng-container *ngIf="entry.value['IsMedicare']; else nonMedicare">   -->
                                            <!-- Medicare Billing :<ejs-switch onLabel="Yes" offLabel="No" [checked]="entry.value['IsMedicare']" (change)="entry.value['IsMedicare'] = !entry.value['IsMedicare']"></ejs-switch> -->
                                           
                                            <!-- <ejs-dropdownlist floatLabelType="Always" placeholder="CPT Code" formControlName="cptCodeId" [dataSource]="medicareCptCodesData"
                                                [fields]="{text: 'code', value: 'id'}" (change)="changedProcedureCode($event)" />
                                            <ejs-dropdownlist floatLabelType="Always" placeholder="Locality" formControlName="localityId" [dataSource]="medicareLocalitiesData"
                                                [fields]="{text: 'localityName', value: 'id'}" />
                                            <ejs-dropdownlist floatLabelType="Always" placeholder="Participating" formControlName="participating" [dataSource]="medicareParticipating"
                                                [fields]="{text: 'name', value: 'name'}" />
                                            <ejs-numerictextbox floatLabelType="Always" placeholder="Multiplier" formControlName="multiplier" />
                                            <ejs-checkbox floatLabelType="Always" label="Facility?" formControlName="facility" /> -->
                                    
                                        <!-- </ng-container> -->
                                        <!-- <ng-template #nonMedicare> -->
                                            <!-- Medicare Billing: <ejs-switch onLabel="Yes" offLabel="No" [checked]="entry.value['IsMedicare']" (change)="entry.value['IsMedicare'] = !entry.value['IsMedicare']"></ejs-switch> -->
                                            <ejs-dropdownlist floatLabelType="Always" placeholder="Procedure Code" formControlName="Code" [dataSource]="service.cptCodesData"
                                            [fields]="{text: 'ProcedureCodeName', value: 'Id'}" (change)="changedProcedureCode($event)" />
                                            <ejs-numerictextbox floatLabelType="Always" placeholder="Billed Amount" formControlName="BilledAmount" />
                                            <ejs-numerictextbox floatLabelType="Always" placeholder="Front End Reimbursment Rate" formControlName="BackEndReimbursmentRate" />
                                            <ejs-numerictextbox floatLabelType="Always" placeholder="Back End Reimbursement Rate" formControlName="FrontEndReimbursmentRate" />
                                            <button ejs-button type="button" iconCss="e-icons e-circle-add" [isPrimary]="false" (click)="addEntry()"></button>
                                            <button ejs-button type="button" iconCss="e-icons e-circle-remove" [isPrimary]="false" (click)="removeEntry()"></button>
                                           
 
                                        <!-- </ng-template> -->
                                        <ejs-checkbox floatLabelType="Always" label="Split Invoice?" formControlName="SplitInvoice" />
                                        <!-- <button ejs-button type="button" iconCss="e-icons e-circle-add" [isPrimary]="false" (click)="addEntry()"></button>
                                        <div class="d-flex flex-row justify-content-end align-items-center mt-3">
                                            <button ejs-button type="button" iconCss="e-icons e-circle-remove" [isPrimary]="false" (click)="removeEntry()"></button>
                                        </div> -->
                                    </div>
                                </ng-container>
                                <div class="d-flex flex-row justify-content-end align-items-center mt-3" *ngIf="entries.length ===0">
                                    <button ejs-button type="button" iconCss="e-icons e-circle-add" [isPrimary]="false" (click)="addEntry()"></button>
                                    <button ejs-button type="button" iconCss="e-icons e-circle-remove" [isPrimary]="false" (click)="removeEntry()"></button>
                                </div>
                            </div>
                        </form>
                    </ng-container>
                </div>

            </div>

            <div class="col-12">
                <label style="margin-bottom: 1rem;">Providers</label>
                <div class="custom-providers-editor">
                    <custom-assignments-editor
                        [availableItems]="availableProviders"
                        [assignedItems]="assignedProviders"
                        (assignedItemsChange)="onAssignedProvidersChange($event)"
                    />
                </div>
            </div>

            <!-- <div class="col-10"> </div> -->
            <div class="col-2 form-submit ">
                <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true">Submit</button>
            </div>
        </div>
    </form>
</div>