<header id="app-header">

    <div class="app-nav">

        <div class="app-nav-menu">
        
            <ng-container [ngSwitch]="layoutMode()">
                <ng-container *ngSwitchCase="'app'">
                    <ejs-sidebar #sidebar id="_ejs-component-mainSidebar" style="visibility: hidden"
                        width="250px"
                        dockSize="72px"
                        type="Push"
                        target="_ejs-component-mainSidebar"
                        [enableDock]="true"
                        [enablePersistence]="true"
                        [enableGestures]="false"
                        (created)="onCreated()">

                        <div class="app-logo">
                            <div class="logo">
                                <a (click)="goHome()"><img [src]="logo()" /></a>
                            </div>
                        </div>

                        <app-main-nav [isSideBarSmall]="!this.sidebar.isOpen"></app-main-nav>

                        <div class="expand">
                            <button (click)="toggleSidebar($event)">
                                <span class="icon">
                                    <fa-icon *ngIf="toggleSideBarIcon" [icon]="toggleSideBarIcon"></fa-icon>
                                    <span *ngIf="!toggleSideBarIcon" class="e-icons e-documents"></span>
                                </span>
                            </button>
                        </div>
                    </ejs-sidebar>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <div class="app-logo">
                        <div class="logo">
                            <a (click)="goHome()" class="logo">
                                <img [src]="logo()" />
                            </a>
                        </div>
                    </div>
                    <app-main-nav></app-main-nav>
                </ng-container>
            </ng-container>

        </div>
    </div>
</header>