// Angular
import { Component, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rd Party
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ThemeService } from '@services/theme/theme.service';

@Component({
  selector: 'theme-color-picker',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    ButtonModule,
    TextBoxModule,
    ReactiveFormsModule,
    ColorPickerModule
  ],
  templateUrl: './theme-color-picker.component.html',
  styleUrl: './theme-color-picker.component.scss'
})
export class ThemeColorPickerComponent {

  constructor(
    private userPreferences: UserPreferencesService,
    private theme: ThemeService,
  ) { }

  customColors: any = this.userPreferences.getLocalUserPreference('customThemeColors') ?? {};
  formGroup: FormGroup = new FormGroup({
    primaryColor: new FormControl<string>(this.customColors.PrimaryColor ?? '#FA0000', [Validators.required]),
    secondaryColor: new FormControl<string>(this.customColors.SecondaryColor ?? '#FA0000', [Validators.required]),
    tertiaryColor: new FormControl<string>(this.customColors.TertiaryColor ?? '#FA0000', [Validators.required]),
  });

  handleClearThemeClick(e: Event) {
    e.preventDefault();
    this.theme.resetTheme();
    this.userPreferences.getLogo({ theme: 'default' });
  }

  onSubmit = () => {
    const colors = {
      PrimaryColor: this.formGroup.value.primaryColor,
      SecondaryColor: this.formGroup.value.secondaryColor,
      TertiaryColor: this.formGroup.value.tertiaryColor
    }
    this.userPreferences.saveLocalUserPreference('theme', 'custom');
    this.userPreferences.saveLocalUserPreference('customThemeColors', colors);
    this.theme.setTheme(colors.PrimaryColor, colors.SecondaryColor, colors.TertiaryColor);
  }
}
