import { Injectable, Provider } from '@angular/core';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { Query } from '@syncfusion/ej2-data';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  private providerDetailsSubject = new BehaviorSubject<any>(null);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public providerDetails$ = this.providerDetailsSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();

  constructor(private api: ApiService) {}

  getProviderById(id: number, query: Query): Observable<any> {
    this.loadingSubject.next(true);
    
    return new Observable(subscriber => {
      this.api.getOdata(`${APIEndpoints.Providers}(${id})`).executeQuery(query)
        .then((response: any) => {
          const provider = response.result[0];
          this.providerDetailsSubject.next(provider);
          this.loadingSubject.next(false);
          subscriber.next(provider);
          subscriber.complete();
        })
        .catch((error: any) => {
          console.error('Error fetching provider:', error);
          this.loadingSubject.next(false);
          subscriber.error(error);
        });
    });
  }
}