<form class="row mt-0 flex-row" [formGroup]="intakeForm" (submit)="onSubmit($event)">

    <div class="col-12 col-md-6">
        <body-part-selector (injuries)="getInjuryData($event)"></body-part-selector>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Accident Info</label>
            <ejs-dropdownlist
                formControlName="AccidentInfoId"
                [dataSource]="accidentInfo" 
                [fields]="{ text: 'Description', value: 'Id'}" />
            <div *ngIf="intakeForm.get('AccidentInfoId')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('AccidentInfoId')?.errors?.['required'] && intakeForm.get('AccidentInfoId')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Accident Details</label>
            <ejs-multiselect
                mode="CheckBox"
                formControlName="AccidentDetails"
                [showDropDownIcon]="true"
                [dataSource]="accidentDetails" 
                [fields]="{ text: 'Description', value: 'Id'}" />
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Anticipated Settlement</label>
            <ejs-dropdownlist 
                formControlName="AnticipatedSettlement"    
                [dataSource]="settlementTimeframe" />
            <div *ngIf="intakeForm.get('AnticipatedSettlement')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('AnticipatedSettlement')?.errors?.['required'] && intakeForm.get('AnticipatedSettlement')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Additional Information Regarding Injury</label>
            <ejs-textbox formControlName="IntakeComments" [multiline]="true" />
            <div *ngIf="intakeForm.get('IntakeComments')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('IntakeComments')?.errors?.['required'] && intakeForm.get('IntakeComments')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Medical Costs to Date</label>
            <ejs-numerictextbox formControlName="MedicalCosts" />
            <div *ngIf="intakeForm.get('MedicalCosts')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('MedicalCosts')?.errors?.['required'] && intakeForm.get('MedicalCosts')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Property Damage Amount</label>
            <ejs-numerictextbox formControlName="PropertyDamage" />
            <div *ngIf="intakeForm.get('PropertyDamage')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('PropertyDamage')?.errors?.['required'] && intakeForm.get('PropertyDamage')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Previous cash assistance, liens or loans?</label>
            <ejs-switch
                formControlName="PreviousAssistance" 
                offLabel="No"
                onLabel="Yes" />
            <div *ngIf="intakeForm.get('PreviousAssistance')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('PreviousAssistance')?.errors?.['required'] && intakeForm.get('PreviousAssistance')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Gap in treatment > 30 days?</label>
            <ejs-switch
                formControlName="GapInTreatment"
                offLabel="No"
                onLabel="Yes" />
            <div *ngIf="intakeForm.get('GapInTreatment')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('GapInTreatment')?.errors?.['required'] && intakeForm.get('GapInTreatment')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Clear Liability</label>
            <ejs-switch
                formControlName="ClearLiability"
                offLabel="No"
                onLabel="Yes" />
            <div *ngIf="intakeForm.get('ClearLiability')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('ClearLiability')?.errors?.['required'] && intakeForm.get('ClearLiability')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Prior Representation</label>
            <ejs-switch
                formControlName="PriorRepresentation"
                offLabel="No"
                onLabel="Yes" />
            <div *ngIf="intakeForm.get('PriorRepresentation')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('PriorRepresentation')?.errors?.['required'] && intakeForm.get('PriorRepresentation')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-6">
        <div class="d-flex flex-column">
            <label>Limits Search</label>
            <ejs-switch
                formControlName="LimitsSearch"
                offLabel="No"
                onLabel="Yes" />
            <div *ngIf="intakeForm.get('LimitsSearch')?.errors" class="error-container">
                <div *ngIf="intakeForm.get('LimitsSearch')?.errors?.['required'] && intakeForm.get('LimitsSearch')?.touched"
                    class="e-error">
                    <span>This field is required.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
    </div>
        <div class="row">
            <div class="col-10"> </div>
            <div class="col-2 form-submit ">
              <button ejs-button class="e-primary e-flat" ejs-button type="submit" isPrimary="true">Submit</button>
            </div>
        </div>
</form>


<div class="d-flex flex-column">
   Number of Existing Insurances: {{existingInsurances.length}}
</div>
<button ejs-button 
isPrimary="false" 
cssClass="e-outline"
type="button"
[iconCss]="toggleInsurancePolicyBtnIconClass" 
(click)="toggleInsurancePolicy($event)">
<span>{{toggleInsurancePolicyBtnText}}</span>
</button>

<ejs-dialog id="insurancePolicy" #insurancePolicy
    header="Add Insurance"
    [showCloseIcon]="true"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="insurancePolicyFormVisible"
    (close)="toggleInsurancePolicy($event)"
    [width]="'90%'"
>
    <casefile-insurance-policy-form
        [caseFileId]="caseFileId"
        (result)="handleInsuranceResult($event)"
    />
</ejs-dialog>