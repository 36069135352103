// Angular 
import { Injectable, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, Subject } from 'rxjs';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Tab, tabs } from './hub.model';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { CaseFile, Deposit, Invoice, InvoicePayment, InvoiceRow } from '@models/data-contracts';
import { PerformanceData, performanceData } from '@models/components/financial-performance.model';
import { GlobalsService } from '@services/globals/globals.service';
import { PhoneNumber } from '@models/components/provider-map.model';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';

@Injectable({
  providedIn: 'root'
})
export class FileHubService {

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastMessageService,
    private globals: GlobalsService
  ) { }

  // Signals & Variables
  private caseFileSignal: WritableSignal<CaseFile | undefined> = signal(undefined);
  private caseFileIdSignal: WritableSignal<number | undefined> = signal(undefined);
  private tabFragmentSignal: WritableSignal<string | undefined> = signal(undefined);
  private activeTabIDSignal: WritableSignal<string | undefined> = signal(undefined);
  private performanceDataSignal: WritableSignal<PerformanceData | undefined> = signal(undefined);
  private caseFileSubject = new BehaviorSubject<CaseFile | undefined>({});
  public caseFile$: Observable<CaseFile | undefined> = this.caseFileSubject.asObservable();
  public reloadSignal: WritableSignal<boolean | undefined> = signal(undefined);
  public tabs = tabs;
  private reloadTrigger = new Subject<void>();
  public reload$ = this.reloadTrigger.asObservable();
  public authLimitPercentage: number | undefined = undefined;

  /**
   * Getters
   */
  get caseFile(): CaseFile | undefined {
    return this.caseFileSignal();
  }

  get caseFileId(): number | undefined {
    return this.caseFileIdSignal();
  }

  get performanceData(): PerformanceData | undefined {
    return this.performanceDataSignal();
  }

  get getCaseFile$(): CaseFile | undefined {
    return this.caseFileSubject.getValue();
  }

  get tabFragment(): string | undefined {
    return this.tabFragmentSignal();
  }

  get activeTab(): string | undefined {
    return this.activeTabIDSignal();
  }

  get reload(): boolean | undefined {
    return this.reloadSignal();
  }

  /**
   * Setters
   */

  triggerReload() {
    this.reloadTrigger.next();
  }

  // Updates case file signal and subject
  updateCaseFile(key: string, value: any) {
    const updatedCaseFile = { ...this.caseFileSignal(), [key]: value };
    const updatedCaseFile$ = { ...this.caseFileSubject.value, [key]: value };
    this.caseFileSignal.set(updatedCaseFile);
    this.caseFileSubject.next(updatedCaseFile$);
  }

  // Updates active tab ID signal
  updateActiveTabString(tab: string) {
    this.activeTabIDSignal.set(tab);
  }

  // Gets route params
  getRouteParams() {
    let paramMapArray: any;
    this.route.queryParamMap.subscribe(params => paramMapArray = params);
    return paramMapArray ?? undefined;
  }

  // Gets route param value
  getRouteParamValue(paramName: string): string | undefined | null {
    let params = new URL(document.location.toString()).searchParams;
    return params.get(paramName);
  }

  // Gets fragment string from current route, ex: #Home
  getRouteFragment() {
    return this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) this.tabFragmentSignal.set(fragment);
      return fragment;
    });
  }

  // Adds string as fragment to URL, ex: #Home
  addFragmentToURL(fragmentString: string) {
    this.router.navigate([], { fragment: fragmentString, queryParamsHandling: 'merge' }); 
  }

  // Tracks changes to query params
  handleQueryChange(paramsObj: Params) {
    const queryParams: Params = paramsObj;
  
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

  // Subscribe to hash changes in the URL
  hashChanges() {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        const fragment = this.router.routerState.snapshot.root.fragment;
        if (fragment) this.activeTabIDSignal.set(fragment);
      });
  }

  // Update URL with hashtag
  setFragmentToSelectedTab(fragment: string) {
    const queryParams = this.router.routerState.snapshot.root.queryParams;
    const navigationExtras: NavigationExtras = {
      fragment: fragment,
      queryParams: queryParams,
    };

    this.router.navigate([], navigationExtras);
  }

  // Initializes Case File signal and subject
  async setCaseFile(param: any | undefined = undefined, getAll: boolean | undefined = undefined) {
    const caseFileParam = param?.fileNumber ?? this.getRouteParamValue('fileNumber');

    if (!caseFileParam) {
      const errMsg = `Error: Missing url query paramater, \"<strong>fileNumber</strong>\".`;
      this.toast.showError(errMsg);

    } else {
      const endpoint = getAll === true ? APIEndpoints.CaseFilesTableData : APIEndpoints.Casefiles;
      const query = new Query().where('FileNumber', 'equal', caseFileParam);

      await this.api.getOdata(endpoint).executeQuery(query).then(async (res: any) => {
        const file: CaseFile = res.result[0];
        this.caseFileSignal.set(file);
        this.caseFileIdSignal.set(file.Id);
        this.caseFileSubject.next(file);
        //if (file.IsSurgical === true) this.addSurgicalTabToTabs();
        
        if(!this.authLimitPercentage) { await this.getAuthLimitWarning(); }
        await this.setCaseManagerDetails();
        await this.setPAndLDetails();
        await this.setStatuserDetails();
        // Refresh performance data after setting new case file
        await this.setPerformanceData();
      });
    }
  }

  async setPatient() {
    
    if (!this.caseFile?.Patient) {
      const endpoint = `${APIEndpoints.Casefiles}(${this.caseFile?.Id})`;
      const query = new Query().expand('Patient($expand=Address,Phone($expand=PhoneNumber))').select('Patient');
      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        this.caseFileSignal.update((file) => ({ ...file, Patient: res.result[0].Patient }));
      });
    }
  }

  // Select tab on page load
  setTabOnLoad(tabComponent: TabComponent) {
    if (!tabComponent) return;

    // Check for URL fragment first
    const selectedTab = this.tabs.find((tab) => tab.HeaderText === this.tabFragment);
    if (selectedTab) {
      const tabIndex = this.tabs.indexOf(selectedTab);
      tabComponent.select(tabIndex);
      this.activeTabIDSignal.set(selectedTab.HeaderText);
      return;
    }

    // Fallback to localStorage
    const localStorageTab = JSON.parse(localStorage.getItem(`tab${tabComponent.element.id}`) ?? '{}');
    if (localStorageTab?.selectedItem) {
      tabComponent.select(localStorageTab.selectedItem);
      this.activeTabIDSignal.set(this.tabs[localStorageTab.selectedItem].HeaderText);
      this.addFragmentToURL(this.tabs[localStorageTab.selectedItem].HeaderText);
      return;
    }

    // Fallback to Home tab
    tabComponent.select(0);
    this.activeTabIDSignal.set('Home');
    this.addFragmentToURL('Home');
  }

  // Initializes performance data signal
  async setPerformanceData(invoices?: Invoice[] | null, deposits?: Deposit[] | null) {
    let performance: PerformanceData | undefined;

    // Exit if case file does not exist
    if (!this.caseFile) return;

    if (!invoices || !deposits) {
      const endpoint = `${APIEndpoints.Casefiles}(${this.caseFile.Id})`;
      const query = new Query()
        .expand('Invoices($expand=InvoiceRows,InvoicePayments),Deposits,SignedAuthorizations')  // Add SignedAuthorizations
        .select('Invoices,Deposits,SignedAuthorizations');

      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {    
        const file = res.result[0];
        invoices = file.Invoices as Invoice[];
        deposits = file.Deposits as Deposit[];
        
        // Calculate total signed authorization amount
        const signedAuthAmount = file.SignedAuthorizations
          ?.filter((auth: any) => auth.AuthType === 'File Authorization Limit')
          ?.reduce((sum: number, auth: any) => sum + (auth.Amount || 0), 0) || 0;
        
        performance = this.returnPerformanceDataObject(this.caseFile as CaseFile, invoices, deposits);
        if (performance) {
          performance.AuthorizedAmount = signedAuthAmount;
        }
        this.performanceDataSignal.set(performance);
      });
    } else {
      performance = this.returnPerformanceDataObject(this.caseFile, invoices, deposits);
      this.performanceDataSignal.set(performance);
    }
  }

  /**
   * Helper Functions 
   */

  // Quick reference for displaying toast error when Case File data is missing
  showNoCaseFileError(message?: string) {
    const defaultMsg = `<strong>No Case File</strong>`;
    const errMsg = message ? `${defaultMsg}<p>${message}</p>` : defaultMsg;
    this.toast.showError(errMsg);
  }

  async getPatientPhoneNumber(patientId: number): Promise<PhoneNumber | undefined> {
    const endpoint = `${APIEndpoints.Phones}`;
    const query = new Query().where('ObjectId', 'equal', patientId);
    
    return this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
      return res.result.length > 0 ? res.result[0] : undefined;
    });
  }

  // Adds Surgical tab to tabs array
  addSurgicalTabToTabs() {
    const surgicalTab: Tab = {
      Id: 'surgical',
      HeaderText: 'Surgical',
      faIcon: faUserDoctor
    }

    this.tabs.splice(this.tabs.length - 1, 0, surgicalTab);
  }

  // Returns formatted object for performance data
  returnPerformanceDataObject(caseFile: CaseFile, invoices: Invoice[], deposits: Deposit[]): PerformanceData | undefined {

    if (!caseFile || !invoices || !deposits) {
      return undefined;

    } else {
      let performance: PerformanceData = performanceData;
      invoices?.map((invoice: Invoice) => {
        invoice.InvoiceRows?.map((invoiceRow: InvoiceRow) => {
          performance.TotalBilledCost += invoiceRow.AmountBilled as number;
          performance.FullSettlementValue += invoiceRow.SettlementValue as number;
        });
  
        invoice.InvoicePayments?.map((invoicePayment: InvoicePayment) => {
          performance.ActualSettlementCost += invoicePayment.AmountPaid as number;
        });
      });

      deposits?.map((deposit: Deposit, index: number) => {
        performance.TotalPaymentReceived += deposit.DepositAmount as number;
        performance.CourtesyReduction += deposit.CourtesyReduction as number;
        performance.FinalBalanceDueGenerated = index === this.caseFile?.Deposits?.length ? deposit.FinalCheck as boolean : false;
      });

      performance.DaysOpen = this.globals.countDays(this.caseFile?.FileOpened);
      performance.FinalFSV = performance.FullSettlementValue - performance.CourtesyReduction;
      performance.BalanceDue = performance.FinalFSV - performance.TotalPaymentReceived;
      performance.Profit = performance.FinalFSV - performance.ActualSettlementCost;
      performance.ROIC = performance.ActualSettlementCost !== 0 ? (performance.Profit / performance.ActualSettlementCost) : 'Unknown';

      return performance;
    }
  }
  
  async getAuthLimitWarning() {
    try {
      const query = new Query().where('VariableName', 'equal', 'auth_limit_percentage').select('VariableName,VariableValue');
      const response: any = await this.api.getOdata(APIEndpoints.ConfigVariables).executeQuery(query);
      this.authLimitPercentage = parseInt(response.result[0].VariableValue.replace('%', ''));
    } catch (error) {
      console.error('Error getting auth limit warning:', error);
    }
  }

  async setCaseManagerDetails() {
    if (!this.caseFile?.CaseManager) return;
    
    const endpoint = `${APIEndpoints.Users}(${this.caseFile.CaseManager})`;
    const query = new Query().select('Id,Name');
    
    try {
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      if (res.result?.[0]) {
        this.updateCaseFile('CaseManagerNavigation', res.result[0]);
      }
    } catch (error) {
      console.error('Error fetching case manager details:', error);
    }
  }

  async setPAndLDetails() {
    if (!this.caseFile?.PAndL) return;
    
    const endpoint = `${APIEndpoints.PandLs}(${this.caseFile.PAndL})`;
    const query = new Query().select('Id,Description');
    
    try {
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      if (res.result?.[0]) {
        this.updateCaseFile('PAndLNavigation', res.result[0]);
      }
    } catch (error) {
      console.error('Error fetching P&L details:', error);
    }
  }

  async setStatuserDetails() {
    if (!this.caseFile?.Statuser) return;
    
    const endpoint = `${APIEndpoints.StatusingGroups}(${this.caseFile.Statuser})`;
    const query = new Query().select('Id,Description');
    
    try {
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      if (res.result?.[0]) {
        this.updateCaseFile('StatusingGroupNavigation', res.result[0]);
      }
    } catch (error) {
      console.error('Error fetching statuser details:', error);
    }
  }
}
