// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup
} from '@angular/forms';

// 3rd Party
import { Browser, EmitType } from '@syncfusion/ej2-base';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';

// Internal
import * as vars from '@models/global-vars';
import { BACKENDURL } from '@environments/environment';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { Query } from '@syncfusion/ej2-data';

export interface KeyValuePair { [key: string]: Object };

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  constructor(
    private http: HttpClient
  ) { }

  // Initialize variables
  ODataEndpoint: string = BACKENDURL + 'odata/';
  body: HTMLElement = document.body;
  isResponsive: boolean = this.setResponsiveDisplay();
  isMobile: boolean = window.innerWidth < 480 ? true : false;
  isTablet: boolean = window.innerWidth > 480 && window.innerWidth < 768 ? true : false;
  isDesktopSm: boolean = window.innerWidth > 768 && window.innerWidth < 992 ? true : false;
  isDesktop: boolean = window.innerWidth > 992 ? true : false;
  responsiveTablet: boolean = this.isTablet && Browser.isDevice ? true : false;
  responsiveDesktop: boolean = this.isDesktop && Browser.isDevice ? true : false;
  states: any = vars.states;
  languages: any = vars.languages;
  genders: any = vars.genders;
  caseFileTimeFrame: string[] = vars.caseFileTimeFrame;
  caseFileCurrentStatus: object[] = vars.caseFileCurrentStatus;
  caseFilesCaseTypes: string[] = vars.caseFilesCaseTypes;
  caseFilesCaseTypeIDs: object[] = vars.caseFilesCaseTypeIDs;
  caseFilesRatings: object[] = vars.caseFileRating;
  listPAndL: object[] = vars.listPAndL;
  referralSources: any = vars.referralSources;
  caseFileStatuses: object[] = vars.caseFileStatuses;
  caseFilesFileGroups: object[] = vars.caseFilesFileGroups;

  hideSyncfusionTrialMessage() {
    const trialMessage = document.querySelector('div[style*="position: fixed"][style*="z-index: 999999999"]');
    if (trialMessage && (!trialMessage.id && !trialMessage.className)) trialMessage.remove();
  }

  // Detect responsive screens
  setResponsiveDisplay(): boolean {
    if ((Browser.isDevice && window.innerWidth < 768) || window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  };

  // Removes syncfusion trial banner
  removeSyncfusionTrialBanner() {
    const trialBanner = document.querySelector('div[style*="z-index: 999999999"][style*="background: #EEF2FF"]')

    if (trialBanner && window.location.hostname !== 'localhost') {
      trialBanner.remove();
    }
  }

  // Refresh entire page
  refreshPage(): void {
    location.reload();
  };

  // Remove item currently stored in localStorage
  removeLocalStorageItem(item: string): void {
    localStorage.removeItem(item);
  };

  // Remove multiple items from localStorage using an array of strings 
  removeMultipleLocalStorageItems(items: string[]): void {
    items.forEach((item: string) => {
      localStorage.removeItem(item);
    });
  };

  // Display an svg from a local file
  getSvgFromUrl(url: string) {
    return this.http.get(url, { responseType: 'text' }).subscribe(
      (data) => {
        console.log(data);
        return data;
      },
      (error) => {
        console.error('Error loading SVG:', error);
        return error;
      }
    );
  };

  // Isolate username from email string
  removeEmailFromString(inputString: string): any {
    const atIndex = inputString.indexOf('@');

    if (atIndex !== -1) {
      return inputString.substring(0, atIndex);
    } else {
      return inputString;
    }
  };

  // Verify new passwords match each other
  confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.password1 === control.value.password2 ? null : { doNotMatch: true };
  };

  // Custom validator for verifying unique passwords
  strongPasswordValidator(minLength: number = 8, requireUppercase: boolean = true, requireLowercase: boolean = true, requireNumber: boolean = true, requireSpecialCharacter: boolean = true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password: string = control.value;
      const errors: ValidationErrors = {};

      if (!password) {
        return null; // No validation error if the password is empty
      }

      // Check password length
      if (password.length < minLength) {
        errors['passwordLength'] = true;
      }

      // Check for uppercase letter
      if (requireUppercase && !/[A-Z]/.test(password)) {
        errors['passwordUppercase'] = true;
      }

      // Check for lowercase letter
      if (requireLowercase && !/[a-z]/.test(password)) {
        errors['passwordLowercase'] = true;
      }

      // Check for number
      if (requireNumber && !/\d/.test(password)) {
        errors['passwordNumber'] = true;
      }

      // Check for special character
      if (requireSpecialCharacter && !/[!@#$%^&*(),.?":{}|<>_]/.test(password)) {
        errors['passwordSpecialCharacter'] = true;
      }

      // Return validation errors object if there are any errors, otherwise return null
      return Object.keys(errors).length ? errors : null;
    };
  };

  // Matches backend CCUtils.IsValidPhoneNumber pattern
  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      const valid = /^\+?(\d{1,3})?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})$/.test(control.value);
      return valid ? null : { 
        invalidPhone: { 
          message: 'Invalid phone format. Examples: +1-800-555-0199, (800) 555-0199, 800-555-0199' 
        } 
      };
    };
  }

  formatPhoneNumber(phone: string): string {
    if (!phone) return '';
    // Remove all non-digits except leading +
    const digitsOnly = phone.replace(/[^\d+]/g, '');
    // Add +1 for 10-digit numbers without country code
    if (digitsOnly.length === 10 && !digitsOnly.startsWith('+')) {
      return `+1${digitsOnly}`;
    }
    return digitsOnly;
  }

  // Adds icon that toggles visibility of passwords to TextBoxComponents
  addVisibilityToggleToPasswordInput(elementId: string, passwordComponent: TextBoxComponent) {
    passwordComponent.addIcon('append', 'e-icons e-eye-slash');
    let elmObj = document.getElementById(elementId);
    let elmIcons = elmObj?.getElementsByClassName('e-eye-slash');

    if (elmIcons && elmIcons.length > 0) {

      Array.from(elmIcons).forEach((icon) => {
        icon.addEventListener('click', (event) => {
          if (passwordComponent.type === 'text') {
            passwordComponent.type = 'password';
            icon.classList.remove('e-eye');
            icon.classList.add('e-eye-slash');
          } else {
            passwordComponent.type = 'text';
            icon.classList.remove('e-eye-slash');
            icon.classList.add('e-eye');
          }
        })
      });
    }
  };

  formatDateForEjsDatepicker(inputDate: Date): string {
    // Get month, day, and year
    const day = inputDate.getDate() < 10 ? '0' + inputDate.getDate() : inputDate.getDate();
    const month = inputDate.getMonth() < 0 ? '0' + (inputDate.getMonth() + 1) : inputDate.getMonth() + 1; // Months start at 0
    const year = inputDate.getFullYear();

    return `${month}/${day}/${year}`;
  }

  // Format date for mm/dd/yyyy
  formatDateForDB(inputDate: Date): string {

    if (inputDate) {
      return inputDate.getFullYear() +
        '-' + ((inputDate.getMonth() > 8) ? (inputDate.getMonth() + 1) : ('0' + (inputDate.getMonth() + 1))) +
        '-' + ((inputDate.getDate() > 9) ? inputDate.getDate() : ('0' + inputDate.getDate()));
    } else {
      return 'Please enter a valid date.';
    }
  }

  formatDateForBackend(date: Date | string): string {
    let dateObj: Date;
    if (typeof date === 'string') {
      dateObj = new Date(date);
    } else {
      dateObj = date;
    }

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  // Helper function to pad single digit numbers with leading zeros
  padZero(num: number) {
    return num < 10 ? `0${num}` : num.toString();
  }

  // Uses Date() input to return timezone
  getTimezone(dateInput: Date) {
    // Create a Date object from the provided string
    const date = new Date(dateInput);

    // Get the timezone offset in minutes
    const offsetInMinutes = date.getTimezoneOffset();

    // Convert the offset to hours and minutes
    const hours = Math.abs(Math.floor(offsetInMinutes / 60));
    const minutes = Math.abs(offsetInMinutes % 60);

    // Construct the timezone string
    const timezoneString = `${offsetInMinutes >= 0 ? '-' : '+'}${this.padZero(hours)}:${this.padZero(minutes)}`;

    return timezoneString;
  }

  formatKey(key: string): string{
    return key
      .replace(/([A-Z])/g, ' $1') // Add a space before each capital letter
      .replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/^\s+|\s+$/g, ""); // Capitalize the first letter of each word
  };
  
  getName(data: any): string {
    let name;

    switch (true) {
      case !!data?.Patient?.Firstname:
        name = `${data.Patient.Firstname} ${data.Patient.Lastname}`;
        break;
      case !!data?.Firstname:
        name = `${data.Firstname} ${data.Lastname}`;
        break;
      case !!data?.Description:
        name = `${data.Description}`;
        break;
      case !!data?.ContactName:
        name = `${data.ContactName}`;
        break;
      default:
        name = data?.Name ?? ''; // Use nullish coalescing operator to provide a default value
    }
    return this.formatKey(name);
  }

  // For quickly logging any function
  logData(event: Event, args: any) {
    event.stopPropagation();
    event.preventDefault();
    console.log(args);
  }

  // Intended to add entries to objects
  addObjectEntry<T>(obj: T, key: string, value: any): T & { [key: string]: any } {
    return { ...obj, [key]: value };
  }

  // Marks form controls as touched so user can easily identify any missing values
  markControlsTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
    })
  }

  // Use a filter to remove an object's key value pairs where the value is null
  removeNullFromObj(obj: object): object {
    const newObj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    return newObj;
  }

  // Check if object empty
  isObjEmpty(obj: object): boolean {
    return ((Object.keys(obj).length === 0) || JSON.stringify(obj) === '{}') ?? false;
  }

  appContentHeight(): string {
    const height = document.body.offsetHeight ?
      `${document.body.offsetHeight - 65 - 32}` :
      'No height found.';
    return height;
  }

  convertDateTimeToDateOnly(inputDate: Date) {
    return inputDate ? inputDate.toISOString().slice(0, 10) : undefined;
  }

  // Determines if object contains any valid items or is empty, "{}"
  objectIsEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }

  getAllSyncfusionElements() {
    return Array.from(document.querySelectorAll('*')).filter(el => el.tagName.toLowerCase().startsWith('ejs-'));
  }

  getAllSyncfusionElementsWithPersistence() {
    return Array.from(document.querySelectorAll('*')).filter(el => el.id.includes('_ejs-component'));
  }

  getSyncfusionElementWithId(substring: string) {
    return document.querySelector(`[id^=_ejs-component-${substring}`);
  }

  objHasKey(obj?: Record<string, any>, key?: string): boolean {
    return obj && key ? key in obj : false;
  }

  countDays(dateString: string | undefined) {
    const date = dateString !== undefined ? new Date(dateString) : undefined;
    const today = new Date();
    const msInDay = 1000 * 60 * 60 * 24;
    return date !== undefined ? Math.floor((today.getTime() - date.getTime()) / msInDay) : 'Unknown';
  }

  onDropdownFiltering: EmitType<FilteringEventArgs> = (filterEvent: FilteringEventArgs, data: any, fieldName?: string,) => {
    let query = new Query();
    const field = fieldName ?? 'key';

    query = (filterEvent.text != "") ? query.where(field, "contains", filterEvent.text, true) : query;
    filterEvent.updateData(data, query);
  };

  observeChangesToElement(element: HTMLElement) {
    const observer = new MutationObserver((mutationsList, observer) => {
      return mutationsList;
    });

    // Start observing the target node for configured mutations
    return observer.observe(element, {
      childList: true,
      subtree: true
    });
  }
}
