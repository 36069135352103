import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownMultiComponent } from "../../../ui/dropdown-multi/dropdown-multi.component";
import { Dialog, DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorComponent } from "../../../ui/custom-rich-text-editor/rich-text-editor.component";
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { DatePipe } from '@angular/common';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

@Component({
  selector: 'app-balance-statement-email',
  standalone: true,
  imports: [
    CommonModule,
    DropdownMultiComponent,
    ReactiveFormsModule,
    DialogAllModule,
    ButtonModule,
    TextBoxModule,
    RichTextEditorComponent
  ],
  providers: [DatePipe],
  templateUrl: './balance-statement-email.component.html',
  styleUrl: './balance-statement-email.component.scss'
})
export class BalanceStatementEmailComponent {
  @ViewChild('Modal') modalDialog: DialogComponent;


  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private datePipe: DatePipe,
    private toast: ToastMessageService,
  ) {
  }

  //State Management
  @ViewChild('Modal') modal: Dialog;
  sendingEmail = false;
  EmailTemplate = '';

  formGroup: FormGroup = this.fb.group({
    UserName: ['',],
    UserEmail: ['',],
    To: [''],
    BoxId: ['',],
    Subject: ['Balance Due Summary']
  });

  //When the popup is opened
  show(
      boxId: string, 
      CaseFileId: number, 
      UserName: string, 
      UserEmail: string, 
      ContactEmail: string, 
      CaseManagerName: string, 
      CaseManagerEmail: string, 
      PAndL: string,
    ) {
    this.modal.show()
    this.formGroup.patchValue({
      BoxId: boxId,
      CaseFileId: CaseFileId,
      UserName: UserName,
      UserEmail: UserEmail,
      To: [ContactEmail],
      PAndL: PAndL,
    });
    this.EmailTemplate = this.getInitalTemplate(CaseManagerName, CaseManagerEmail, PAndL);
  }

  //Get ininital email body
  getInitalTemplate(CaseManagerName: string, CaseManagerEmail: string, PAndL: string) {
    let today = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm a')
    let result =
      "<p> Please find the attached Balance Due Report for the current balance as of " + today + ".</p>"
      + "<p> Please contact the Case Manager, " + CaseManagerName + " at: <a href='mailto:" + CaseManagerEmail + "'>" + CaseManagerEmail + "</a> if you have any questions.</p>"
      + "<p> Thank you for the opportunity to serve your client!</p>"
      + "<p>" + PAndL + "</p>"
    return result;
  }

  onSubmit() {
    this.sendingEmail = true;
  
    var body = {
      UserName: this.formGroup.value.UserName,
      UserEmail: this.formGroup.value.UserEmail,
      To: this.formGroup.value.To,
      BoxId: this.formGroup.value.BoxId,
      Subject: this.formGroup.value.Subject,
      Template: this.EmailTemplate,
    }
  
    this.api.basicPost("api/balance-statement/send-email", body, false)
      .then(res => {
        this.sendingEmail = false;
        if (res && res.status === 200) {
          this.showSuccessMessage('Email sent successfully to ' + this.formGroup.value.To);
          this.modalDialog.hide(); 

        } else {
          throw new Error('Unexpected response status');
        }
      })
      .catch(err => {
        this.sendingEmail = false;
        this.handleError(err);
      });
  }
  
  private showSuccessMessage(message: string): void {
    this.toast.showSuccess(message);
  }
  
  private handleError(error: any): void {
    const errorMessage = (error as Error).message || 'Failed to send email. Please try again.';
    this.toast.showError(errorMessage);
  }
}
