<div id="locations">
    <ng-container *ngIf="schedulerSignals.loading.Locations(); else locationsList">
        <loading-overlay [loading]="schedulerSignals.loading.Locations"></loading-overlay>
    </ng-container>

    <ng-template #locationsList>
        <div *ngFor="let location of locations(); index as i;">
            <div class="category {{location.Description}}">
                <ejs-checkbox #locationCheckbox
                    label="{{location.Description}}" 
                    value="{{location.Id}}"
                    [checked]="true"
                    (change)="onLocationChange($event, location)">
                </ejs-checkbox>
            </div>
        </div>
    </ng-template>
</div>