<form [formGroup]="surgeryForm" (ngSubmit)="onSubmit()" [ngClass]="formClasses.form">
    <div [ngClass]="formClasses.inputContainer">
      <ejs-datepicker formControlName="SurgeryDate" placeholder="Select Surgery Date"></ejs-datepicker>
      <div *ngIf="surgeryForm.get('SurgeryDate')?.invalid && (surgeryForm.get('SurgeryDate')?.touched || surgeryForm.get('SurgeryDate')?.dirty)" class="error-red">
        <i class="error-icon"></i> Surgery date is required.
      </div>
    </div>
  
    <div [ngClass]="formClasses.inputContainer">
      <ejs-textbox formControlName="Surgeon" placeholder="Enter Surgeon Name"></ejs-textbox>
      <div *ngIf="surgeryForm.get('Surgeon')?.invalid && (surgeryForm.get('Surgeon')?.touched || surgeryForm.get('Surgeon')?.dirty)" class="error-red">
        <i class="error-icon"></i> Surgeon name is required.
      </div>
    </div>
  
    <div [ngClass]="formClasses.inputContainer">
      <ejs-dropdownlist formControlName="ProviderId" [dataSource]="providerTypes" [fields]="providerTypeFields" placeholder="Select Provider"></ejs-dropdownlist>
      <div *ngIf="surgeryForm.get('ProviderId')?.invalid && (surgeryForm.get('ProviderId')?.touched || surgeryForm.get('ProviderId')?.dirty)" class="error-red">
        <i class="error-icon"></i> Provider is required.
      </div>
    </div>
  
    <div [ngClass]="formClasses.inputContainer">
      <ejs-dropdownlist formControlName="SurgeryTypeId" [dataSource]="surgeryTypes" [fields]="surgeryTypeFields" placeholder="Select Surgery Type"></ejs-dropdownlist>
      <div *ngIf="surgeryForm.get('SurgeryTypeId')?.invalid && (surgeryForm.get('SurgeryTypeId')?.touched || surgeryForm.get('SurgeryTypeId')?.dirty)" class="error-red">
        <i class="error-icon"></i> Surgery type is required.
      </div>
    </div>
  
    <div [ngClass]="formClasses.inputContainer">
      <ejs-textbox formControlName="SurgeryCostEstimate" placeholder="Enter Surgery Cost Estimate"></ejs-textbox>
      <div *ngIf="surgeryForm.get('SurgeryCostEstimate')?.invalid && (surgeryForm.get('SurgeryCostEstimate')?.touched || surgeryForm.get('SurgeryCostEstimate')?.dirty)" class="error-red">
        <i class="error-icon"></i> Surgery cost estimate is required and must be a valid number.
      </div>
    </div>
  
    <div [ngClass]="formClasses.actions">
      <button ejs-button type="submit" class="submit-button">Submit</button>
    </div>
</form>