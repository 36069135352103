// Angular
import { Component, Input, Signal, computed } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule, TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faComments, faCalendar, faDollarSign, faFileInvoice, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { EditRemittance } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Type & Interface setup
type BulkRemittanceFormControls = {
  [K in keyof EditRemittance]: FormControl<EditRemittance[K] | null | undefined>;
};

@Component({
  selector: 'bulk-edit-remittances-form',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    DatePickerModule,
    TooltipAllModule,
    DropDownListAllModule,
    FontAwesomeModule
  ],
  providers: [DatePipe],
  templateUrl: './bulk-edit-remittances.component.html',
  styleUrl: './bulk-edit-remittances.component.scss'
})
export class BulkEditRemittancesForm {

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private toast: ToastMessageService
  ) { }

  /**
   * Declare Variables
   */
  // Decorator variables
  @Input() remittances!: Signal<EditRemittance[] | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE' = 'PATCH';
  @Input() displayFields: string[] = ['DatePaid', 'TransactionNumber', 'InvoiceNotes', 'PaymentMethod', 'PaymentStatus'];
  @Input() displaySubmit: boolean = false;

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  HTMLElement: Element | null = null;
  selectedRows: any;
  paymentMethodData: DataManager;
  paymentMethodQuery: Query;
  paymentStatusData: DataManager;
  paymentStatusQuery: Query;
  bulkEditRemittancesForm: FormGroup = new FormGroup<BulkRemittanceFormControls>({
    DatePaid: new FormControl<EditRemittance['DatePaid']>(undefined, [Validators.required]),
    TransactionNumber: new FormControl<EditRemittance['TransactionNumber']>(undefined, [Validators.required]),
    InvoiceNotes: new FormControl<EditRemittance['InvoiceNotes']>(undefined),
    PaymentMethod: new FormControl<EditRemittance['PaymentMethod']>(undefined, [Validators.required]),
    PaymentStatus: new FormControl<EditRemittance['PaymentStatus']>(undefined, [Validators.required]),
  });
  remittanceIcons = {
    datePaid: faCalendar,
    transactionNumber: faMoneyBillTransfer,
    invoiceNotes: faComments,
    paymentMethod: faDollarSign,
    paymentStatus: faFileInvoice,
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column w100 d-flex justify-content-center align-items-center',
    subForm: 'cc-sub-form',
    section: 'cc-form-section w100 d-flex justify-content-center align-items-center',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12'
  };

  /**
   * State management
   */
  // Form
  protected readonly formState = computed(() => {
    const currentRemittances = this.remittances();
    return {
      isValid: this.bulkEditRemittancesForm.valid,
      hasChanges: currentRemittances !== this.bulkEditRemittancesForm.value,
      currentValue: currentRemittances
    };
  });

  // Validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.bulkEditRemittancesForm.controls).forEach(key => {
      const control = this.bulkEditRemittancesForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  /**
   * Angular Lifecycle Hooks
   */

  // After view renders
  ngAfterViewInit() {
    this.HTMLElement = document.querySelector('bulk-edit-remittances-form');
    this.updateWidth(this.HTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  /**
   * Declare functions
   */

  // Submit form
  async submit() {
    let submitResult: 'success' | 'error' = 'error';
    let rows = this.remittances();
    let formatedDate = this.datePipe.transform(this.bulkEditRemittancesForm.value.DatePaid, "yyyy-MM-dd");
    let overwriteObject = { Id: null, DatePaid: formatedDate, TransactionNumber: this.bulkEditRemittancesForm.value.TransactionNumber, PaymentMethod: this.bulkEditRemittancesForm.value.PaymentMethod, PaymentStatus: this.bulkEditRemittancesForm.value.PaymentStatus, InvoiceNotes: this.bulkEditRemittancesForm.value.InvoiceNotes }
    this.markAllFieldsAsTouched();

    if (!rows) {
      this.loadingForm = false;
      this.toast.showError('No rows selected');
      return submitResult;
    }

    // Check for valid fields
    if (!this.bulkEditRemittancesForm.valid) {
      this.loadingForm = false;
      this.toast.showError('Form Invalid');
      return submitResult;
    }

    // Check if form has changes
    if (this.bulkEditRemittancesForm.pristine) {
      this.loadingForm = false;
      this.toast.showWarning('No changes to submit');
      return submitResult;
    }

    try {
      const results = await Promise.all(rows.map(async (row: any) => {
        if (!row.InvoicePaymentId) throw new Error('Row InvoicePaymentId is required');
        overwriteObject.Id = row.InvoicePaymentId;
        const res = await this.api.fetchRequest(`odata${APIEndpoints.InvoicePayments}(${row.InvoicePaymentId})`, 'PATCH', overwriteObject);
        return res?.Id ? true : false;
      }));

      submitResult = results.every(result => result) ? 'success' : 'error';

    } catch (error) {
      console.error('Error updating remittances:', error);
      submitResult = 'error';
    }

    this.loadingForm = false;
    window.location.reload();
    return submitResult;
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.bulkEditRemittancesForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Clear form
  clearForm = () => {
    this.bulkEditRemittancesForm.reset();
  }
  
  // Set different error messages for each input field
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.bulkEditRemittancesForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Changes form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update visual styles on input focus and blur
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Get paymnet methods
  onBeforeOpenPaymentMethod = (args: any) => {
    this.paymentMethodData = this.api.getOdata(APIEndpoints.PaymentMethods);
    this.paymentMethodQuery = new Query().select('Id,Description');
  }

  // Get payment statuses
  onBeforeOpenPaymentStatus = (args: any) => {
    this.paymentStatusData = this.api.getOdata(APIEndpoints.PaymentStatuses);
    this.paymentStatusQuery = new Query().select('Id,Description');
  }
}
