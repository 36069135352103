<form class="row mt-0 flex-row" [formGroup]="insuranceForm" (submit)="onSubmit($event)">
    <div class="cc-form-section">
        <div class="cc-form-group row">
            <div class="cc-input-container col-12 col-md-6">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faBuilding" />
                </span>
                <label>Insurance Company</label>
                <ejs-dropdownlist
                    formControlName="InsuranceCompany"
                    [dataSource]="insuranceCompanies" 
                    [fields]="{ text: 'Description', value: 'Id'}" />
            </div>

            <div class="cc-input-container col-12 col-md-6">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faHashtag" />
                </span>
                <label>Claim Number</label>
                <ejs-textbox formControlName="ClaimNumber" />
            </div>

            <div class="cc-input-container col-12 col-md-6 policy-limits">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faMoneyBill" />
                </span>
                <label>Policy Limits</label>
                <ejs-dropdownlist formControlName="PolicyLimits" [dataSource]="policyLimits" />
            </div>

            <div class="cc-input-container col-12 col-md-6">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faNotes" />
                </span>
                <label>Notes</label>
                <ejs-textbox formControlName="Note" [multiline]="true" />
            </div>
        </div>
    </div>

    <div class="col-12">
        <h2 class="form-title">
            <fa-icon [icon]="icons.faUser" /> Claim Adjuster Information
        </h2>
        <contact-form-component
            #adjusterContact    
            [contact]="adjusterContactSignal"
            [showSubmitButton]="false"
            [displayFields]="[
                'ContactName', 
                'ContactEmail', 
                'ContactPhone',
                'ContactFax',
                'XrefAddressContacts',
                'XrefPhoneContacts'
            ]"
            [fieldLabels]="{
                ContactName: 'Claim Adjuster',
                ContactEmail: 'Adjuster Email',
                ContactPhone: 'Adjuster Phone Number',
                ContactFax: 'Adjuster Fax Number',
                XrefAddressContacts: 'Billing Address'
            }">
        </contact-form-component>
    </div>

    <div class="submit-container">
        <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true">Submit</button>
    </div>
</form>