// Angular
import { Component, EventEmitter, Input, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, FormsModule, FormBuilder } from '@angular/forms';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { Dialog, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Component({
  selector: 'add-deposit-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    SwitchModule,
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    DatePickerAllModule,
    DialogModule
  ],
  templateUrl: './add-deposit.component.html',
  styleUrl: './add-deposit.component.scss',
})
export class AddDepositForm {

  constructor(
    private api: ApiService, 
    private toast: ToastMessageService,
    private fb: FormBuilder
  ) { }

  @Input() caseFileId: Number;
  @Output() submit = new EventEmitter<any>();
  visibility = false;
  listPandL = this.api.getOdata(APIEndpoints.PandLs);
  pAndLQuery = new Query().select(["Id", "Description"]);
  pAndLFields = {
    text: "Description",
    value: "Id",
  };
  createdBy = JSON.parse(localStorage.getItem('userId') || "0");
  depositForm: WritableSignal<FormGroup>;
  endpoint = APIEndpoints.Deposits;

  ngOnInit() {
    this.depositForm = signal(this.fb.group({
      DepositDate: [''],
      DepositAmount: [0],
      DepositToAccount: [0],
      Notes: [''],
      FinalCheck: [false]
    }));
  }

  onSubmit() {
    this.api.basicPost(
      APIEndpoints.Deposits, ({ CaseFileId: this.caseFileId, ...this.depositForm().value })
    ).then((response) => {
      // close form and refresh grid
      this.submit.emit();
      if (response?.status === 400) {
        this.toast.showError("There was a problem submitting the form")
      }
    })
  }
}
