// Angular
import { Component, OnInit, ViewChild, AfterViewInit, signal, WritableSignal, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { Query } from '@syncfusion/ej2-data';

// Models
import { SurgicalProgression } from '@models/data-contracts';

// Components
import { TabCardComponent } from "../tab-card/tab-card.component";
import { SurgeriesGridComponent } from '@grids/surgeries-grid/surgeries-grid.component';
import { FileHubService } from '@shared/services/file-hub/file-hub.service';

@Component({
  selector: 'surgical-tab',
  standalone: true,
  imports: [
    TabCardComponent,
    CommonModule,
    SurgeriesGridComponent,
    DialogModule
  ],
  templateUrl: './surgical-tab.component.html',
  styleUrl: './surgical-tab.component.scss',
})
export class SurgicalTab implements OnInit, OnDestroy {

  constructor(
    private api: ApiService,
    private fileHub: FileHubService
  ) {}

  // Public variables
  @ViewChild(SurgeriesGridComponent) surgeriesComponent: SurgeriesGridComponent;
  segments: SurgicalProgression[] = [];
  selectedSegments: boolean[];
  currentStep = 0;
  selectedSurgeryName: string;
  selectedSurgeryDate: string;
  selectedSegmentId: number | undefined = undefined;


  dialogBody = ''; // Dynamically set dialog header
  isDialogVisible = false; // Control dialog visibility
  currentStepIndex = 0; // Current step index
  selectedSurgeryId: number | null = null;
  confirmUndoDialogVisible = false;
  confirmUndoDialogBody = 'Are you sure that you\'d like to undo this step?';
  surgeries: any = {};
  isLoading: WritableSignal<boolean> = signal(true);
  dialogButtons = [
    {
      click: () => this.onSaveClick(),
      buttonModel: {
        content: 'Confirm',
        isPrimary: true, // Highlights the button as primary
        cssClass: 'e-success', // Optional: Add a custom class
      },
    },
    {
      click: () => this.onCancelClick(),
      buttonModel: {
        content: 'Cancel',
        cssClass: 'e-danger', // Optional: Add a custom class
      },
    },
  ];
  confirmUndoButtons = [
    {
      click: () => this.onConfirmUndo(),
      buttonModel: {
        content: 'Yes',
        isPrimary: true,
        cssClass: 'e-success',
      },
    },
    {
      click: () => this.onCancelUndo(),
      buttonModel: {
        content: 'No',
        cssClass: 'e-danger',
      },
    },
  ];

  // Private variables
  private segmentToUndo: number | null = null;

  ngOnInit(): void {
    if (!this.fileHub.caseFile) {
      this.fileHub.showNoCaseFileError('Unable to load surgical progression data.');
      return;
    }
    this.loadProgressionSteps();
    this.getFirstSurgery();
  }

  // Add this to ensure proper cleanup
  ngOnDestroy(): void {
    // Clean up any subscriptions or listeners if they exist
    this.isLoading.set(false);
  }

  private async loadProgressionSteps(): Promise<void> {
    this.isLoading.set(true);

    try {
      await this.api.getOdata(APIEndpoints.SurgicalProgression)
        .executeQuery(new Query().expand('XrefSurgeriesSurgicalProgressions'))
        .then((result: any) => {
          console.log(result)
          const progressionSteps = (result as any).result;
          this.segments = progressionSteps;
          this.selectedSegments = Array(this.segments.length).fill(true);
          console.log(this.selectedSegments)
        });

    } catch (error) {
      console.error('Error loading progression steps:', error);

    } finally {
      this.isLoading.set(false);
    }
  }

  onSegmentClick(index: number): void {
    const xref = this.segments[index].XrefSurgeriesSurgicalProgressions;
    if (xref && xref.length > 0) {
        console.log(xref[0].Id); 
        this.selectedSegmentId = xref[0].Id;
    } else {
        console.log('No XrefSurgeriesSurgicalProgressions found for this segment.');
    }    

    if (this.selectedSegments[index]) {
      // If segment is active, show undo confirmation
      this.segmentToUndo = index;
      this.confirmUndoDialogVisible = true;
    } else {
      // If segment is inactive, show regular confirmation dialog
      this.currentStepIndex = index;
      this.dialogBody = this.segments[index].Content ?? '';
      this.isDialogVisible = true;
    }
  }

  // Close dialog
  onDialogClose(): void {
    this.isDialogVisible = false;
  }

  shouldPreselectSegment(segment: SurgicalProgression): boolean {
    return segment?.XrefSurgeriesSurgicalProgressions?.some((xref: any) => xref.SurgeriesId === this.selectedSurgeryId) ?? false;
  }

  // Save button click handler
  onSaveClick(): void {
    this.selectedSegments[this.currentStepIndex] = !this.selectedSegments[this.currentStepIndex];
    this.currentStep = this.currentStepIndex + 1;
    if (this.selectedSurgeryId) {
      console.log(this.selectedSurgeryId)
      console.log(this.currentStep)
      this.api.postOdata(`${APIEndpoints.XrefSurgeriesSurgicalProgression}`, {
        SurgicalProgressionId: this.currentStep,
        SurgeriesId: this.selectedSurgeryId
      });
    }
    
    this.isDialogVisible = false;
  }

  // Cancel button click handler
  onCancelClick(): void {  
    this.isDialogVisible = false; // Close the dialog
  }

  // Add event handler for surgery selection
  onSurgerySelect(data: any): void {
    this.selectedSurgeryId = data.surgery.Id;
    this.selectedSurgeryName = data.surgery.SurgeryType;
    this.selectedSurgeryDate = data.surgery.SurgeryDate;
   

    // Preselect segments based on xref
    this.selectedSegments = this.segments.map(segment => this.shouldPreselectSegment(segment));
    this.currentStep = this.selectedSegments.filter(segment => segment).length;
}

  onConfirmUndo(): void {
  console.log('Confirm undo clicked');
    if (this.segmentToUndo !== null && this.selectedSurgeryId) {
      console.log(this.selectedSegmentId);
      this.api.deleteOdata(`${APIEndpoints.XrefSurgeriesSurgicalProgression}/${this.selectedSegmentId}`)
    }
  }

  onCancelUndo(): void {
    console.log('Cancel undo clicked');
    this.confirmUndoDialogVisible = false;
    this.segmentToUndo = null;
  }

  getFirstSurgery(): any {
    console.log(this.surgeries)
    if (this.surgeries && this.surgeries.length > 0) {
      return this.surgeries[0];
    }
    return null;
  }

  formatDate(dateString: string): string {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
  }
}