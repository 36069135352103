// Angular
import { Component, EventEmitter, Input, Output, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
// 3rd party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';
// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { Invoice } from '@models/data-contracts';

@Component({
  selector: 'transfer-invoice',
  standalone: true,
  imports: [
    FormsModule,
    DropDownListModule,
    ButtonModule,
    CommonModule,
  ],
  templateUrl: './transfer-invoice.component.html',
  styleUrl: './transfer-invoice.component.scss',
})
export class TransferInvoiceComponent {
  constructor(private api: ApiService) {}
  @Input() selectedInvoices: WritableSignal<Invoice[] | null>;
  @Output() submit = new EventEmitter<any>();  // Emit backend response

  public selectedCaseFile: any | null = null;

  caseFileList = this.api.getOdata(APIEndpoints.Casefiles);
  caseFileQuery = new Query().select(['Id', 'FileNumber']);
  caseFileFields = {
    text: 'FileNumber',
    value: 'Id',
  };

  onSubmit() {
    this.api.basicPost(
      `${APIEndpoints.Invoices}/moveto(${this.selectedCaseFile})`,
      this.selectedInvoices()?.map((invoice) => invoice.Id),
    ).then((response)=> {
      this.submit.emit();
      this.selectedCaseFile = null;
    }).catch((e) => {console.log(e)});
  }
}
