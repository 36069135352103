<grid-template #contactsGrid [settings]="contactsGridSettings" [name]="'Contacts'" [useRoundedEdges]="true"></grid-template>

<ejs-dialog
  #addContactDialog
  [(visible)]="addContactDialogVisibility" 
  [header]="'Add Contact'"
  [showCloseIcon]="true"
  (beforeOpen)="beforeOpening($event)"
  (close)="addContactDialogVisibility = false"
>
    <contact-form-component
      [submitType]="'POST'"
      [displayFields]="['ContactName', 'ContactType', 'ContactTitle', 'ContactEmail', 'Notes', 'XrefAddressContacts', 'XrefPhoneContacts']"
      (formSubmitted)="addContactDialogVisibility = false; refreshGrid()"
      [selectedAccountId]="selectedAccountId"
      [isLawFirm]="isLawFirm"
      (formCancelled)="addContactDialogVisibility = false;"
    >
    </contact-form-component>
</ejs-dialog>

<ejs-dialog
  #editContactDialog
  [(visible)]="editContactDialogVisibility" 
  [header]="'Edit Contact'"
  [showCloseIcon]="true"
  (beforeOpen)="beforeOpening($event)"
>
    <contact-form-component
      [contact]="selectedContact"
      [submitType]="'PATCH'"
      [displayFields]="['ContactName', 'ContactType', 'ContactTitle', 'ContactEmail', 'Notes', 'XrefAddressContacts', 'XrefPhoneContacts']"
      (formSubmitted)="editContactDialogVisibility = false; refreshGrid()"
      (formCancelled)="editContactDialogVisibility = false"
    >
    </contact-form-component>
</ejs-dialog>



