// Angular
import { Component, ViewChild, Output, EventEmitter, OnInit, WritableSignal, signal, SimpleChanges } from '@angular/core';

// 3rd Party
import { CommandModel, GridModel, CommandClickEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule} from '@syncfusion/ej2-angular-popups';
import { DetailRowService } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';

import { FileHubService } from '@services/file-hub/file-hub.service';
import { detailDataSource } from './sample-data';
import { surgeries } from './sample-data';
import { Surgery } from '@root/src/app/shared/models/data-contracts';
import { formatBoolean } from '@root/src/app/utils';
import { FormGroup } from '@angular/forms';
import { surgeryForm } from '@root/src/app/shared/constants/form-groups/surgery';
import { SurgeryFormComponent } from '../../forms/surgery-form/surgery-form.component';

interface SurgeryProgress {
  selectedSegments: boolean[];
  currentStep: number;
}

interface DetailData {
  surgeryId: number;
  providerType: string;
  provider: string;
  dateOfService: string;
  sumOfInvoices: number;
}

@Component({
  selector: 'surgeries-grid',
  standalone: true,
  imports: [
    DialogModule,
    GridTemplateModule,
    SurgeryFormComponent
  ],
  templateUrl: './surgeries-grid.component.html',
  styleUrl: './surgeries-grid.component.scss',
  providers: [DetailRowService]
})
export class SurgeriesGridComponent implements OnInit {

  constructor (
    private api: ApiService,
    private toast: ToastMessageService,
    private fileHub: FileHubService
  ) { }

  // Outputs
  @Output() surgerySelected = new EventEmitter<any>();

  // HTML references
  @ViewChild('addSurgeryDialog') addSurgeryDialog!: DialogComponent;
  @ViewChild('surgeriesGrid') surgeriesGridTemplate: GridTemplateComponent;
  surgerySignal = signal<Surgery | undefined>(undefined);

  addSurgeryDialogVisibility: boolean = false;
  providerTypeFields: Object;
  caseFileId: number | undefined = this.fileHub.caseFileId;

  @ViewChild('editSurgeryDialog') editSurgeryDialog!: DialogComponent;
  editSurgeryDialogVisibility: boolean = false;

  @ViewChild('deleteConfirmDialog') deleteConfirmDialog!: DialogComponent;

  // Public variables
  surgeriesGrid: any;
  selectedSurgery: any;
  selectedSurgerySignal: WritableSignal<any> = signal(undefined);
  currentProgress: SurgeryProgress;

  surgeriesGridTemplateSettings: GridModel;
  formattedDBData: any[] = [];
  selectedRows: any[] = [];
  batchChangedRecords: any[] = [];
  surgeriesGridTemplateManageColumnCommands: CommandModel[] = [
    { buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat e-danger' } }
  ];
  readonly addSurgeryButtons = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
  ];
  editSurgeryButtons: Object[] = [
    { click: this.closeEditDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
  ];
  deleteConfirmButtons: Object[] = [
    { click: this.closeDeleteDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.confirmDelete.bind(this), buttonModel: { content: 'Delete', cssClass: 'e-danger', isPrimary: true } }
  ];

  readonly surgeriesGridDefaultToolbar: string[] = ['Add'];
  providerTypes: string[] = [
    'Facility',
    'Professional',
    'Anesthesia',
    'Surgical Assist',
    'Neuromonitoring',
    'Hardware'
  ];
  detailTemplate: string = `
  <div class="detail-container">
    <table class="detail-table">
      <thead>
        <tr>
          <th>Surgery Details</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Provider</td>
          <td>\${provider}</td>
        </tr>
        <tr>
          <td>Surgery Type</td>
          <td>\${surgeryType}</td>
        </tr>
        <tr>
          <td>Surgery Date</td>
          <td>\${surgeryDate}</td>
        </tr>
        <tr>
          <td>Cost Estimate</td>
          <td>\${surgeryCostEstimate}</td>
        </tr>
      </tbody>
    </table>
  </div>`;

  // Private variables
  private surgeryProgressMap: Map<number, SurgeryProgress> = new Map();
  private detailData: DetailData[] = detailDataSource;
  private surgeriesData: any[] = surgeries;
  public testDialogVisible: boolean = false;
  
  // Simple dialog settings
  public addSurgeryConfig = {
    header: 'Add Surgery',
    width: '600px'
  };

  public editSurgeryConfig = {
    header: 'Edit Surgery',
    width: '600px'
  };

  
  public deleteConfirmDialogVisibility: boolean = false;

  public deleteConfirmConfig = {
    header: 'Confirm Delete',
    width: '400px'
  };

 

  ngOnInit() {
    this.loadSurgeries();

    this.surgeriesGridTemplateSettings = {
      columns: [
          { field: 'id', visible: false },
          { field: 'caseFileId', visible: false },
          { field: 'surgeryType.Description', headerText: 'Surgery Type', allowEditing: false },
          { field: 'provider', headerText: 'Provider', allowEditing: false, visible: false },
          { field: 'surgeon', headerText: 'Surgeon', allowEditing: false },
          { 
            field: 'surgeryDate', 
            headerText: 'Surgery Date',
            format: 'MM/dd/yyyy',
            type: 'date'
          },
          { field: 'surgeryCostEstimate', headerText: 'Surgery Cost Estimate', type: 'number', format: 'C2' },
          { 
            field: 'missingInvoices', 
            headerText: 'Missing Invoices', 
            width: 125,
            valueAccessor: (field: string, data: any) => formatBoolean(data[field])

          },
          { 
            field: 'invoicesConfirmed', 
            headerText: 'Invoices Confirmed', 
            width: 125,
            valueAccessor: (field: string, data: any) => formatBoolean(data[field])

          },
          { field: 'confirmedBy', headerText: 'Confirmed By' },
          { 
            type: 'commands', 
            commands: this.surgeriesGridTemplateManageColumnCommands, 
            headerText: 'Actions',
            width: 85
          },
        ],
        toolbarClick: ($event: any) => this.customToolbarClick($event),
        commandClick: ($event: CommandClickEventArgs) => this.commandClick($event),
        rowSelected: ($event: any) => {
          this.onSurgerySelect($event);
        },
        selectionSettings: { 
          type: 'Single',
          mode: 'Row',
          checkboxOnly: false,
          persistSelection: false,
          enableToggle: false,
          allowColumnSelection: false
        },
        dataBound: () => {
          setTimeout(() => {
            if (this.surgeriesGridTemplate?.grid) {
              this.surgeriesGridTemplate.grid.selectRow(0);
              const firstRowData = this.surgeriesData[0];
              // Manually trigger the selection event
              this.onSurgerySelect({ data: firstRowData });
            }
          });
        },
        allowGrouping: false,
        enableHover: true,
        allowTextWrap: true,
        enableAltRow: true,
        rowHeight: 45,
        toolbar: this.surgeriesGridDefaultToolbar,
        gridLines: 'Both',
        detailTemplate: this.detailTemplate,
        detailDataBound: ($event: any) => this.onDetailDataBound($event)
      };
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.surgeriesGridTemplate?.grid) {
        // Force UI update for selection
        this.surgeriesGridTemplate.grid.selectRow(0);
        const firstRowData = this.surgeriesData[0];
        // Manually trigger the selection event
        this.onSurgerySelect({ data: firstRowData });
        
        // Force grid to refresh selection UI
        this.surgeriesGridTemplate.grid.refresh();
      }

      // Subscribe to future data bound events
      this.surgeriesGridTemplate.grid.dataBound.subscribe(() => {
        const firstSurgery = this.getFirstSurgery();
        if (firstSurgery) {
          this.selectedSurgery = firstSurgery.surgery;
          this.selectedSurgerySignal.set(firstSurgery.surgery);
          this.surgeryProgressMap.set(firstSurgery.surgery.id, firstSurgery.progress);
          this.currentProgress = firstSurgery.progress;
        }
      });
    }, 100); // Added small delay
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    return this.emitSelectedSurgery();
  }

  onAddFormSubmitted() {
    this.addSurgeryDialogVisibility = false;
    this.surgeriesGridTemplate.refresh();
    this.loadSurgeries();
  }

  onEditFormSubmitted() {
    this.editSurgeryDialogVisibility = false;
    this.surgeriesGridTemplate.refresh();
    this.loadSurgeries();
  }

  emitSelectedSurgery() {
    this.surgerySelected.emit(this.selectedSurgery);
    return this.selectedSurgerySignal();
  }

  // Value accessor for displaying strings in comments column
  getCommentValues(field: string, data: object): string[] {
    if ((data as any)[field]) {
      return ((data as any)[field as string]);
    } else {
      return [''];
    }
  }

 


  // Add logic for navigating to individual case files
  commandClick(commandClickArgs: CommandClickEventArgs) {
    const rowData = commandClickArgs.rowData as any;

    if (commandClickArgs.commandColumn?.buttonOption?.iconCss === 'e-icons e-edit') {
    
      commandClickArgs.cancel = true; 
      this.editSurgeryDialogVisibility = true; 
      this.surgerySignal.set(rowData as Surgery);
   
      if (this.surgerySignal() && this.editSurgeryDialog) {
        this.editSurgeryDialog.show();
      }
    } else if (commandClickArgs.commandColumn?.buttonOption?.iconCss === 'e-icons e-delete') {
      this.selectedSurgery = rowData;
      this.selectedSurgerySignal.set(rowData);
      this.deleteConfirmDialogVisibility = true;
    }
  }

  onSurgerySelect(event: any): void {
    const surgeryId = event.data.id;
    
    // Get existing progress or create new
    const progress = this.surgeryProgressMap.get(surgeryId) || {
      selectedSegments: Array(5).fill(false), // Assuming 5 segments
      currentStep: 0
    };
    
    // Emit both surgery data and its progress
    this.surgerySelected.emit({
      surgery: event.data,
      progress: progress
    });
  }

  // Add method to update progress
  updateSurgeryProgress(surgeryId: number, progress: SurgeryProgress): void {
    this.surgeryProgressMap.set(surgeryId, progress);
  }

  getFirstSurgery(): any {
    const surgeries = this.surgeriesGridTemplate.grid.getCurrentViewRecords();
    if (surgeries && surgeries.length > 0) {
      const surgery = surgeries[0] as any;  // Type assertion to any
      return {
        surgery: surgery,
        progress: this.surgeryProgressMap.get(surgery.id) || { selectedSegments: [], currentStep: 0 }
      };
    }
    return null;
  }

  // Add this method to handle detail template data binding
  onDetailDataBound(args: any): void {
    const rowData = args.data;
    const detailDiv = document.createElement('div');
    detailDiv.setAttribute('id', `detail-div-${rowData.id}`);

    const providerTypes = [
      'Facility',
      'Professional',
      'Anesthesia',
      'Surgical Assist',
      'Neuromonitoring',
      'Hardware'
    ];

    // Handle case where no detail data exists for the surgery
    const rowSpecificData = this.getDetailDataForSurgery(rowData.id) || [];

    let tableRows = '';
    providerTypes.forEach((type) => {
      const item = rowSpecificData.find((data) => data.providerType === type);
      const isMissing = !item;
      const isDateMismatch = item && new Date(item.dateOfService).toLocaleDateString() !== new Date(rowData.surgeryDate).toLocaleDateString();
      const rowStyle = isMissing
        ? 'background-color: rgba(220, 53, 69, 0.1) !important; border-color: rgba(220, 53, 69, 0.2) !important;'  // Light red
        : isDateMismatch
        ? 'background-color: rgba(255, 193, 7, 0.1) !important; border-color: rgba(255, 193, 7, 0.2) !important;'  // Light yellow
        : '';

      tableRows += `
        <tr style="${rowStyle}">
          <td style="width: 15%;">${type}</td>
          <td style="width: 45%;">${item?.provider || ''}</td>
          <td style="width: 25%;">${item?.dateOfService || ''}</td>
          <td style="width: 15%;">${item?.sumOfInvoices ? '$' + item.sumOfInvoices.toLocaleString('en-US', { minimumFractionDigits: 2 }) : ''}</td>
        </tr>
      `;
    });

    const total = rowSpecificData.reduce((sum: number, item: DetailData) => sum + item.sumOfInvoices, 0);

    const detailContent = `
      <div class="detail-container">
        <table class="detail-table" style="width: 50%;">
          <thead>
            <tr>
              <th>Provider Type</th>
              <th>Provider</th>
              <th>Date of Service</th>
              <th>Sum of Invoices</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
            <tr class="total-row">
              <td colspan="3"><b>Total</b></td>
              <td><b>$${total.toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
            </tr>
          </tbody>
        </table>
      </div>`;

    detailDiv.innerHTML = detailContent;
    ((args.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
  }

  // Add this method to simulate different detail data for each surgery
  private getDetailDataForSurgery(surgeryId: number): DetailData[] {
    return this.detailData.filter((item: DetailData) => item.surgeryId === surgeryId);
  }

  // Add method to load surgeries
  private async loadSurgeries() {

    if (!this.caseFileId) {
      this.toast.showError('No case file selected');
      return;
    }

    const query = new Query()
      .where('caseFileId', 'equal', this.caseFileId).expand('SurgeryType')
      //.expand(['detailData']);  // If you need to expand related data

    try {
      const response: any = await this.api.getOdata(APIEndpoints.Surgeries)
        .executeQuery(query);
      
      if (this.surgeriesGridTemplate?.grid) {
        this.surgeriesGridTemplate.grid.dataSource = response.result;
        this.surgeriesGridTemplate.grid.refresh();
      }
    } catch (error) {
      this.toast.showError('Error loading surgeries');
      console.error('Error loading surgeries:', error);
    }
  }

  // Update save method to use API
  async saveSurgery(): Promise<void> {
 
  }

  // Add method to close dialog
  closeDialog(): void {
    this.addSurgeryDialogVisibility = false;
  }

  // Update your existing toolbar click handler
  customToolbarClick(args: any): void {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addSurgeryDialogVisibility = true;
     
      if (this.addSurgeryDialog) {
        this.addSurgeryDialog.show();
      }
    }

    if (args.item.id === 'EditSurgery') {
      this.testDialogVisible = true;
    }
  }

  closeEditDialog(): void {
    this.editSurgeryDialogVisibility = false;
  }

  // Add this method near other dialog-related methods
  onDialogClose(): void {
  
  }



  closeDeleteDialog(): void {
    this.deleteConfirmDialogVisibility = false;
    this.selectedSurgery = null;
    this.selectedSurgerySignal.set(undefined);
  }

  // Update delete method to use API
  async confirmDelete(): Promise<void> {
    if (this.selectedSurgery) {
      try {
        //await this.api.delete(`${APIEndpoints.Surgeries}(${this.selectedSurgery.id})`);
        await this.loadSurgeries(); // Reload grid data
        this.toast.showSuccess('Surgery deleted successfully');
        this.closeDeleteDialog();
      } catch (error) {
        this.toast.showError('Error deleting surgery');
        console.error('Error deleting surgery:', error);
      }
    }
  }

}
