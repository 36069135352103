import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ApiService } from '@services/api/api.service';
import { Task, TaskEntity } from '../../../shared/models/task.model';
import { Query } from '@syncfusion/ej2-data';

@Component({
  selector: 'app-task-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TextBoxModule,
    DatePickerModule,
    DropDownListModule,
    ButtonModule
  ],
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss']
})
export class TaskFormComponent implements OnInit {
  @Input() task: Task | null = null;
  @Output() taskSaved = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  taskForm!: FormGroup;
  users: any[] = [];
  taskTypes: any[] = [];
  priorityTypes: any[] = [];
  providers: any[] = [];
  
  constructor(
    private fb: FormBuilder,
    private api: ApiService
  ) {
    this.initForm(); // Initialize form in constructor
  }

  async ngOnInit() {
    try {
      await this.loadDropdownData();
    } catch (error) {
      console.error('Error loading dropdown data:', error);
    }
  }

  private async loadDropdownData() {
    try {
      const usersQuery = new Query().select(['Id', 'Name']).where('Active', 'equal', true);
      const users = await this.api.getOdata('/Users').executeQuery(usersQuery) as any;
      this.users = users.result;

      const taskTypesQuery = new Query().select(['Id', 'Description']).where('Active', 'equal', true);
      const taskTypes = await this.api.getOdata('/TaskTypes').executeQuery(taskTypesQuery) as any;
      this.taskTypes = taskTypes.result;

      const priorityTypesQuery = new Query()
          .select(['Id', 'Description'])
          .where('Active', 'equal', true);
      const priorityTypes = await this.api.getOdata('/TaskPriorityTypes').executeQuery(priorityTypesQuery) as any;
      this.priorityTypes = priorityTypes.result;

      const providersQuery = new Query().select(['Id', 'Name']).where('Active', 'equal', true);
      const providers = await this.api.getOdata('/Providers').executeQuery(providersQuery) as any;
      this.providers = providers.result;
    } catch (error) {
      console.error('Error in loadDropdownData:', error);
      throw error;
    }
  }

  private initForm() {
    this.taskForm = this.fb.group({
      TaskName: [this.task?.Name || '', [Validators.required]],
      TaskDescription: [this.task?.Description || ''],
      TaskTypeId: [this.task?.TaskTypeId || null, [Validators.required]],
      Priority: [this.task?.PriorityTypeId || null],
      NextDateOfActivity: [this.task?.DueDate || null],
      Entities: [this.task?.Entities || []],
      Comments: [this.task?.Comments || '']
    });
  }

  async onSubmit() {
    if (this.taskForm?.valid) {
      const formData = this.taskForm.value;
      
      try {
        if (this.task) {
          await this.api.patchOdata(`/Tasks(${this.task.Id})`, formData);
        } else {
          await this.api.postOdata('/Tasks', formData);
        }
        this.taskSaved.emit();
      } catch (error) {
        console.error('Error saving task:', error);
      }
    }
  }

  onCancelClick() {
    this.cancel.emit();
  }
} 