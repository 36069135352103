<!-- Provider Form Start -->
<div id="provider-form" class="form-container">
    <h2 class="form-title"><fa-icon [icon]="faUsersRectangle" /> Provider Information</h2>

    <form [formGroup]="providerForm" class="form" (ngSubmit)="onSubmit()">

        <!-- Grid Container for Three Columns -->
        <div class="grid-container">
            <!-- Column 1 -->
            <div class="grid-item">
                <div *ngIf="displayFields.includes('Name')" class="input-container" [class.error]="providerForm.get('Name')?.errors && providerForm.get('Name')?.touched">
                    <span class="icon">
                        <fa-icon [icon]="faUser" />
                    </span>
                    <ejs-textbox class="input" formControlName="Name" placeholder="Provider Name" [floatLabelType]="'Auto'" />
                </div>

                <div *ngIf="displayFields.includes('ProviderType')" class="input-container" [class.error]="providerForm.get('ProviderType')?.errors && providerForm.get('ProviderType')?.touched">
                    <span class="icon">
                        <fa-icon [icon]="faIdCard" />
                    </span>
                    <div class="input-with-icon">
                        <ejs-dropdownlist formControlName="ProviderType" 
                            placeholder="Provider Type" 
                            class="input"
                            [fields]="providerTypeFields" 
                            [dataSource]="providerTypes" 
                            [value]="providerForm.get('ProviderType')?.value" 
                            [floatLabelType]="'Auto'"
                            />
                    </div>
                </div>

                <div *ngIf="displayFields.includes('TaxId')" class="input-container">
                    <span class="icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox class="input" formControlName="TaxId" placeholder="Tax ID" [floatLabelType]="'Auto'" />
                </div>

                <div *ngIf="displayFields.includes('NotesImportant')" class="input-container">
                    <ejs-checkbox class="input" formControlName="NotesImportant" label="Notes Important" />
                </div>

                <div *ngIf="displayFields.includes('WebsiteUrl')" class="input-container">
                    <span class="icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox class="input" formControlName="WebsiteUrl" placeholder="Website" [floatLabelType]="'Auto'" />
                </div>

                <div *ngIf="displayFields.includes('W9Name')" class="input-container">
                    <span class="icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox class="input" formControlName="W9Name" placeholder="W9 Name" [floatLabelType]="'Auto'" />
                </div>

                <div *ngIf="displayFields.includes('ReimbursementRate')" class="input-container">
                    <span class="icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox class="input" formControlName="ReimbursementRate" placeholder="Reimbursement Rate" [floatLabelType]="'Auto'" />
                </div>
            </div>

            <!-- Column 2 -->
            <div class="grid-item">
                <div *ngIf="displayFields.includes('FeeScheduleId')" class="input-container">
                    <span class="icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-dropdownlist 
                        class="input" 
                        formControlName="FeeScheduleId" 
                        [dataSource]="feeSchedules" 
                        [fields]="{ text: 'Name', value: 'Id' }" 
                        placeholder="Select a Fee Schedule" 
                        [floatLabelType]="'Auto'">
                    </ejs-dropdownlist>
                </div>

                <div *ngIf="displayFields.includes('InNetwork')" class="input-container">
                    <ejs-checkbox class="input" formControlName="InNetwork" label="In Network" />
                </div>
                <div *ngIf="displayFields.includes('SignedPurchaseAgreement')" class="input-container">
                    <ejs-dropdownlist id="signedPurchaseAgreement" class="input" formControlName="SignedPurchaseAgreement" 
                        [dataSource]="signedPurchaseAgreementOptions" 
                        [fields]="{ text: 'label', value: 'value' }" 
                        placeholder="Signed Purchase Agreement"
                        [floatLabelType]="'Auto'">
                    </ejs-dropdownlist>
                </div>

                <div *ngIf="displayFields.includes('SignedHipaa')" class="input-container">
                    <ejs-dropdownlist id="signedHipaa" class="input" formControlName="SignedHipaa" 
                        [dataSource]="yesNoOptions" 
                        [fields]="{ text: 'label', value: 'value' }" 
                        placeholder="Signed HIPAA"
                        [floatLabelType]="'Auto'">
                    </ejs-dropdownlist>
                </div>
                <div *ngIf="displayFields.includes('SignedW9')" class="input-container">
                    <ejs-dropdownlist id="signedW9" class="input" formControlName="SignedW9" 
                        [dataSource]="yesNoOptions" 
                        [fields]="{ text: 'label', value: 'value' }" 
                        placeholder="Signed W9"
                        [floatLabelType]="'Auto'">
                    </ejs-dropdownlist>
                </div>
                <div *ngIf="displayFields.includes('SignedHipaa')" class="input-container">
                    <ejs-datepicker id="signedHipaaDate" class="input" formControlName="SignedHipaaDate" 
                        placeholder="signedHipaaDate" 
                        [floatLabelType]="'Auto'"
                        format="MM/dd/yyyy"
                        appDateFormat>
                    </ejs-datepicker>
                </div>

                <div *ngIf="displayFields.includes('SignedW9Date')" class="input-container">
                    <ejs-datepicker id="signedW9Date" class="input" formControlName="SignedW9Date" 
                        placeholder="signedW9Date" 
                        [floatLabelType]="'Auto'"
                        format="MM/dd/yyyy"
                        appDateFormat>
                    </ejs-datepicker>
                </div>
            </div>

            <!-- Column 3 -->
            <div class="grid-item">
              

                <div *ngIf="displayFields.includes('ReferringProvider')" class="input-container">
                    <ejs-dropdownlist id="referringProvider" class="input" formControlName="ReferringProvider" 
                        [dataSource]="yesNoOptions" 
                        [fields]="{ text: 'label', value: 'value' }" 
                        placeholder="Select Referring Provider"
                        [floatLabelType]="'Auto'">
                    </ejs-dropdownlist>
                </div>

                <div *ngIf="displayFields.includes('VirtualProvider')" class="input-container">
                    <ejs-dropdownlist id="virtualProvider" class="input" formControlName="VirtualProvider" 
                        [dataSource]="yesNoOptions" 
                        [fields]="{ text: 'label', value: 'value' }" 
                        placeholder="Select Virtual Provider"
                        [floatLabelType]="'Auto'">
                    </ejs-dropdownlist>
                </div>
                <div *ngIf="displayFields.includes('HoldsOwnAr')" class="input-container">
                    <ejs-checkbox class="input" formControlName="HoldsOwnAr" label="Provider Holds Own AR" />
                </div>
                <div *ngIf="displayFields.includes('HoldsOwnAr')" class="input-container">
                    <ejs-checkbox class="input" formControlName="HoldsOwnAr" label="Holds Own AR" />
                </div>
                <div *ngIf="displayFields.includes('SpanishSpeaking')" class="input-container">
                    <ejs-checkbox class="input" formControlName="SpanishSpeaking" label="Spanish Speaking" />
                </div>

                <div *ngIf="displayFields.includes('CheckpointEligible')" class="input-container">
                    <ejs-checkbox class="input" formControlName="CheckpointEligible" label="Checkpoint Eligible" />
                </div>
                <div *ngIf="displayFields.includes('Active')" class="input-container">
                    <ejs-checkbox class="input" formControlName="Active" label="Active" />
                </div>
                <div *ngIf="displayFields.includes('XrefPhoneProviders')" [class]="formClasses.inputContainerFullWidth">
                    <phone-form #phoneFormComp [phone]="providerPhone"  [displayFields]="['PhoneNumber', 'PhoneType']" [displayButtons]="false"></phone-form>
                </div>
            </div>
        </div>

        <!-- Address Section -->
        <div class="address-section">
            <p class="section-title"><fa-icon [icon]="faMapLocationDot" /> Provider Address</p>
            <address-form #addressFormComp [address]="providerAddress" [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" [displayButtons]="false" />
        </div>
        
        <div class="actions">
            <button ejs-button [isPrimary]="true" type="submit">Submit</button>
            <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
        </div>

    </form>

</div>
<!-- Provider Form End -->