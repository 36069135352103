<div id="file-hub-header">

    <div class="header-info">
        <ng-container *ngIf="!isDataLoaded(); else headerContent">
            <loading-overlay [loading]="loadingHeader" />
        </ng-container>
        <ng-template #headerContent>
            
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-end">
                    <h1>{{headerData().fileNumber}} / {{headerData().patient?.Firstname}} {{headerData().patient?.Lastname}}</h1>
                    <span class="h4">{{headerData().comments}}</span>
                </div>
                <div class="d-flex align-items-end">
                    <ejs-tooltip content="Edit Case File">
                        <button ejs-button 
                            cssClass="action-button" 
                            (click)="editCaseFileDialog.show()">
                            <fa-icon [icon]="faEdit"></fa-icon>
                        </button>
                    </ejs-tooltip>
                </div>
            </div>
        </ng-template>
    </div>
    
    <div class="file-info">
        <ejs-accordion (expanded)="expanded($event)">
            <e-accordionitems>
                <e-accordionitem>
                    <ng-template #header>
                        <div class="row">
                            <div class="col-12 col-md-1">
                                <div class="d-flex flex-column patient-info">
                                    <div class="d-flex flex-wrap">
                                        <span><label>File Status:</label></span>
                                        <span><status [status]="fileHub.caseFile?.RecordStatus?.Description ?? null" /></span>
                                    </div>
                                    <div class="d-flex justify-content-between patient-info-buttons">
                                        <ejs-tooltip content="Copy Patient Demographics">
                                            <button ejs-button 
                                                cssClass="action-button" 
                                                (click)="handleCopyPatientDemographics($event)">
                                                <fa-icon [icon]="faCopy"></fa-icon>
                                            </button>
                                        </ejs-tooltip>
                                        <ejs-tooltip content="Send Auth Request">
                                            <button ejs-button 
                                                cssClass="action-button" 
                                                (click)="emailAuthRequest()">
                                                <fa-icon [icon]="faEnvelope"></fa-icon>
                                            </button>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-11">
                                <ng-container *ngIf="!isDataLoaded() || !headerData().patient; else patientInfo">
                                    <loading-overlay [loading]="loadingPatient" />
                                </ng-container>
                                <ng-template #patientInfo>
                                    <patient-info />
                                </ng-template>
                            </div>
                        </div>
                    </ng-template>
    
                    <ng-template #content>
                        <div class="patient-info-expanded">
                            <div class="row">
                                <ng-container *ngFor="let item of data.additionalInfo();">
                                    <div class="col {{ui.columnClass()}}">
                                        <div class="info">
                                            <label class="info-label">{{item.label}}: </label>
                                            <span class="info-text" 
                                                  [class.clickable]="item.clickable"
                                                  (click)="item.clickable && item.onClick && item.onClick()">
                                                {{item.value ?? 'N/A'}}
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
    
    <div class="dialog-container">
        <ejs-dialog id="edit-case-file-dialog" #editCaseFileDialog 
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="false"
            [buttons]="editCaseFileButtons"
            [header]="'Editing ' + (fileHub.caseFile?.FileNumber ?? 'Case File')"
            (close)="editCaseFileDialog.hide()">
            <case-file-form #editFileForm [editType]="'edit'" [endpoint]="'/CaseFiles/' + fileHub.caseFile?.Id" [currentCaseFile]="fileHub.caseFile" />
        </ejs-dialog>
    </div>

</div>
<ejs-dialog #contactDialog 
    [buttons]="contactDialogButtons" 
    [isModal]="true"
    [visible]="false"
    width="30%" >
    <ng-template #content>
        <div *ngIf="loadingContact()">
            <loading-overlay [loading]="loadingContact" />
         </div>
        <div class="contact-details" *ngIf="selectedContact() as contact">
            <!-- Contact Header Section -->
            <div class="contact-header mb-4">
                <h3 class="contact-name">{{capitalizeFirstLetter(contact.ContactName ?? '')}}</h3>
                <div class="contact-title text-muted" *ngIf="contact.ContactTitle">{{contact.ContactTitle}}</div>
            </div>

            <!-- Quick Actions -->
            <div class="quick-actions mb-4">
                <button ejs-button *ngIf="contact.ContactPhone" 
                    cssClass="e-outline" 
                    [iconCss]="'fa fa-phone'"
                    (click)="makePhoneCall(contact.ContactPhone.toString())">
                    Call
                </button>
                <button ejs-button *ngIf="contact.ContactEmail" 
                    cssClass="e-outline ms-2" 
                    [iconCss]="'fa fa-envelope'"
                    (click)="sendEmail(contact.ContactEmail)">
                    Email
                </button>
            </div>

            <!-- Contact Details Grid -->
            <div class="contact-grid">
                <div class="detail-row" *ngIf="contact.ContactPhone">
                    <div class="detail-icon"><fa-icon [icon]="faPhone"></fa-icon></div>
                    <div class="detail-content">
                        <div class="detail-label">Phone</div>
                        <div class="detail-value">{{contact.ContactPhone}}</div>
                    </div>
                </div>

                <div class="detail-row" *ngIf="contact.ContactEmail">
                    <div class="detail-icon"><fa-icon [icon]="faEnvelope"></fa-icon></div>
                    <div class="detail-content">
                        <div class="detail-label">Email</div>
                        <div class="detail-value">{{contact.ContactEmail}}</div>
                    </div>
                </div>

                <div class="detail-row" *ngIf="contact.ContactFax">
                    <div class="detail-icon"><fa-icon [icon]="faFax"></fa-icon></div>
                    <div class="detail-content">
                        <div class="detail-label">Fax</div>
                        <div class="detail-value">{{contact.ContactPhone}}</div>
                    </div>
                </div>

                <div class="detail-row" *ngIf="contact.Address">
                    <div class="detail-icon"><fa-icon [icon]="faLocationDot"></fa-icon></div>
                    <div class="detail-content">
                        <div class="detail-label">Address</div>
                        <div class="detail-value">
                            {{contact.Address}}
                            <span *ngIf="contact.Suite"><br>{{contact.Suite}}</span>
                            <br>{{contact.City}}, {{contact.State}} {{contact.Zip}}
                        </div>
                    </div>
                </div>

                <div class="detail-row" *ngIf="contact.Notes">
                    <div class="detail-icon"><fa-icon [icon]="faNotes"></fa-icon></div>
                    <div class="detail-content">
                        <div class="detail-label">Notes</div>
                        <div class="detail-value">{{contact.Notes}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ejs-dialog>