<!-- Modal to open on custom add function -->
<ejs-dialog #Modal width="1000px" header="Bulk Edit Law Firms" [isModal]="true" [showCloseIcon]='true' [visible]="false"
    (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <form [formGroup]="editForm" class="d-flex flex-column search-form">
            <div class="row">
                <div class="col-6 mb-3">
                    <ejs-combobox id="market_manager" 
                        formControlName="MarketManager" 
                        placeholder="Market Manager" 
                        [dataSource]="marketManagersDataSource"
                        [query]="marketManagersQuery"
                        [fields]="{ text: 'Name', value: 'Id' }"></ejs-combobox>
                </div>
                <div class="col-6 mb-3">
                    <ejs-combobox id="case_manager" 
                        formControlName="CaseManager" 
                        placeholder="Case Manager" 
                        [dataSource]="caseManagersDataSource"
                        [query]="caseManagersQuery"
                        [fields]="{ text: 'Name', value: 'Id' }"></ejs-combobox>
                </div>
                <div class="col-12 mb-3">
                    <div class="form-check">
                        <ejs-checkbox id="active" formControlName="Active" 
                            label="Active"></ejs-checkbox>
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex justify-content-end">
                        <button ejs-button cssClass="e-primary" (click)="onSubmit()" type="button">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
</ejs-dialog> 