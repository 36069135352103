// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faIdCard, faEnvelope, faUserTag, faPhone, faComments, faMapLocationDot, faUsersRectangle } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, Phone, Provider } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form/phone-number-form.component';
import {  DatePickerModule } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'provider-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    AddressFormComponent,
    CheckBoxModule,
    PhoneFormComponent,
    DatePickerModule,
    
  ],
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.scss']
})
export class ProviderFormComponent {
  providerTypeFields: Object;
  providerTypes: any;
  marketManagersQuery: Query;
  caseManagersQuery: Query;
  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 
    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();
      
      if (currentValue) {
        Promise.all([this.getProviderAddress(), this.getProviderPhone()]).then(([address, phone]) => {
          const currentValue = this.formState().currentValue;
          if (currentValue) {
            this.providerForm.patchValue({
              ...currentValue,
              CreatedAt: currentValue.CreatedAt ? Date.now().toString() : null,
              UpdatedAt: currentValue.UpdatedAt instanceof Date ? currentValue.UpdatedAt.toISOString() : null,
            }, { emitEvent: false });
            this.addressFormComp.addressForm.patchValue(address as Address, { emitEvent: false });
            this.phoneFormComp.phoneForm.patchValue(phone as Phone, { emitEvent: false });
          }
        });
      }

      // Cleanup
      onCleanup(() => this.providerForm.reset());
    });
  }

  faUser = faUser;
  faIdCard = faIdCard;
  faUserTag = faUserTag;
  faComments = faComments;
  faMapLocationDot = faMapLocationDot;
  faUsersRectangle = faUsersRectangle;

  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12'
  };

  // Decorator variables
  @Input() selectedAccountId?: number;
  @Input() isProvider?: boolean;
  @Input() provider!: Signal<Provider | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  providerPhone = signal<Phone | undefined>(undefined);
  @Input() displayFields: string[] = [
    'Id', 'Name', 'ProviderType', 'TaxId', 'NotesImportant', 
    'ReimbursementRate', 'FeeScheduleId', 'InNetwork', 'PriorityId', 
    'AddressId', 'FaxId', 'PhoneId', 'SignedPurchaseAgreement', 
    'SignedHipaa', 'SignedW9', 'SpanishSpeaking', 'CheckpointEligible', 
    'ReferringProvider', 'HoldsOwnAr', 'VirtualProvider', 'Active', 
    'CreatedBy', 'CreatedAt', 'UpdatedBy', 'UpdatedAt', 'XrefPhoneProviders', 'WebsiteUrl', 
    'W9Name', 'SignedHipaaDate', 'SignedW9Date'
  ];

  @ViewChild('phoneFormComp') phoneFormComp!: PhoneFormComponent;
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  selectedProvider: Signal<Provider | undefined>;

  feeSchedules: Array<{ Id: number, Name: string }> = [];


  signedPurchaseAgreementOptions = [
    { label: 'Yes', value: 0 },
    { label: 'No', value: 1 },
    { label: 'SCA Only', value: 2 }
  ];

  yesNoOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  // Form state
  protected readonly formState = computed(() => {
    const currentProvider = this.provider();
    return {
      isValid: this.providerForm.valid,
      hasChanges: currentProvider !== this.providerForm.value,
      currentValue: currentProvider
    };
  });

  // Public variables
  providerAddress = signal<Address | undefined>(undefined);
  loadingForm: boolean = true;
  errorMessage: string = '';
  providerHTMLElement: Element | null = null;
  providerForm = new FormGroup({
    Id: new FormControl<number | null>(null),
    Name: new FormControl<string | null>(null, Validators.required),
    ProviderType: new FormControl<number | null>(null, Validators.required),
    TaxId: new FormControl<string | null>(null),
    Notes: new FormControl<string | null>(null),
    NotesImportant: new FormControl<boolean>(false), 
    ReimbursementRate: new FormControl<number | null>(0),
    FeeScheduleId: new FormControl<number | null>(null),
    InNetwork: new FormControl<boolean>(false), 
    PriorityId: new FormControl<number | null>(0),
    AddressId: new FormControl<number | null>(null),
    FaxId: new FormControl<number | null>(null),
    PhoneId: new FormControl<number | null>(null),
    SignedPurchaseAgreement: new FormControl<number | null>(0),
    SignedHipaa: new FormControl<number | null>(0),
    SignedW9: new FormControl<number | null>(0),
    SignedHipaaDate: new FormControl<string | null>(null),
    SignedW9Date: new FormControl<string | null>(null),
    SpanishSpeaking: new FormControl<boolean>(false), 
    CheckpointEligible: new FormControl<boolean>(false), 
    ReferringProvider: new FormControl<number>(0), 
    HoldsOwnAr: new FormControl<boolean>(false), 
    WebsiteUrl: new FormControl<string | null>(null),
    W9Name: new FormControl<string | null>(null),
    VirtualProvider: new FormControl<number>(0), 
    Active: new FormControl<boolean>(true), 

    CreatedAt: new FormControl<string | null>(null),
    UpdatedBy: new FormControl<number | null>(null),
    UpdatedAt: new FormControl<string | null>(null),
});
  ngOnInit() {
    this.getProviderTypes();
    this.getFeeSchedules();
  }

  async getProviderTypes() {
    const query = new Query();
    this.providerTypes = this.api.getOdata(APIEndpoints.ProviderTypes);

    this.providerTypes.executeQuery(query).then((result: any) => {
        this.providerTypes = result.result;
    }).catch((error: any) => {
        console.error('Error fetching provider types:', error);
    });
    this.providerTypeFields = { text: 'Description', value: 'Id' };
  }

  // Fetches provider address
  async getProviderAddress() {
    const providerId = this.formState().currentValue?.Id as number;
    if (!providerId) throw new Error('Provider Id required to fetch address');  

    const endpoint = `${APIEndpoints.XrefAddressProviders}`;
    const expandString = 'Address($select=Id,AddressType,Address1,Address2,City,State,Zip),Provider($select=Id)';
    const predicate = new Predicate('Provider/Id', 'equal', providerId);
    const query = new Query().expand(expandString).where(predicate);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
        if (res instanceof Error) throw new Error('Failed to fetch provider address');
        
        const address = (res as any).result[0]?.Address;
        if (!address) {
          // Set all address fields to empty if address is not found
          this.providerAddress.set({
            AddressType: 0,
            Address1: '',
            Address2: '',
            City: '',
            State: undefined,
            Zip: ''
          });
        } else {
          this.providerAddress.set(address);
        }
        
        this.addressFormComp.address = this.providerAddress;
      });
      
      return this.providerAddress();
    } catch (error) {
      console.error('Error fetching provider address:', error);
      this.toast.showError('Error fetching provider address');
      return error;
    }
  }
  ngAfterViewInit() {
    this.providerHTMLElement = document.querySelector('provider-form');
    this.updateWidth(this.providerHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // On form submit
  async onSubmit() {
    let phoneResponse: any;
    let addressResponse: any;
    const submitType = this.determineSubmitType();
    const endpoint = this.determineEndpoint();

    const signedW9Value = this.providerForm.get('SignedW9')?.value ? 1 : 0;
    this.providerForm.patchValue({ SignedW9: signedW9Value }, { emitEvent: false });

    let providerId = this.providerForm.get('Id')?.value;

    const phoneCurrentId = this.phoneFormComp.phoneForm.get('Id')?.value;
    await this.phoneFormComp.onSubmit().then((res) => {
        phoneResponse = res;
    });

    const addressId = this.addressFormComp.addressForm.get('Id')?.value;
    await this.addressFormComp.onSubmit().then((res) => {
        return addressResponse = res;
    });

    try {
        if (submitType === 'POST') {
          await this.handlePostRequest(addressResponse.Id).then((res) => {
            return providerId = (res as any).Id;
          });
        } else {
          await this.handlePatchRequest(endpoint, submitType);
        }
      } finally {
        this.loadingForm = false;
      }
  
      if (!phoneCurrentId) this.createXrefPhoneProvider((providerId as number), (phoneResponse as any).Id);
      if (!addressId) this.createXrefAddressProvider((providerId as number), (addressResponse as any).Id);
      this.formSubmitted.emit();
   
  }
  
  private determineSubmitType(): 'POST' | 'PATCH' | 'DELETE' {
    return this.submitType ? this.submitType : this.providerForm.get('Id')?.value ? 'PATCH' : 'POST';
  }

  private determineEndpoint(): string {
    return this.providerForm.get('Id')?.value ? `${APIEndpoints.Providers}/${this.providerForm.get('Id')?.value}` : `${APIEndpoints.Providers}`;
  }

  private async handlePostRequest(addressId: number) {
    const newProvider = await this.createProvider(addressId);
    if (!newProvider) {
      throw new Error('Failed to create provider');
    }
    this.toast.showSuccess('Provider created successfully');
    return newProvider;
  }

  private async handlePatchRequest(endpoint: string, submitType: 'PATCH' | 'DELETE') {
    await this.addressFormComp.onSubmit();
  
    await this.formCRUD.submitForm(this.providerForm, `odata${endpoint}`, submitType).then((res) => {
      this.handleFormSubmissionResponse(res);
    });
  }

  private handleFormSubmissionResponse(res: any) {
    if (res instanceof Error) this.toast.showError(res.message as string);
    else if (res instanceof Response && res.ok) this.toast.showSuccess('Contact Submitted Successfully');
    else if (res?.status === 'warning') console.warn(res.message as string);
    else if (res?.status === 'success') this.toast.showSuccess(res.message as string);
    else if (res === undefined) console.warn('Not sure what happened there.');
    else if (typeof res === 'object') this.toast.showSuccess('Contact Submitted Successfully');
    else console.log(res);
    this.loadingForm = false;
    this.formSubmitted.emit();
  }

  private async createProvider(addressId: number): Promise<number> {
    this.providerForm.patchValue({
      AddressId: addressId,
    });
    const res = await this.api.fetchRequest(`odata${APIEndpoints.Providers}`, 'POST', this.providerForm.value);
    if (res instanceof Error || !res.Id) throw new Error('Failed to create provider');
    return res;
  }

  private async createXrefPhoneProvider(newProviderId: number, phoneId: number) {
    const res = await this.api.fetchRequest(`odata${APIEndpoints.XrefPhoneProviders}`, 'POST', { ProviderId: newProviderId, PhoneId: phoneId });
    if (res instanceof Error) throw new Error('Failed to create xref phone provider');
  }

  private async createXrefAddressProvider(newProviderId: number, addressId: number) {
    const res = await this.api.fetchRequest(`odata${APIEndpoints.XrefAddressProviders}`, 'POST', { ProviderId: newProviderId, AddressId: addressId });
    if (res instanceof Error) throw new Error('Failed to create xref address provider');
  }


  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.providerHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.providerForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  async getProviderPhone() {
    const providerId = this.formState().currentValue?.Id as number;
    if (!providerId) throw new Error('Provider Id required to fetch phone provider details');
    
    const endpoint = `${APIEndpoints.XrefPhoneProviders}`;
    const expandString = 'Phone($select=Id,PhoneType,PhoneNumber),Provider($select=Id,Name)';
    const predicate = new Predicate('Provider/Id', 'equal', providerId);
    const query = new Query().expand(expandString).where(predicate);
    
    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
        if (res instanceof Error) throw new Error('Failed to fetch phone provider details');
        const phoneProvider = (res as any).result.find((item: any) => item.Provider.Id === providerId)?.Phone;
        if (phoneProvider) {
          this.providerPhone.set(phoneProvider);
        } else {
          throw new Error('No phone provider details found for the specified provider ID');
        }
      });
      
      return this.providerPhone();
    } catch (error) {
      console.error('Error fetching phone provider details:', error);
      this.toast.showError('Error fetching phone provider details');
      
      if (this.phoneFormComp) {
        this.phoneFormComp.phoneForm.reset({
          Id: null,
          PhoneType: null,
          PhoneNumber: ''
        });
      }
  
      return error;
    }
  }

  async getFeeSchedules() {
    const endpoint = `${APIEndpoints.FeeSchedules}`;
    const query = new Query().select('Id,Name,Active,CreatedAt,UpdatedAt'); // Adjust fields as needed
  
    try {
      const response: any = await this.api.getOdata(endpoint).executeQuery(query);
  
      // Access the 'result' array directly from the response
      this.feeSchedules = response.result.map((item: any) => ({
        Id: item.Id,
        Name: item.Name,
        Active: item.Active,
        CreatedAt: item.CreatedAt,
        UpdatedAt: item.UpdatedAt
      }));
  
      console.log('Mapped Fee Schedules:', this.feeSchedules);
    } catch (error) {
      console.error('Error fetching fee schedules:', error);
    }
  }
}
