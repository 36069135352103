// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faIdCard, faEnvelope, faUserTag, faPhone, faComments, faMapLocationDot, faUsersRectangle } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, Contact, Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { ContactTypes } from './contact-types';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form/phone-number-form.component';

// Type & Interface setup
type ContactFormControls = {
  [K in keyof Contact]: FormControl<Contact[K] | null>;
};

@Component({
  selector: 'contact-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    AddressFormComponent,
    PhoneFormComponent,
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 

    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();
      
      if (currentValue) {
        this.getContactAddress().then((address) => {
          this.getContactPhone().then((phone) => {
            this.contactForm.patchValue(this.formState().currentValue!, { emitEvent: false });
            this.addressFormComp.addressForm.patchValue(address as Address, { emitEvent: false });
            this.phoneFormComp.phoneForm.patchValue(phone as Phone, { emitEvent: false });
          });
        });
      }

      // Cleanup
      onCleanup(() => this.contactForm.reset());
    });
  }

  // Decorator variabls
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() contact!: Signal<Contact | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayFields: string[] = ['ContactName', 'ContactType', 'ContactTitle', 'ContactEmail', 'Notes', 'XrefAddressContacts', 'XrefPhoneContacts'];
  @Input() fieldLabels: { [key: string]: string } = {
    ContactName: 'Contact Name',
    ContactEmail: 'Email',
    ContactPhone: 'Phone Number',
    ContactFax: 'Fax Number',
    XrefAddressContacts: 'Address'
  };
  @Input() showSubmitButton: boolean = true;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  @ViewChild('phoneFormComp') phoneFormComp!: PhoneFormComponent;
  @ViewChild('faxFormComp') faxFormComp!: PhoneFormComponent;

  // Form state
  protected readonly formState = computed(() => {
    const currentContact = this.contact();
    return {
      isValid: this.contactForm.valid,
      hasChanges: currentContact !== this.contactForm.value,
      currentValue: currentContact
    };
  });  

  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.contactForm.controls).forEach(key => {
      const control = this.contactForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  contactAddress = signal<Address | undefined>(undefined);
  contactPhone = signal<Phone | undefined>(undefined);
  contactFax = signal<Phone | undefined>(undefined);
  selectedContact: Signal<Contact | undefined>;
  loadingForm: boolean = true;
  errorMessage: string = '';
  contactHTMLElement: Element | null = null;
  contactTypes: any = ContactTypes;
  contactTypesQuery: Query;
  states: any;
  statesQuery: Query;
  contactForm = new FormGroup<ContactFormControls>({
    Id: new FormControl<Contact['Id']>(undefined),
    ContactType: new FormControl<Contact['ContactType']>(0),
    ContactName: new FormControl<Contact['ContactName']>(undefined, Validators.required),
    ContactTitle: new FormControl<Contact['ContactTitle']>(undefined),
    ContactEmail: new FormControl<Contact['ContactEmail']>(undefined, Validators.required),
    Notes: new FormControl<Contact['Notes']>(undefined),
    ContactPhone: new FormControl<Contact['ContactPhone']>(undefined),
    ContactFax: new FormControl<Contact['ContactFax']>(undefined),
    XrefAddressContacts: new FormControl<Contact['XrefAddressContacts']>(undefined),
    XrefLawFirmContacts: new FormControl<Contact['XrefLawFirmContacts']>(undefined),
    XrefPhoneContacts: new FormControl<Contact['XrefPhoneContacts']>(undefined),
    XrefProviderContacts: new FormControl<Contact['XrefProviderContacts']>(undefined)
  });
  contactIcons = {
    faUser: faUser,
    faIdCard: faIdCard,
    faEnvelope: faEnvelope,
    faUserTag: faUserTag,
    faPhone: faPhone,
    faComments: faComments,
    faMapLocationDot: faMapLocationDot,
    faUsersRectangle: faUsersRectangle
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12'
  };

  ngAfterViewInit() {
    this.contactHTMLElement = document.querySelector('contact-form');
    this.updateWidth(this.contactHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // On form submit
  async onSubmit() {
    let phoneResponse: any;
    let addressResponse: any;
    let contactResponse: any;
    const submitType = this.determineSubmitType();
    const endpoint = this.determineEndpoint();
  
    let contactId = this.contactForm.get('Id')?.value;

    // Get address response first
    const addressId = this.addressFormComp.addressForm.get('Id')?.value;
    await this.addressFormComp.onSubmit().then((res) => {
      addressResponse = res;
    });

    // Get phone response
    const phoneCurrentId = this.phoneFormComp.phoneForm.get('Id')?.value;
    await this.phoneFormComp.onSubmit().then((res) => {
      phoneResponse = res;
    });

    if (submitType === 'POST') {
      await this.handlePostRequest().then((res) => {
        contactResponse = res;
        contactId = (res as any).Id;
        return contactId;
      });
    } else {
      await this.handlePatchRequest(endpoint, submitType);
    }

    if (!phoneCurrentId) this.createXrefPhoneContact(contactId as number, (phoneResponse as any).Id);
    if (!addressId) this.createXrefAddressContact((addressResponse as any).Id, contactId as number);


    if (contactResponse && addressResponse) {
      contactResponse.AddressId = addressResponse.Id;
    }

    this.formSubmitted.emit();
    return contactResponse;
  }
  
  private determineSubmitType(): 'POST' | 'PATCH' | 'DELETE' {
    return this.submitType ? this.submitType : this.contactForm.get('Id')?.value ? 'PATCH' : 'POST';
  }
  
  private determineEndpoint(): string {
    return this.contactForm.get('Id')?.value ? `${APIEndpoints.Contacts}/${this.contactForm.get('Id')?.value}` : `${APIEndpoints.Contacts}`;
  }
  
  private async handlePostRequest() {
    const newContact = await this.createContact();

    if (!newContact) {
      throw new Error('Failed to create contact');
    }

    await this.createXrefAccountContact((newContact as any).Id);
    this.toast.showSuccess('Contact created successfully');
    return newContact;    
  }
  
  private async createContact(): Promise<number> {
    const res = await this.api.fetchRequest(`odata${APIEndpoints.Contacts}`, 'POST', this.contactForm.value);
    if (res instanceof Error || !res.Id) throw new Error('Failed to create contact');
    return res;
  }


  
  private async createXrefAddressContact(newAddressId: number, newContactId: number) {
    const res = await this.api.fetchRequest(`odata${APIEndpoints.XrefAddressContacts}`, 'POST', { AddressId: newAddressId, ContactId: newContactId });
    if (res instanceof Error) throw new Error('Failed to create xref address contact');
  }

  private async createXrefPhoneContact(newContactId: number, phoneId: number) {
    if (!phoneId) throw new Error('Phone Id is required to create xref phone contact');
  
    const res = await this.api.fetchRequest(`odata${APIEndpoints.XrefPhoneContacts}`, 'POST', { PhoneId: phoneId, ContactId: newContactId });
    if (res instanceof Error) throw new Error('Failed to create xref phone contact');
  }
  
  private async createXrefAccountContact(newContactId: number) {
    if (this.selectedAccountId) {
      const xrefEndpoint = this.isLawFirm ? APIEndpoints.XrefLawFirmContacts : APIEndpoints.XrefProviderContacts;
      const xrefPayload = this.isLawFirm
        ? { LawFirmId: this.selectedAccountId, ContactId: newContactId }
        : { ProviderId: this.selectedAccountId, ContactId: newContactId };
  
      const res = await this.api.fetchRequest(`odata${xrefEndpoint}`, 'POST', xrefPayload);
      if (res instanceof Error) throw new Error(`Failed to create xref ${this.isLawFirm ? 'lawfirm' : 'provider'} contact`);
    }
  }
  
  private async handlePatchRequest(endpoint: string, submitType: 'PATCH' | 'DELETE') {
    await this.addressFormComp.onSubmit();
  
    await this.formCRUD.submitForm(this.contactForm, `odata${endpoint}`, submitType).then((res) => {
      this.handleFormSubmissionResponse(res);
    });
  }
  
  private handleFormSubmissionResponse(res: any) {
    if (res instanceof Error) this.toast.showError(res.message as string);
    else if (res instanceof Response && res.ok) this.toast.showSuccess('Contact Submitted Successfully');
    else if (res?.status === 'warning') console.warn(res.message as string);
    else if (res?.status === 'success') this.toast.showSuccess(res.message as string);
    else if (res === undefined) console.warn('Not sure what happened there.');
    else if (typeof res === 'object') this.toast.showSuccess('Contact Submitted Successfully');
    else console.log(res);
    this.loadingForm = false;
    this.formSubmitted.emit();
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.contactHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.contactForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Fetches contact address
  async getContactAddress() {
    const contactId = this.formState().currentValue?.Id as number;
    if (!contactId) throw new Error('Contact Id required to fetch address');  
  
    const endpoint = `${APIEndpoints.XrefAddressContacts}`;
    const expandString = 'Address($select=Id,AddressType,Address1,Address2,City,State,Zip),Contact($select=Id)';
    const predicate = new Predicate('Contact/Id', 'equal', contactId)
      .and(new Predicate('Address/AddressType', 'equal', 1)
        .or('Address/AddressType', 'equal', 2)
        .or('Address/AddressType', 'equal', 3)
        .or('Address/AddressType', 'equal', 4)
        .or('Address/AddressType', 'equal', 5));
    const query = new Query().expand(expandString).where(predicate);
    
    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
        if (res instanceof Error) throw new Error('Failed to fetch contact address');
        const address = (res as any).result.find((item: any) => item.Contact.Id === contactId)?.Address;
        if (!address) throw new Error('No address found for the specified contact ID');
        this.contactAddress.set(address);
        this.addressFormComp.address = this.contactAddress;
      });
      
      return this.contactAddress();
    } catch (error) {
      console.error('Error fetching contact address:', error);
      this.toast.showError('Error fetching contact address');
      
      // Reset the address form fields to empty
      this.addressFormComp.addressForm.reset({
        Id: null,
        AddressType: null,
        Address1: '',
        Address2: '',
        City: '',
        State: null,
        Zip: ''
      });
  
      return error;
    }
  }

  async getContactPhone() {
    const contactId = this.formState().currentValue?.Id as number;
    if (!contactId) throw new Error('Contact Id required to fetch phone details');  
  
    const endpoint = `${APIEndpoints.XrefPhoneContacts}`;
    const expandString = 'Phone($select=Id,PhoneType,PhoneNumber),Contact($select=Id)';
    const predicate = new Predicate('Contact/Id', 'equal', contactId)
      .and(new Predicate('Phone/PhoneType', 'equal', 1)
        .or('Phone/PhoneType', 'equal', 2)
        .or('Phone/PhoneType', 'equal', 3)
        .or('Phone/PhoneType', 'equal', 4)
        .or('Phone/PhoneType', 'equal', 5));
    const query = new Query().expand(expandString).where(predicate);
    
    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
        if (res instanceof Error) throw new Error('Failed to fetch contact phone details');
        const phone = (res as any).result.find((item: any) => item.Contact.Id === contactId)?.Phone;
        if (!phone) throw new Error('No phone details found for the specified contact ID');
        
        // Check if phoneFormComp is defined
        if (this.phoneFormComp) {
          this.contactPhone.set(phone);
          this.phoneFormComp.phone = this.contactPhone;
        } else {
          console.warn('phoneFormComp is not defined');
        }
      });
      
      return this.contactPhone();
    } catch (error) {
      console.error('Error fetching contact phone details:', error);
      
      // Check if phoneFormComp is defined before resetting
      if (this.phoneFormComp) {
        this.phoneFormComp.phoneForm.reset({
          Id: null,
          PhoneType: null,
          PhoneNumber: ''
        });
      }
  
      return error;
    }
  }

}
