// Angular
import { Injectable, signal, WritableSignal } from '@angular/core';

// 3rd Party
import { ScheduleComponent } from '@syncfusion/ej2-angular-schedule';
import { ResourcesModel } from '@syncfusion/ej2-angular-schedule';
import { Query, ReturnOption } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Appointment, ListModality, ListModalityType, ListSchedulerLocation, ListAppointmentType, ListAppointmentStatus, CaseFile, Patient, ProcedureCode, Provider } from '@models/data-contracts';
import { CalendarSignals, CalendarStates, SetFetchedDataAvailableTypes } from '@models/components/scheduler.model';

// Services
import { ApiService } from '@services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerSignalsService {

  constructor(
    private api: ApiService
  ) { }

  // Signals
   state: CalendarStates = {
    Calendar: {
      Component: signal<ScheduleComponent | undefined>(undefined),
      State: signal<string | undefined>(undefined),
      Resources: signal<ResourcesModel[] | undefined>(undefined),
    },
    Loading: {
      Locations: signal<boolean>(true),
      ModalityTypes: signal<boolean>(true),
      Modalities: signal<boolean>(true),
      Appointments: signal<boolean>(true),
      FormCaseFiles: signal<boolean>(true),
      FormModality: signal<boolean>(true),
      FormModalityType: signal<boolean>(true),
      FormProviders: signal<boolean>(true), 
      FormProcedureCodes: signal<boolean>(true),
      FormAppointmentTypes: signal<boolean>(true),
      FormAppointmentStatuses: signal<boolean>(true),
    },
    Data: {
      Locations: signal<ListSchedulerLocation[] | undefined>(undefined),
      ModalityTypes: signal<ListModalityType[] | undefined>(undefined),
      Modalities: signal<ListModality[] | undefined>(undefined),
      Appointments: signal<Appointment[] | undefined>(undefined),
      FormCaseFiles: signal<CaseFile[] | undefined>(undefined),
      FormModality: signal<ListModality | undefined>(undefined),
      FormModalityType: signal<ListModalityType | undefined>(undefined),
      FormProviders: signal<Provider[] | undefined>(undefined),
      FormProcedureCodes: signal<ProcedureCode[] | undefined>(undefined),
      FormAppointmentTypes: signal<ListAppointmentType[] | undefined>(undefined),
      FormAppointmentStatuses: signal<ListAppointmentStatus[] | undefined>(undefined),
    }
  };

  // Getters
  get calendar() { return this.state.Calendar; };
  get loading() { return this.state.Loading; };
  get data() { return this.state.Data; };

  // Setters
  setCalendar(signals: CalendarSignals){
    this.state.Calendar.Component = signals.Component;
    this.state.Calendar.State = signals.State;
    this.state.Calendar.Resources = signals.Resources;
  };
  private async setFetchedData<T extends SetFetchedDataAvailableTypes>(endpoint: string, query: Query, dataKey: keyof typeof this.state.Data, loadingKey: keyof typeof this.state.Loading): Promise<T[] | undefined> {

    try {

      const result = await this.api.getOdata(endpoint).executeQuery(query).then((res: ReturnOption) => res.result as T[]);
      this.state.Data[dataKey].set(result as any);
      return result;

    } catch (error) {
      console.error('Error fetching data:', error);
      return undefined;

    } finally {
      this.state.Loading[loadingKey].set(false);
    }
  }

  /**
   * Data Setting
   */
  setAppointments(appointments: Appointment[]) {

    appointments.forEach((appointment: Appointment) => {
      return {
        Id: appointment.Id,
        Subject: appointment.ProcedureCode?.Description,
        StartTime: new Date(appointment.StartDatetime + 'Z'),
        EndTime: new Date(appointment.EndDatetime + 'Z'),
        RecurrenceRule: appointment.RecurrenceRule,
        RecurrenceException: appointment.RecurrenceException,
        ProviderId: appointment.ProviderId,
        IsBlock: appointment.Block,
        Description: appointment.Notes,
        CaseFileId: appointment.CaseFileId,
        IsCompleted: appointment.IsCompleted,
        AppointmentTypeId: appointment.AppointmentTypeId,
        AppointmentStatus: appointment.AppointmentStatus,
        AppointmentStatusId: appointment.AppointmentStatusId,
        Location: appointment.Location,
        LocationId: appointment.Location?.Id,
        ModalityId: appointment.ModalityId,
        Modality: appointment.Modality,
        ModalityTypeId: appointment.Modality?.ModalityTypeId,
        ModalityType: appointment.Modality?.ModalityType,
        ProcedureCode: appointment.ProcedureCode,
        ProcedureCodeId: appointment.ProcedureCodeId,
        CaseFile: appointment.CaseFile
      }
    });

    this.state.Data.Appointments.set(appointments);
  }

  /**
   * Data Fetching
   */
  fetchLocations = async () => {
    return this.setFetchedData<ListSchedulerLocation>(
      APIEndpoints.Locations,
      new Query().expand('ListModalities($select=Id,Description,ModalityTypeId,LocationId,Color)').select('Id,Description,Active,LocationHoursStart,LocationHoursEnd'),
      'Locations', // dataKey
      'Locations' // loadingKey
    );
  }

  fetchModalityTypes = async () => {
    return this.setFetchedData<ListModalityType>(
      APIEndpoints.ModalityTypes,
      new Query().select('Id,Description,Color').expand('ListModalities($select=Id,Description,ModalityTypeId,LocationId,Color)'),
      'ModalityTypes', // dataKey
      'ModalityTypes' // loadingKey
    );
  }

  fetchModalities = async () => {
    return this.setFetchedData<ListModality>(
      APIEndpoints.Modalities,
      new Query().select('Id,Description,Color,ModalityTypeId,LocationId'),
      'Modalities', // dataKey
      'Modalities' // loadingKey
    );
  }

  fetchModality = async (modalityId?: number) => {
    if (!modalityId) return;
    const endpoint = `${APIEndpoints.Modalities}/${modalityId}`;
    const key = modalityId && modalityId > 0 ? 'FormModality' : 'Modalities';
    return this.setFetchedData<ListModality>(
      endpoint,
      new Query(),
      key, // dataKey
      key // loadingKey
    );
  } 

  fetchAppointments = async () => {
    return this.setFetchedData<Appointment>(
      APIEndpoints.Appointments,
      new Query().expand('Location($expand=ListModalities),Modality,ProcedureCode,CaseFile($expand=Patient)'),
      'Appointments', // dataKey
      'Appointments' // loadingKey
    );
  }

  fetchCaseFiles = async () => {
    return this.setFetchedData<CaseFile>(
      APIEndpoints.Casefiles,
      new Query().expand('Patient'),
      'FormCaseFiles', // dataKey
      'FormCaseFiles' // loadingKey
    );
  }

  fetchProviders = async () => {
    return this.setFetchedData<Provider>(
      APIEndpoints.Providers,
      new Query(),
      'FormProviders', // dataKey
      'FormProviders' // loadingKey
    );
  } 

  fetchProcedureCodes = async () => {
    return this.setFetchedData<ProcedureCode>(
      APIEndpoints.ProcedureCodes,
      new Query().select('Id,Description,ModalityTypeId'),
      'FormProcedureCodes', // dataKey
      'FormProcedureCodes' // loadingKey
    );
  }

  fetchProcedureCodesMatchingModalityType = async (modalityTypeId: number) => {
    return this.setFetchedData<ProcedureCode>(
      APIEndpoints.ProcedureCodes,
      new Query().select('Id,Description,ModalityTypeId').where('ModalityTypeId', 'equal', modalityTypeId),
      'FormProcedureCodes', // dataKey
      'FormProcedureCodes' // loadingKey
    );
  }

  fetchAppointmentTypes = async () => {
    return this.setFetchedData<ListAppointmentType>(
      APIEndpoints.AppointmentTypes,
      new Query().select('Id,Description'),
      'FormAppointmentTypes', // dataKey
      'FormAppointmentTypes' // loadingKey
    );
  } 

  fetchAppointmentStatuses = async () => {
    return this.setFetchedData<ListAppointmentStatus>(
      APIEndpoints.AppointmentStatuses,
      new Query().select('Id,Description'),
      'FormAppointmentStatuses', // dataKey
      'FormAppointmentStatuses' // loadingKey
    );
  }
}
