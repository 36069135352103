// Angular
import { Component, ViewEncapsulation, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { Query } from '@syncfusion/ej2-data';

/**
 * This component was converted from the vue-body-part-selector
 * https://github.com/HamadaFMahdi/vue-body-part-selector/tree/master?tab=readme-ov-file
 */
@Component({
  selector: 'body-part-selector',
  standalone: true,
  imports: [],
  templateUrl: './body-part-selector.component.html',
  styleUrl: './body-part-selector.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BodyPartSelectorComponent {

  constructor (private api: ApiService) {}

  @Input() existingInjuries: any[] = [];

  @Output() injuries = new EventEmitter<any>();
  selection: any = {};
  injury: FormGroup;
  masterList: any[] = [];

  ngOnInit() {
    this.getMasterList();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['existingInjuries']) {
      this.mapExistingInjuries();
    }
  }

  pathClicked(event: any) {
    event.stopPropagation();
    let bodyPart = event.target.getAttribute('id') as string;
    let bodyPartId = Number(event.target.getAttribute('partId'));
    let severity = event.target.getAttribute('severity') as string;

    if(bodyPart) {
      // Find matching body part from master list
      const matchingPart = this.masterList.find(item => 
        item.Description.toLowerCase() === bodyPart.toLowerCase()
      );
      
      if (severity === 'none') {
        severity = 'minor';
      } else if (severity === 'minor') {
        severity = 'severe';
      } else {
        severity = 'none';
      }

      (event.target as Element).setAttribute('severity', severity.toString());

      if (severity !== 'none') {
        this.selection[bodyPart] = {
          severity: severity,
          bodyPartId: matchingPart?.Id
        };
      } else {
        delete this.selection[bodyPart];
      }
    }

    this.injuries.emit(this.selection);
  }

  getInjuries() {
    this.injuries.emit(this.selection);
  }

  getMasterList() {
    this.api.getOdata(`${APIEndpoints.BodyParts}`)
      .executeQuery(new Query().select(['Id', 'Description']))
      .then((response: any) => {
        this.masterList = response.result;
      })
  }

  mapExistingInjuries() {
    this.existingInjuries.forEach((injury: any) => {
      // Find matching description from master list using body part ID
      const matchingPart = this.masterList.find(item => 
        item.Id === injury.BodyPartId
      );
      
      if (matchingPart) {
        this.selection[matchingPart.Description] = {
          severity: injury.Severity,
          bodyPartId: injury.BodyPartId
        };
      }
    });
  }
}
