<div id="compliance-tab">

    <tab-card [heading]="'Compliance'" [loadingContent]="loadingComplianceData">
        <!-- 
        <ng-container *ngIf="!loadingComplianceData() && !caseFileCompliance; else complianceData">
            <no-data [warningText]="noComplianceDataText" />
        </ng-container>
        <ng-template #complianceData>
            <div *ngFor="let entry of caseFileCompliance | keyvalue">
                <strong>{{ entry.key }}:</strong> {{ entry.value }}
            </div>
        </ng-template>
        -->

        <compliance 
          [caseFile]="fileHub.caseFile"
          [compliance]="caseFileCompliance">
        </compliance>
    </tab-card>
    
</div>