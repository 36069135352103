import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { Schedule, Day, Week, WorkWeek, Month, Year, Agenda, MonthAgenda, TimelineViews, TimelineMonth, TimelineYear, Resize, DragAndDrop, ExcelExport, ICalendarExport, ICalendarImport, Print, RecurrenceEditor } from '@syncfusion/ej2-schedule';
const _c0 = ["dateHeaderTemplate"];
const _c1 = ["dateRangeTemplate"];
const _c2 = ["dayHeaderTemplate"];
const _c3 = ["cellHeaderTemplate"];
const _c4 = ["cellTemplate"];
const _c5 = ["eventTemplate"];
const _c6 = ["monthHeaderTemplate"];
const _c7 = ["resourceHeaderTemplate"];
const _c8 = ["headerIndentTemplate"];
const _c9 = ["timeScaleMinorSlotTemplate"];
const _c10 = ["timeScaleMajorSlotTemplate"];
const _c11 = ["groupHeaderTooltipTemplate"];
const _c12 = ["template"];
const _c13 = ["eventSettingsTooltipTemplate"];
const _c14 = ["eventSettingsTemplate"];
const _c15 = ["editorTemplate"];
const _c16 = ["editorHeaderTemplate"];
const _c17 = ["editorFooterTemplate"];
const _c18 = ["quickInfoTemplatesHeader"];
const _c19 = ["quickInfoTemplatesContent"];
const _c20 = ["quickInfoTemplatesFooter"];
export * from '@syncfusion/ej2-schedule';
import { CommonModule } from '@angular/common';
let input$3 = ['allowVirtualScrolling', 'cellHeaderTemplate', 'cellTemplate', 'dateFormat', 'dateHeaderTemplate', 'dateRangeTemplate', 'dayHeaderTemplate', 'displayDate', 'displayName', 'enableLazyLoading', 'endHour', 'eventTemplate', 'firstDayOfWeek', 'firstMonthOfYear', 'group', 'headerIndentTemplate', 'headerRows', 'interval', 'isSelected', 'maxEventsPerRow', 'monthHeaderTemplate', 'monthsCount', 'numberOfWeeks', 'option', 'orientation', 'readonly', 'resourceHeaderTemplate', 'showWeekNumber', 'showWeekend', 'startHour', 'timeFormat', 'timeScale', 'workDays'];
let outputs$5 = [];
/**
 * `e-views` directive represent a view of the Angular Schedule.
 * It must be contained in a Schedule component(`ejs-schedule`).
 * ```html
 * <ejs-schedule>
 *   <e-views>
 *    <e-view option='day' dateFormat='dd MMM'></e-view>
 *    <e-view option='week'></e-view>
 *   </e-views>
 * </ejs-schedule>
 * ```
 */
class ViewDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$5);
    this.directivePropList = input$3;
  }
}
ViewDirective.ɵfac = function ViewDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ViewDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ViewDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ViewDirective,
  selectors: [["e-view"]],
  contentQueries: function ViewDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
      i0.ɵɵcontentQuery(dirIndex, _c9, 5);
      i0.ɵɵcontentQuery(dirIndex, _c10, 5);
      i0.ɵɵcontentQuery(dirIndex, _c11, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dateHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dateRangeTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dayHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cellHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cellTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.eventTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.monthHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.resourceHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerIndentTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.timeScale_minorSlotTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.timeScale_majorSlotTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.group_headerTooltipTemplate = _t.first);
    }
  },
  inputs: {
    allowVirtualScrolling: "allowVirtualScrolling",
    cellHeaderTemplate: "cellHeaderTemplate",
    cellTemplate: "cellTemplate",
    dateFormat: "dateFormat",
    dateHeaderTemplate: "dateHeaderTemplate",
    dateRangeTemplate: "dateRangeTemplate",
    dayHeaderTemplate: "dayHeaderTemplate",
    displayDate: "displayDate",
    displayName: "displayName",
    enableLazyLoading: "enableLazyLoading",
    endHour: "endHour",
    eventTemplate: "eventTemplate",
    firstDayOfWeek: "firstDayOfWeek",
    firstMonthOfYear: "firstMonthOfYear",
    group: "group",
    headerIndentTemplate: "headerIndentTemplate",
    headerRows: "headerRows",
    interval: "interval",
    isSelected: "isSelected",
    maxEventsPerRow: "maxEventsPerRow",
    monthHeaderTemplate: "monthHeaderTemplate",
    monthsCount: "monthsCount",
    numberOfWeeks: "numberOfWeeks",
    option: "option",
    orientation: "orientation",
    readonly: "readonly",
    resourceHeaderTemplate: "resourceHeaderTemplate",
    showWeekNumber: "showWeekNumber",
    showWeekend: "showWeekend",
    startHour: "startHour",
    timeFormat: "timeFormat",
    timeScale: "timeScale",
    workDays: "workDays"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ViewDirective.prototype, "dateHeaderTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "dateRangeTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "dayHeaderTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "cellHeaderTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "cellTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "eventTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "monthHeaderTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "resourceHeaderTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "headerIndentTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "timeScale_minorSlotTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "timeScale_majorSlotTemplate", void 0);
__decorate([Template()], ViewDirective.prototype, "group_headerTooltipTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewDirective, [{
    type: Directive,
    args: [{
      selector: 'e-views>e-view',
      inputs: input$3,
      outputs: outputs$5,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    dateHeaderTemplate: [{
      type: ContentChild,
      args: ['dateHeaderTemplate']
    }],
    dateRangeTemplate: [{
      type: ContentChild,
      args: ['dateRangeTemplate']
    }],
    dayHeaderTemplate: [{
      type: ContentChild,
      args: ['dayHeaderTemplate']
    }],
    cellHeaderTemplate: [{
      type: ContentChild,
      args: ['cellHeaderTemplate']
    }],
    cellTemplate: [{
      type: ContentChild,
      args: ['cellTemplate']
    }],
    eventTemplate: [{
      type: ContentChild,
      args: ['eventTemplate']
    }],
    monthHeaderTemplate: [{
      type: ContentChild,
      args: ['monthHeaderTemplate']
    }],
    resourceHeaderTemplate: [{
      type: ContentChild,
      args: ['resourceHeaderTemplate']
    }],
    headerIndentTemplate: [{
      type: ContentChild,
      args: ['headerIndentTemplate']
    }],
    timeScale_minorSlotTemplate: [{
      type: ContentChild,
      args: ['timeScaleMinorSlotTemplate']
    }],
    timeScale_majorSlotTemplate: [{
      type: ContentChild,
      args: ['timeScaleMajorSlotTemplate']
    }],
    group_headerTooltipTemplate: [{
      type: ContentChild,
      args: ['groupHeaderTooltipTemplate']
    }]
  });
})();
/**
 * View Array Directive
 * @private
 */
class ViewsDirective extends ArrayBase {
  constructor() {
    super('views');
  }
}
ViewsDirective.ɵfac = function ViewsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ViewsDirective)();
};
ViewsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ViewsDirective,
  selectors: [["e-views"]],
  contentQueries: function ViewsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ViewDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-schedule>e-views',
      queries: {
        children: new ContentChildren(ViewDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$2 = ['allowMultiple', 'colorField', 'cssClassField', 'dataSource', 'endHourField', 'expandedField', 'field', 'groupIDField', 'idField', 'name', 'query', 'startHourField', 'textField', 'title', 'workDaysField'];
let outputs$4 = [];
/**
 * `e-resources` directive represent a resources of the Angular Schedule.
 * It must be contained in a Schedule component(`ejs-schedule`).
 * ```html
 * <ejs-schedule>
 *   <e-resources>
 *    <e-resource field='RoomId' name='Rooms'></e-resource>
 *    <e-resource field='OwnerId' name='Owners'></e-resource>
 *   </e-resources>
 * </ejs-schedule>
 * ```
 */
class ResourceDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$2;
  }
}
ResourceDirective.ɵfac = function ResourceDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ResourceDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ResourceDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ResourceDirective,
  selectors: [["e-resource"]],
  inputs: {
    allowMultiple: "allowMultiple",
    colorField: "colorField",
    cssClassField: "cssClassField",
    dataSource: "dataSource",
    endHourField: "endHourField",
    expandedField: "expandedField",
    field: "field",
    groupIDField: "groupIDField",
    idField: "idField",
    name: "name",
    query: "query",
    startHourField: "startHourField",
    textField: "textField",
    title: "title",
    workDaysField: "workDaysField"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResourceDirective, [{
    type: Directive,
    args: [{
      selector: 'e-resources>e-resource',
      inputs: input$2,
      outputs: outputs$4,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Resource Array Directive
 * @private
 */
class ResourcesDirective extends ArrayBase {
  constructor() {
    super('resources');
  }
}
ResourcesDirective.ɵfac = function ResourcesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ResourcesDirective)();
};
ResourcesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ResourcesDirective,
  selectors: [["e-resources"]],
  contentQueries: function ResourcesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ResourceDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResourcesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-schedule>e-resources',
      queries: {
        children: new ContentChildren(ResourceDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$1 = ['option', 'template'];
let outputs$3 = [];
/**
 * `e-header-rows` directive represent a header rows of the Schedule.
 * It must be contained in a Schedule component(`ejs-schedule`).
 * ```html
 * <ejs-schedule>
 *   <e-header-rows>
 *    <e-header-row option='Week'></e-header-row>
 *    <e-header-row option='Date'></e-header-row>
 *   </e-header-rows>
 * </ejs-schedule>
 * ```
 */
class HeaderRowDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$3);
    this.directivePropList = input$1;
  }
}
HeaderRowDirective.ɵfac = function HeaderRowDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HeaderRowDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
HeaderRowDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HeaderRowDirective,
  selectors: [["e-header-row"]],
  contentQueries: function HeaderRowDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c12, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    option: "option",
    template: "template"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], HeaderRowDirective.prototype, "template", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderRowDirective, [{
    type: Directive,
    args: [{
      selector: 'e-header-rows>e-header-row',
      inputs: input$1,
      outputs: outputs$3,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();
/**
 * HeaderRow Array Directive
 * @private
 */
class HeaderRowsDirective extends ArrayBase {
  constructor() {
    super('headerrows');
  }
}
HeaderRowsDirective.ɵfac = function HeaderRowsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HeaderRowsDirective)();
};
HeaderRowsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HeaderRowsDirective,
  selectors: [["e-header-rows"]],
  contentQueries: function HeaderRowsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, HeaderRowDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HeaderRowsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-schedule>e-header-rows',
      queries: {
        children: new ContentChildren(HeaderRowDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input = ['align', 'cssClass', 'disabled', 'htmlAttributes', 'id', 'name', 'overflow', 'prefixIcon', 'showAlwaysInPopup', 'showTextOn', 'suffixIcon', 'tabIndex', 'template', 'text', 'tooltipText', 'type', 'visible', 'width'];
let outputs$2 = ['click'];
/**
 * `e-toolbaritems` directive represent a custom toolbar items of the Schedule.
 * It must be contained in a Schedule component(`ejs-schedule`).
 * ```html
 * <ejs-schedule>
 *   <e-toolbaritems>
 *    <e-toolbaritem name='Today'></<e-toolbaritem>
 *    <e-toolbaritem name='DateRangeText'></e-toolbaritem>
 *    <e-toolbaritem prefixIcon='e-icons e-cut' text='Cut'></e-toolbaritem>
 *   <e-toolbaritems>
 * </ejs-schedule>
 * ```
 */
class ToolbarItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input;
  }
}
ToolbarItemDirective.ɵfac = function ToolbarItemDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToolbarItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ToolbarItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToolbarItemDirective,
  selectors: [["e-toolbaritem"]],
  contentQueries: function ToolbarItemDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c12, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    align: "align",
    cssClass: "cssClass",
    disabled: "disabled",
    htmlAttributes: "htmlAttributes",
    id: "id",
    name: "name",
    overflow: "overflow",
    prefixIcon: "prefixIcon",
    showAlwaysInPopup: "showAlwaysInPopup",
    showTextOn: "showTextOn",
    suffixIcon: "suffixIcon",
    tabIndex: "tabIndex",
    template: "template",
    text: "text",
    tooltipText: "tooltipText",
    type: "type",
    visible: "visible",
    width: "width"
  },
  outputs: {
    click: "click"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ToolbarItemDirective.prototype, "template", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-toolbaritems>e-toolbaritem',
      inputs: input,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();
/**
 * ToolbarItem Array Directive
 * @private
 */
class ToolbarItemsDirective extends ArrayBase {
  constructor() {
    super('toolbaritems');
  }
}
ToolbarItemsDirective.ɵfac = function ToolbarItemsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToolbarItemsDirective)();
};
ToolbarItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToolbarItemsDirective,
  selectors: [["e-toolbaritems"]],
  contentQueries: function ToolbarItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ToolbarItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-schedule>e-toolbaritems',
      queries: {
        children: new ContentChildren(ToolbarItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$1 = ['agendaDaysCount', 'allowClipboard', 'allowDragAndDrop', 'allowInline', 'allowKeyboardInteraction', 'allowMultiCellSelection', 'allowMultiDrag', 'allowMultiRowSelection', 'allowResizing', 'allowSwiping', 'calendarMode', 'cellHeaderTemplate', 'cellTemplate', 'cssClass', 'currentView', 'dateFormat', 'dateHeaderTemplate', 'dateRangeTemplate', 'dayHeaderTemplate', 'editorFooterTemplate', 'editorHeaderTemplate', 'editorTemplate', 'enableAdaptiveUI', 'enableAllDayScroll', 'enableHtmlSanitizer', 'enablePersistence', 'enableRecurrenceValidation', 'enableRtl', 'endHour', 'eventDragArea', 'eventSettings', 'firstDayOfWeek', 'firstMonthOfYear', 'group', 'headerIndentTemplate', 'headerRows', 'height', 'hideEmptyAgendaDays', 'locale', 'maxDate', 'minDate', 'monthHeaderTemplate', 'monthsCount', 'quickInfoOnSelectionEnd', 'quickInfoTemplates', 'readonly', 'resourceHeaderTemplate', 'resources', 'rowAutoHeight', 'selectedDate', 'showHeaderBar', 'showQuickInfo', 'showTimeIndicator', 'showWeekNumber', 'showWeekend', 'startHour', 'timeFormat', 'timeScale', 'timezone', 'timezoneDataSource', 'toolbarItems', 'views', 'weekRule', 'width', 'workDays', 'workHours'];
const outputs$1 = ['actionBegin', 'actionComplete', 'actionFailure', 'beforePaste', 'cellClick', 'cellDoubleClick', 'created', 'dataBinding', 'dataBound', 'destroyed', 'drag', 'dragStart', 'dragStop', 'eventClick', 'eventDoubleClick', 'eventRendered', 'hover', 'moreEventsClick', 'navigating', 'popupClose', 'popupOpen', 'renderCell', 'resizeStart', 'resizeStop', 'resizing', 'select', 'virtualScrollStart', 'virtualScrollStop', 'currentViewChange', 'selectedDateChange'];
const twoWays$1 = ['currentView', 'selectedDate'];
/**
 * `ej-schedule` represents the Angular Schedule Component.
 * ```html
 * <ejs-schedule></ejs-schedule>
 * ```
 */
let ScheduleComponent = class ScheduleComponent extends Schedule {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['views', 'resources', 'headerRows', 'toolbarItems'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('ScheduleDay');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleWeek');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleWorkWeek');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleMonth');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleYear');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleAgenda');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleMonthAgenda');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleTimelineViews');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleTimelineMonth');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleTimelineYear');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleResize');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleDragAndDrop');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleExcelExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleICalendarExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ScheduleICalendarImport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('SchedulePrint');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childViews;
    if (this.childResources) {
      this.tagObjects[1].instance = this.childResources;
    }
    if (this.childHeaderRows) {
      this.tagObjects[2].instance = this.childHeaderRows;
    }
    if (this.childToolbarItems) {
      this.tagObjects[3].instance = this.childToolbarItems;
    }
    this.context.ngAfterContentChecked(this);
  }
};
ScheduleComponent.ɵfac = function ScheduleComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScheduleComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ScheduleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ScheduleComponent,
  selectors: [["ejs-schedule"]],
  contentQueries: function ScheduleComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c13, 5);
      i0.ɵɵcontentQuery(dirIndex, _c14, 5);
      i0.ɵɵcontentQuery(dirIndex, _c15, 5);
      i0.ɵɵcontentQuery(dirIndex, _c16, 5);
      i0.ɵɵcontentQuery(dirIndex, _c17, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
      i0.ɵɵcontentQuery(dirIndex, _c9, 5);
      i0.ɵɵcontentQuery(dirIndex, _c10, 5);
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
      i0.ɵɵcontentQuery(dirIndex, _c18, 5);
      i0.ɵɵcontentQuery(dirIndex, _c19, 5);
      i0.ɵɵcontentQuery(dirIndex, _c20, 5);
      i0.ɵɵcontentQuery(dirIndex, _c11, 5);
      i0.ɵɵcontentQuery(dirIndex, ViewsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ResourcesDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, HeaderRowsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ToolbarItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dateHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dateRangeTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dayHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cellTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.cellHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.eventSettings_tooltipTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.eventSettings_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editorTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editorHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editorFooterTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.monthHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.timeScale_minorSlotTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.timeScale_majorSlotTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.resourceHeaderTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerIndentTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.quickInfoTemplates_header = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.quickInfoTemplates_content = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.quickInfoTemplates_footer = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.group_headerTooltipTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childViews = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childResources = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childHeaderRows = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childToolbarItems = _t.first);
    }
  },
  inputs: {
    agendaDaysCount: "agendaDaysCount",
    allowClipboard: "allowClipboard",
    allowDragAndDrop: "allowDragAndDrop",
    allowInline: "allowInline",
    allowKeyboardInteraction: "allowKeyboardInteraction",
    allowMultiCellSelection: "allowMultiCellSelection",
    allowMultiDrag: "allowMultiDrag",
    allowMultiRowSelection: "allowMultiRowSelection",
    allowResizing: "allowResizing",
    allowSwiping: "allowSwiping",
    calendarMode: "calendarMode",
    cellHeaderTemplate: "cellHeaderTemplate",
    cellTemplate: "cellTemplate",
    cssClass: "cssClass",
    currentView: "currentView",
    dateFormat: "dateFormat",
    dateHeaderTemplate: "dateHeaderTemplate",
    dateRangeTemplate: "dateRangeTemplate",
    dayHeaderTemplate: "dayHeaderTemplate",
    editorFooterTemplate: "editorFooterTemplate",
    editorHeaderTemplate: "editorHeaderTemplate",
    editorTemplate: "editorTemplate",
    enableAdaptiveUI: "enableAdaptiveUI",
    enableAllDayScroll: "enableAllDayScroll",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRecurrenceValidation: "enableRecurrenceValidation",
    enableRtl: "enableRtl",
    endHour: "endHour",
    eventDragArea: "eventDragArea",
    eventSettings: "eventSettings",
    firstDayOfWeek: "firstDayOfWeek",
    firstMonthOfYear: "firstMonthOfYear",
    group: "group",
    headerIndentTemplate: "headerIndentTemplate",
    headerRows: "headerRows",
    height: "height",
    hideEmptyAgendaDays: "hideEmptyAgendaDays",
    locale: "locale",
    maxDate: "maxDate",
    minDate: "minDate",
    monthHeaderTemplate: "monthHeaderTemplate",
    monthsCount: "monthsCount",
    quickInfoOnSelectionEnd: "quickInfoOnSelectionEnd",
    quickInfoTemplates: "quickInfoTemplates",
    readonly: "readonly",
    resourceHeaderTemplate: "resourceHeaderTemplate",
    resources: "resources",
    rowAutoHeight: "rowAutoHeight",
    selectedDate: "selectedDate",
    showHeaderBar: "showHeaderBar",
    showQuickInfo: "showQuickInfo",
    showTimeIndicator: "showTimeIndicator",
    showWeekNumber: "showWeekNumber",
    showWeekend: "showWeekend",
    startHour: "startHour",
    timeFormat: "timeFormat",
    timeScale: "timeScale",
    timezone: "timezone",
    timezoneDataSource: "timezoneDataSource",
    toolbarItems: "toolbarItems",
    views: "views",
    weekRule: "weekRule",
    width: "width",
    workDays: "workDays",
    workHours: "workHours"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    beforePaste: "beforePaste",
    cellClick: "cellClick",
    cellDoubleClick: "cellDoubleClick",
    created: "created",
    dataBinding: "dataBinding",
    dataBound: "dataBound",
    destroyed: "destroyed",
    drag: "drag",
    dragStart: "dragStart",
    dragStop: "dragStop",
    eventClick: "eventClick",
    eventDoubleClick: "eventDoubleClick",
    eventRendered: "eventRendered",
    hover: "hover",
    moreEventsClick: "moreEventsClick",
    navigating: "navigating",
    popupClose: "popupClose",
    popupOpen: "popupOpen",
    renderCell: "renderCell",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    select: "select",
    virtualScrollStart: "virtualScrollStart",
    virtualScrollStop: "virtualScrollStop",
    currentViewChange: "currentViewChange",
    selectedDateChange: "selectedDateChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ScheduleComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], ScheduleComponent.prototype, "dateHeaderTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "dateRangeTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "dayHeaderTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "cellTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "cellHeaderTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "eventSettings_tooltipTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "eventSettings_template", void 0);
__decorate([Template()], ScheduleComponent.prototype, "editorTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "editorHeaderTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "editorFooterTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "monthHeaderTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "timeScale_minorSlotTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "timeScale_majorSlotTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "resourceHeaderTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "headerIndentTemplate", void 0);
__decorate([Template()], ScheduleComponent.prototype, "quickInfoTemplates_header", void 0);
__decorate([Template()], ScheduleComponent.prototype, "quickInfoTemplates_content", void 0);
__decorate([Template()], ScheduleComponent.prototype, "quickInfoTemplates_footer", void 0);
__decorate([Template()], ScheduleComponent.prototype, "group_headerTooltipTemplate", void 0);
ScheduleComponent = __decorate([ComponentMixins([ComponentBase])], ScheduleComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScheduleComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-schedule',
      inputs: inputs$1,
      outputs: outputs$1,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childViews: new ContentChild(ViewsDirective),
        childResources: new ContentChild(ResourcesDirective),
        childHeaderRows: new ContentChild(HeaderRowsDirective),
        childToolbarItems: new ContentChild(ToolbarItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    dateHeaderTemplate: [{
      type: ContentChild,
      args: ['dateHeaderTemplate']
    }],
    dateRangeTemplate: [{
      type: ContentChild,
      args: ['dateRangeTemplate']
    }],
    dayHeaderTemplate: [{
      type: ContentChild,
      args: ['dayHeaderTemplate']
    }],
    cellTemplate: [{
      type: ContentChild,
      args: ['cellTemplate']
    }],
    cellHeaderTemplate: [{
      type: ContentChild,
      args: ['cellHeaderTemplate']
    }],
    eventSettings_tooltipTemplate: [{
      type: ContentChild,
      args: ['eventSettingsTooltipTemplate']
    }],
    eventSettings_template: [{
      type: ContentChild,
      args: ['eventSettingsTemplate']
    }],
    editorTemplate: [{
      type: ContentChild,
      args: ['editorTemplate']
    }],
    editorHeaderTemplate: [{
      type: ContentChild,
      args: ['editorHeaderTemplate']
    }],
    editorFooterTemplate: [{
      type: ContentChild,
      args: ['editorFooterTemplate']
    }],
    monthHeaderTemplate: [{
      type: ContentChild,
      args: ['monthHeaderTemplate']
    }],
    timeScale_minorSlotTemplate: [{
      type: ContentChild,
      args: ['timeScaleMinorSlotTemplate']
    }],
    timeScale_majorSlotTemplate: [{
      type: ContentChild,
      args: ['timeScaleMajorSlotTemplate']
    }],
    resourceHeaderTemplate: [{
      type: ContentChild,
      args: ['resourceHeaderTemplate']
    }],
    headerIndentTemplate: [{
      type: ContentChild,
      args: ['headerIndentTemplate']
    }],
    quickInfoTemplates_header: [{
      type: ContentChild,
      args: ['quickInfoTemplatesHeader']
    }],
    quickInfoTemplates_content: [{
      type: ContentChild,
      args: ['quickInfoTemplatesContent']
    }],
    quickInfoTemplates_footer: [{
      type: ContentChild,
      args: ['quickInfoTemplatesFooter']
    }],
    group_headerTooltipTemplate: [{
      type: ContentChild,
      args: ['groupHeaderTooltipTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Schedule component.
 */
class ScheduleModule {}
ScheduleModule.ɵfac = function ScheduleModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScheduleModule)();
};
ScheduleModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ScheduleModule
});
ScheduleModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScheduleModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ScheduleComponent, ViewDirective, ViewsDirective, ResourceDirective, ResourcesDirective, HeaderRowDirective, HeaderRowsDirective, ToolbarItemDirective, ToolbarItemsDirective],
      exports: [ScheduleComponent, ViewDirective, ViewsDirective, ResourceDirective, ResourcesDirective, HeaderRowDirective, HeaderRowsDirective, ToolbarItemDirective, ToolbarItemsDirective]
    }]
  }], null, null);
})();
const DayService = {
  provide: 'ScheduleDay',
  useValue: Day
};
const WeekService = {
  provide: 'ScheduleWeek',
  useValue: Week
};
const WorkWeekService = {
  provide: 'ScheduleWorkWeek',
  useValue: WorkWeek
};
const MonthService = {
  provide: 'ScheduleMonth',
  useValue: Month
};
const YearService = {
  provide: 'ScheduleYear',
  useValue: Year
};
const AgendaService = {
  provide: 'ScheduleAgenda',
  useValue: Agenda
};
const MonthAgendaService = {
  provide: 'ScheduleMonthAgenda',
  useValue: MonthAgenda
};
const TimelineViewsService = {
  provide: 'ScheduleTimelineViews',
  useValue: TimelineViews
};
const TimelineMonthService = {
  provide: 'ScheduleTimelineMonth',
  useValue: TimelineMonth
};
const TimelineYearService = {
  provide: 'ScheduleTimelineYear',
  useValue: TimelineYear
};
const ResizeService = {
  provide: 'ScheduleResize',
  useValue: Resize
};
const DragAndDropService = {
  provide: 'ScheduleDragAndDrop',
  useValue: DragAndDrop
};
const ExcelExportService = {
  provide: 'ScheduleExcelExport',
  useValue: ExcelExport
};
const ICalendarExportService = {
  provide: 'ScheduleICalendarExport',
  useValue: ICalendarExport
};
const ICalendarImportService = {
  provide: 'ScheduleICalendarImport',
  useValue: ICalendarImport
};
const PrintService = {
  provide: 'SchedulePrint',
  useValue: Print
};
/**
 * NgModule definition for the Schedule component with providers.
 */
class ScheduleAllModule {}
ScheduleAllModule.ɵfac = function ScheduleAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScheduleAllModule)();
};
ScheduleAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ScheduleAllModule
});
ScheduleAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [DayService, WeekService, WorkWeekService, MonthService, YearService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, TimelineYearService, ResizeService, DragAndDropService, ExcelExportService, ICalendarExportService, ICalendarImportService, PrintService],
  imports: [[CommonModule, ScheduleModule], ScheduleModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScheduleAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ScheduleModule],
      exports: [ScheduleModule],
      providers: [DayService, WeekService, WorkWeekService, MonthService, YearService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, TimelineYearService, ResizeService, DragAndDropService, ExcelExportService, ICalendarExportService, ICalendarImportService, PrintService]
    }]
  }], null, null);
})();
const inputs = ['calendarMode', 'cssClass', 'dateFormat', 'enablePersistence', 'enableRtl', 'endTypes', 'firstDayOfWeek', 'frequencies', 'locale', 'maxDate', 'minDate', 'selectedType', 'startDate', 'value'];
const outputs = ['change'];
const twoWays = [];
/**
 * `ejs-recurrenceeditor` represents the Angular RecurrenceEditor Component.
 * ```html
 * <ejs-recurrenceeditor></ejs-recurrenceeditor>
 * ```
 */
let RecurrenceEditorComponent = class RecurrenceEditorComponent extends RecurrenceEditor {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
RecurrenceEditorComponent.ɵfac = function RecurrenceEditorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RecurrenceEditorComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
RecurrenceEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RecurrenceEditorComponent,
  selectors: [["ejs-recurrenceeditor"]],
  inputs: {
    calendarMode: "calendarMode",
    cssClass: "cssClass",
    dateFormat: "dateFormat",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    endTypes: "endTypes",
    firstDayOfWeek: "firstDayOfWeek",
    frequencies: "frequencies",
    locale: "locale",
    maxDate: "maxDate",
    minDate: "minDate",
    selectedType: "selectedType",
    startDate: "startDate",
    value: "value"
  },
  outputs: {
    change: "change"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function RecurrenceEditorComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
RecurrenceEditorComponent = __decorate([ComponentMixins([ComponentBase])], RecurrenceEditorComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RecurrenceEditorComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-recurrenceeditor',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the RecurrenceEditor component.
 */
class RecurrenceEditorModule {}
RecurrenceEditorModule.ɵfac = function RecurrenceEditorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RecurrenceEditorModule)();
};
RecurrenceEditorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RecurrenceEditorModule
});
RecurrenceEditorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RecurrenceEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [RecurrenceEditorComponent],
      exports: [RecurrenceEditorComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the RecurrenceEditor component with providers.
 */
class RecurrenceEditorAllModule {}
RecurrenceEditorAllModule.ɵfac = function RecurrenceEditorAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RecurrenceEditorAllModule)();
};
RecurrenceEditorAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RecurrenceEditorAllModule
});
RecurrenceEditorAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, RecurrenceEditorModule], RecurrenceEditorModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RecurrenceEditorAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RecurrenceEditorModule],
      exports: [RecurrenceEditorModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AgendaService, DayService, DragAndDropService, ExcelExportService, HeaderRowDirective, HeaderRowsDirective, ICalendarExportService, ICalendarImportService, MonthAgendaService, MonthService, PrintService, RecurrenceEditorAllModule, RecurrenceEditorComponent, RecurrenceEditorModule, ResizeService, ResourceDirective, ResourcesDirective, ScheduleAllModule, ScheduleComponent, ScheduleModule, TimelineMonthService, TimelineViewsService, TimelineYearService, ToolbarItemDirective, ToolbarItemsDirective, ViewDirective, ViewsDirective, WeekService, WorkWeekService, YearService };
