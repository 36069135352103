//Angular
import { Component, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';

// 3rd Party
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations'
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { DropdownMultiComponent } from "../../../ui/dropdown-multi/dropdown-multi.component";
import { BalanceDueGridComponent } from "../balance-due-grid/balance-due-grid.component";
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';
import { BalanceStatementService } from '../balance-statement.service';

@Component({
  selector: 'app-generate-balance-statement',
  templateUrl: './generate-balance-statement.component.html',
  styleUrl: './generate-balance-statement.component.scss',
  standalone: true,
  imports: [
    AccordionModule,
    CheckBoxAllModule,
    MultiSelectAllModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownMultiComponent,
    BalanceDueGridComponent,
  ]
})

export class GenerateBalanceStatementComponent {
  constructor(
    private balanceStatementService: BalanceStatementService,
    private toast: ToastMessageService,
  ) { }

  @ViewChild('BalanceDueGrid') balanceDueGrid: BalanceDueGridComponent

  //For Loading
  isGeneratingStatement = false;

  //For dropdown
  PandLs = APIEndpoints.PandLs

  @Input() caseFileId: number;
  final: boolean = false;
  formGroup: FormGroup = new FormGroup({ pandls: new FormControl<number[] | null>([], [FormValidators.required]) }, {});
  floatLabelType: string = "Never";

  ngOnInit(): void { }

  //Call the backend to generate a balance statement
  GenerateBalanceStatement() {
    this.isGeneratingStatement = true;
    this.balanceStatementService.generateBalanceStatement(this.caseFileId, this.final, this.formGroup.value.pandls)
      .then(res => {
        this.isGeneratingStatement = false;
        if (res && res.status === 200) {
          this.balanceDueGrid.refresh();
          this.showSuccessMessage('Balance statement generated successfully.', this.caseFileId);
        } else if (res && res.status === 500) {
          this.handleError('An internal server error occurred while generating the balance statement. Please try again.');
        } else {
          throw new Error('Unexpected response status');
        }
      })
      .catch(error => {
        this.isGeneratingStatement = false;
        this.handleError(error);
      });
  }

  private handleError(error: any): void {
    const errorMessage = (error as Error).message || 'Failed to generate balance statement. Please try again.';
    this.toast.showError(errorMessage);
  }

  private showSuccessMessage(message: string, caseFileId: number): void {
    this.toast.showSuccess(`  
      ${message}
      <a href="/balance-statements/${caseFileId}" class="toast-link">
        <i class="fa fa-external-link"></i>
        View balance statement
      </a>
    `);
  }


}
