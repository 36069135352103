<div class="tasks-container">
  <div class="header">
    <h2>Tasks</h2>
    <button ejs-button [isPrimary]="true" (click)="onAddClick()">Add Task</button>
  </div>

  <ejs-grid 
    #grid
    [dataSource]="settings.dataSource"
    [allowPaging]="settings.allowPaging"
    [pageSettings]="settings.pageSettings"
    [allowSorting]="settings.allowSorting"
    [allowFiltering]="settings.allowFiltering"
    [filterSettings]="settings.filterSettings"
    [toolbar]="settings.toolbar"
    [sortSettings]="settings.sortSettings"
    [query]="settings.query"
    [columns]="settings.columns">
  </ejs-grid>
</div>

<!-- Add Task Dialog -->
<ejs-dialog 
  #addDialog
  [visible]="showAddDialog"
  [header]="'Add Task'"
  [showCloseIcon]="true"
  [width]="600"
  (close)="onDialogClose()">
  <ng-template #content>
    <app-task-form
      [task]="selectedTask"
      (taskSaved)="onTaskSaved()"
      (cancel)="onDialogClose()">
    </app-task-form>
  </ng-template>
</ejs-dialog>

<!-- Edit Task Dialog -->
<ejs-dialog 
  #editDialog
  [visible]="showEditDialog"
  [header]="'Edit Task'"
  [showCloseIcon]="true"
  [width]="600"
  (close)="onDialogClose()">
  <ng-template #content>
    <app-task-form
      [task]="selectedTask"
      (taskSaved)="onTaskSaved()"
      (cancel)="onDialogClose()">
    </app-task-form>
  </ng-template>
</ejs-dialog> 