// Angular
import { Component, computed, Input, signal, WritableSignal, ViewChild } from '@angular/core';

// 3rd Party
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';
import { faCopy, faEnvelope, faEdit, faNoteSticky, faLocationDot, faFax, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, Contact, LawFirm, Patient, User } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@services/file-hub/file-hub.service';
import { LoadingModule } from '@modules/loading.module';
import { StatusComponent } from '@ui/status/status.component';
import { PatientInfoComponent } from '@ui/patient-info/patient-info.component';
import { Subscription } from 'rxjs';
import { AccordionAllModule, ExpandEventArgs } from '@syncfusion/ej2-angular-navigations';
import { PerformanceData } from '@root/src/app/shared/models/components/financial-performance.model';
import { CommonModule } from '@angular/common';
import { CaseFileForm } from '@forms/case-file-form/case-file-form.component';
import { ContactsService } from '@root/src/app/shared/services/contacts/contacts.service';
import { capitalizeFirstLetter } from '@app/utils';

interface AdditionalInfoItem {
  id: string;
  label: string;
  value: any;
  clickable?: boolean;
  onClick?: () => void;
}

@Component({
  selector: 'hub-header',
  standalone: true,
  imports: [
    LoadingModule,
    FontAwesomeModule,
    TooltipAllModule, 
    ButtonModule,
    StatusComponent,
    PatientInfoComponent,
    AccordionAllModule,
    CommonModule,
    DialogAllModule,
    CaseFileForm
  ],
  templateUrl: './file-hub-header.component.html',
  styleUrl: './file-hub-header.component.scss'
})
export class FileHubHeaderComponent {
[x: string]: any;

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    public fileHub: FileHubService,
    public contactsService: ContactsService,
  ) {}

  // Signals
  public loadingHeader = signal(true);
  public loadingPatient = signal(true);
  protected readonly headerData = signal<{
    fileNumber: string | null;
    comments: string | null;
    patient: Patient | null;
    lawFirm: LawFirm | null;
    caseManager: User | null;
    marketManager: User | null;
  }>({
    fileNumber: null,
    comments: null,
    patient: null,
    lawFirm: null,
    caseManager: null,
    marketManager: null
  });
  protected readonly loading = {
    performance: signal(true),
    patient: signal(true)
  };
  protected readonly loadingContact = signal(false);
  protected capitalizeFirstLetter = capitalizeFirstLetter;  
  protected readonly data = {
    header: signal<Array<{ id: string; label: string; value: any }>>([]),
    additionalInfo: signal<Array<AdditionalInfoItem>>([]), 
    performance: signal<PerformanceData | undefined>(undefined),
    phoneNumber: signal('Loading...')
  };
  protected readonly ui = {
    columnClass: signal('col-3'),
    expandText: signal('Show Additional Info')
  };
  protected readonly isDataLoaded = computed(() => 
    !this.loadingHeader() && 
    !this.loadingPatient() && 
    !!this.headerData().patient
  );
  protected readonly shouldReload = computed(() => this.fileHub.reload);

  // Private variables
  private watchReload?: Subscription;

  // Public variables
  patient?: Patient;
  faCopy: IconDefinition = faCopy;
  faEnvelope: IconDefinition = faEnvelope;
  faEdit: IconDefinition = faEdit;
  faPhone = faPhone;
  faFax = faFax;
  faLocationDot = faLocationDot;
  faNotes = faNoteSticky;
 
  
  @ViewChild('contactDialog') contactDialog!: DialogComponent;
  protected selectedContact = signal<Contact | null>(null);
  public contactDialogButtons: object[] = [
    { 
      click: () => {
        this.contactDialog.hide();
        this.selectedContact.set(null);
      }, 
      buttonModel: { 
        content: 'Close', 
        cssClass: 'e-outline'
      } 
    }
  ];

  async onContactClick(type: 'law-firm-contact' | 'attorney') {
    const contact = type === 'law-firm-contact' 
      ? this.fileHub.caseFile?.LawFirmContactNavigation
      : this.fileHub.caseFile?.AttorneyNavigation;

    const query = new Query()
      .expand('XrefPhoneContacts');

    if (contact?.Id) {
      try {
        this.loadingContact.set(true);
        this.contactDialog.show();

        const contactDetails = await this.contactsService.getContactById(contact.Id, query);
        if (contactDetails) {
          this.selectedContact.set(contactDetails);
        }
      } catch (error) {
        console.error('Error fetching contact details:', error);
        this.toast.showError('Failed to load contact details');
      } finally {
        this.loadingContact.set(false);
      }
    }
  }

  makePhoneCall(phoneNumber: string) {
    window.location.href = `tel:${phoneNumber}`;
  }

  sendEmail(email: string) {
    try {
      window.location.href = `mailto:${email}`;
    } catch (error) {
      console.error('Failed to open email client:', error);
      // You can also use your toast service here
      this.toast.showError('Failed to open email client');
    }
  }



  // Edit Case File Dialog
  @ViewChild('editCaseFileDialog', { static: true}) editCaseFileDialog: DialogComponent;
  @ViewChild('editFileForm') editFileForm: CaseFileForm;
  editCaseFileButtons: object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearCaseFileForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onEditCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Watch for reloads
  ngOnInit() {
    this.watchReload = this.fileHub.reload$.subscribe(() => {
      this.loadingHeader.set(true);
      this.loadingPatient.set(true);
      this.checkData();
    });
    this.checkData();
  }

  ngOnDestroy() {
    if (this.watchReload) {
      this.watchReload.unsubscribe();
    }
  }

  // Ensure data dependencies are loaded at component level
  async checkData() {

    if (!this.fileHub.caseFile) {
      this.fileHub.showNoCaseFileError();
      return;
    }
    
    await this.addHeaderDataToCaseFile();
  }

  // Fetch and update case file with header data
  private async addHeaderDataToCaseFile() {
    this.loadingHeader.set(true);
    this.loadingPatient.set(true);
    const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
    const expandString = `Patient($expand=Address($expand=StateNavigation), XrefAddressPatients($expand=Address($expand=StateNavigation)), XrefPhonePatients($expand=Phone)),
                          CaseManagerNavigation,
                          MarketManagerNavigation,
                          LawFirm,
                          PAndLNavigation,
                          LawFirmContactNavigation,
                          AttorneyNavigation,
                          ReferralSourceNavigation,
                          RecordStatus($select=Description),
                          ReferringPhysicianNavigation,`;
    const selectString = `Patient,LawFirm,CaseManagerNavigation,MarketManagerNavigation,PAndL,PAndLNavigation,LawFirmContactNavigation,AttorneyNavigation,ReferralSourceNavigation,RecordStatus,ReferringPhysicianNavigation`;
    const hubHeaderQuery = new Query().expand(expandString).select(selectString);

    try {
      const res = await this.api.getOdata(endpoint).executeQuery(hubHeaderQuery).then(r => r);
      const file = (res as any).result[0] as CaseFile;

      

      // Update signal in file hub header service
      this.headerData.update(current => ({
        ...current,
        fileNumber: this.fileHub.caseFile?.FileNumber ?? null,
        comments: this.fileHub.caseFile?.Comments ?? null,
        patient: file.Patient ?? null,
        lawFirm: file.LawFirm ?? null,
        pAndL: file.PAndLNavigation ?? null,
        caseManager: file.CaseManagerNavigation ?? null,
        marketManager: file.MarketManagerNavigation ?? null
      }));

      // Update global state with all new data
      const updates: Partial<CaseFile> = {
        Patient: file.Patient,
        LawFirm: file.LawFirm,
        CaseManagerNavigation: file.CaseManagerNavigation,
        MarketManagerNavigation: file.MarketManagerNavigation,
        PAndLNavigation: file.PAndLNavigation,
        LawFirmContactNavigation: file.LawFirmContactNavigation,
        AttorneyNavigation: file.AttorneyNavigation,
        ReferralSourceNavigation: file.ReferralSourceNavigation,
        ReferringPhysicianNavigation: file.ReferringPhysicianNavigation,
        RecordStatus: file.RecordStatus
      };

      // Update each property in the global caseFile signal
      Object.entries(updates).forEach(([key, value]) => {
        if (value !== undefined) this.fileHub.updateCaseFile(key, value);
      });

      // Update global state only if needed
      if (!this.fileHub.caseFile?.Patient) {
        this.fileHub.updateCaseFile('Patient', file.Patient);
      }

      this.loadingPatient.set(false);
    } catch (error) {
      this.toast.showError('Failed to load case file data');
      console.error('Error loading case file:', error);
      throw error; // Re-throw to be handled by caller
    } finally {
      this.loadingHeader.set(false);
    }
  }

  // Copies patient demographics to clipboard
  handleCopyPatientDemographics(args: any) {
    const patient = this.fileHub.caseFile?.Patient;
    const formattedText = `Patient Information:
      Name: ${patient?.Firstname} ${patient?.Lastname}
      Date of Birth: ${patient?.Dob ? new Date(patient.Dob).toLocaleDateString() : 'Not provided'}
      Gender: ${patient?.Gender || 'Not provided'}
      Language: ${patient?.Language || 'Not provided'}
      Email: ${patient?.Email || 'Not provided'}
      Minor: ${patient?.Minor ? 'Yes' : 'No'}
      Phone: ${patient?.Minor ? 'On file' : 'Not provided'}

      File Details:
      File #: ${this.fileHub.caseFile?.FileNumber}
      Law Firm: ${this.fileHub.caseFile?.LawFirm?.Name}
      Liability:
      Notes: ${this.fileHub.caseFile?.Comments || 'None'}`;

    navigator.clipboard.writeText(formattedText).then(() => {
      this.toast.showSuccess(`Copied patient demographics to clipboard.`);
    })
    .catch((err) => {
      this.toast.showError(`Error copying patient demographics to clipboard.`);
      console.error(err);
    });
  }

  // Generates email with file information
  emailAuthRequest() {
    if (this.fileHub.caseFile !== undefined) {
      const email = 'fileauth@wshcgroup.com';
      const subject = this.fileHub.caseFile.FileNumber as string;
      const body = `File #: ${this.fileHub.caseFile.FileNumber} 
        URL: ${window.location.href}
        Name: ${this.fileHub.caseFile.Patient?.Firstname} ${this.fileHub.caseFile?.Patient?.Lastname}
        Law Firm ${this.fileHub.caseFile.LawFirm?.Name}
        Liability:
        MVA or Premise:
        UM/UIM:
        Specials:
        Auth Amount Requested:
        Note Regarding Request: `;
      const href = `${email}?subject=${subject}&body=${body}`;
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }
  }

  // Expanded state for additional info accordion
  expanded(args: ExpandEventArgs) {
    if (!this.fileHub.caseFile) return;
    this.mapAdditionalInfoData();
    this.initializeTooltip();
    this.ui.expandText.set(args.isExpanded ? 'Hide Additional Info' : 'Show Additional Info');
  }

  // Displays tooltip if text overflows
  private initializeTooltip() {
    const infoDivs = document.querySelectorAll('.info');

    infoDivs.forEach((infoDiv, index) => {
      const infoText = infoDiv.querySelector('span');
      if (!infoText) return;

      const hasOverflowText = infoText.scrollWidth > infoText.clientWidth;
      if (!hasOverflowText) return;

      infoDiv.id = `info-${index}`;
      new Tooltip({
        content: infoText.textContent as string,
        target: `#info-${index}`,
      }).appendTo(infoDiv as HTMLElement);

      infoDiv.classList.remove('e-control');
    });
  }

  // Set additional info displayed in header's accordion
  private mapAdditionalInfoData() {
    const lawFirmContact = this.fileHub.caseFile?.LawFirmContactNavigation && 
    ((this.fileHub.caseFile.LawFirmContactNavigation as Contact).ContactEmail ?? 
     (this.fileHub.caseFile.LawFirmContactNavigation as Contact).ContactPhone);

    const recordStatus = this.fileHub.caseFile?.RecordStatus?.Description ?? 'Unknown';
    const patientAddress = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address;

    this.data.additionalInfo.set([
      { id: 'language', label: 'Language', value: this.fileHub.caseFile?.Patient?.Language },
      { id: 'record-status', label: 'Record Status', value: recordStatus },
      { id: 'in-treatment', label: 'In Treatment', value: this.fileHub.caseFile?.InTreatment ?? null },
      { 
        id: 'law-firm-contact', 
        label: 'Law Firm Contact', 
        value: lawFirmContact,
        clickable: true,
        onClick: () => this.sendEmail(lawFirmContact?.toString() ?? '')
      },
      { 
        id: 'attorney', 
        label: 'Attorney', 
        value: capitalizeFirstLetter(this.fileHub.caseFile?.AttorneyNavigation?.ContactName ?? ''),
        clickable: true,
        onClick: () => this.onContactClick('attorney')
      },
      { id: 'statute-of-limitations', label: 'Statute of Limitations', value: this.fileHub.caseFile?.StatuteOfLimitations },
      { id: 'client-address-1', label: 'Client Address 1', value: this.fileHub.caseFile?.Patient?.Address?.Address1 },
      { id: 'client-address-2', label: 'Client Address 2', value: this.fileHub.caseFile?.Patient?.Address?.Address2 },
      { id: 'gender', label: 'Gender', value: this.fileHub.caseFile?.Patient?.Gender },
      { id: 'client-city', label: 'Client City', value: this.fileHub.caseFile?.Patient?.Address?.City },
      { id: 'client-state', label: 'Client State', value: this.fileHub.caseFile?.Patient?.Address?.StateNavigation?.Name },
      { id: 'client-zip', label: 'Client Zip', value: this.fileHub.caseFile?.Patient?.Address?.Zip },
      { id: 'client-county', label: 'Client County', value: this.fileHub.caseFile?.Patient?.Address?.County },
      { id: 'referral-source', label: 'Referral Source', value: this.fileHub.caseFile?.ReferralSourceNavigation?.Description },
      { id: 'state-legal-characteristics', label: 'State Legal Characteristics', value: this.fileHub.caseFile?.Patient?.Address?.StateNavigation?.Description },
    ]);
    this.loading.patient.set(false);
  }

  closeDialog() {
    if (this.editCaseFileDialog) this.editCaseFileDialog.hide();
  }

  // Set fields to blank
  clearCaseFileForm() {
    if (this.editFileForm) this.editFileForm.caseFileForm().reset();
  }

  onEditCaseFileSubmit(args: any) {
    
    this.editFileForm.formSubmit().then((result: any) => {
      if (result && result.ok) {
        this.closeDialog();
      }
    });
  }
}
