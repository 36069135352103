// Angular
import { AfterViewInit, Component, Input, SimpleChanges, WritableSignal, signal } from '@angular/core';

// 3rd Party
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'loading-overlay',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent implements AfterViewInit {

  @Input() loading: WritableSignal<boolean | undefined> = signal(false);
  @Input() text?: string;
  overlays: any;

  ngAfterViewInit() {
    this.applyLoadingOverlays();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['loading']) {
      this.applyLoadingOverlays();
    }
  }

  startSpinner(target: HTMLElement) {
    createSpinner({
      target: target,
      label: this.text ? '' : 'Loading...'
    });
    showSpinner(target);
  }

  endSpinner(target: HTMLElement) {
    hideSpinner(target);
  }

  applyLoadingOverlays() {
    this.overlays = document.querySelectorAll('loading-overlay');

    if (this.overlays.length > 0) {
      this.overlays.forEach((overlay: any) => {
        const loading: HTMLElement = overlay.querySelector('#loading') as HTMLElement;
        const loadingParent: HTMLElement = (overlay as HTMLElement).parentElement as HTMLElement;
        
        if (this.loading() === true) {
          this.startSpinner(loading);
          loadingParent.style.minHeight = '45px';
          loadingParent.style.position = 'relative';
        } else {
          this.endSpinner(loading);
          loadingParent.style.minHeight = '';
          loadingParent.style.position = '';
        }
      })
    }
  }
}
