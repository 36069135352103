import { Tab, OpenFile } from './hub.model';

export enum UserError {
    FETCH = 'There was a problem loading Your settings',
    PATCH = 'There was a problem updating Your settings',
    REMOVED = 'removed from userPreferences localStorage.',
    NOT_FOUND = 'not found localStorage.userPreferences',
}

export interface Preferences {
    UserId?: string | number,
    AppMode?: string,
    DarkMode?: string,
    Theme?: Theme,
    CaseFilesHub?: CaseFileHubSettings,
}

export interface Theme extends ThemeColors {
    Id: string,
    Title: string
}

export interface ThemeColors {
    PrimaryColor: string,
    SecondaryColor: string,
    TertiaryColor: string
}

export interface CaseFileHubSettings { 
    Tabs: Tab[],
    OpenFiles: OpenFile[]
}