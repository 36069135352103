// Angular
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Servuces
import { ApiService } from '@services/api/api.service';

type FormResult = {
  status?: 'success' | 'warning' | 'error';
  message?: string;
} | Error | Response | undefined;
@Injectable({
  providedIn: 'root'
})
export class FormCrudService {

  constructor(
    private api: ApiService
  ) { }


  // General catch all for submiting forms
  async submitForm(form: FormGroup, endpoint: string, submitType: 'POST' | 'PATCH' | 'DELETE' = 'PATCH'): Promise<any> {
    // Check for endpoint first
    if (!form || !form.value) return new Error('Error: No form found');
    if (!endpoint) return new Error('Error: No endpoint found');

    // Mark all fields as touched to trigger validation
    form.markAllAsTouched();

    // Check for valid form
    if (!form.valid) return { status: 'warning', message: 'Please fill in all required fields' };
    
    switch (submitType) {
      case 'POST': return await this.postRequest(form, endpoint);
      case 'PATCH': return await this.patchRequest(form, endpoint);
      case 'DELETE': return await this.deleteRequest(endpoint);
      default: return await this.patchRequest(form, endpoint);
    }
  }

  // Helper function to create new data
  async postRequest(form: FormGroup, endpoint: string) {
    if (!endpoint) return new Error('No endpoint found for form');

    try {
      return await this.api.fetchRequest(endpoint, 'POST', form.value);
    } catch (error) {
      console.error(`Error creating data at ${endpoint}. ${error}`);
      return new Error(`Error creating data at ${endpoint}. ${error}`);
    }
  }

  // Helper function to handle our PATCH request
  async patchRequest(form: FormGroup, endpoint: string) {
    if (!endpoint) return new Error('Endpoint is required');

    try {
      return await this.api.fetchRequest(endpoint, 'PATCH', form.value);
    } catch (error: any) {
      console.error(`Error updating data at ${endpoint}. ${error}`);
      return new Error(`Error updating data at ${endpoint}. ${error}`);
    }
  }

  // Helper function to handle our DELETE request
  async deleteRequest(endpoint: string) {
    if (!endpoint) return new Error('Endpoint is required');

    try {
      return await this.api.fetchRequest(endpoint, 'PATCH', { Active: false });
    } catch (error) {
      console.error(`Error deactivating data at ${endpoint}. ${error}`);
      return new Error(`Error deactivating data at ${endpoint}. ${error}`);
    }
  }
}