import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter, OnInit, HostListener } from "@angular/core";
import { 
  FormControl, 
  FormGroup, 
  ReactiveFormsModule, 
  Validators
} from "@angular/forms";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListModule, MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DataManager } from "@syncfusion/ej2-data";
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GlobalsService } from '@services/globals/globals.service';
import { MaskedTextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { User } from "@root/src/app/shared/models/data-contracts";

interface ApiError {
  code: string;
  message: string;
  field: string | null;
}

interface ApiResponse<T> {
  success: boolean;
  data: T | null;
  errors: ApiError[];
}

/**
 * Component for adding new users to the system
 * Includes form validation and role assignment
 */
@Component({
  selector: 'add-user',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,  
    DropDownListModule,
    MultiSelectModule,
    TooltipModule,
    MaskedTextBoxModule
  ],
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})

export class AddUserComponent implements OnInit {
  @Input() grid: GridComponent;
  @Output() closeForm = new EventEmitter<void>();
  
  // Form group with validation rules
  addUserForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    title: new FormControl(''),
    phoneNumber: new FormControl('', [Validators.required, this.globals.phoneNumberValidator()]),
    phoneType: new FormControl('', Validators.required),
    roleId: new FormControl('', Validators.required),
    rightIds: new FormControl('')
  });

  rolesData: DataManager;
  rightsData: DataManager;
  errorMessage: string = '';
  phoneTypes: any[] = [];

  constructor(
    private api: ApiService, 
    private toast: ToastMessageService,
    private globals: GlobalsService
  ) {}

  ngOnInit(): void {
    this.resetFormState();
    this.setRoles();
    this.setRights();
    this.api.basicFetch(APIEndpoints.PhoneTypes)
      .then((response: any) => {
        this.phoneTypes = response.value.map((item: any) => ({
          id: item.Id,
          description: item.Description
        }));
      });
  }

  /**
   * Fetches available roles from the API
   */
  private setRoles(): void {
    this.rolesData = this.api.getOdata(APIEndpoints.Roles);
  }

  /**
   * Fetches available rights from the API
   */
  private setRights(): void {
    this.rightsData = this.api.getOdata(APIEndpoints.Rights);
  }

  /**
   * Handles escape key press to close form
   */
  @HostListener('document:keydown.escape')
  handleEscapeKey(): void {
    this.cancel();
    this.closeForm.emit();
  }

  /**
   * Resets form and clears error messages
   */
  cancel(): void {
    this.addUserForm.reset();
    this.errorMessage = '';
    this.closeForm.emit();
  }

  /**
   * Handles form submission and user creation
   */
  async onSubmit(): Promise<void> {
    if (!this.addUserForm.valid) {
      this.markFormGroupTouched(this.addUserForm);
      return;
    }
  
    try {
      const cognitoData = await this.createCognitoUser();
      const phoneId = await this.createPhone();
      const userData = this.prepareUserData(cognitoData.userSub);
      const newUserId = await this.createDatabaseUser(userData);
      await this.createPhoneUserXref(newUserId, phoneId);
      this.showSuccessMessage(userData.Name ?? '', newUserId);
      this.resetFormState();
      this.closeForm.emit();
    } catch (error) {
      this.handleError(error);
    }
  }
  
  private formatPhoneNumberForCognito(phoneNumber: string): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    return `+1${cleaned}`; // E.164 format required by Cognito
  }

  private async createCognitoUser(): Promise<any> {
    const cognitoResponse = await this.api.basicPost('api/cognito/create-user', {
      email: this.addUserForm.value.email,
      name: this.addUserForm.value.name,
      phoneNumber: this.formatPhoneNumberForCognito(this.addUserForm.value.phoneNumber ?? ''),
      userName: this.addUserForm.value.userName
    }, false) as Response;
  
    const cognitoData = await this.parseResponse(cognitoResponse, 'Cognito');
    if (!cognitoResponse.ok || !cognitoData.success) {
      throw new Error(cognitoData.message || `Failed to create Cognito user: ${cognitoResponse.statusText}`);
    }
    return cognitoData;
  }
  
  private async createPhone(): Promise<number> {
    const formData = {
      phoneNumber: this.formatPhoneNumber(this.addUserForm.value.phoneNumber ?? ''),
      phoneType: this.addUserForm.value.phoneType
    };
    
    const response = await this.api.basicPost(APIEndpoints.Phones, formData) as Response;
    const data = await response.json();
    return data.Id;  // Note: Capital 'I' in Id based on backend convention
  }
  
  private prepareUserData(userSub: string): any {
    return {
      Name: this.addUserForm.value.name,
      Username: this.addUserForm.value.userName,
      Email: this.addUserForm.value.email,
      PhoneNumber: this.formatPhoneNumberForCognito(this.addUserForm.value.phoneNumber ?? ''),
      Title: this.addUserForm.value.title || '',
      FileGroupId: null,
      Active: 1,
      UserPreferences: '',
      UserSub: userSub,
      XrefUsersRights: this.getRightIds().map(rightId => ({ RightId: rightId })),
      XrefUsersRoles: this.getRoleIds().map(roleId => ({ RoleId: roleId })),
      XrefAddressUsers: [],
      XrefPhoneUsers: []
    };
  }
  
  private async createDatabaseUser(userData: User): Promise<number> {
    const response = await this.api.basicPost(APIEndpoints.Users, userData) as Response;
    if (!response.ok) {
        throw new Error('Failed to create database user');
    }
    const data = await response.json();
    return data.Id;  // Note: Capital 'I' in Id based on backend convention
  }
  
  private async parseResponse(response: Response, context: string): Promise<any> {
    try {
      return await response.json();
    } catch (error) {
      throw new Error(`Failed to parse ${context} response: ${response.statusText}`);
    }
  }
  
  private showSuccessMessage(userName: string, newUserId: number): void {
    this.toast.showSuccess(`  
      User ${userName} has been created.
      <a href="/users/${newUserId}" class="toast-link">
        <i class="fa fa-external-link"></i>
        View user profile
      </a>
    `);
  }
  
  private handleError(error: any): void {
    this.errorMessage = (error as Error).message || 'Failed to create user. Please try again.';
    this.toast.showError(this.errorMessage);
  }
  // Helper method to mark all form controls as touched
  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Returns appropriate error message for form validation
   */
  getErrorMessage(controlName: string): string {
    const control = this.addUserForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) return 'This field is required';
      if (control.errors['email']) return 'Invalid email format';
      if (control.errors['invalidPhone']) return 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) return this.errorMessage;
    }
    return '';
  }

  // Add this method to clear form state
  resetFormState(): void {
    this.addUserForm.reset();
    this.errorMessage = '';
  }

  private getRoleIds(): number[] {
    const roleIds = Array.isArray(this.addUserForm.value.roleId) 
      ? this.addUserForm.value.roleId 
      : [this.addUserForm.value.roleId];
    return roleIds.map(Number);
  }

  private getRightIds(): number[] {
    const rightIds = Array.isArray(this.addUserForm.value.rightIds)
      ? this.addUserForm.value.rightIds
      : [this.addUserForm.value.rightIds];
    return rightIds.map(Number);
  }

  /**
   * Formats phone number to E.164 format for Cognito
   */
  private formatPhoneNumber(phoneNumber: string): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }

  private async createPhoneUserXref(userId: number, phoneId: number): Promise<void> {
    const xrefData = {
      userId: userId,
      phoneId: phoneId
    };
    await this.api.basicPost(APIEndpoints.XrefPhoneUsers, xrefData);
  }

}
