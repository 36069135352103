// Angular
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
// Internal
import { FileHubService } from '@services/file-hub/file-hub.service';
import { TabCardComponent } from '@fileHub-tabs/tab-card/tab-card.component';

@Component({
  selector: 'settings-tab',
  standalone: true,
  imports: [
    TabCardComponent,
    CommonModule,
  ],
  templateUrl: './settings-tab.component.html',
  styleUrl: './settings-tab.component.scss'
})
export class SettingsTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor (
    public fileHub: FileHubService
  ) {}


}
