import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { Query, Predicate, DataManager } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';
import { ColumnModel, GridModel, EditSettingsModel, EditMode } from '@syncfusion/ej2-angular-grids';
import { ApiService } from '@services/api/api.service';

@Component({
  selector: 'app-work-items-list',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateModule
  ],
  template: `
    <grid-template
      #grid
      [name]="'Work Items'"
      [settings]="gridSettings"
      (actionComplete)="onActionComplete($event)">
    </grid-template>
  `,
  styleUrls: ['./work-items-list.component.scss']
})
export class WorkItemsListComponent {
  @ViewChild('grid') grid!: GridTemplateComponent;
  private dataManager: DataManager;
  public gridSettings: GridModel;

  constructor(private api: ApiService) {
    this.dataManager = this.api.getOdata(APIEndpoints.WorkItems);
    this.initializeGridSettings();
  }

  private initializeGridSettings() {
    this.gridSettings = {
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'Title', headerText: 'Title', width: 150 },
        { field: 'Description', headerText: 'Description', width: 200 },
        { 
          field: 'StatusId', 
          headerText: 'Status', 
          width: 120,
          editType: 'dropdownedit',
          edit: {
            params: {
              dataSource: this.api.getOdata(APIEndpoints.WorkItemStatus),
              fields: { text: 'Name', value: 'Id' },
              query: new Query().select(['Id', 'Name'])
            }
          },
          template: '${Status.Name}'
        },
        { 
          field: 'PriorityId', 
          headerText: 'Priority', 
          width: 120,
          editType: 'dropdownedit',
          edit: {
            params: {
              dataSource: this.api.getOdata(APIEndpoints.WorkItemPriority),
              fields: { text: 'Name', value: 'Id' },
              query: new Query().select(['Id', 'Name'])
            }
          },
          template: '${Priority.Name}'
        },
        {
          field: 'TypeId',
          headerText: 'Type',
          width: 120,
          editType: 'dropdownedit',
          edit: {
            params: {
              dataSource: this.api.getOdata(APIEndpoints.WorkItemTypes),
              fields: { text: 'Name', value: 'Id' },
              query: new Query().select(['Id', 'Name'])
            }
          },
          template: '${Type.Name}'
        },
        {
          field: 'CategoryId',
          headerText: 'Category',
          width: 120,
          editType: 'dropdownedit',
          edit: {
            params: {
              dataSource: this.api.getOdata(APIEndpoints.WorkItemCategories),
              fields: { text: 'Name', value: 'Id' },
              query: new Query().select(['Id', 'Name'])
            }
          },
          template: '${Category.Name}'
        },
        {
          field: 'AssignedTo',
          headerText: 'Assigned To',
          width: 120,
          editType: 'dropdownedit',
          edit: {
            params: {
              dataSource: this.api.getOdata(APIEndpoints.Users),
              fields: { text: 'Name', value: 'Id' },
              query: new Query().select(['Id', 'Name'])
            }
          },
          template: '${AssignedToNavigation.Name}'
        },
        { field: 'DueDate', headerText: 'Due Date', type: 'date', format: 'M/d/y', width: 120 }
      ],
      allowPaging: true,
      pageSettings: { pageSize: 20 },
      allowSorting: true,
      allowFiltering: true,
      toolbar: ['Search', 'Add', 'Edit', 'Delete', 'Update', 'Cancel'],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Dialog' as EditMode
      },
      dataSource: this.dataManager,
      query: new Query()
        .expand([
          'Type',
          'Category',
          'Priority',
          'Status',
          'AssignedToNavigation'
        ])
    };
  }

  onActionComplete(args: any): void {
    // Let the grid template handle cleanup first
    this.grid.onGridActionComplete(args);

    // Only refresh on save/delete operations
    if (args.requestType === 'save' || args.requestType === 'delete') {
      // Use simple refresh - don't recreate data sources
      this.grid.grid.refresh();
    }
  }
}
