<!-- Modal to open on custom function -->
<ejs-dialog #Modal width="1000px" header="Change Account Password" [isModal]="true" [showCloseIcon]='true'
    [visible]="false" (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <form [formGroup]="formGroup" (ngSubmit)="changePassword()" class="d-flex flex-column search-form">
            <div class="row">
                <div class="col-12">
                    <p> Confirm your current password and enter valid new one to change your password. </p>
                </div>

                <div class="col-4">
                    <ejs-textbox id="password1" type="password" placeholder="Enter new password" required
                        #password1Input formControlName="password1" floatLabelType="Auto"></ejs-textbox>
                    <ng-container *ngIf="formGroup.get('password1')?.errors && formGroup.get('password1')?.touched">
                        <ng-container [ngSwitch]="true">
                            <p *ngSwitchCase="!!formGroup.get('password1')?.errors?.['passwordLength']">
                                Password must be at least 8 characters
                            </p>
                            <p *ngSwitchCase="!!formGroup.get('password1')?.errors?.['passwordUppercase']">
                                Password must have at least 1 uppercase letter
                            </p>
                            <p *ngSwitchCase="!!formGroup.get('password1')?.errors?.['passwordLowercase']">
                                Password must have at least 1 lowercase letter
                            </p>
                            <p *ngSwitchCase="!!formGroup.get('password1')?.errors?.['passwordNumber']">
                                Password must have at least 1 number
                            </p>
                            <p *ngSwitchCase="!!formGroup.get('password1')?.errors?.['passwordSpecialCharacter']">
                                Password must have at least 1 special character
                            </p>
                            <p *ngSwitchCase="!!formGroup.get('password1')?.errors?.['required']">
                                Entry required
                            </p>
                        </ng-container>
                    </ng-container>
                </div>

                <div class="col-4">
                    <ejs-textbox id="old-password" type="password" placeholder="Enter current password" required
                        formControlName="oldPassword" floatLabelType="Auto"></ejs-textbox>
                    <ng-container *ngIf="formGroup.get('oldPassword')?.errors && formGroup.get('oldPassword')?.touched">
                        <p *ngIf="formGroup.get('oldPassword')?.errors?.['required']">
                            Unable to verify current password
                        </p>
                    </ng-container>
                </div>

                <div class="col-4">
                    <ejs-textbox id="password2" type="password" placeholder="Confirm new password" required
                        #password2Input formControlName="password2" floatLabelType="Auto"></ejs-textbox>
                    <ng-container *ngIf="formGroup.errors && formGroup.get('password2')?.touched">
                        <p *ngIf="formGroup.errors['doNotMatch']">
                            Passwords do not match
                        </p>
                    </ng-container>
                </div>

                <div class="col-4 form-submit ">
                    <button ejs-button type="submit" isPrimary="true">Submit</button>
                </div>
            </div>
        </form>
    </ng-template>
</ejs-dialog>