// Angular
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, SignedAuthorization } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { LoadingModule } from "@modules/loading.module";
import { TabCardComponent } from '@fileHub-tabs/tab-card/tab-card.component';
import { FileHubService } from '@services/file-hub/file-hub.service';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { AddAuthorizationsComponent } from '../../../forms/add-forms/add-authorizations/add-authorizations.component';
import { GridTemplateComponent } from '../../../grids/grid-template/grid-template.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'authorizations-tab',
  standalone: true,
  imports: [
    TabCardComponent,
    GridTemplateModule,
    LoadingModule,
    AddAuthorizationsComponent,
    DialogModule,
    CommonModule,
  ],
  templateUrl: './authorizations-tab.component.html',
  styleUrl: './authorizations-tab.component.scss'
})
export class AuthorizationsTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService,
    public fileHub: FileHubService
  ) {}

  loadingAuthorizationsData: WritableSignal<boolean> = signal(true);
  authorizations: SignedAuthorization;
  authorizationsGrid: GridModel;

  selectedAuthorization: WritableSignal<SignedAuthorization | null> = signal(null);

  @ViewChild('addAuthorizations') addAuthorizations: DialogComponent;
  addAuthorizationsVisibility: boolean = false;

  @ViewChild('grid') grid: GridTemplateComponent;

  ngAfterViewInit() {
    this.checkData();
  }

  // Verifies data is available
  async checkData() {

    if (!this.fileHub.caseFile) {
      this.toast.showError(`Error: Case File missing. Unable to display signed authorizations.`);
    } else {
      this.setActiveFileData();
      this.setAuthorizationsGrid();
      this.loadingAuthorizationsData.set(false);
    }
  }

  async setActiveFileData() {
    const authorizationsExist = this.fileHub.caseFile ? this.globals.objHasKey(this.fileHub.caseFile, 'SignedAuthorizations') : false;

    if (!authorizationsExist) {
      const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
      const query = new Query().expand('SignedAuthorizations').select('SignedAuthorizations');

      return await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        const file = res.result[0] as CaseFile;
        this.authorizations = file.SignedAuthorizations as SignedAuthorization;
        this.fileHub.updateCaseFile('SignedAuthorizations', file.SignedAuthorizations);
      });
    }
  }

  setAuthorizationsGrid() {
    const caseFileId = this.fileHub.caseFileId ?? this.fileHub.caseFile?.Id;

    this.authorizationsGrid = {
      dataSource: this.api.getOdata(APIEndpoints.SignedAuthorizations),
      query: new Query().where('CaseFileId', 'equal', caseFileId).expand('Provider'),
      toolbar: ['Add Authorization', 'ColumnChooser'],
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DateSigned', headerText: 'Signed' },
        { field: 'Amount', format: 'C2', editType: 'numeric' },
        { field: 'AuthType', headerText: 'Type' },
        { field: 'Provider.Name', headerText: 'Provider', allowEditing: false },
        { field: 'ProviderId', headerText: 'Provider Id' },
        { field: 'Notes' },
        { type: 'commands', headerText: 'Actions', commands: [
          { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
        ] }
      ],
      toolbarClick: (args: any) => this.onToolbarClick(args),
      commandClick: (args: any) => this.onCommandClick(args)
    }
  }

  onToolbarClick(args: any) {
    if (args.item.text === 'Add Authorization') {
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
  }

  onCommandClick(args: any) {
    console.log(args);
    const rowData = args.rowData;
    args.cancel = true;
    if (args.commandColumn.title === 'Edit') {
      this.selectedAuthorization.set(rowData);
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
  }

  async onSubmit() {
    this.addAuthorizations.hide();
    await this.fileHub.setPerformanceData(); //
    this.selectedAuthorization.set(null);
    this.grid.grid.refresh();
  }
}
