import { flattenedVerify } from '../flattened/verify.js';
import { JWSInvalid } from '../../util/errors.js';
import { decoder } from '../../lib/buffer_utils.js';
export async function compactVerify(jws, key, options) {
  if (jws instanceof Uint8Array) {
    jws = decoder.decode(jws);
  }
  if (typeof jws !== 'string') {
    throw new JWSInvalid('Compact JWS must be a string or Uint8Array');
  }
  const {
    0: protectedHeader,
    1: payload,
    2: signature,
    length
  } = jws.split('.');
  if (length !== 3) {
    throw new JWSInvalid('Invalid Compact JWS');
  }
  const verified = await flattenedVerify({
    payload,
    protected: protectedHeader,
    signature
  }, key, options);
  const result = {
    payload: verified.payload,
    protectedHeader: verified.protectedHeader
  };
  if (typeof key === 'function') {
    return {
      ...result,
      key: verified.key
    };
  }
  return result;
}