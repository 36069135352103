<div class="form-control" [formGroup]="updatedComplianceForm">
  <!-- Left Column -->
  <div>
    <div class="form-field">
      <label>UCC Filed</label>
      <div>
        <ejs-switch #UCCFiled onLabel="Yes" offLabel="No" formControlName="UccFiled"></ejs-switch>
        <div [ngClass]="{'hidden': !updatedComplianceForm.get('UccFiled')?.value}">
          <ejs-datepicker formControlName="UccFiledDate" placeholder="UCC Filed Date" floatLabelType="Auto"></ejs-datepicker>
        </div>
      </div>
    </div>

    <div class="form-field">
      <label>Medical Lien Filed</label>
      <div>
        <ejs-switch #LeinFiled onLabel="Yes" offLabel="No" formControlName="MedicalLienFiled"></ejs-switch>
        <div [ngClass]="{'hidden': !updatedComplianceForm.get('MedicalLienFiled')?.value}">
          <ejs-datepicker formControlName="MedicalLienFiledDate" placeholder="Medical Lien Filed Date" floatLabelType="Auto"></ejs-datepicker>
        </div>
      </div>
    </div>

    <div class="form-field">
      <label>HIPAA Signed</label>
      <ejs-dropdownlist formControlName="SignedHipaa" [dataSource]="hipaaSignedSource" placeholder="Select Status"></ejs-dropdownlist>
    </div>

    <div class="form-field">
      <label>Intake Form</label>
      <ejs-dropdownlist formControlName="IntakeForm" [dataSource]="intakeSource" placeholder="Select Status"></ejs-dropdownlist>
    </div>

    <div class="form-field">
      <label>Documents Private Link</label>
      <ejs-textbox formControlName="PublicLink"></ejs-textbox>
    </div>

    <div class="form-field">
      <label>Additional Notes</label>
      <ejs-textbox formControlName="Notes" multiline="true"></ejs-textbox>
    </div>
  </div>

  <!-- Right Column -->
  <div>
    <div class="form-field">
      <label>UCC Terminated</label>
      <div>
        <ejs-switch #UCCTerminated onLabel="Yes" offLabel="No" formControlName="UccTerminated"></ejs-switch>
        <div [ngClass]="{'hidden': !updatedComplianceForm.get('UccTerminated')?.value}">
          <ejs-datepicker formControlName="UccTerminatedDate" placeholder="UCC Terminated Date" floatLabelType="Auto"></ejs-datepicker>
        </div>
      </div>
    </div>

    <div class="form-field">
      <label>Signed Lien</label>
      <ejs-dropdownlist formControlName="SignedMedicalLien" [dataSource]="signedLeinSource" placeholder="Select Status"></ejs-dropdownlist>
    </div>

    <div class="form-field">
      <label>LOP Obtained</label>
      <ejs-switch formControlName="LopObtained" onLabel="Yes" offLabel="No"></ejs-switch>
    </div>

    <div class="form-field">
      <label>NOSA</label>
      <div>
        <ejs-switch onLabel="Yes" offLabel="No" formControlName="NosaCompliance"></ejs-switch>
        <div [ngClass]="{'hidden': !updatedComplianceForm.get('NosaCompliance')?.value}">
          <ejs-datepicker formControlName="NosaMailedDate" placeholder="NOSA Mailed Date" floatLabelType="Auto"></ejs-datepicker>
          <ejs-textbox formControlName="NosaCertifiedMailTrackingNumber" placeholder="Tracking Number" floatLabelType="Auto"></ejs-textbox>
          <ejs-datepicker formControlName="NosaDeliveredDate" placeholder="Delivered Date" floatLabelType="Auto"></ejs-datepicker>
          <ejs-textbox formControlName="NosaReceivedBy" placeholder="Received By" floatLabelType="Auto"></ejs-textbox>
        </div>
      </div>
    </div>

    <div class="form-field">
      <label>MRI Results</label>
      <ejs-switch onLabel="Yes" offLabel="No" formControlName="MriResults"></ejs-switch>
    </div>

    <div class="form-field">
      <label>Documents Link</label>
      <ejs-textbox formControlName="DocumentLink"></ejs-textbox>
    </div>

    <div class="form-field">
      <label>Docket Number</label>
      <ejs-textbox formControlName="DocketNum"></ejs-textbox>
    </div>

    <div class="form-field">
      <label>County</label>
      <ejs-textbox formControlName="County"></ejs-textbox>
    </div>
  </div>

  <!-- Update button container with new styles -->
  <div class="button-container">
    <button ejs-button 
      cssClass="e-outline"
      (click)="onCancel()"
      [disabled]="!formChanged">
      <span>Cancel</span>
    </button>
    <button ejs-button 
      cssClass="e-primary"
      type="button"
      [disabled]="!formChanged"
      (click)="onSubmit()">
      <span>Update</span>
    </button>
  </div>
</div>

<style>
.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  padding: 1rem;
}

.button-container button {
  min-width: 100px;
}
</style>