// Angular
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule,TextBoxModule } from '@syncfusion/ej2-angular-inputs';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { SignedAuthorization } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { Query } from '@syncfusion/ej2-data';
import { NgIf } from '@angular/common';

@Component({
  selector: 'add-authorizations',
  standalone: true,
  imports: [
    FormsModule,
    ButtonModule,
    TextBoxModule,
    DropDownListModule,
    DatePickerModule,
    NumericTextBoxModule,
    NgIf
  ],
  templateUrl: './add-authorizations.component.html',
  styleUrl: './add-authorizations.component.scss',
})
export class AddAuthorizationsComponent {
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
  ) {}
  @Input() caseId: number;
  @Input() selectedAuthorization: SignedAuthorization | null;
  @Output() submit = new EventEmitter<any>(); // Emit backend response

  public formData: SignedAuthorization;

  public userId: number = JSON.parse(localStorage.getItem('userId') || "null");

  initFormData = (authorization?: SignedAuthorization): SignedAuthorization => ({
    CaseFileId: authorization?.CaseFileId || undefined,
    DateSigned: authorization?.DateSigned || undefined,
    Amount: authorization?.Amount || undefined,
    AuthType: authorization?.AuthType || null,
    ProviderId: authorization?.Provider?.Id ?? undefined,
    Notes: authorization?.Notes || null,
    CreatedBy: this.userId,
    UpdatedBy: this.userId
  });

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges['selectedAuthorization'] && this.selectedAuthorization) {
      console.log(this.selectedAuthorization);
      this.formData = this.initFormData(this.selectedAuthorization);
      // need to populate the form with the selected provider
    }
  }

  ngOnInit() {
    this.formData = this.initFormData();
    this.formData.CaseFileId = this.caseId;
    this.api.getOdata(APIEndpoints.AuthTypes).executeQuery(this.authTypeQuery).then((res: any)=> {
      this.authTypeList = res?.result?.map((item: any) => item.Description)
    });
    this.api.getOdata(APIEndpoints.Providers).executeQuery(this.providerQuery).then((res: any)=> {
      this.providerList = res?.result;
    });
  }

  // setup for drop downs
  
  public providerQuery = new Query()
  .select(['name', 'id'])
  .where('Active', 'equal', true)
  ;
  public providerList: any[] = [];
  
  public providerFields = {
    text: 'Name',
    value: 'Id',
  };
  
  public authTypeQuery = new Query()
    .select(['description'])
    .where('Active', 'equal', true);

  public authTypeList: string[];


  closeForm() {
    this.submit.emit();
    this.formData = this.initFormData();
  }
      // Iterate through each key in the object, Format it for backend
  // CreatedAt needs different format
  formatDates(formObj: any): any {
    for (const key in formObj) {
      if (formObj.hasOwnProperty(key)) {
        const value = formObj[key];
        if (value instanceof Date) {
          let dateStr = value.toISOString();
          key === 'CreatedAt'
            ? // For "CreatedAt", use the full ISO string (not sliced)
              (formObj[key] = dateStr)
            : // For other keys, slice the ISO string to "YYYY-MM-DD" format
              (formObj[key] = dateStr.slice(0, 10));
        }
      }
    }
    return formObj;
  }

  onSubmit() {
    if (this.selectedAuthorization) {
      this.api.basicPatch(`${APIEndpoints.SignedAuthorizations}(${this.selectedAuthorization?.Id})`, this.formatDates(this.formData))
      .then((response) => {
        this.submit.emit();
        if (response?.status === 400) {
          this.toast.showError('There was a problem submitting the form');
        }
      });
    } else {
    this.api
      .basicPost(APIEndpoints.SignedAuthorizations, this.formatDates(this.formData))
      .then((response) => {
        this.submit.emit();
        if (response?.status === 400) {
          this.toast.showError('There was a problem submitting the form');
        }
      });
    }
  }
}
