// Angular
import { Component, Input, ViewChild } from '@angular/core';

// 3rd Party
import { CommandClickEventArgs, GridModel } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '../../../grids/grid-template/grid-template.component';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { BalanceStatementEmailComponent } from "../balance-statement-email/balance-statement-email.component";
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-balance-due-grid',
  standalone: true,
  imports: [GridTemplateModule, BalanceStatementEmailComponent],
  templateUrl: './balance-due-grid.component.html',
  styleUrl: './balance-due-grid.component.scss'
})
export class BalanceDueGridComponent {
  @Input() caseFileId: number;
  data: any[];
  pAndLMap: { [key: number]: string } = {};

  constructor(
    private api: ApiService
  ) { }

  public balanceDue: GridModel;
  @Input() endpoint: string;
  @ViewChild('Template') template: GridTemplateComponent;
  balanceDueToolbar: any[];
  @ViewChild('SendEmailPopup') sendEmailPopup: BalanceStatementEmailComponent

  ngOnInit() {
    if (this.endpoint) {
      this.fetchData(this.endpoint);
    }
    this.balanceDue = {
      dataSource: this.api.getOdata(APIEndpoints.BalanceStatements),
      //Need the patient expand for csv file generation on backend
      query: new Query()
        .expand('CreatedByNavigation,CaseFile($expand=LawFirmContactNavigation, CaseManagerNavigation, Patient)')
        .where('CaseFileId', 'equal', this.caseFileId)
        .sortBy('StatementDate', 'descending'),
      toolbar: [],
      columns: [
        { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
        { field: 'StatementDate', headerText: 'Statement Date' },
        { field: 'CreatedByNavigation.Name', headerText: 'Created By' },
        { field: 'TotalBilled', headerText: 'Total Billed', template: "$${TotalBilled}" },
        { field: 'BalanceDue', headerText: 'Balance Due', template: "$${BalanceDue}" },
        { field: 'Final', headerText: 'Final' },
        { field: 'CreatedAt', headerText: 'Created At' },
        {
          type: 'commands', commands: [
            { type: 'Edit', title: 'Send Email', buttonOption: { iconCss: 'e-icons e-send', cssClass: 'e-flat' } },
            { type: 'Edit', title: 'View Box', buttonOption: { iconCss: 'e-icons e-eye', cssClass: 'e-flat' } },
            { type: 'Edit', title: 'Download PDF', buttonOption: { iconCss: 'e-icons e-download', cssClass: 'e-flat' } },
            { type: 'Edit', title: 'Download CSV', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
          ], headerText: 'Actions'
        }
      ],
      created: () => this.onCreated(),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
    }
  }

  onCreated() {
    // Filter out 'Add' and 'Edit' from the default toolbar
    this.balanceDueToolbar = this.template.defaultToolbar as any[];
    this.balanceDueToolbar = this.balanceDueToolbar.filter(item => item !== 'Add' && item !== 'Edit');
    this.template.defaultToolbar = this.balanceDueToolbar;

    // Filter out 'Add' and 'Edit' from the default mobile toolbar
    const mobileToolbar = this.template.defaultMobileToolbar as any[];
    const filteredMobileToolbar = mobileToolbar.filter(item => item !== 'Add' && item !== 'Edit');
    this.template.defaultMobileToolbar = filteredMobileToolbar;
  }

  fetchData(endpoint: string): void {
    this.api.getOdata(endpoint).executeQuery(new Query())
      .then((e: ReturnOption) => {
        this.data = e.result as any[];
        this.data.forEach(item => {
          this.pAndLMap[item.Id] = item.Description;
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const data = commandClickArgs.rowData as any;
    const description = this.pAndLMap[data.CaseFile.PAndL];

    //  this.toast.showWarning('Cannot edit intake form after case file has been created.');
    if (commandClickArgs.commandColumn?.title === 'Send Email' && data) {
      commandClickArgs.cancel = true;
      this.sendEmailPopup.show(
        data.BoxId,
        data.CaseFile.Id,
        data.CreatedByNavigation.Name,
        data.CreatedByNavigation.Email,
        data.CaseFile.LawFirmContactNavigation.ContactEmail,
        data.CaseFile.CaseManagerNavigation.Name,
        data.CaseFile.CaseManagerNavigation.Email,
        description
      );
    }

    //Opens A Box Link
    else if (commandClickArgs.commandColumn?.title === 'View Box' && data) {
      commandClickArgs.cancel = true;
      this.api.basicPost('api/balance-statement/get-link', data, false)
        .then(res => {
          if (res) {
            return res.text();
          }
          else {
            return "";
          }
        })
        .then(text => {
          if (text) {
            window.open(text);
          }
        });
    }

    //Downloads a CSV version of the balance statement
    else if (commandClickArgs.commandColumn?.title === 'Download CSV' && data) {
      commandClickArgs.cancel = true;
      this.api.basicPost('api/balance-statement/get-rows', data, false).then(res => {
        if (res) {
          return res.json();
        }
        else {
          return "";
        }
      })
        .then(text => {
          if (text) {
            this.downloadCSV(text, 'BalanceStatement ' + data.StatementDate)
          }
        });
    }

    //Downloads a pdf version of the balance statement
    else if (commandClickArgs.commandColumn?.title === 'Download PDF' && data) {
      commandClickArgs.cancel = true;
      this.api.basicPost('api/balance-statement/get-bytes', data, false).then(async res => {
        if (res) {
          let text = await res.text();
          saveAs(this.base64ToPDF(text), 'BalanceStatement ' + data.StatementDate + '.pdf');
        }
      });
    }
  }

  refresh() {
    this.template.refresh()
  }

  //Create and download CSV file from backend data and rename headers
  downloadCSV(data: any[], filename: string = 'data') {
    let headers = Object.keys(data[0]);
    const rows = data.map(row => headers.map(header => row[header]).join(','));
    headers = ["Client Name", "File Number", "Statement Date", "Provider Name", "Date Of Service", "Cpt Code", "Settlement Value"];
    const csvData = headers.join(',') + '\n' + rows.join('\n');
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename + '.csv');
  }

  //Decodes a base64 string to a pdf
  base64ToPDF(base64String: string) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'application/pdf' });
    return blob;
  }
}