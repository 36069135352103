<div id="file-hub-tabs">
    
    <ejs-tab #ejsTab id="_ejs-component-ejsTab" 
        [enablePersistence]="true"
        (selected)="onTabSelected($event)">

        <e-tabitems id="content">
            <e-tabitem *ngFor="let tab of tabs; let i = index; track: trackByTabId" [header]="tab" [id]="tab.Id" [tabIndex]="i">
                <ng-template #headerTemplate>
                    <div class="tab-header">
                        <label>{{tab.HeaderText}}</label>
                    </div>
                </ng-template>

                <ng-template #content let-data>
                    <div class="tab-contents">
                        <ng-container *ngIf="activeTab() === tab.HeaderText">
                            <ng-container *ngIf="tab.Id === 'home'">
                                <home-tab></home-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'appointments'">
                                <appointments-tab></appointments-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'tasks'">
                                <tasks-tab></tasks-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'financial'">
                                <financial-tab></financial-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'documents'">
                                <documents-tab></documents-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'authorizations'">
                                <authorizations-tab></authorizations-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'injury'">
                                <injury-tab></injury-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'box-sign'">
                                <box-sign-tab></box-sign-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'compliance'">
                                <compliance-tab></compliance-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'surgical'">
                                <surgical-tab></surgical-tab>
                            </ng-container>
                            <ng-container *ngIf="tab.Id === 'settings'">
                                <settings-tab></settings-tab>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-template>
            </e-tabitem>
        </e-tabitems>
    </ejs-tab>

</div>