<div *ngIf="selectedInvoices() === null || selectedInvoices()?.length === 0">
  <p>No invoices selected!</p>
</div>
<div *ngIf="!(selectedInvoices() === null || selectedInvoices()?.length === 0)">
  <h3>Selected Invoices</h3>
  <div *ngFor="let invoice of selectedInvoices();">
    <div>
      <p>{{invoice.InternalInvoiceNumber}}</p>
    </div>
  </div>
  <div>
    Transfer to Case File:
    <ejs-dropdownlist [dataSource]='caseFileList' [fields]="caseFileFields" [query]="caseFileQuery"
      [(ngModel)]="selectedCaseFile" allowFiltering="true"></ejs-dropdownlist>
  </div>

  <div class="col-10"> </div>
  <div class="col-2 form-submit ">
    <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true" (click)="onSubmit()">Submit</button>
  </div>
</div>