// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule, DatePickerComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faVenusMars, faEnvelope, faCake, faBaby, faLanguage, faPhone, faComments, faMapLocationDot, faHospitalUser, faUserInjured } from '@fortawesome/free-solid-svg-icons';

// Models
import { Patient, Address, Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { genders } from '@models/global-vars';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '@forms/address-form/address-form.component';

// Type & Interface setup
type PatientFormControls = {
  [K in keyof Patient]: FormControl<Patient[K] | null>;
};  

export type PatientFormResult = 'success' | 'warning' | 'error' | undefined;

@Component({
  selector: 'patient-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    DatePickerAllModule,
    CheckBoxAllModule,
    FontAwesomeModule,
    AddressFormComponent
  ],
  templateUrl: './patient-form.component.html',
  styleUrl: './patient-form.component.scss'
})
export class PatientFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) {

    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();

      if (currentValue) {
        // Update form values silently
        this.getPatientAddress().then((address) => {
          this.patientForm.patchValue(this.formState().currentValue!);
          this.addressFormComp.addressForm.patchValue(address as Address);
        });
      }

      // Cleanup
      onCleanup(() => this.patientForm.reset());
    });
  }

  // Decorator variabls
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() patient!: Signal<Patient | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = false;
  @Input() displayFields: string[] = ['Firstname', 'Lastname', 'Email', 'Dob', 'Gender', 'Language', 'Minor', 'XrefAddressPatients'];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @Output() addressResult = new EventEmitter<any>();
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  @ViewChild('dobComp') dobComp!: DatePickerComponent;
  // Form state
  protected readonly formState = computed(() => {
    const currentPatient = this.patient ? this.patient() : undefined;
    return {
      isValid: this.patientForm.valid,
      hasChanges: currentPatient !== this.patientForm.value,
      currentValue: currentPatient
    };
  });  

  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.patientForm.controls).forEach(key => {
      const control = this.patientForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  patientAddress = signal<Address | undefined>(undefined);
  patientPhone = signal<Phone | undefined>(undefined);
  genders: string[] = genders;
  languages: any;
  languagesQuery: Query;
  loadingForm: boolean = true;
  errorMessage: string = '';
  patientHTMLElement: Element | null = null;
  patientForm = new FormGroup<PatientFormControls>({
    Id: new FormControl<Patient['Id']>(undefined),
    AddressId: new FormControl<Patient['AddressId']>(undefined),
    Firstname: new FormControl<Patient['Firstname']>(undefined, [Validators.required]),
    Lastname: new FormControl<Patient['Lastname']>(undefined, [Validators.required]),
    Email: new FormControl<Patient['Email']>(undefined, [Validators.required, Validators.email]),
    Dob: new FormControl<Patient['Dob']>(undefined),
    Gender: new FormControl<Patient['Gender']>(undefined),
    Language: new FormControl<Patient['Language']>(undefined),
    Minor: new FormControl<Patient['Minor']>(undefined)
  });
  patientIcons = {
    form: faHospitalUser,
    patient: faUser,
    gender: faVenusMars,
    dob: faCake,
    language: faLanguage,
    minor: faBaby,
    phone: faPhone,
    email: faEnvelope,
    address: faMapLocationDot,
    notes: faComments
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12'
  };

  ngAfterViewInit() {
    this.patientHTMLElement = document.querySelector('patient-form');
    this.addressFormComp.displayFields = ['Address1', 'Address2', 'City', 'State', 'Zip'];
    this.updateWidth(this.patientHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
    console.log(this.patient());
  }

  // On form submit
  async onSubmit() {
    let submitResult: any;
    let addressResponse: any;
    this.markAllFieldsAsTouched();
    const submitType = this.submitType ? this.submitType : this.patientForm.get('Id')?.value ? 'PATCH' : 'POST';
    const endpoint = this.patientForm.get('Id')?.value ? `${APIEndpoints.Patients}/${this.patientForm.get('Id')?.value}` : `${APIEndpoints.Patients}`;
    const patientId = this.patientForm.get('Id')?.value;

    if (this.patientForm.invalid) {
      submitResult = 'error';
      this.loadingForm = false;
      this.toast.showError('Form Invalid');
      return submitResult;
    }

    // Check if form has changes
    if (this.patientForm.pristine) {
      submitResult = 'no-change';
      this.loadingForm = false;
      this.toast.showWarning('No changes to submit');
      return submitResult;
    }

    const addressId = this.addressFormComp.addressForm.get('Id')?.value;
    await this.addressFormComp.onSubmit().then((res) => {
      addressResponse = res;
      console.log('Address RESPONSE', this.addressFormComp.addressForm.value);
      return addressResponse;
    });
    console.log('addressId', addressId, 'addressResponse', addressResponse);
    submitResult = await this.formCRUD.submitForm(this.patientForm, `odata${endpoint}`, submitType);
    if (!addressId) {
      console.log('AddressId', addressId, 'AddressResponse', addressResponse);
      console.log('Creating XrefAddressPatient');
      // await this.api.fetchRequest(`odata${APIEndpoints.XrefAddressPatients}`, 'POST', { AddressId: addressResponse.Id, PatientId: submitResult.Id });
    }

    return submitResult;

  }

  markAllFieldsAsTouched(): void {
    Object.values(this.patientForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.patientHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.patientForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['name']) message = 'First and last name are required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Fetches patient address
  async getPatientAddress() {
    const patientId = this.formState().currentValue?.Id as number;
    const addressId = this.formState().currentValue?.AddressId as number;
    if (!patientId) throw new Error('Patient Id required for getPatientAddress().');

    const endpoint = `${APIEndpoints.Patients}(${patientId})`;
    const query = new Query().expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');
    
    try {

      await this.api.getOdata(endpoint).executeQuery(query).then((res) => {

        // Verify response and existing addresses
        if (!(res as any).result || (res as any).result[0].XrefAddressPatients.length <= 0) {
          if (!addressId) console.log(`No Xref found for patient at endpoint: ${endpoint}`);
          else this.getAddressFromId(addressId); // If no Xref, fetch address from patient's addressId
          return;
        }

        // Set address
        const patient = (res as any).result[0]; console.log('Patient', patient);
        const addresses = patient.XrefAddressPatients; console.log('Addresses', addresses);
        const homeAddress = addresses.find((address: any) => address.Address.AddressType === 2); console.log('Home Address', homeAddress);
        this.patientAddress.set(homeAddress.Address ?? addresses[0].Address);
        this.addressFormComp.address = this.patientAddress;

        // Return address
        return this.patientAddress();
      });
      console.log('Patient Address', this.patientAddress());
      // Return address
      return this.patientAddress();

    } catch (error) {
      console.error('Error fetching patient address:', error);
      this.toast.showError('Error fetching patient address');
      return error;
    }
  }

  // Fetches address from id
  async getAddressFromId(addressId: number) {
    if (!addressId) throw new Error('Address Id required for getAddressFromId().');
    
    try {
      const endpoint = `${APIEndpoints.Addresses}(${addressId})`;
      const query = new Query().select('Id,AddressType,Address1,Address2,City,State,Zip');
      return await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
        // Verify response
        if (!(res as any).result || !(res as any).result[0]) throw new Error(`No address found at endpoint: ${endpoint}`);

        const address = (res as any).result[0];
        this.patientAddress.set(address);
        return address;
      });
      
    } catch (error) {
      console.error('Error fetching patient address:', error);
      this.toast.showError('Error fetching patient address');
      return error;
    }
  }

  onLanguagesCreated(args: any) {
    this.languagesQuery = new Query().select('Id,Description');
    this.languages = this.api.getOdata(APIEndpoints.Languages)
  }

  onDobChange(args: any) {
    args.value = new Date(args.value).toISOString().split('T')[0];
    this.patientForm.get('Dob')?.setValue(args.value);
  }

  onDobFocus(args: FocusEventArgs): void {
    this.dobComp.show();
  }
}
