<div id="add-user-role" [class]="formClasses.formContainer">

    <!-- <form #addUserRoleFrom id="add-user-role-form" [formGroup]="addRoleForm" #formDir="ngForm" class="row d-flex flex-column" novalidate="" (ngSubmit)="addRoleSubmit()">

        <div class="form-group required">
            <label for="check">New Role Title</label>
            <div class="input-container">
                <ejs-textbox id="check" formControlName="addRoleForm_RoleName"></ejs-textbox>
                <div *ngIf="addRoleForm.get('addRoleForm_RoleName')?.errors?.['required'] && addRoleForm.get('addRoleForm_RoleName')?.touched" class="e-error">
                    <span>Required</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>New Role Description</label>
            <div class="input-container">
                <ejs-textbox [multiline]="true" formControlName="addRoleForm_Description"></ejs-textbox>
            </div>
        </div>

        <div class="form-group required">
            <label>New Role Rights</label>
            <div class="input-container">
                <ejs-multiselect #addRoleFormRightsMultiSelect 
                    id="multiselect-rights-add" 
                    formControlName="addRoleForm_Rights"  
                    [dataSource]="rightsListData" 
                    [fields]="rightsFields"
                    [showDropDownIcon]="true"
                    placeholder="Select">
                </ejs-multiselect>
                <div *ngIf="addRoleForm.get('addRoleForm_Rights')?.errors?.['required'] && addRoleForm.get('addRoleForm_Rights')?.touched" class="e-error">
                    <span>Required</span>
                </div>
            </div>
        </div>

        <div class="form-group form-submission">
            <div class="row">
                <div class="col-6">
                    <button ejs-button id="resetbtn" type="reset" cssClass="e-outline">Clear</button>
                </div>
                <div class="col-6">
                    <button ejs-button id="validateSubmit" type="submit">Add Role</button>
                </div>
            </div>
        </div>

    </form> -->

    
    <h2 class="form-title"><fa-icon [icon]="addRoleIcons.title" /> Add New Role</h2>

    <form [formGroup]="addRoleForm" [class]="formClasses.form" (ngSubmit)="addRoleSubmit()">

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' contact-section'">
            
            <div [class]="formClasses.group">

                <div [class]="formClasses.inputContainer" [class.error]="addRoleForm.get('addRoleForm_RoleName')?.errors && addRoleForm.get('addRoleForm_RoleName')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addRoleIcons.name" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="addRoleForm_RoleName" placeholder="New Role Title" />
                    <ng-container *ngIf="addRoleForm.get('addRoleForm_RoleName')?.invalid && addRoleForm.get('addRoleForm_RoleName')?.touched">
                        <span [class]="formClasses.error">This field is required</span>
                    </ng-container>
                </div>

                <div [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addRoleIcons.description" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="addRoleForm_Description" placeholder="New Role Description" [multiline]="true" />
                </div>

                <div [class]="formClasses.inputContainer" [class.error]="addRoleForm.get('addRoleForm_Rights')?.errors && addRoleForm.get('addRoleForm_Rights')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addRoleIcons.rights" />
                    </span>
                    <ejs-multiselect formControlName="addRoleForm_Rights"
                        [class]="formClasses.input"
                        [dataSource]="rightsListData" 
                        [fields]="rightsFields"
                        [showDropDownIcon]="true"
                        placeholder="Select">
                    </ejs-multiselect>
                    <ng-container *ngIf="addRoleForm.get('addRoleForm_Rights')?.invalid && addRoleForm.get('addRoleForm_Rights')?.touched">
                        <span [class]="formClasses.error">This field is required</span>
                    </ng-container>
                </div>
            </div>
        </section>
    </form>
</div>