<div id="box-sign" class="p-4">
    <!-- Form section -->
    <div class="sign-form-section">
        <form [formGroup]="boxSignForm" (submit)="submitForm($event)">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <!-- Left column for Template  -->
                        <div class="col-md-3">
                            <h5 class="card-title">Sign Template</h5>
                            <div class="mb-3">
                                <ejs-dropdownlist #signTemplates
                                    [dataSource]="templatesList" 
                                    [fields]="{ value: 'id', text: 'templateName' }" 
                                    (beforeOpen)="noRecordSpinner($event)" 
                                    (change)="emitDropDownSelect($event)"
                                    placeholder="Select a template or create custom email"
                                    class="w-100">
                                    <ng-template #noRecordsTemplate>
                                        <div id="no-record" class="p-3"></div>
                                    </ng-template>
                                </ejs-dropdownlist>
                            </div>
                        </div>

                        <!-- Right column for Subject and Body -->
                        <div class="col-md-8">
                            <h5 class="card-title">Email Subject</h5>
                            <div class="mb-3">
                                <ejs-textbox #emailSubject
                                    [value]="selectedTemplate().emailSubject"
                                    placeholder="Enter email subject"
                                    class="w-100">
                                </ejs-textbox>
                            </div>

                            <h5 class="card-title">Email Body</h5>
                            <app-rich-text-editor [(content)]="selectedTemplate().emailBody"></app-rich-text-editor>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Additional Details Card (Only shows if template selected) -->
            <ng-container *ngIf="selectedTemplateInfo().boxSignTags && selectedTemplateInfo().boxSignTags.length > 0">
                <div class="card mb-4">
                    <div class="card-body">
                        <h5 class="card-title mb-4">Additional Details</h5>
                        <div class="selected-sign-request" formGroupName="requestData">
                            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
                                <ng-container *ngFor="let tag of selectedTemplateInfo().boxSignTags">
                                    <div class="col">
                                        <div class="form-group">
                                            <label class="form-label small mb-1">{{tag.tagFormLabel}}</label>
                                            <ng-container [ngSwitch]="true">
                                                <ng-container *ngSwitchCase="tag.tagId === 'file_number'">
                                                    <ejs-textbox 
                                                        [value]="caseNumber"
                                                        [enabled]="false" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'client_phone_number'">
                                                    <ejs-maskedtextbox 
                                                        formControlName="{{tag.tagId}}" 
                                                        mask='000-000-0000' 
                                                        [placeholder]="tag.textValue" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'client_dob' || tag.tagId === 'client_dol' || tag.tagId === 'date_of_authorization'">
                                                    <ejs-datepicker 
                                                        formControlName="{{tag.tagId}}"
                                                        [placeholder]="tag.textValue" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'provider'">
                                                    <ejs-dropdownlist
                                                        formControlName="{{tag.tagId}}"
                                                        [dataSource]="providersList" 
                                                        [placeholder]="tag.textValue" 
                                                        [fields]="{ id: 'Id', value: 'Id', text: 'Name' }"
                                                        (beforeOpen)="getProviders()"
                                                        >
                                                    </ejs-dropdownlist>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'provider_contact'">
                                                    <ejs-dropdownlist
                                                        formControlName="{{tag.tagId}}"
                                                        [dataSource]="contactsList" 
                                                        [placeholder]="tag.textValue" 
                                                        [fields]="{ id: 'Id', value: 'Id', text: 'ContactName' }"
                                                        (beforeOpen)="getContacts()" />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'client_state'">
                                                    <ejs-dropdownlist
                                                        formControlName="{{tag.tagId}}"
                                                        [dataSource]="statesList" 
                                                        [placeholder]="tag.textValue" 
                                                        [fields]="{ id: 'id', value: 'label', text: 'key' }"
                                                        (beforeOpen)="getStates()" />
                                                    
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'case_manager'">
                                                    <ejs-dropdownlist
                                                        formControlName="{{tag.tagId}}"
                                                        [dataSource]="contactsList" 
                                                        [placeholder]="tag.textValue" 
                                                        [fields]="{ id: 'Id', value: 'Id', text: 'ContactName' }"
                                                        (beforeOpen)="getContacts()" 
                                                        />
                                                </ng-container>
                                                <ng-container *ngSwitchCase="tag.tagId === 'amount_authorized'">
                                                    <ejs-numerictextbox
                                                        formControlName="{{tag.tagId}}"
                                                        [placeholder]="tag.textValue"/>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <ejs-textbox 
                                                        formControlName="{{tag.tagId}}" 
                                                        [placeholder]="tag.textValue" />
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Action Buttons -->
            <div class="d-flex gap-3 justify-content-end mb-4">
                <button ejs-button [isPrimary]="false" cssClass="e-outline" (click)="clearForm()">
                    <i class="fas fa-times me-2"></i>Clear
                </button>
                <button ejs-button [isPrimary]="true">
                    <i class="fas fa-paper-plane me-2"></i>Send
                </button>
            </div>
        </form>
    </div>

    <!-- Grid section - separated with margin -->
    <div class="sign-grid-section mt-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title mb-4">Sign Requests</h5>
                
                <div class="grid-container position-relative">
                    <loading-overlay *ngIf="requestsGridLoading" [text]="'Loading sign requests...'"></loading-overlay>
                    <grid-template *ngIf="!requestsGridLoading" [settings]="requestsGrid"></grid-template>
                </div>
            </div>
        </div>
    </div>
    
    <loading-overlay *ngIf="loadingData()" [loading]="loadingData"></loading-overlay>
</div>