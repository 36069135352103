import { Component, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { AccountDetailsComponent } from '../../../shared/account-details/account-details.component';
import { ActivatedRoute } from '@angular/router';
import { SpecialInstructionsTabComponent } from '../../../shared/account-details/tabs/special-instructions-tab/special-instructions-tab.component';
import { ContactsTabComponent } from '../../../shared/account-details/tabs/contacts-tab/contacts-tab.component';
import { TabItem } from '../../../shared/account-details/tabs/tabs.interface';
import { ProviderStore } from '../data/provider.store';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { Subject } from 'rxjs';
import { LoadingSpinnerComponent } from "../../../../shared/components/loading-spinner/loading-spinner.component";
import { Query } from '@syncfusion/ej2-data';
import { LoadingModule } from "../../../../shared/modules/loading.module";
import { FormGroup } from '@angular/forms';
import { providerForm } from '@root/src/app/shared/constants/form-groups/provider';
import { formatBoolean } from '@root/src/app/utils';
import { providerQuery } from '@root/src/app/shared/queries/provider-queries';

@Component({
  selector: 'app-provider-details',
  standalone: true,
  imports: [
    CommonModule,
    BrowserModule,
    TabModule,
    AccordionModule,
    AccountDetailsComponent,
    LoadingSpinnerComponent,
    LoadingModule
],
  templateUrl: './provider-details.component.html',
  styleUrl: './provider-details.component.scss',
  providers: [DatePipe, ProviderStore]
})
export class ProviderDetailsComponent {
  providerDetailsData: any;  
  expandableDetails: Array<{ label: string, value: any }> = [];
  isLoading = true;
  providerDetails: Array<Array<{ label: string, value: any }>> = [];
  private route: ActivatedRoute;
  public APIEndpoints = APIEndpoints;
  private destroy$ = new Subject<void>();
  providerForm: FormGroup = providerForm; 

  tabs: TabItem[] = [
    { 
      id: 'contacts', 
      text: 'Contacts',
      component: ContactsTabComponent
    },
    { 
      id: 'specialInstructions', 
      text: 'Special Instructions',
      component: SpecialInstructionsTabComponent
    }
  ];

  constructor(
    private providerStore: ProviderStore,
    route: ActivatedRoute
  ) {
    this.route = route;
  }

  ngOnInit() {
    const query = providerQuery;     

    this.route.params.subscribe(params => {
      const id = parseInt(params['id']);
      this.providerStore.fetchProvider(id, query);
      
      this.providerStore.getProvider(id).subscribe(provider => {
        if (provider) {
          this.providerDetailsData = provider;
          this.setupProviderDetails();
        }
      });

      this.providerStore.isLoading(id).subscribe(isLoading => {
        this.isLoading = isLoading;
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupProviderDetails() {
    const providerDetailsData = this.providerDetailsData;
    const phone = this.providerDetailsData?.XrefPhoneProviders[0]?.Phone?.PhoneNumber ?? 'N/A';
    const providerTypes = this.providerDetailsData?.XrefProviderProviderTypes
    ?.map((pt: { ProviderType: { Description: any; }; }) => pt.ProviderType?.Description)
    .filter(Boolean)
    .join(', ') ?? 'N/A';
    const address = this.providerDetailsData?.XrefAddressProviders[0]?.Address ?? {};

    this.expandableDetails = [
      { label: 'Name', value: providerDetailsData?.Name ?? 'N/A' },
      { label: 'Fee Schedule', value: providerDetailsData?.FeeScheduleId ?? 'N/A' },
      { label: 'Phone', value: phone ?? 'N/A' },
      { label: 'City', value: address.City ?? 'N/A' },
      { label: 'Care Coordinator', value: providerDetailsData?.CareCoordinator ?? 'N/A' },
      { label: 'Provider Relations Manager', value: providerDetailsData?.ProviderRelationsManager ?? 'N/A' }
    ];

    this.providerDetails = [
      [
        { label: 'Account Type', value: address.ObjectType ?? 'N/A' },
        { label: 'Name', value: providerDetailsData?.Name ?? 'N/A' },
        { label: 'Main Phone', value: phone ?? 'N/A' },
        { label: 'Main Fax', value: providerDetailsData?.FaxId ?? 'N/A' },
      ],
      [
        { label: 'Address', value: address.Address1 ?? '-' },
        { label: 'City', value: address?.City ?? '-' },
        { label: 'State', value: address?.StateNavigation?.Name ?? '-' },
        { label: 'Zip', value: address?.Zip ?? '-' },
        { label: 'County', value: address?.County ?? '-' },
      ],
      [
        { label: 'Provider Type(s)', value: providerTypes  ?? '-' },
        { label: 'Signed Purchase Agreement', value: formatBoolean(providerDetailsData?.SignedPurchaseAgreement) ?? '-' },
        { label: 'Signed HIPAA', value: formatBoolean(providerDetailsData?.SignedHipaa) ?? '-' },
        { label: 'Signed W-9', value: formatBoolean(providerDetailsData?.SignedW9) ?? '-' },
      ],
      [
        { label: 'Reimbursement Rate (%)', value: providerDetailsData?.ReimbursementRate ?? '-' },
        { label: 'Reimbursement Rate Effective', value: providerDetailsData?.ReimbursementRateEffectiveDate ?? '-' },
        { label: 'Tax ID', value: providerDetailsData?.TaxId ?? '-' },
        { label: 'Spanish Speaking Providers', value: formatBoolean(providerDetailsData?.SpanishSpeaking) ?? '-' },
        { label: 'Referring Provider', value: formatBoolean(providerDetailsData?.ReferringProvider)  ?? '-' },
      ],
      [
        { label: 'Provider Holds Own AR', value: formatBoolean(providerDetailsData?.HoldsOwnAr) ?? '-' },
        { label: 'Virtual Provider', value: formatBoolean(providerDetailsData?.VirtualProvider) ?? '-' },
        { label: 'Treatment Limit($)', value: providerDetailsData?.MainFax ?? '-' },
      ],
    ];
  }
}