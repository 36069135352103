<!-- Patient Form Start -->
<div id="patient-form" [class]="formClasses.formContainer">
    <h2 class="form-title"><fa-icon [icon]="patientIcons.form" /> Patient</h2>

    <form [formGroup]="patientForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' patient-section'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('Firstname') && displayFields.includes('Lastname')" 
                    [class]="formClasses.inputContainer" 
                    [class.error]="(patientForm.get('Firstname')?.errors && patientForm.get('Firstname')?.touched) || (patientForm.get('Lastname')?.errors && patientForm.get('Lastname')?.touched)">
                    
                    <div class="row">
                        <div class="col-12 col-sm-6 d-flex align-items-center">
                            <span [class]="formClasses.icon">
                                <fa-icon [icon]="patientIcons.patient" />
                                <ejs-tooltip *ngIf="patientForm.get('Firstname')?.errors && patientForm.get('Firstname')?.touched" [content]="getErrorMessage('Firstname')" />
                            </span>
                            <ejs-textbox [class]="formClasses.input + ' w-100'" formControlName="Firstname" placeholder="First Name" />
                            <ng-container *ngIf="patientForm.get('Firstname')?.invalid && patientForm.get('Firstname')?.touched">
                                <span [class]="formClasses.error">{{errorMessage}}</span>
                            </ng-container>
                        </div>
                        <div class="col-12 col-sm-6">
                            <ejs-textbox [class]="formClasses.input" formControlName="Lastname" placeholder="Last Name" />
                            <ng-container *ngIf="patientForm.get('Lastname')?.invalid && patientForm.get('Lastname')?.touched">
                                <span [class]="formClasses.error">{{errorMessage}}</span>
                            </ng-container>
                        </div>
                    </div>
                    
                </div>

                <div *ngIf="displayFields.includes('Email')" [class]="formClasses.inputContainer" [class.error]="patientForm.get('Email')?.errors && patientForm.get('Email')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="patientIcons.email" />
                        <ejs-tooltip *ngIf="patientForm.get('Email')?.errors && patientForm.get('Email')?.touched" [content]="getErrorMessage('Email')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Email" placeholder="Email" [type]="'email'" />
                    <ng-container *ngIf="patientForm.get('Email')?.invalid && patientForm.get('Email')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('Gender')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="patientIcons.gender" />
                    </span>
                    <ejs-dropdownlist formControlName="Gender" 
                        [placeholder]="'Gender'" 
                        [class]="formClasses.input"
                        [dataSource]="genders"
                        [value]="patientForm.get('Gender')?.value" />
                </div>

                <div *ngIf="displayFields.includes('Dob')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="patientIcons.dob" />
                    </span>
                    <ejs-datepicker formControlName="Dob" #dobComp
                        [placeholder]="'D.O.B'" 
                        [format]="'yyyy-MM-dd'"
                        [class]="formClasses.input"
                        [value]="patientForm.get('Dob')?.value"
                        (focus)="onDobFocus($event)"
                        (change)="onDobChange($event)" />
                </div>

                <div *ngIf="displayFields.includes('Language')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="patientIcons.language" />
                    </span>
                    <ejs-dropdownlist formControlName="Language" 
                        [placeholder]="'Language'" 
                        [class]="formClasses.input"
                        [dataSource]="languages"
                        [query]="languagesQuery"
                        [fields]="{ text: 'Description', value: 'Description', Id: 'Id' }"
                        [value]="patientForm.get('Language')?.value"
                        (created)="onLanguagesCreated($event)" />
                </div>

                <div *ngIf="displayFields.includes('Minor')" [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="patientIcons.minor" />
                    </span>
                    <ejs-checkbox formControlName="Minor" [label]="'Minor'" [labelPosition]="'Before'" />
                </div>

            </div>
    
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' address-section'">
            <!-- Address Form Start -->
            <address-form #addressFormComp [address]="patientAddress" [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" [displayButtons]="false" />
            <!-- Address Form End -->
        </section>
        
        <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
            </div>
        </section>

    </form>

</div>
<!-- Patient Form End -->