// Angular Imports
import { Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';

// 3rd Party Imports
import { GridModel } from '@syncfusion/ej2-grids';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { CommandClickEventArgs } from '@syncfusion/ej2-grids';

// Internal Imports
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { CognitoService } from '@root/src/app/shared/services/auth/cognito.service';
import { Contact, LawFirm } from '@root/src/app/shared/models/data-contracts';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateComponent } from '../grid-template/grid-template.component';
import { toggleActive } from '../helpers';
import { Router } from '@angular/router'; 
import { LawFirmFormComponent } from '../../forms/law-firm-form/law-firm-form.component';
import { BulkEditLawFirmsComponent } from '../../forms/bulk-edit-forms/bulk-edit-lawfirms/bulk-edit-lawfirms.component';

// Component Metadata
@Component({
  selector: 'law-firms-grid',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateModule,
    GridAllModule,
    DialogModule,
    LoadingModule,
    AuditLogsComponent,
    LawFirmFormComponent,
    BulkEditLawFirmsComponent
  ],
  templateUrl: './law-firms-grid.component.html',
  styleUrl: './law-firms-grid.component.scss',
})
export class LawFirmsGridComponent implements OnInit {
  // ViewChild Declarations
  @ViewChild('logsDialog') logsDialog: any;
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  @ViewChild('lawFirmsGrid') lawFirmsGrid!: GridTemplateComponent;
  @ViewChild('addLawFirmDialog', { static: true }) addLawFirmFormDialog!: DialogComponent;
  @ViewChild('editLawFirmDialog', { static: true }) editLawFirmFormDialog!: DialogComponent;
  @ViewChild('bulkEditLawFirmsComponent') bulkEditLawFirmsComponent: BulkEditLawFirmsComponent;

  public editDialogVisibility: boolean = false;


  // Properties
  loadingData: WritableSignal<boolean> = signal(true);
  lawFirmSignal = signal<LawFirm | undefined>(undefined);
  lawFirms: GridModel;
  marketMangers: DataManager;
  caseManagers: DataManager;
  viewLawFirmAlert: boolean = false;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility: boolean = false;
  showActive: boolean = true;
  editLawFirm?: LawFirm;
  addLawFirmDialogVisibility = false;
  editLawFirmDialogVisibility = false;

  // Track selected rows count for toolbar
  private selectedRowCount: number = 0;

  // Button Configurations
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  // Constructor
  constructor(
    private api: ApiService,
    public cognito: CognitoService,
    private router: Router,
    private auditLogService: AuditLogService,
    private toast: ToastMessageService
  ) { }

  // Lifecycle Hooks
  ngOnInit(): void {
    this.getData().then(() => {
      this.api.getOdata(APIEndpoints.Lawfirms).executeQuery(this.query)
      this.lawFirms = {
        dataSource: this.api.getOdata(APIEndpoints.Lawfirms),
        query: this.query,
        editSettings: { allowEditing: false }, 
        toolbar: this.getToolbarItems(),
        columns: [
          { type: 'checkbox', allowFiltering: false },
          { field: 'Id' },
          { field: 'Abbreviation', headerText: 'Abbreviation' },
          { field: 'Name', template: this.nameTemplate },
          { 
            field: 'XrefAddressLawfirms',
            headerText: 'Address',
            valueAccessor: (field: string, data: Object): string[] => this.getAddress1(field, data)
          },
          { 
            field: 'XrefAddressLawfirms',
            headerText: 'City',
            valueAccessor: (field: string, data: Object): string[] => this.getCity(field, data)
          },
          { 
            field: 'XrefAddressLawfirms',
            headerText: 'State',
            valueAccessor: (field: string, data: Object): string[] => this.getState(field, data)
          },
          { 
            field: 'XrefAddressLawfirms',
            headerText: 'Zip',
            valueAccessor: (field: string, data: Object): string[] => this.getZip(field, data)
          },
          { field: 'MarketManager', headerText: 'Market Manager', foreignKeyValue: 'ContactName', foreignKeyField: 'Id', dataSource: this.marketMangers, editType: 'dropdownedit' },
          { field: 'CaseManager', headerText: 'Case Manager', foreignKeyValue: 'ContactName', foreignKeyField: 'Id', dataSource: this.caseManagers, editType: 'dropdownedit' },
          { field: 'Active', headerText: 'Active' },
          { type: 'commands', headerText: 'Actions', commands: [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', title: 'Toggle Active', buttonOption: { cssClass: 'e-flat', iconCss: 'e-icons e-circle-check' } },
            { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } }
          ] },
        ],
        toolbarClick: ($event: any) => this.customToolbarClick($event),
        commandClick: ($event: any) => this.onCommandClick($event),
        rowSelected: () => this.onRowSelected(),
        rowDeselected: () => this.onRowDeselected(),
      };
    });
  }

  query: Query = new Query()
    .expand([
      'CaseManagerNavigation',
      'MarketManagerNavigation',
      'XrefAddressLawfirms($expand=Address($expand=StateNavigation))'
    ])
    .where('Active', 'equal', this.showActive);

  getAddress1(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.Address1 || ''];
  }
    
  getCity(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.City || ''];
  }
    
  getState(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.StateNavigation?.Name || ''];
  }
    
  getZip(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.Zip || ''];
  }

  customToolbarClick(args: any) {
    if (args.item.id === 'showActive') {
      this.showActive = toggleActive(this.lawFirmsGrid, args.item.id, this.showActive, this.query);
    }
    if (args.item.id === 'addLawFirm') {
      this.addLawFirmDialogVisibility = true;
    }
    if (args.item.id === 'bulkEditLawFirms') {
      this.getSelected();
    }
  }

  async getData() {
    const predicate = new Predicate('ContactTitle', 'equal', 'Market Manager')
      .or('ContactTitle', 'equal', 'Case Manager');
  
    const response = await this.api
      .getOdata(APIEndpoints.Contacts)
      .executeQuery(new Query().where(predicate))
      .then((response: any) => response.result);
  
    this.marketMangers = response.filter((contact: Contact) => contact.ContactTitle === 'Market Manager');
    this.caseManagers = response.filter((contact: Contact) => contact.ContactTitle === 'Case Manager');

    this.loadingData.set(false);
  }

  onLawFirmClick(data: any) {
    this.router.navigate(['/law-firm-detail', data.Id]);
  }

  async onCommandClick(args: CommandClickEventArgs) {
    const lawFirm = args.rowData as any;

    if (lawFirm && lawFirm.Id && args.commandColumn?.title === 'Logs') {
      try {
        this.auditLogsLoading = true;
        this.showLogsDialog();
        const logs = await this.auditLogService.getAuditLogs(lawFirm.Id, 'LawFirm');
        
        if (!logs) {
          throw new Error('No audit logs returned from service');
        }

        try {
          this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(logs);
        } catch (formatError) {
          this.closeLogsDialog();
          this.toast.showError('Error formatting audit log data. Please try again.');
          console.error('Error formatting audit logs:', formatError);
          return;
        }
      } catch (error) {
        this.closeLogsDialog();
        this.toast.showError('Failed to load audit logs. Please try again.');
        console.error('Error loading audit logs:', error);
      } finally {
        this.auditLogsLoading = false;
      }
    } else if (args.commandColumn?.type === 'Edit') {
      this.openEditDialog(lawFirm);
    }
  }

  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        throw new Error('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = [];
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  beforeOpening(args: any) {
    try {
      args.maxHeight = '100%';
    } catch (error) {
      this.toast.showError('Error configuring dialog');
      console.error('Error in beforeOpening:', error);
    }
  }

  closeDialog(): void {
    this.editDialogVisibility = false;
  }

  openEditDialog(lawFirm: LawFirm): void {
    this.lawFirmSignal.set(lawFirm);
    this.editDialogVisibility = true;
  }

  private getToolbarItems(): any[] {
    const items = [
      { text: 'Show Inactive', id: 'showActive' },
      { text: 'Add Law Firm', id: 'addLawFirm' }
    ];

    // Only add bulk edit button if more than one row is selected
    if (this.selectedRowCount > 1) {
      items.push({ text: 'Bulk Edit', id: 'bulkEditLawFirms' });
    }

    return items;
  }

  onRowSelected(): void {
    this.selectedRowCount = this.lawFirmsGrid.getSelectedRows().length;
    this.lawFirmsGrid.grid.toolbar = this.getToolbarItems();
  }

  onRowDeselected(): void {
    this.selectedRowCount = this.lawFirmsGrid.getSelectedRows().length;
    this.lawFirmsGrid.grid.toolbar = this.getToolbarItems();
  }

  getSelected() {
    const selectedRecords = this.lawFirmsGrid.getSelectedRows() as LawFirm[];
    if (selectedRecords && selectedRecords.length > 1) {
      this.bulkEditLawFirmsComponent.selectedRows = selectedRecords;
      this.bulkEditLawFirmsComponent.showModal();
    } else {
      this.toast.showWarning('Please select at least two law firms to bulk edit.');
    }
  }
}