import { Component, Input, Output, EventEmitter, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { ListBox } from '@syncfusion/ej2-dropdowns';
import { getInstance } from '@syncfusion/ej2-base';

@Component({
  selector: 'custom-assignments-editor',
  standalone: true,
  imports: [
    CommonModule,
    ListBoxAllModule
  ],
  templateUrl: './custom-assignments-editor.component.html',
  styleUrls: ['./custom-assignments-editor.component.scss']
})

export class CustomAssignmentsEditorComponent {

  
  @Input() availableItems: any[] = [];
  @Input() assignedItems: WritableSignal<any[]>;
  @Output() assignedItemsChange = new EventEmitter<any[]>();

  @ViewChild('assignedList', { static: true }) assignedListBox!: ListBox;

  fields: object = { text: 'Name', value: 'Id' };
  
  toolbar = { 
    items: [
      'moveTo',
      'moveFrom',
    ]
  };

  onAvailableFilterChange(event: Event): void {
    const listBox = getInstance(document.getElementById("availableListbox") as HTMLElement, ListBox) as ListBox;
    if (listBox) {
      listBox.filter(
        this.availableItems, 
        new Query().where('Name', 'contains', (event.target as HTMLInputElement).value, true)
      );
    }
  }

  onAssignedFilterChange(event: Event): void {
    const listBox = getInstance(document.getElementById("assignedListbox") as HTMLElement, ListBox) as ListBox;
    if (listBox) {
      listBox.filter(
        this.assignedItems() || [], 
        new Query().where('Name', 'contains', (event.target as HTMLInputElement).value, true)
      );
    }
  }

  onAssignedChange(args: any): void {
    this.assignedItemsChange.emit(args);
  }
}
