export const languages: string[] = [
    'English', 
    'Spanish', 
    'French', 
    'German', 
    'Russian', 
    'Bi-Lingual - English/Spanish', 
    'Other'
];

export const states: object[] = [
    { key: "Alabama", label: "AL", id: 1 },
    { key: "Alaska", label: "AK", id: 2 },
    { key: "Arizona", label: "AZ", id: 3 },
    { key: "Arkansas", label: "AR", id: 4 },
    { key: "California", label: "CA", id: 5 },
    { key: "Colorado", label: "CO", id: 6 },
    { key: "Connecticut", label: "CT", id: 7 },
    { key: "Delaware", label: "DE", id: 8 },
    { key: "Florida", label: "FL", id: 9 },
    { key: "Georgia", label: "GA", id: 10 },
    { key: "Hawaii", label: "HI", id: 11 },
    { key: "Idaho", label: "ID", id: 12 },
    { key: "Illinois", label: "IL", id: 13 },
    { key: "Indiana", label: "IN", id: 14 },
    { key: "Iowa", label: "IA", id: 15 },
    { key: "Kansas", label: "KS", id: 16 },
    { key: "Kentucky", label: "KY", id: 17 },
    { key: "Louisiana", label: "LA", id: 18 },
    { key: "Maine", label: "ME", id: 19 },
    { key: "Maryland", label: "MD", id: 20 },
    { key: "Massachusetts", label: "MA", id: 21 },
    { key: "Michigan", label: "MI", id: 22 },
    { key: "Minnesota", label: "MN", id: 23 },
    { key: "Mississippi", label: "MS", id: 24 },
    { key: "Missouri", label: "MO", id: 25 },
    { key: "Montana", label: "MT", id: 26 },
    { key: "Nebraska", label: "NE", id: 27 },
    { key: "Nevada", label: "NV", id: 28 },
    { key: "New Hampshire", label: "NH", id: 29 },
    { key: "New Jersey", label: "NJ", id: 30 },
    { key: "New Mexico", label: "NM", id: 31 },
    { key: "New York", label: "NY", id: 32 },
    { key: "North Carolina", label: "NC", id: 33 },
    { key: "North Dakota", label: "ND", id: 34 },
    { key: "Ohio", label: "OH", id: 35 },
    { key: "Oklahoma", label: "OK", id: 36 },
    { key: "Oregon", label: "OR", id: 37 },
    { key: "Pennsylvania", label: "PA", id: 38 },
    { key: "Rhode Island", label: "RI", id: 39 },
    { key: "South Carolina", label: "SC", id: 40 },
    { key: "South Dakota", label: "SD", id: 41 },
    { key: "Tennessee", label: "TN", id: 42 },
    { key: "Texas", label: "TX", id: 43 },
    { key: "Utah", label: "UT", id: 44 },
    { key: "Vermont", label: "VT", id: 45 },
    { key: "Virginia", label: "VA", id: 46 },
    { key: "Washington", label: "WA", id: 47 },
    { key: "West Virginia", label: "WV", id: 48 },
    { key: "Wisconsin", label: "WI", id: 49 },
    { key: "Wyoming", label: "WY", id: 50 }
];

export const genders: string[] = [
    'Male', 
    'Female', 
    'Transgender', 
    'Non-Binary'
];

export interface keyValuePair {
    [key: string]: Object
}

export const caseFileCurrentStatus: object[] = [
    { key: 'Declined Surgery', value: 0} , 
    { key: 'Injection - Complete', value: 1 }, 
    { key: 'Injection - Pending', value: 2 }, 
    { key: 'Injection Eval - Complete', value: 3 }, 
    { key: 'Injection Eval - Pending', value: 4 }, 
    { key: 'NR', value: 5 }, 
    { key: 'Pending Surgery', valye: 6 }, 
    { key: 'Pending Surgery Consult', value: 7 }, 
    { key: 'Surgery Completed', value: 8 }, 
    { key: 'Surgery Consult Completed', value: 9 }, 
    { key: 'Waiting - Internal Follow Up', value: 10 }, 
    { key: 'Waiting on Attorney', value: 11 }, 
    { key: 'Waiting on Provider', value: 12 }
];

export const caseFileRating: object[] = [
    { Id: 0, Value: 'Standard' },
    { Id: 1, Value: 'Good' },
    { Id: 2, Value: 'Bad' },
    { Id: 3, Value: 'Delinquent' }
];

export const caseFileTimeFrame: string[] = [
    '0 - 3 months', 
    '4 - 6 months', 
    '7 - 9 months', 
    '10 - 12 months', 
    '+1 year', 
    '+2 years'
];

export const listPAndL: object[] = [
    { key: 'AMF', value: 0 },
    { key: 'Closed - No Activity', value: 1 },
    { key: 'MFS', value: 2 },
    { key: 'MFS Statusing', value: 3 },
    { key: 'Varent', value: 4 },
    { key: 'Write-Off', value: 5 },
    { key: 'WSHC', value: 6 },
];

export const caseFileStatuses: object[] = [
    { key: 'Active', value: 0 },
    { key: 'Archived', value: 1 },
    { key: 'Collections', value: 2 },
    { key: 'Deleted', value: 3 },
    { key: 'Test', value: 4 }
];

export const caseFilesFileGroups: object[] = [
    { key: 'RadiusGs', value: 0 },
    { key: 'Cypress Partners', value: 1 },
    { key: 'MMC', value: 2 },
];

export const caseFilesCaseTypes: string[] = [
    'MVA',
    'PI'
];

export const caseFilesCaseTypeIDs: object[] = [
    { key: 'MVA', value: 0 },
    { key: 'PI', value: 1 }
]

export const referralSources: keyValuePair[] = [
    { label: 'Attorney', group: 'Attorney', value: 1 },
    { label: 'MMC', group: 'Attorney', value: 2 },
    { label: 'Texas Attorney - NOT MMC', group: 'Attorney', value: 3 },
    { label: 'AHW LLC', group: 'Broker', value: 4 },
    { label: 'ARxChange', group: 'Broker', value: 5 },
    { label: 'Ann Gelvin/Ocean Funding Group', group: 'Broker', value: 6 },
    { label: 'CPF', group: 'Broker', value: 7 },
    { label: 'Cypress Partners', group: 'Broker', value: 8 },
    { label: 'IM Paralegal Services, LLC', group: 'Broker', value: 9 },
    { label: 'Lienstar', group: 'Broker', value: 10 },
    { label: 'MDJD', group: 'Broker', value: 3 },
    { label: 'MFS - MedLink Capital', group: 'Broker', value: 11 },
    { label: 'MFS - Surgical Capital', group: 'Broker', value: 12 },
    { label: 'Medical Capital Resources', group: 'Broker', value: 13 },
    { label: 'Norbu Funding', group: 'Broker', value: 14 },
    { label: 'OMG', group: 'Broker', value: 15 },
    { label: 'Terry Consultants', group: 'Broker', value: 16 },
    { label: 'Axcess Accident Center', group: 'None', value: 17 },
    { label: 'FZMDPI, LLC', group: 'None', value: 18 },
    { label: 'Integrity Administrative Solutions, LLC', group: 'None', value: 19 },
    { label: 'National Medication Management', group: 'None', value: 20 },
    { label: 'NorthStar Neurology, PC', group: 'None', value: 21 },
    { label: 'Saguaro Pain Clinic', group: 'None', value: 22 },
    { label: 'TBI Imaging Associates', group: 'None', value: 23 },
    { label: 'Blockbuster 2022', group: 'Other', value: 24 },
    { label: 'Other', group: 'Other', value: 25 },
    { label: 'WSHC Group', group: 'Other', value: 26 },
    { label: '1st Health', group: 'Provider', value: 27 },
    { label: 'AA Orthopedics', group: 'Provider', value: 28 },
    { label: 'Absolute Health', group: 'Provider', value: 29 },
    { label: 'AccuSpine', group: 'Provider', value: 30 },
    { label: 'Affiliated Arm', group: 'Provider', value: 31 },
    { label: 'Alliance Health Partners – CO Spgs', group: 'Provider', value: 32 },
    { label: 'Alpine Behavior Therapy', group: 'Provider', value: 33 },
    { label: 'American Health imaging', group: 'Provider', value: 34 },
    { label: 'Arizona Neurology and Sleep (Dr. Shayya)', group: 'Provider', value: 35 },
    { label: 'Arnsperger Brady DC', group: 'Provider', value: 36 },
    { label: 'Arrowhead Physical Medicine', group: 'Provider', value: 37 },
    { label: 'Arthritis and Pain Centers of America (APCA)', group: 'Provider', value: 38 },
    { label: 'Baker DC', group: 'Provider', value: 39 },
    { label: 'Centeno Schultz Clinic', group: 'Provider', value: 40 },
    { label: 'Chiropractic Rehabilitation Center', group: 'Provider', value: 41 },
    { label: 'Chirowell', group: 'Provider', value: 42 },
    { label: 'Comprehensive Integrated Care', group: 'Provider', value: 43 },
    { label: 'Comprehensive Pain Management', group: 'Provider', value: 44 },
    { label: 'Copper Star Anesthesia', group: 'Provider', value: 45 },
    { label: 'Denver Diagnostic Pain', group: 'Provider', value: 46 },
    { label: 'Desert Hand and Physical Therapy', group: 'Provider', value: 47 },
    { label: 'Dr. Robert Bess', group: 'Provider', value: 48 },
    { label: 'East Valley Neuro (Dr. Andalkar)', group: 'Provider', value: 49 },
    { label: 'Expert Spine', group: 'Provider', value: 50 },
    { label: 'FXRX', group: 'Provider', value: 51 },
    { label: 'First Choice', group: 'Provider', value: 52 },
    { label: 'Fitzmaurice Hand Institute', group: 'Provider', value: 53 },
    { label: 'Fort Collins Physical Therapy', group: 'Provider', value: 54 },
    { label: 'Foundation Chiropractic', group: 'Provider', value: 55 },
    { label: 'Gateway Pain', group: 'Provider', value: 56 },
    { label: 'Harding Place Medical', group: 'Provider', value: 57 },
    { label: 'Injury and Health Institute', group: 'Provider', value: 58 },
    { label: 'LESSpine', group: 'Provider', value: 59 },
    { label: 'LLJ Legal &amp; Medical Solutions', group: 'Provider', value: 60 },
    { label: 'Legacy Brain &amp; Spine', group: 'Provider', value: 61 },
    { label: 'Northstar Neurology', group: 'Provider', value: 62 },
    { label: 'Optima Rehab', group: 'Provider', value: 63 },
    { label: 'Outpatient Diagnostic Center', group: 'Provider', value: 64 },
    { label: 'Peachtree Orthopedics', group: 'Provider', value: 65 },
    { label: 'Phoenix Spine', group: 'Provider', value: 66 },
    { label: 'Pine Creek Vision Clinic', group: 'Provider', value: 67 },
    { label: 'Post-Surgery Care Of Atlanta, LLC.', group: 'Provider', value: 68 },
    { label: 'Precision Medical', group: 'Provider', value: 69 },
    { label: 'Rebound Sports &amp; Physical', group: 'Provider', value: 70 },
    { label: 'Results Physiotherapy', group: 'Provider', value: 71 },
    { label: 'Rocky Mountain Family Physicians', group: 'Provider', value: 72 },
    { label: 'Royal Spine', group: 'Provider', value: 73 },
    { label: 'SAMPLE Ormond Physical Therapy', group: 'Provider', value: 74 },
    { label: 'Safeway Psychological Services', group: 'Provider', value: 75 },
    { label: 'Scott Chiropractic', group: 'Provider', value: 76 },
    { label: 'SonoSpine', group: 'Provider', value: 77 },
    { label: 'Spinal Rehab Chiropractic', group: 'Provider', value: 78 },
    { label: 'Spokane Spine Center', group: 'Provider', value: 79 },
    { label: 'Stamp Medical', group: 'Provider', value: 80 },
    { label: 'Starting Point Rehabilitation', group: 'Provider', value: 81 },
    { label: 'Stitches Urgent Care', group: 'Provider', value: 82 },
    { label: 'T&amp;GR VENTURES LLC DBA PI MENTAL HEALTH SERVICES (V)', group: 'Provider', value: 83 },
    { label: 'Therahand Wellness', group: 'Provider', value: 84 },
    { label: 'Towery Chiropractic', group: 'Provider', value: 85 },
    { label: 'Trauma Treatment Services', group: 'Provider', value: 86 },
    { label: 'Upright MRI of New Mexico', group: 'Provider', value: 87 },
    { label: 'Valdosta Orthopedic', group: 'Provider', value: 88 },
    { label: 'Vision Transformations', group: 'Provider', value: 89 },
    { label: 'Willamette Pain &amp; Spine Center', group: 'Provider', value: 90 },
    { label: 'Workplace Wellness LLC', group: 'Provider', value: 91 },
    { label: 'allthingsneuro', group: 'Provider', value: 92 }
];

export const countryCodes = [
    { id: 0, code: '+1', description: 'United States', icon: 'fi fi-us', group: 'english' },
    { id: 1, code: '+1', description: 'Canada', icon: 'fi fi-ca', group: 'english' },
    { id: 2, code: '+44', description: 'United Kingdom', icon: 'fi fi-gb', group: 'english' },
    { id: 3, code: '+93', description: 'Afghanistan', icon: 'fi fi-af', group: 'non-english' },
    { id: 4, code: '+355', description: 'Albania', icon: 'fi fi-al', group: 'non-english' },
    { id: 5, code: '+213', description: 'Algeria', icon: 'fi fi-dz', group: 'non-english' }
];

export const insuranceTypes = [
    { id: 0, description: 'Liability' },
    { id: 1, description: 'MedPay / PIP' },
    { id: 2, description: 'UM / UIM' }
];

export const policyLimits = [
    '3,000',
    '5,000',
    '10,000',
    '15,000',
    '20,000',
    '25,000',
    '30,000',
    '50,000',
    '100,000',
    '125,000',
    '150,000',
    '200,000',
    '250,000',
    '300,000',
    '500,000',
    '750,000',
    '1,000,000',
    'Commercial',
    'Unknown',
];

export const insuranceCompanies = [
    "1st Star",
    "21st Century Insurance",
    "AAA Insurance",
    "AARP Midicare Complete",
    "Absolute Insurance Absolute Insurance",
    "ACCC General Insurance ACCC General Insurance",
    "Acceptance Insurance Acceptance Insurance",
    "Ace American Ins. & Sedgewick Claims Ace American Ins. & Sedgewick Claims",
    "Ace North America Ace North America",
    "Acuity Acuity",
    "Advance Insurance Co Advance Insurance Co",
    "AIFA Insurance Co AIFA Insurance Co",
    "AIG Insurance AIG Insurance",
    "Alliance United Insurance Co Alliance United Insurance Co",
    "Allied/Nationwide Insurance Company Allied/Nationwide Insurance Company",
    "Allstate Allstate",
    "Alpha Property Alpha Property",
    "American Access American Access",
    "American Alternative Insurance American Alternative Insurance",
    "American Country Insurance American Country Insurance",
    "American Family American Family",
    "American National American National",
    "AmeriClaim AmeriClaim",
    "Ameriprise Ameriprise",
    "Amica Amica",
    "ANPAC ANPAC",
    "AON Insurance AON Insurance",
    "Arbella Protection Arbella Protection",
    "Arch Arch",
    "Arizona Municipal Risk Retention Pool Arizona Municipal Risk Retention Pool",
    "Arizona Risk Management Arizona Risk Management",
    "Arizona School Risk Retention Trust Arizona School Risk Retention Trust",
    "Arrowhead General Insurance Arrowhead General Insurance",
    "Ascension Insurance Services, Inc. Ascension Insurance Services, Inc.",
    "Asian American General Insurance Asian American General Insurance",
    "Austin Mutual Austin Mutual",
    "Auto Claims Inc Auto Claims Inc",
    "Auto Owners Auto Owners",
    "Avis Avis",
    "Badger Mutual Badger Mutual",
    "Bear River Bear River",
    "Blue Cross Blue Cross",
    "Bristol West Bristol West",
    "Broadspire Insurance Broadspire Insurance",
    "Brotherhood Mutual Insurance Brotherhood Mutual Insurance",
    "C.W. Resse Company C.W. Resse Company",
    "California Casualty California Casualty",
    "CapSpeciality CapSpeciality",
    "Casualty Underwriters Insurance Co. Casualty Underwriters Insurance Co.",
    "Centennial Insurance Centennial Insurance",
    "Central Insurance Company Central Insurance Company",
    "Cherry Creek Nursing Home Cherry Creek Nursing Home",
    "Chubb Group Chubb Group",
    "Cigna Cigna",
    "Cincinnati Insurance Company Cincinnati Insurance Company",
    "City of Phoenix City of Phoenix",
    "CJ Hester, Inc. CJ Hester, Inc.",
    "CMI (Claims Management Inc.) CMI (Claims Management Inc.)",
    "CNA CNA",
    "Coast National Insurance Coast National Insurance",
    "Colorado Casualty Colorado Casualty",
    "Colorado Medicaid Colorado Medicaid",
    "CommonWealth Insurance CommonWealth Insurance",
    "Community Health Plan Community Health Plan",
    "Connect Insurance Connect Insurance",
    "Continental Western Group Continental Western Group",
    "Cornerstone National Insurance Cornerstone National Insurance",
    "Country Financial Insurance Co Country Financial Insurance Co",
    "County Technical Services County Technical Services",
    "CoWest Insurance Group CoWest Insurance Group",
    "CSE Insurance Group CSE Insurance Group",
    "Custards Insurance Custards Insurance",
    "Dairy Autro Dairy Autro",
    "Dairyland Insurance Dairyland Insurance",
    "Denver Health Denver Health",
    "Department of Labor and Industries Department of Labor and Industries",
    "Depositers Insurance Co Depositers Insurance Co",
    "Direct General Insurance CO Direct General Insurance CO",
    "DMA Claims DMA Claims",
    "EC Real Insurance EC Real Insurance",
    "Echelon Property & Casualty Echelon Property & Casualty",
    "Employers Mutual Casualty Employers Mutual Casualty",
    "Encompass Insurance Encompass Insurance",
    "Enterprise Enterprise",
    "Equity Lifestyle Properties Equity Lifestyle Properties",
    "ESIS ESIS",
    "Esurance Esurance",
    "Farm Bureau Farm Bureau",
    "Farmers Insurance Company Farmers Insurance Company",
    "Farmington School Dist Farmington School Dist",
    "Federated Insurance Federated Insurance",
    "FedEx FedEx",
    "Finders Insurance"
];

export const fileIntakeStatuses = [
    'New Intake',
    'Needs Law Firm Setup',
    'File Created',
    'Incomplete',
    'Duplicate'
]

export const USDateformat: object = { type: 'date', format: 'MM/dd/yyyy'};
export const USDateTimeformat: object = { type: 'date', format: 'MM/dd/yyyy HH:mm'};

export const paymentStatusesList: string[] = [
    'Select',
    'Canceled Transaction',
    'GBS',
    'LEGACY WSHC',
    'MFS',
    'OFSG',
    'Pending Approval',
    'Pending Approval - Varent',
    'Pending Payment',
    'Pending Payment - Varent',
    'SPV-MFS',
    'SPV-WSHC',
    'Secure Bill/Notes - Pending Approval',
    'Secure Bill/Notes - Pending Payment',
    'Varent - Fund1',
    'WSHC'
];