import { Invoice, Deposit } from "../data-contracts"

export interface PerformanceData {
    TotalBilledCost:  number,
    TotalPaymentReceived:  number,
    ActualSettlementCost:  number,
    BalanceDue:  number,
    AuthorizedAmount:  number,
    DaysOpen:  number | string,
    FullSettlementValue:  number,
    Profit:  number | string,
    NonSOCALInvoices?:  number | string,
    ROIC:  number | string,
    Split2ndPayments:  number,
    XIRR:  number,
    CourtesyReduction:  number,
    CourtesyReductionPercent:  number | string,
    FinalBalanceDueGenerated:  boolean,
    FinalFSV:  number
}

export interface FinancialData {
    Invoices?: Invoice[],
    Deposits?: Deposit[]
}

export const performanceData = {
    TotalBilledCost: 0,
    TotalPaymentReceived: 0,
    ActualSettlementCost: 0,
    BalanceDue: 0,
    AuthorizedAmount: 0,
    DaysOpen: 0,
    FullSettlementValue: 0,
    Profit: 0,
    NonSOCALInvoices: 0,
    ROIC: 0,
    Split2ndPayments: 0,
    XIRR: 0,
    CourtesyReduction: 0,
    CourtesyReductionPercent: 0,
    FinalBalanceDueGenerated: false,
    FinalFSV: 0
  }