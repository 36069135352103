import { Injectable } from "@angular/core";
import { ApiService } from "@root/src/app/shared/services/api/api.service";

@Injectable({
    providedIn: 'root'
  })
  export class BalanceStatementService {
    constructor(private api: ApiService) {}
  
    generateBalanceStatement(CaseFileId: number, Final: boolean, Pandls: string[]): Promise<any> {
      return this.api.basicPost("api/balance-statement/generate", { 
        UserId: localStorage.getItem("userId"), 
        CaseFileId, 
        Preliminary: !Final, 
        Pandls 
      }, false);
    }
  }