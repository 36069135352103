<div [ngClass]="formClasses.formContainer">
  <form [formGroup]="phoneForm" [ngClass]="formClasses.form" (ngSubmit)="onSubmit()">
    <div [ngClass]="formClasses.section">
      <div [ngClass]="formClasses.group">
        <div [ngClass]="formClasses.inputContainer">
          <ejs-maskedtextbox id="phoneNumber" 
            formControlName="PhoneNumber" 
            [ngClass]="formClasses.input" 
            [mask]="'(999) 999-9999'">
          </ejs-maskedtextbox>
          <fa-icon [icon]="phoneIcons.phoneNumber" [ngClass]="formClasses.icon"></fa-icon>
          <!-- <div *ngIf="errorMessages().PhoneNumber" [ngClass]="formClasses.error">{{ errorMessages().PhoneNumber }}</div> -->
        </div>
      </div>
      <div [ngClass]="formClasses.group">
        <div [ngClass]="formClasses.inputContainer">
          <label [ngClass]="formClasses.label" for="phoneType">Phone Type</label>
          <div class="input-with-icon">
            <ejs-dropdownlist id="phoneType" 
              [placeholder]="'Select Phone Type'"
              formControlName="PhoneType" 
              [dataSource]="phoneTypes" 
              [fields]="{ text: 'description', value: 'id' }">
            </ejs-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="displayButtons" [ngClass]="formClasses.actions">
      <button ejs-button type="submit" [disabled]="!formState().isValid">Submit</button>
    </div>
  </form>
</div>