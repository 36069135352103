// Angular
import { Component, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
// 3rd PartY
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';
import { GridModule } from '@syncfusion/ej2-angular-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Appointment, CaseFile, Invoice } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@services/file-hub/file-hub.service';
import { GridTemplateModule } from "@modules/grid-template.module";
import { LoadingModule } from '@modules/loading.module';
import { TabCardComponent } from '@fileHub-tabs/tab-card/tab-card.component';

export interface InvoiceSummary {
  Id?: number,
  ProviderName?: string | null,
  DOS?: Date | string | null,
  AmountBilled?: number | null,
  AmountPaid?: number | null
}

interface CountData {
  Active: number;
  Total: number;
}

@Component({
  selector: 'home-tab',
  standalone: true,
  templateUrl: './home-tab.component.html',
  styleUrl: './home-tab.component.scss',
  imports: [
    DashboardLayoutModule,
    GridModule,
    GridTemplateModule,
    TabCardComponent,
    LoadingModule,
    CommonModule,
  ],
  encapsulation: ViewEncapsulation.None
})
export class HomeTab {
  // Count data
  workItemsCountData: CountData = { Active: 0, Total: 0 };
  appointmentsCountData: CountData = { Active: 0, Total: 0 };
  invoicesCountData: { Providers: number; Total: number } = { Providers: 0, Total: 0 };

  // Loading state
  loadingHomeTabData: WritableSignal<boolean> = signal(false);

  // Grid settings
  recentActivityGrid: GridModel = {
    dataSource: [],
    columns: []
  };
  invoiceSummaryGrid: GridModel = {
    dataSource: [],
    columns: []
  };
  invoiceSummaryGridData: InvoiceSummary[] = [];
  financialSummaryData: any;
  
  constructor(
    private api: ApiService,
    private fileHub: FileHubService
  ) {}

  ngAfterViewInit() {
    this.checkData();
  }

  // Calls all other functions in order to properly ensure data is set up and ready.
  async checkData() {
    if (!this.fileHub.caseFile) {
      this.fileHub.showNoCaseFileError('Unable to load dashboard data.');
    } else {
      this.loadingHomeTabData.set(true);
      await this.addHomeTabDataToCaseFile(); // await ensures this function resolves first
      this.setRecentActivityGrid();
      this.setInvoiceSummaryGrid();
      this.setInvoicesCountData(this.fileHub.caseFile.Invoices as Invoice[]); 
      this.setInvoiceSummaryData(this.fileHub.caseFile.Invoices as Invoice[]);
      this.setAppointmentsCountData(this.fileHub.caseFile.Appointments as Appointment[]);
      this.setWorkItemsCountData(this.fileHub.caseFile.XrefWorkItemCaseFiles);
      this.loadingHomeTabData.set(false);
    }
  }

  async addHomeTabDataToCaseFile() {
    const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
    const expandString = 'Invoices($expand=InvoiceRows,InvoicePayments,Provider),Appointments,XrefWorkItemCaseFiles($expand=WorkItem)';
    const selectString = 'Invoices,Appointments,XrefWorkItemCaseFiles';
    const homeTabQuery = new Query().expand(expandString).select(selectString);

    return await this.api.getOdata(endpoint).executeQuery(homeTabQuery).then((res: any) => {
      const file = res.result[0] as CaseFile;
      if (!this.fileHub.caseFile?.Invoices) this.fileHub.updateCaseFile('Invoices', file.Invoices); 
      if (!this.fileHub.caseFile?.Appointments) this.fileHub.updateCaseFile('Appointments', file.Appointments);
      if (!this.fileHub.caseFile?.XrefWorkItemCaseFiles) this.fileHub.updateCaseFile('XrefWorkItemCaseFiles', file.XrefWorkItemCaseFiles);
      return;
    });
  }

  setRecentActivityGrid() {
    this.recentActivityGrid = {
      dataSource: this.fileHub.caseFile ? this.api.getOdata(`${APIEndpoints.Casefiles}(${this.fileHub.caseFile.Id})/RecentActivity`)
      .executeQuery(new Query()
      .select('Category,Action,ModifiedBy,ModifiedDate')
      .sortBy('ModifiedDate', 'descending')
      .take(15)) : [],
      columns: [
        { field: 'Category', headerText: 'Category' },
        { field: 'Action', headerText: 'Actions' },
        { field: 'ModifiedBy', headerText: 'Modified By' },
        { field: 'ModifiedDate', headerText: 'Modified Date', isPrimaryKey: true },
      ]
    };
  }

  setInvoiceSummaryGrid() {
      this.invoiceSummaryGrid = {
      dataSource: this.fileHub.caseFile && this.fileHub.caseFile.Invoices ? this.setInvoiceSummaryData(this.fileHub.caseFile.Invoices) : [],
      columns: [
        { field: 'Id' },
        { field: 'ProviderName', headerText: 'Provider' },
        { field: 'DOS', headerText: 'Date of Service' },
        { field: 'AmountBilled', headerText: 'Billed', format: 'C2', editType: 'numeric' },
        { field: 'AmountPaid', headerText: 'Paid', format: 'C2', editType: 'numeric' }
      ]
    }
  }

  setInvoicesCountData(invoices: Invoice[]) {
    const providerIds = new Set<number>(); // A Set can hold any unique values for Provider Ids

    // Add  providerIds to Set so they can easily be counted
    invoices.forEach((invoice: any) => {
      if (invoice.Provider?.Id != null) {
        providerIds.add(invoice.Provider.Id);
      }
    });

    this.invoicesCountData = {
      Total: invoices.length,
      Providers: providerIds.size
    }
  }

  setAppointmentsCountData(appointments: any[]) {
    this.appointmentsCountData = {
      Active: appointments.filter((appointment: any) => appointment.IsCompleted !== true).length,
      Total: appointments.length
    }
  }

  setInvoiceSummaryData(invoices: Invoice[]) {
    return invoices.map((invoice: Invoice) => {
      return {
        Id: invoice.Id,
        ProviderName: invoice.Provider?.Name,
        DOS: invoice.InvoiceDate,
        AmountBilled: invoice.InvoiceRows?.reduce((sum, row) => sum + (row?.AmountBilled as number), 0) || 0,
        AmountPaid: invoice.InvoicePayments?.reduce((sum, payment) => sum + (payment?.AmountPaid as number), 0) || 0,
      };
    });
  }

  setWorkItemsCountData(xrefWorkItems: any[] | null | undefined) {
    if (!xrefWorkItems || !Array.isArray(xrefWorkItems)) {
      this.workItemsCountData = { Active: 0, Total: 0 };
      return;
    }

    const workItems = xrefWorkItems.map(xref => xref.WorkItem);
    const total = workItems.length;
    const active = workItems.filter(item => item.Status !== 'Completed').length;

    this.workItemsCountData = { Active: active, Total: total };
  }
}
