<div *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</div>

<app-account-details
  *ngIf="providerDetailsData"
  [entityType]="APIEndpoints.Providers"
  [entityId]="providerDetailsData.Id"
  [payload]="providerDetailsData"
  [expandableDetails]="expandableDetails"
  [details]="providerDetails"
  [tabs]="tabs"
  [isLoading]="isLoading" 
  >
</app-account-details>
