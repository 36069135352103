import { CommonModule } from '@angular/common';
import { Component, Input, Provider, signal, ViewChild, WritableSignal } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { TabItem } from './tabs/tabs.interface';
import { AccordionModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { LawFirmFormComponent } from '../../forms/law-firm-form/law-firm-form.component';
import { LawFirm } from '@root/src/app/shared/models/data-contracts';
import { ProviderFormComponent } from "../../forms/provider-form/provider-form.component";


interface Detail {
  label: string;
  value: string;
}

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    CommonModule,
    BrowserModule,
    TabModule,
    AccordionModule,
    DialogModule,
    ButtonModule,
    LawFirmFormComponent,
    ProviderFormComponent,
],
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  
})
export class AccountDetailsComponent {
  @Input() expandableDetails: Detail[] = [];
  @Input() details: Detail[][] = [];
  @Input() tabs: TabItem[] = [];
  @Input() isLoading: boolean = false;
  @Input() entityType: APIEndpoints;
  @Input() entityId!: number;
  @Input() payload: any;
  

  constructor(  
  
  ) { }
  
  editEndpoint = '';

  isExpanded: boolean = true;
  @ViewChild('editDialog') editDialog!: DialogComponent;

  public editLawFirmDialogVisibility: boolean = false;
  public editProviderDialogVisibility: boolean = false;

  lawFirmSignal = signal<LawFirm | undefined>(undefined);
  providerSignal = signal<Provider | undefined>(undefined);
  
  onTabSelected(event: any) {
  
  }

  closeDialog(): void {
    this.editLawFirmDialogVisibility = false;
    this.editProviderDialogVisibility = false;
  }

  openEditDialog(): void {
    this.editEndpoint = this.entityType.replace(/^\//, '') + '/odata/' + this.entityId;
    if (this.entityType === APIEndpoints.Lawfirms) {
        this.lawFirmSignal.set(this.payload);
        this.editLawFirmDialogVisibility = true;
    } else if (this.entityType === APIEndpoints.Providers) {
        this.providerSignal.set(this.payload);
        this.editProviderDialogVisibility = true;
    }
  }

  refreshGrid() {
    window.location.reload();
  }

  beforeOpening(args: any): void {
    args.maxHeight = '90vh';
  }

}