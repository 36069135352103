<div id="provider-map">

    <div class="row">

        <div class="col-12 col-md-4">
            <div class="search">
                <form class="d-flex flex-colum justify-content-between" [formGroup]="searchFormGroup" (ngSubmit)="generateMarkersBasedOnFilter()">
                    <ejs-textbox id="enter_address" formControlName="client_address" floatLabelType="Auto" placeholder="Client Address" />
                    <ejs-numerictextbox id="enter_radius" formControlName="radius" floatLabelType="Auto" placeholder="Search Radius (mi)" />
                    <ejs-multiselect #providerTypesDrpdwn name="provider_type" formControlName="provider_type" floatLabelType="Auto" placeholder="Provider Type" 
                        [dataSource]="providerTypes" 
                        [fields]="{ text: 'description', value: 'id' }" />
                    <div class="form-submit d-flex justify-content-end">
                        <button id="search-provider-btn" ejs-button type="submit" isPrimary="true">Search</button>
                    </div>
                </form>
            </div>
        
            <spacer-element />
            
            <div class="list">
                <ejs-listview #providersListView id="providersListView" headerTitle="Results" cssClass="e-list-template" sortOrder="Ascending"  headerTitle='Results'  
                    [showHeader]="true"
                    [dataSource]="providersListData"
                    (select)="onProvidersListSelect()">
                    <ng-template #template let-data>
                        <div [id]="data.index" class="e-list-item e-card" (click)="onProviderClick($event)">
                            <div [id]="data.index" class="e-list-wrapper e-list-multi-line">
                                <div class="provider-card">
                                    <div class="network-indicator" [style.background-color]="getMarkerColors(data.priorityId)"></div>
                                    <div class="provider-info">
                                        <h2>{{data.name}}</h2>
                                        <div class="provider-details">
                                            <span class="distance">{{data.distance}} Miles</span>
                                            <span class="provider-type">{{getProviderName(data.providerType)}}</span>
                                            <span class="network-status" [style.color]="getMarkerColors(data.priorityId)">
                                                {{getNetworkStatusLabel(data.priorityId)}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ejs-listview>
            </div>
        </div>

        <div class="col-12 col-md-8">
            <div class="map">
                <google-map height="100%" width="100%" [center]="center" [zoom]="zoom" [options]="{}" mapId="DEMO_MAP_ID">
                    <map-marker-clusterer [imagePath]="clustererImagePath" [zoomOnClick]="false" (clusterClick)="clusterClick($event)">
        
                        <ng-container *ngFor="let mark of markers; let idx = index;">
                            <map-info-window #infoWindow="mapInfoWindow">
                                <div class="provider-card">
                                    <div class="network-indicator" [style.background-color]="getMarkerColors(markers[idx].priorityId)"></div>
                                    <div class="provider-info">
                                        <div class="provider-heading">
                                            <h2>{{markers[idx].name}}</h2>
                                            <div class="status-label" [style.color]="getMarkerColors(markers[idx].priorityId)">
                                                {{getNetworkStatusLabel(markers[idx].priorityId)}}
                                            </div>
                                        </div>
                                        <div class="provider-details">
                                            <div class="detail-row">
                                                <label>Distance:</label>
                                                <span>{{markers[idx].distance}} Miles</span>
                                            </div>
                                            <div class="detail-row">
                                                <label>Reimbursement Rate:</label>
                                                <span>{{markers[idx].reimbursement || 'N/A'}}%</span>
                                            </div>
                                            <div class="detail-row">
                                                <label>Spanish Speaking:</label>
                                                <span>{{markers[idx].spanishSpeaking ? 'Yes' : 'No'}}</span>
                                            </div>
                                            <div class="detail-row">
                                                <label>Phone:</label>
                                                <span>{{(markers[idx].XrefPhoneProviders && markers[idx].XrefPhoneProviders[0].Phone.PhoneNumber) || 'Not Available'}}</span>
                                            </div>
                                            <div class="detail-row">
                                                <label>Signed Purchase Agreement:</label>
                                                <span>{{markers[idx].signedPurchaseAgreement ? 'Yes' : 'No'}}</span>
                                            </div>
                                            <div class="detail-row">
                                                <label>Special Instructions:</label>
                                                <span>{{markers[idx].notesImportant ? (markers[idx].notes || 'No special instructions available') : 'No special instructions'}}</span>
                                            </div>
                                        </div>
                                        <div class="navigation-controls" *ngIf="isCluster">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <button ejs-button (click)="getPreviousInfoWindow(windowReference[clusterMarkerIds[clusterMarkerIndex]])" iconCss="e-icons e-chevron-left-double"></button>
                                                <span>{{clusterMarkerIndex + 1}}/{{clusterMarkerIds.length}}</span>
                                                <button ejs-button (click)="getNextInfoWindow(windowReference[clusterMarkerIds[clusterMarkerIndex]])" iconCss="e-icons e-chevron-right-double"></button>
                                            </div>
                                        </div>
                                        <div class="provider-actions" *ngIf="!hideWindowBtnFn">
                                            <button ejs-button (click)="windowBtnFn($event)" isPrimary="true">{{windowBtnText}}</button>
                                        </div>
                                    </div>
                                </div>
                            </map-info-window>
        
                            <map-marker #marker="mapMarker"
                                [position]="mark.latling"
                                [icon]="mark.icon!"
                                [title]="mark.name"
                                (mapClick)="OnMarkerclick(infoWindow, marker)">
                            </map-marker>
                        </ng-container>
                        
                    </map-marker-clusterer>
        
                    <map-info-window #window="mapInfoWindow">
                        <div class="provider-heading">
                            <h2>Home</h2>
                        </div>
                        <div class="provider-info">
                            <p>Address: {{patientAddress}}</p>
                        </div>
                    </map-info-window>
        
                    <map-marker #marker="mapMarker"
                        [position]="patientAddressLatLong"
                        [icon]="'/assets/maps/images/home-32.png'"
                        (mapClick)="OnMarkerclick(window, marker)">
                    </map-marker>
        
                    <map-marker #placeholdermarker="mapMarker" 
                        [position]="placeHolderPosition"
                        [icon]="'/assets/maps/images/blank-32.png'"
                        (markerInitialized)="setPlaceholderMarker(placeholdermarker)">
                    </map-marker>
        
                    <div class="legend" *ngIf="isNetworkStatusesLoaded">
                        <div class="legend-item" *ngFor="let status of networkStatuses">
                            <i class="fas fa-map-marker-alt" [style.color]="status.Color" [title]="status.Description"></i>
                        </div>
                    </div>
                </google-map>
            </div>
        </div>
    </div>
</div>