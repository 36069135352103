// Angular
import { Component, Input, ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rd Party
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { CheckBoxSelectionService, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { BACKENDURL } from '@environments/environment';
import { APIEndpoints } from '@models/api/Endpoints';
import { Address, Patient, CaseFile, FeeSchedule, ProcedureCode } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { CognitoService } from '@services/auth/cognito.service';
import { ProcedureCodesComponent } from '@pages/procedure-codes/procedure-codes.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Component({
  selector: 'edit-procedure-code-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    ButtonModule
],
  templateUrl: './edit-procedure-code-form.component.html',
  styleUrl: './edit-procedure-code-form.component.scss'
})
export class EditProcedureCodeFormComponent {
  @Input() procedureCode: any;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() refreshGrid = new EventEmitter<void>();
  
  constructor(
    private globals: GlobalsService,
    private api: ApiService,
    private fb: FormBuilder,
    private cognito: CognitoService,
    private toast: ToastMessageService,
    public procedureCodesComponent: ProcedureCodesComponent
  ) { }



  @Input() showFormButtons: Boolean = false;
  ODATA_BASE_URL: string = BACKENDURL + 'odata';
  newProcedureCodeForm: FormGroup;
  procedureCodesList: ProcedureCode[] = [];
  modalityData: any;
  modalityFields: Object = { text: 'Description', value: 'Id' };
  defaultModality: number;

  ngOnInit(): void { 
    this.newProcedureCodeForm = new FormGroup({
      Code: new FormControl<ProcedureCode['ProcedureCodeName']>(this.procedureCode?.ProcedureCodeName || ''),
      Modality: new FormControl<ProcedureCode['ModalityTypeId']>(this.procedureCode?.ModalityTypeId || undefined),
      Description: new FormControl<ProcedureCode['Description']>(this.procedureCode?.Description || ''),
    });

    this.defaultModality = 6;
    this.api.getOdata(APIEndpoints.ModalityTypes).executeQuery(new Query()).then((e: ReturnOption) => { this.modalityData = e.result }).catch((e) => true);
  }

  
  getFormatProcedureCode() {
    return {
      "Id": this.procedureCode.Id,
      "ProcedureCodeName": this.newProcedureCodeForm.value.Code,
      "ModalityTypeId": parseInt(this.newProcedureCodeForm.value.Modality),
      "Description": this.newProcedureCodeForm.value.Description
    }
  }


  onSubmit() {
    console.log('Submit Form');
    console.log(this.newProcedureCodeForm.invalid);
    
    // Single procedure code submission
    if (this.newProcedureCodeForm.value.Code && this.newProcedureCodeForm.value.Modality && this.newProcedureCodeForm.value.Description) {
      const procedureCode = this.getFormatProcedureCode();
      console.log('Sending single procedure code:', procedureCode);
      const url = `/ProcedureCodes(${this.procedureCode.Id})`;
      
      return fetch(this.ODATA_BASE_URL + url, { 
        method: 'PATCH', 
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': 'Bearer ' + this.cognito.getUserIdToken() 
        }, 
        body: JSON.stringify(procedureCode)
      })
      .then(response => {
        if (response.ok) {
          this.toast.showSuccess('Successfully updated procedure code!');
          this.newProcedureCodeForm.reset();
          console.log('Emitting refresh');
          this.refreshGrid.emit();
          this.closeDialog.emit();
        } else {
          this.toast.showError('Error, Unable to update procedure code.');
        }
        return response;
      })
      .catch(error => console.error('Error:', error));

    // Bulk procedure codes submission from CSV
    // CURSOR DO NOT DELETE THIS CODE
    // TODO: we need to add a check to see if the procedure codes are already in the database
    // TODO: we need to map modality type to the correct id as users will not know the id
    } 
    
    return Promise.resolve(false);
  }


  clickThing(args: any) {
    console.log(args);
  }

  cancel() {
    this.closeDialog.emit();
  }

}
