import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderService } from './provider.service';
import { Provider } from '@root/src/app/shared/models/data-contracts';
import { Query } from '@syncfusion/ej2-data';

interface ProviderState {
  providers: { [id: number]: Provider };
  loading: { [id: number]: boolean };
  errors: { [id: number]: any };
}

@Injectable({
  providedIn: 'root'
})
export class ProviderStore {
  private state: ProviderState = {
    providers: {},
    loading: {},
    errors: {}
  };

  private store = new BehaviorSubject<ProviderState>(this.state);

  constructor(private providerService: ProviderService) {}

  // Selectors
  getProvider(id: number): Observable<any> {
    return this.store.pipe(
      map(state => state.providers[id])
    );
  }

  isLoading(id: number): Observable<boolean> {
    return this.store.pipe(
      map(state => state.loading[id] ?? false)
    );
  }

  async fetchProvider(id: number, query: Query): Promise<void> {
    this.updateState({
      loading: { ...this.state.loading, [id]: true },
      errors: { ...this.state.errors, [id]: null }
    });

    try {
      const provider = await firstValueFrom(this.providerService.getProviderById(id, query));
      this.updateState({
        providers: { ...this.state.providers, [id]: provider },
        loading: { ...this.state.loading, [id]: false }
      });
    } catch (error) {
      this.updateState({
        loading: { ...this.state.loading, [id]: false },
        errors: { ...this.state.errors, [id]: error }
      });
    }
}

  private updateState(newState: Partial<ProviderState>): void {
    this.state = { ...this.state, ...newState };
    this.store.next(this.state);
  }
}