import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIEndpoints } from '@models/api/Endpoints';
import { Audit } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { User } from 'box-typescript-sdk-gen/lib/schemas/user.generated';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(
    private api: ApiService
  ) {}

  async getAuditLogs(id: number, auditableType: string, pageSize?: number): Promise<Audit[]> {
    const url = APIEndpoints.Audits;
    const predicate = new Predicate('AuditableType', 'equal', auditableType).and('AuditableId', 'equal', id);
    const query = new Query()
        .where(predicate)
        .expand('User')
        .select('Id,Event,CreatedAt,User,OldValues,NewValues')
        .sortBy('CreatedAt', 'desc')
        .take(pageSize ?? 50);

    return this.api.getOdata(url).executeQuery(query).then((data: any) => {
        return data.result;
    });
  }

  mapAuditDataToLogFormat(auditData: Audit[]): any[] {
    return auditData
      .map(audit => ({
        type: audit.Event?.toLowerCase() ?? 'updated',
        user: audit.User?.Name ?? `User ${audit.UserId}`,
        dateTime: audit.CreatedAt,
        oldValues: audit.OldValues ? JSON.parse(audit.OldValues) : {},
        newValues: audit.NewValues ? JSON.parse(audit.NewValues) : {}
      }))
      // Sort by date time descending
      .sort((a, b) => new Date(b.dateTime ?? 0).getTime() - new Date(a.dateTime ?? 0).getTime());
  }
}