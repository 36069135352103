// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';

// 3rd Party
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { Fetch } from '@syncfusion/ej2-base';

// Internal
import { BACKENDURL } from '@environments/environment';
import { CognitoService } from '@services/auth/cognito.service';

export interface BatchData {
  action: string,
  Changed: any[],
  Added: any[],
  Deleted: any[]
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private cognito: CognitoService,
  ) { }

  public ODATA_BASE_URL: string = BACKENDURL + 'odata';

  /**
   * basicFetch
   * @description This method will build the request object for the API calls for the rest of the methods in this service
   * @param {string} url
   * @memberof ApiService
   * @returns Promise - fetch() returns a promise 
   */
  async basicFetch(url: string, hasOdata = true) {
    const handleBaseUrl = hasOdata ? this.ODATA_BASE_URL : BACKENDURL;
    const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
    return fetch(handleBaseUrl + url, { method: 'GET', headers: headers }).then((res) => res.json());
  }

  /**
   * basicPatch
   * @description Builds a basic patch request that accepts url and data
   * @param {string} url
   * @param data 
   * @returns promise
   */
  basicPatch(url: string, data: any, hasOdata = true) {
    const fetchURL = async () => {
      const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
      return await fetch(hasOdata ? this.ODATA_BASE_URL + url : BACKENDURL + url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(data)
      }).catch(error => console.log(error))
    }
    return fetchURL();
  }

  basicPost(url: string, data: any, hasOdata = true) {
    const fetchURL = async () => {
      const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
      return await fetch(hasOdata ? this.ODATA_BASE_URL + url : BACKENDURL + url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }).catch(error => console.log(error))
    }
    return fetchURL();
  }

  /**
   * basicPut
   * @description Builds a basic put request that accepts url and data
   * @param {string} url
   * @param data
   * @returns promise
   */
  basicPut(url: string, data: any) {
    const fetchURL = async () => {
      const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
      return await fetch(BACKENDURL + url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data)
      }).catch(error => console.log(error))
    }
    return fetchURL();
  }

  /**
   * GET
   * @description Fetches data from api endpoint into a DataManager
   * @param {string} url
   * @memberof ApiService
   * @returns DataManager - builds fetched data through ODataV4Adaptor()
   */
  getOdata(url: string): DataManager {
    return new DataManager({
      url: this.ODATA_BASE_URL + url,
      adaptor: new VarentODataAdaptor(this.cognito.getUserIdToken()),
    });
  }

  /**
   * PATCH
   * @description Saves any updated server to odata endpoit
   * @param {string} url
   * @memberof ApiService
   */
  patchOdata(url: string, data: any) {
    const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
    return fetch(this.ODATA_BASE_URL + url, { method: 'PATCH', headers: headers, body: JSON.stringify(data) });
  }

  /**
   * POST
   * @description Creates a new record in the odata endpoint
   * @param {string} url
   * @memberof ApiService
   */
  postOdata(url: string, data: any) {
    const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
    return fetch(this.ODATA_BASE_URL + url, { method: 'POST', headers: headers, body: JSON.stringify(data) });
  }

  /**
   * DELETE
   * @description Deletes any updated server to odata endpoit
   * @param {string} url
   * @memberof ApiService
   */
  deleteOdata(url: string) {
    const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
    return fetch(this.ODATA_BASE_URL + url, { method: 'DELETE', headers: headers });
  }

  /**
   * Fetch for all method types
   * @param url 
   * @param method 
   * @param body 
   * @param headers 
   * @returns fetch()
   */
  async fetchRequest(url: string, method?: string, body?: any, headers?: any, hasOdata: boolean = false) {
    const requestUrl = `${hasOdata ? this.ODATA_BASE_URL : BACKENDURL}${url}`
    const requestMethod = method ?? 'GET';
    const requestHeader = headers ?? {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.cognito.getUserIdToken()
    };

    try {

      const response = await fetch(requestUrl, {
        method: requestMethod,
        headers: requestHeader,
        body: body ? JSON.stringify(body) : undefined
      });

      // Check if response is ok (status in 200-299 range)
      if (!response.ok) {
        // Detailed console logging for debugging
        console.group('API Request Failed');
        console.log('URL:', requestUrl);
        console.log('Method:', requestMethod);
        console.log('Status:', response.status);
        console.log('Status Text:', response.statusText);
        if (body) console.log('Request Body:', JSON.stringify(body));
        console.groupEnd();

        const errorResponse = response.clone();
        const errorText = await errorResponse.text();
        let errorMessage = errorText;

        try {
          const errorJson = JSON.parse(errorText);
          errorMessage = errorJson.message || errorJson.error || errorText;
        } catch {
          // If JSON parsing fails, use the text as is
          errorMessage = errorText;
        }

        throw new Error(`HTTP error! status: ${response.status}, message: ${errorMessage}`);
      }

      // Only try to parse JSON if we're expecting it
      if (response.headers.get('content-type')?.includes('application/json')) {
        return await response.json();
      }

      return response;
    } catch (error) {
      console.error('API Request Error:', error);
      throw error;
    }
  }
}

class VarentODataAdaptor extends ODataV4Adaptor {
  token: string | null = null;

  constructor(token: string) {
    super();
    this.token = token
  }

  public override beforeSend(
    dm: DataManager,
    request: Request,
    settings: Fetch,
  ): void {
    request.headers.append('Authorization', `Bearer ${this.token}`);

    return super.beforeSend(dm, request, settings);
  }
}