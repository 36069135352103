<div class="dual-list-wrapper">
    <div class="list-box">
      <h4>Available</h4>
      <input class="e-input" type="text" 
        (input)="onAvailableFilterChange($event)" 
        placeholder="Filter available items">
      <ejs-listbox 
        #availableList
        id="availableListbox"
        [dataSource]="availableItems" 
        [fields]="fields"
        [toolbarSettings]="toolbar" 
        scope="#assignedListbox"
        height="330px"
        (actionComplete)="onAssignedChange($event)"
    >
      </ejs-listbox>
    </div>

    <div class="list-box">
      <h4>Assigned</h4>
      <input class="e-input" type="text" 
        (input)="onAssignedFilterChange($event)" 
        placeholder="Filter assigned items">
      <ejs-listbox 
        #assignedList
        id="assignedListbox"
        [dataSource]="assignedItems()" 
        [fields]="fields"
        height="330px"
        (actionComplete)="onAssignedChange($event)"

        >
      </ejs-listbox>
    </div>
  </div>