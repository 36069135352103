import { Injectable } from "@angular/core";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { LawFirm } from "@root/src/app/shared/models/data-contracts";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { Query } from "@syncfusion/ej2-data";

@Injectable({
    providedIn: 'root'
  })

  export class LawFirmService {
    constructor(private api: ApiService) {}
  
    getLawFirmById(id: number, query: Query): Promise<LawFirm> {
        return this.api.getOdata(`${APIEndpoints.Lawfirms}(${id})`)
          .executeQuery(query)
          .then((response: any) => response.result?.[0] || null);
      }
}