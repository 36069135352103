<div [class]="loadingContent() ? 'tab-card loading' : 'tab-card'">

    <div class="e-card position-relative">
        <div class="tab-card-heading position-relative">
            <h3 class="heading">{{heading}}</h3>
        </div>
        <div class="tab-card-content position-relative">
            <ng-container *ngIf="loadingContent(); else content">
                <loading-overlay [loading]="loadingContent" />
            </ng-container>
            <ng-template #content>
                <ng-content></ng-content>
            </ng-template>
        </div>
    </div>
</div>