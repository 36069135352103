// Angular
import { Component, computed, effect, ElementRef, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerAllModule, DatePickerComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { 
  faNotesMedical, faHospitalUser, faFolderOpen, faMinus, faLink, faCalendarXmark, faToggleOff, faLayerGroup, faUsersRectangle, faCalendarDays, 
  faUser, faUserDoctor, faUserPlus, faBuilding, faFileInvoiceDollar, faUserInjured, faToggleOn, faStethoscope, faClock, faUserShield, faStar, 
  faFileSignature, faFileLines, faGavel, faHashtag, faCheckSquare, faComments, faIdCard, faUserTag, faChartLine, 
  faBarsProgress,
  faHouseMedicalCircleXmark} from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, CaseFile, Patient } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Components
import { PatientFormComponent } from '@forms/patient-form/patient-form.component';

type CaseFileFormControls = {
  [K in keyof CaseFile]: FormControl<CaseFile[K] | null | undefined>;
};

@Component({
  selector: 'case-file-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    MultiSelectAllModule,
    DatePickerAllModule,
    TooltipAllModule,
    CheckBoxAllModule,
    FontAwesomeModule,
    PatientFormComponent
  ],
  templateUrl: './case-file.component.html',
  styleUrl: './case-file.component.scss'
})
export class CaseFileFormComponent {

  /**
   * Constructor 
   */
  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 

    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();

      if (currentValue) {
        // Update form values silently
        this.getPatient().then((patient) => {
          this.caseFileForm.patchValue(this.formState().currentValue!);
          this.patientFormComp.patientForm.patchValue(patient as Patient);
        });
      }

      // Cleanup
      onCleanup(() => this.caseFileForm.reset());
    });
  }

  /**
   * Declare variables
   */
  // Decorator variabls
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() caseFile!: Signal<CaseFile | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = true;
  @Input() displayFields: string[] = [
    'Id', 'CaseNumber', 'CaseType', 'CaseTypeId', 'ClientHasCheckpointLimitations', 'Comments', 'DateOfLoss', 'ExcludeFromBalanceDue', 
    'FileGroupId', 'FileNumber', 'FileOpened', 'FileRating', 'FileSecuredBy', 'InitialTimeFrame', 'InTreatment', 'Active', 'IsSurgical', 'LawFirmContact', 
    'LawFirmFileNumber', 'LawFirmId', 'MarketManager', 'CaseManager', 'PAndL', 'PatientId', 'ReferralSource', 'ReferringPhysician', 'ResponsibleParty', 'Statuser', 
    'StatuteOfLimitations', 'StatusingGroup', 'RecordStatusId'];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @ViewChild('caseFileFormContainer') caseFileFormContainer!: ElementRef;
  @ViewChild('patientFormComp') patientFormComp!: PatientFormComponent;

  // State variables
  protected readonly formState = computed(() => {
    
    return {
      isValid: this.caseFileForm.valid,
      hasChanges: this.caseFile() !== this.caseFileForm.value,
      currentValue: this.caseFile()  
    };
  });
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.caseFileForm.controls).forEach(key => {
      const control = this.caseFileForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });
  
  // Public variables
  patientSignal = signal<Patient | undefined>(undefined);
  patientData = computed(() => this.patientSignal());
  loadingForm: boolean = true;
  caseFileHTMLElement: Element | null = null;
  errorMessage: string = '';
  recordStatuses: any;
  recordStatusesQuery: Query;
  caseManagers: any;
  caseManagersQuery: Query;
  marketManagers: any;
  marketManagersQuery: Query;
  pAndL: any;
  pAndLQuery: Query;
  referralSources: any;
  referralSourcesQuery: Query;
  referringPhysicians: any;
  referringPhysiciansQuery: Query;
  initialTimeFrames: any;
  statusers: any;
  statusersQuery: Query;
  statusingGroups: any;
  statusingGroupsQuery: Query;
  caseTypes: any;
  caseTypesQuery: Query;
  users: any;
  usersQuery: Query;
  fileGroups: any;
  fileGroupsQuery: Query;
  companionCases: any;
  companionCasesQuery: Query;
  lawFirms: any;
  lawFirmsQuery: Query;
  lawFirmContacts: any;
  lawFirmContactsQuery: Query;
  attorneys: any;
  attorneysQuery: Query;
  caseFileForm = new FormGroup<CaseFileFormControls>({
    Id: new FormControl<CaseFile['Id']>(undefined),
    Attorney: new FormControl<CaseFile['Attorney']>(undefined),
    CaseManager: new FormControl<CaseFile['CaseManager']>(undefined),
    CaseNumber: new FormControl<CaseFile['CaseNumber']>(undefined),
    CaseType: new FormControl<CaseFile['CaseType']>(undefined),
    CaseTypeId: new FormControl<CaseFile['CaseTypeId']>(undefined),
    ClientHasCheckpointLimitations: new FormControl<CaseFile['ClientHasCheckpointLimitations']>(undefined),
    Comments: new FormControl<CaseFile['Comments']>(undefined),
    CompanionCases: new FormControl<CaseFile['CompanionCases']>(undefined),
    DateOfLoss: new FormControl<CaseFile['DateOfLoss']>(undefined, [Validators.required]),
    ExcludeFromBalanceDue: new FormControl<CaseFile['ExcludeFromBalanceDue']>(undefined),
    FileGroupId: new FormControl<CaseFile['FileGroupId']>(undefined),
    FileNumber: new FormControl<CaseFile['FileNumber']>(undefined, [Validators.required]),
    FileOpened: new FormControl<CaseFile['FileOpened']>(undefined, [Validators.required]),
    FileRating: new FormControl<CaseFile['FileRating']>(undefined),
    FileSecuredBy: new FormControl<CaseFile['FileSecuredBy']>(undefined),
    InitialTimeFrame: new FormControl<CaseFile['InitialTimeFrame']>(undefined),
    InTreatment: new FormControl<CaseFile['InTreatment']>(undefined),
    IsSurgical: new FormControl<CaseFile['IsSurgical']>(undefined),
    LawFirmContact: new FormControl<CaseFile['LawFirmContact']>(undefined),
    LawFirmFileNumber: new FormControl<CaseFile['LawFirmFileNumber']>(undefined),
    LawFirmId: new FormControl<CaseFile['LawFirmId']>(undefined, [Validators.required]),
    MarketManager: new FormControl<CaseFile['MarketManager']>(undefined),
    PAndL: new FormControl<CaseFile['PAndL']>(undefined, [Validators.required]),
    PatientId: new FormControl<CaseFile['PatientId']>(undefined),
    ReferralSource: new FormControl<CaseFile['ReferralSource']>(undefined),
    ReferringPhysician: new FormControl<CaseFile['ReferringPhysician']>(undefined),
    ResponsibleParty: new FormControl<CaseFile['ResponsibleParty']>(undefined),
    Statuser: new FormControl<CaseFile['Statuser']>(undefined),
    StatuteOfLimitations: new FormControl<CaseFile['StatuteOfLimitations']>(undefined),
    StatusingGroup: new FormControl<CaseFile['StatusingGroup']>(undefined),
    RecordStatusId: new FormControl<CaseFile['RecordStatusId']>(undefined, [Validators.required]),
  });
  caseFileIcons = {
    form: faNotesMedical,
    default: faMinus,
    patient: faHospitalUser,
    fileOpened: faFolderOpen,
    recordStatus: faBarsProgress,
    caseManager: faIdCard,
    marketManager: faUserTag,
    pAndL: faChartLine,
    referringPhysician: faUserDoctor,
    responsibleParty: faUser,
    statuteOfLimitations: faCalendarDays,
    statuser: faUser,
    statusingGroup: faUsersRectangle,
    attorney: faGavel,
    caseNumber: faHashtag,
    caseType: faFileLines,
    clientHasCheckpointLimitations: faUserShield,
    comments: faComments,
    companionCases: faLink,
    dateOfLoss: faCalendarXmark,
    excludeFromBalanceDue: faHouseMedicalCircleXmark,
    fileGroupId: faLayerGroup,
    fileNumber: faHashtag,
    fileRating: faStar,
    fileSecuredBy: faUserShield,
    initialTimeFrame: faClock,
    inTreatment: faStethoscope,
    active: faToggleOn,
    isSurgical: faUserInjured,
    lawFirmContact: faBuilding,
    lawFirmFileNumber: faFileInvoiceDollar,
    lawFirmId: faBuilding,
    patientId: faUserPlus,
    referralSource: faHospitalUser
  }
  private readonly baseFormClasses = {
    formContainer: 'cc-form-container',
    form: window.innerWidth <= 768 ? 'cc-form flex-column' : 'cc-form flex-row flex-wrap',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: window.innerWidth <= 768 ? 'col-12' : window.innerWidth <= 992 ? 'col-6' : 'col-4'
  };
  formClasses = { ...this.baseFormClasses };

  /**
   * Lifecycle hooks
   */
  ngOnInit() {
    this.caseFileForm.patchValue(this.caseFile() as Partial<CaseFile>);
    this.caseFileHTMLElement = this.caseFileFormContainer.nativeElement;
    this.updateWidth(this.caseFileHTMLElement?.clientWidth ?? window.innerWidth);
    this.watchInputElements();
  }

  ngAfterViewInit() {
    if (this.patientFormComp && this.patientFormComp.addressFormComp) this.patientFormComp.addressFormComp.displayTitle = false;
  }

  /**
   * Methods
   */
  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.caseFileForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Hit the API to create or update a case file
  async onSubmit() {
    let submitResult: any;
    let patientResponse: any;
    this.markAllFieldsAsTouched();
    this.patientFormComp.markAllFieldsAsTouched();
    this.patientFormComp.addressFormComp.markAllFieldsAsTouched();
    const submitType = this.submitType ? this.submitType : this.caseFileForm.get('Id')?.value ? 'PATCH' : 'POST';
    const endpoint = this.caseFileForm.get('Id')?.value ? `${APIEndpoints.Casefiles}/${this.caseFileForm.get('Id')?.value}` : `${APIEndpoints.Casefiles}`;
    this.caseFileForm.touched;

    if (this.caseFileForm.invalid) {
      submitResult = 'error';
      this.loadingForm = false;
      this.toast.showError('Form Invalid');
      return submitResult;
    }

    // Check if form has changes
    if (this.caseFileForm.pristine) {
      submitResult = 'no-change';
      this.loadingForm = false;
      this.toast.showWarning('No changes to submit');
      return submitResult;
    }

    const currentPatientId = this.caseFileForm.get('PatientId')?.value;
    await this.patientFormComp.onSubmit().then((res: any) => {
      if (res instanceof Error || !res.Id) throw new Error('Failed to create patient');
      patientResponse = res;
      this.caseFileForm.get('PatientId')?.setValue(patientResponse.Id);
      console.log('Patient RESPONSE', patientResponse);
      return patientResponse;
    });

    try {
      if (!currentPatientId) this.caseFileForm.patchValue({PatientId: patientResponse.Id});
      submitResult = await this.formCRUD.submitForm(this.caseFileForm, `odata${endpoint}`, submitType);

    } catch (error) {
      console.error('Error submitting Case File:', error);
      this.toast.showError('Error submitting Case File');
      throw new Error('Error submitting Case File', (error as any).message);
    }
    
    console.log('SUBMIT RESULT', submitResult);
    if (submitResult instanceof Error || !submitResult.Id) this.toast.showError('Error submitting Case File');
    else this.toast.showSuccess('Case File Submitted Successfully');
    this.loadingForm = false;
    this.formSubmitted.emit();
    return submitResult;
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.caseFileForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

   // Perform updates on input elements
   watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {
    // Reset form class to base state
    this.formClasses = { ...this.baseFormClasses };

    if (containerWidth <= 768) {
      this.formClasses.form = `${this.baseFormClasses.form} flex-column`;
      this.formClasses.twoCol = 'col-12';
    } else if (containerWidth > 768 && containerWidth <= 992) {
      this.formClasses.form = `${this.baseFormClasses.form} flex-row flex-wrap`;
      this.formClasses.twoCol = 'col-6';
    } else { 
      this.formClasses.form = `${this.baseFormClasses.form} flex-row flex-wrap`;
      this.formClasses.twoCol = 'col-4';
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.caseFileHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  async getPatient() {
    const patientId = this.formState().currentValue?.PatientId as number;
    if (!patientId) throw new Error('Patient Id required to fetch address');  

    const endpoint = `${APIEndpoints.Patients}(${patientId})`;
    const selectString = 'Id,FirstName,LastName,Gender,Dob,Gender,Language,Email,AddressId,XrefAddressPatients';
    const expandString = 'Address($select=Id,Address1,Address2,City,State,Zip),XrefAddressPatients($expand=Address($select=AddressType))';
    const query = new Query().select(selectString).expand(expandString);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        if (res instanceof Error || !res.result[0]) throw new Error('Failed to fetch patient address');
        this.patientSignal.set((res as any).result[0] as Patient);
      });
      
      return this.patientData();
    } catch (error) {
      console.error('Error fetching patient address:', error);
      this.toast.showError('Error fetching patient address');
      return error;
    }
  }

  onRecordStatusCreated(e: any) {
    this.recordStatusesQuery = new Query().select('Id,Description')
    this.recordStatuses = this.api.getOdata(APIEndpoints.RecordStatuses)
  }

  onCaseManagerCreated(e: any) {
    this.caseManagersQuery = new Query().select('Id,Name')
      .expand('XrefUsersRoles($expand=Role($select=RoleName))')
      .where('XrefUsersRoles/any(x: x/Role/RoleName eq \'Case Manager\')', 'equal', true);
    this.caseManagers = this.api.getOdata(APIEndpoints.Users);
    this.caseFileForm.get('CaseManager')?.setValue(14);
  }
  
  onMarketManagerCreated(e: any) {
    this.marketManagersQuery = new Query().select('Id,Name')
      .expand('XrefUsersRoles($expand=Role($select=RoleName))')
      .where('XrefUsersRoles/any(x: x/Role/RoleName eq \'Market Manager\')', 'equal', true);
    this.marketManagers = this.api.getOdata(APIEndpoints.Users);
    this.caseFileForm.get('MarketManager')?.setValue(30);
  }
  
  onPAndLCreated(e: any) {
    this.pAndLQuery = new Query().select('Id,Description');
    this.pAndL = this.api.getOdata(APIEndpoints.PandLs);
  }
  
  onReferralSourceCreated(e: any) {
    this.referralSourcesQuery = new Query().select('Id,Description');
    this.referralSources = this.api.getOdata(APIEndpoints.ReferralSources);
  }

  onInitialTimeFrameCreated(e: any) {
    this.initialTimeFrames = this.api.getOdata(APIEndpoints.InitialTimeFrames);
  }
  
  onReferringPhysicianCreated(e: any) {
    this.referringPhysiciansQuery = new Query().select('Id, ContactName, ContactTitle');
    this.referringPhysicians = this.api.getOdata(APIEndpoints.Contacts);
  }
  
  onStatuserCreated(e: any) {
    this.statusersQuery = new Query().select('Id,ContactName,ContactTitle').where('ContactTitle', 'equal', 'Statusing User');
    this.statusers = this.api.getOdata(APIEndpoints.Contacts);
  }

  onCaseTypeCreated(e: any) {
    this.caseTypesQuery = new Query().select('Id,Description');
    this.caseTypes = this.api.getOdata(APIEndpoints.CaseTypes);
  }

  onDatePickerFocus(focusEvent: FocusEventArgs) {
    if (!(focusEvent.model instanceof DatePickerComponent)) return;
    focusEvent.model.show();
  }

  onDateOnlyChange(args: any, controlName: string) {
    args.value = new Date(args.value).toISOString().split('T')[0];
    this.caseFileForm.get(controlName)?.setValue(args.value);
  }

  onFileSecuredByCreated(e: any) {
    this.usersQuery = new Query().select('Id,Name');
    this.users = this.api.getOdata(APIEndpoints.Users);
  }
  
  onStatusingGroupCreated(e: any) {
    this.statusingGroupsQuery = new Query().select('Id,Description');
    this.statusingGroups = this.api.getOdata(APIEndpoints.StatusingGroups);
  }
  
  onFileGroupCreated(e: any) {
    this.fileGroupsQuery = new Query().select('Id,Description');
    this.fileGroups = this.api.getOdata(APIEndpoints.FileGroups);
  }

  onCompanionCasesCreated(e: any) {
    this.companionCasesQuery = new Query().select('Id,FileNumber');
    this.companionCases = this.api.getOdata(APIEndpoints.Casefiles);
    const currentValue = this.caseFileForm.get('CompanionCases')?.value;
    if (currentValue && typeof currentValue === 'string') {
      try {
        const parsedArray = JSON.parse(currentValue);
        this.caseFileForm.get('CompanionCases')?.setValue(parsedArray);
      } catch (error) {
        console.error('Error parsing companion cases:', error);
      }
    }
  }
  
  onLawFirmCreated(e: any) {
    this.lawFirmsQuery = new Query().select('Id,Name');
    this.lawFirms = this.api.getOdata(APIEndpoints.Lawfirms);
  }
  
  onLawFirmContactCreated(e: any) {
    const lawFirmId = this.caseFileForm.get('LawFirmId')?.value;
    
    if (lawFirmId) {
      this.lawFirmContactsQuery = new Query()
        .select('Id,ContactName,ContactTitle,XrefLawFirmContacts')
        .expand('XrefLawFirmContacts')
        .where('XrefLawFirmContacts/any(x: x/LawFirmId eq ' + lawFirmId + ')', 'equal', true);
    } else {
      this.lawFirmContactsQuery = new Query()
        .select('Id,ContactName,ContactTitle')
        .where('ContactTitle', 'contains', 'Law Firm');
    }

    this.lawFirmContacts = this.api.getOdata(APIEndpoints.Contacts);
  }

  beforeLawFirmContactOpened(e: any) {
    this.onLawFirmContactCreated(e);
  }

  onAttorneyCreated(e: any) {
    this.attorneysQuery = new Query().select('Id,ContactName,ContactTitle').where('ContactTitle', 'contains', 'Law Firm');
    this.attorneys = this.api.getOdata(APIEndpoints.Contacts);
  }
}
