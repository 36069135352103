// Angular
import { Component, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// Syncfusion
import { GridComponent, CommandModel, GridModel, CommandClickEventArgs, RowSelectEventArgs, RowSelectingEventArgs, RowDeselectingEventArgs, RowDeselectEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { BulkEditDeposits, BulkEditDepositsForm } from '@root/src/app/components/forms/bulk-edit-forms/bulk-edit-deposits/bulk-edit-deposits.component';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-richtexteditor';
import { AddDepositForm } from '@forms/add-forms/add-deposit/add-deposit.component';

@Component({
  selector: 'deposits-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateModule,
    DialogModule,
    AddDepositForm,
    BulkEditDepositsForm,
    CommonModule,
  ],
  templateUrl: './deposits-grid.component.html',
  styleUrl: './deposits-grid.component.scss'
})
export class DepositsGridComponent {

  constructor (
    private api: ApiService,
    private globals: GlobalsService,
    private toast: ToastMessageService,
    private router: Router
  ) { }  

  @ViewChild('depositsGridTemplateComponent') depositsGridTemplateComponent: GridTemplateComponent;
  depositsGrid: GridComponent;
  depositsGridSettings: GridModel;
  gridSelectedRows: number[];
  defaultToolbar: any[];
  selectedRecords: any[];
  selectedRowData: Deposit;
  depositsEndpoint = APIEndpoints.Deposits;
  actionCommands: CommandModel[] = [
    { title: 'View', buttonOption: { iconCss: 'e-icons e-eye', cssClass: 'e-flat' } }, 
    { title: 'Edit', type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }
  ];

  // Custom dialogs for adding & bulk editing files
  @ViewChild('addDepositDialog', { static: true}) addDepositDialog: DialogComponent;
  @ViewChild('newDepositForm') newDepositForm: AddDepositForm;
  @ViewChild('bulkEditForm') bulkEditForm: BulkEditDepositsForm;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  addDepositDialogVisibility: Boolean = false;
  modalTarget: string = '#deposits-grid';
  formattedDBData: Deposit[] = [];
  batchChangedRecords: any[] = [];
  addDepositButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearNewDepositForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewDepositSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];
  @ViewChild('bulkEditDepositsDialog') bulkEditDepositsDialog: DialogComponent;
  bulkEditDepositsDialogVisibility: Boolean = false;
  bulkUpdateDepositsButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];
  
  ngOnInit() {
    this.depositsGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Deposits),
      query: new Query().expand(['DepositToAccountNavigation', 'CaseFile($expand=Patient)']),
      showColumnChooser: true,
      columns : [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', allowEditing: false },
        { field: 'CaseFile.Patient.Lastname', headerText: 'Client Last Name', allowEditing: false },
        { field: 'CaseFile.Patient.Firstname', headerText: 'Client First Name', allowEditing: false },
        { field: 'DepositDate', headerText: 'Date Received', editType: 'datePickerEdit' },
        { field: 'DepositAmount', headerText: 'Amount' },
        { field: 'FinalCheck', headerText: 'Final Check' },
        { field: 'Notes' },
        { type: 'commands', commands: this.actionCommands }
      ],
      created: ($event: any) => this.onCreated($event),
      toolbarClick: ($event: ToolbarClickEventArgs) => this.onToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.commandClick($event),
      rowSelecting: ($event: RowSelectingEventArgs) => this.onRowSelecting($event),
      rowSelected: ($event: RowSelectEventArgs) => this.onRowSelected($event),
      rowDeselected: ($event: RowDeselectEventArgs) => this.onRowDeselected($event),
      rowDeselecting: ($event: RowDeselectingEventArgs) => this.onRowDeselecting($event)
    }
  }

  onCreated(args: any) {
    this.defaultToolbar = this.depositsGridTemplateComponent.settings.toolbar as any[];
    this.depositsGrid = this.depositsGridTemplateComponent.grid;
    this.depositsGrid.toolbar = this.defaultToolbar;
  } 

  // Add logic for rendering modals
  onToolbarClick(args: any) {

    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addDepositDialog.show();
    }
    
    if (args.item.id === 'BulkEdit') {
      args.cancel = true;
      this.bulkEditDepositsDialog.show();
    }
  }

  // Add logic for navigating to individual case files
  commandClick(commandClickArgs: CommandClickEventArgs) {
    const data: any = commandClickArgs?.rowData;
    const btn = commandClickArgs.commandColumn?.buttonOption;

    if (data !== undefined) {
      const viewItem: boolean | undefined = btn?.iconCss?.includes('e-eye');
      const deleteItem: boolean | undefined = btn?.iconCss?.includes('e-trash');

      if (viewItem) {
        
        if (data.CaseFile && data.CaseFile.FileNumber) {
          this.caseFileNavigation('/case-files/hub', { fileNumber: `${data.CaseFile.FileNumber}`, hash: 'Financial' });
          // window.open(`${window.location.origin}/case-files/${data.CaseFile.FileNumber}#Financial`, '_blank');
        } else {
          this.toast.showError('Unable to open file. No File Number associated with Deposit.')
        }
        
      }

    } else {
      console.error('commandClickArgs: ', commandClickArgs);
      this.toast.showError('No data found.');
    }
  }

  // After a row is selected
  onRowSelected(args: any) {
    this.selectedRecords = this.depositsGrid.getSelectedRecords();
    this.selectedRowData = this.selectedRecords[this.selectedRecords.length - 1];
    this.updateToolbar();
  }

  // Detect selection of multiple rows
  onRowSelecting(args: any) {
    this.selectedRecords = this.depositsGrid.getSelectedRecords();
    this.selectedRowData = this.selectedRecords[this.selectedRecords.length - 1];
    this.updateToolbar();
  }

  // Detect deselection of multiple rows
  onRowDeselected(args: any) {
    this.selectedRecords = this.depositsGrid.getSelectedRecords();
    this.selectedRowData = this.selectedRecords[this.selectedRecords.length - 1];
    this.updateToolbar();
  }

  // Occurs as rows are deselected
  onRowDeselecting(args: any) {
    this.selectedRecords = this.depositsGrid.getSelectedRecords();
    this.selectedRowData = this.selectedRecords[this.selectedRecords.length - 1];
    this.updateToolbar();
  }

  // Set toolbar according to number of selected rows
  updateToolbar() {

    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;

      if (length === 1) {
        this.depositsGrid.toolbar = this.defaultToolbar;
      } else if (length > 1) {
        const bulkEditButton = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit', prefixIcon: 'e-properties-1' };
        const buttonExists = this.depositsGrid.toolbar.some(button => (button as any).id === bulkEditButton.id);
        if (!buttonExists) this.depositsGridTemplateComponent.insertToolbarItem(bulkEditButton); // make sure button is not already in toolbar
      }

    } else {
      this.depositsGrid.toolbar = this.defaultToolbar;
    }
  }

  // Close both modal windows
  closeDialog() {
    if (this.addDepositDialog) this.addDepositDialog.hide();
    if (this.bulkEditDepositsDialog) this.bulkEditDepositsDialog.hide();
  }

  // Set fields to blank
  clearNewDepositForm() {
    if (this.newDepositForm) this.newDepositForm.depositForm().reset();
  }

  // Add new file to grid
  onNewDepositSubmit(args: any) {
    let form = this.newDepositForm.depositForm;

    if (form().valid) {
      this.depositsGridTemplateComponent?.grid.editModule.addRecord(form().value);
      this.newDepositForm.onSubmit();
      this.closeDialog();

    } else {
      console.log(form);
      this.toast.showError('Form invalid.');
    }
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    if (this.bulkEditForm) this.bulkEditForm.bulkEditDepositsForm().reset();
  }

  // Apply bulk changes to data
  async onBulkEditSubmit(args: any) {
    let form = this.bulkEditForm.bulkEditDepositsForm;

    const updateDeposits = async () => {

      if (form().valid) {
        return this.selectedRecords.forEach(async (row) => {
          console.log(row);
          const dateOnly = new Date(form().value.DepositDate).toISOString().slice(0, 10);
          form().value.DepositDate = dateOnly;
  
          try {
            return await this.api.fetchRequest(`odata${APIEndpoints.Deposits}/${row.Id}`, 'PATCH', form().value);
          } catch (error) {
            console.error('Error updating deposit: ', error);
            return;
          }
        });
      } else {
        console.error(form().errors);
        alert('Please enter a valid form.');
        return;
      }
    }

    await updateDeposits().then(() =>{
      form().reset();
      this.closeDialog();
      this.depositsGrid.refresh();
    });
  }

  // Update array of selected row Indexes
  removeNumberFromArray(numbers: number[], target: number) {
    const index = numbers.findIndex(num => num === target);
    
    return index !== -1 ? numbers.splice(index, 1) : numbers;
  }

  async caseFileNavigation(url: string, params: { [key: string]: any }, fragment?: string): Promise<void> {
    // Remove hash from params if it exists
    const { hash, ...queryParams } = params;
    const queryString = new URLSearchParams(queryParams).toString();
    const fullUrl = `${url}?${queryString}`;
    const fragmentHash = hash ? `#${hash}` : '';
    
    await this.router.navigateByUrl(fullUrl + fragmentHash, { 
      replaceUrl: true,
      skipLocationChange: false
    });
  }
}
