import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { Contact } from '@models/data-contracts';
import { Query } from '@syncfusion/ej2-data';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(
    private api: ApiService
  ) {}

  async getContactById(id: number, query: Query): Promise<Contact | null> {
    try {
      const response = await this.api.getOdata(`${APIEndpoints.Contacts}(${id})`)
        .executeQuery(query)
        .then((response: any) => response.result?.[0] || null);
      return response;
    } catch (error) {
      console.error('Error fetching contact:', error);
      throw error;
    }
  }
}