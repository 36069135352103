// Angular
import { Component, signal, WritableSignal } from '@angular/core';

// Internal
import { FileHubService } from '@services/file-hub/file-hub.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { LoadingModule } from '@modules/loading.module';
import { BoxSignComponent } from '../../../features/box/box-sign/box-sign.component';
import { TabCardComponent } from '@fileHub-tabs/tab-card/tab-card.component';
import { NoDataComponent } from '../../../ui/no-data/no-data.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'box-sign-tab',
  standalone: true,
  imports: [
    BoxSignComponent,
    TabCardComponent,
    LoadingModule,
    NoDataComponent,
    CommonModule,
  ],
  templateUrl: './box-sign-tab.component.html',
  styleUrl: './box-sign-tab.component.scss'
})
export class BoxSignTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private toast: ToastMessageService,
    public fileHub: FileHubService
  ) {}

  loadingBoxSign: WritableSignal<boolean> = signal(true);

  ngAfterViewInit() {
    this.checkData();
  }

  // Verifies data is available
  async checkData() {

    if (!this.fileHub.caseFile) {
      const errMsg = `
        <strong>No Case File</strong>
        <p>Unable to populate Sign Requests.</p>
      `;
      this.toast.showError(errMsg);
    } else {
      this.loadingBoxSign.set(false);
    }
  }

  // Simple function to return passed data in box sign
  getData(data: any) {
    return data;
  }
}
