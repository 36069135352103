import { Injectable } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { ApiService } from '@services/api/api.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { APIEndpoints } from '@models/api/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class WorkItemsService {
  constructor(
    private api: ApiService,
    private userService: UserPreferencesService
  ) {}

  private readonly DEFAULT_TAKE_LIMIT = 50;

  getWorkItemsQuery(userId: number): Query {
    const baseQuery = new Query()
      .select([
        'Id',
        'Title',
        'Description',
        'DueDate',
        'StatusId',
        'PriorityId',
        'CategoryId',
        'UpdatedDate'
      ])
      .expand([
        'XrefWorkItemCaseFiles($expand=CaseFile($select=FileNumber,Patient($select=Firstname,Lastname)))',
        'XrefWorkItemLawFirms($expand=LawFirm($select=Name))',
        'XrefWorkItemPatients($expand=Patient($select=Firstname,Lastname))',
        'XrefWorkItemProviders($expand=Provider($select=Name))'
      ])
      .sortBy('DueDate', 'asc')
      .take(this.DEFAULT_TAKE_LIMIT);

    return this.userService.isAdmin() ? baseQuery : baseQuery.where('AssignedTo', 'equal', userId);
  }

  getOverdueWorkItemsQuery(userId: number): Query {
    return this.getWorkItemsQuery(userId)
      .where('DueDate', 'lessThan', new Date())
      .where('Status', 'notEqual', 'Completed');
  }

  getUpcomingWorkItemsQuery(userId: number): Query {
    return this.getWorkItemsQuery(userId)
      .where('DueDate', 'greaterThanOrEqual', new Date())
      .where('Status', 'notEqual', 'Completed');
  }

  getWorkItemsByEntityQuery(entityType: string, entityId: number): Query {
    const xrefTable = `XrefWorkItem${entityType}`;
    const entityField = `${entityType}Id`;

    return new Query()
      .expand([xrefTable])
      .where(`${xrefTable}.${entityField}`, 'equal', entityId)
      .sortBy('DueDate', 'asc');
  }
} 