// Angular
import { Injectable } from '@angular/core';

// 3rd Party
import { createElement } from '@syncfusion/ej2-base';

@Injectable({
  providedIn: 'root'
})
export class DOMManipulationService {

  constructor() { }

  // Creates empty HTML element for adding components
  appendComponentToElement(element: Element, component: any, attrName?: string, containerClass?: string) {
    let containerElement: HTMLElement = createElement('div', { 
      className: containerClass !== undefined ? containerClass : 'component-container',
      attrs: attrName ? { name: attrName } : undefined 
    });
    
    element.appendChild(containerElement);
    containerElement.appendChild(component);
  }

  // Creates empty HTML element for adding components
  addBtnToHTMLElement(element: Element, button: HTMLButtonElement, containerClass?: string) {
    let containerElement: HTMLElement = createElement('div', { 
      className: containerClass !== undefined ? containerClass : 'component-container' 
    })

    element.appendChild(containerElement);
    containerElement.appendChild(button);
  }

  // Removes all elements matching query selector
  removeElements(querySelector: string) {
    let elements = document.querySelectorAll(querySelector);

    // Remove animating elements
    if (elements.length > 0) {
      
      elements.forEach((element: Element) => {
        element.remove();
      })
    }
  }
}
