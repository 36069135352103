import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LawFirmService } from './law-firm.service';
import { LawFirm } from '@root/src/app/shared/models/data-contracts';
import { Query } from '@syncfusion/ej2-data';

interface LawFirmState {
  lawFirms: { [id: number]: LawFirm };
  loading: { [id: number]: boolean };
  errors: { [id: number]: any };
}

@Injectable({
  providedIn: 'root'
})

export class LawFirmStore {
  private state: LawFirmState = {
    lawFirms: {},
    loading: {},
    errors: {}
  };



  private store = new BehaviorSubject<LawFirmState>(this.state);

  constructor(private lawFirmService: LawFirmService) {}

  // Selectors
  getLawFirm(id: number): Observable<any> {
    return this.store.pipe(
      map(state => state.lawFirms[id])
    );
  }

  isLoading(id: number): Observable<boolean> {
    return this.store.pipe(
      map(state => state.loading[id] ?? false)
    );
  }

  // Actions
  async fetchLawFirm(id: number, query: Query): Promise<void> {
    // Set loading state
    this.updateState({
      loading: { ...this.state.loading, [id]: true },
      errors: { ...this.state.errors, [id]: null }
    });

    try {
      const lawFirm = await this.lawFirmService.getLawFirmById(id, query);
      this.updateState({
        lawFirms: { ...this.state.lawFirms, [id]: lawFirm },
        loading: { ...this.state.loading, [id]: false }
      });
    } catch (error) {
      this.updateState({
        loading: { ...this.state.loading, [id]: false },
        errors: { ...this.state.errors, [id]: error }
      });
    }
  }

  private updateState(newState: Partial<LawFirmState>): void {
    this.state = { ...this.state, ...newState };
    this.store.next(this.state);
  }
}