import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter, OnInit, HostListener } from "@angular/core";
import { 
  FormControl, 
  FormGroup, 
  ReactiveFormsModule, 
  Validators
} from "@angular/forms";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListModule, MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DataManager } from "@syncfusion/ej2-data";
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GlobalsService } from '@services/globals/globals.service';
import { User } from '@models/data-contracts';
import { UserUpdateDTO } from '@models/data-contracts';

interface ApiError {
  code: string;
  message: string;
  field: string | null;
}

interface ApiResponse<T> {
  success: boolean;
  data: T | null;
  errors: ApiError[];
}

/**
 * Component for editing existing users in the system
 * Includes form validation and role assignment
 */
@Component({
  selector: 'edit-user',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,  
    DropDownListModule,
    MultiSelectModule,
    TooltipModule
  ],
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})

export class EditUserComponent implements OnInit {
  @Input() grid: GridComponent;
  @Input() user: User;
  @Output() closeForm = new EventEmitter<boolean>();
  
  // Form group with validation rules
  editUserForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    title: new FormControl(''),
    // TODO re-enable phone number validation once cognito update is complete
    //phoneNumber: new FormControl('', [Validators.required, this.globals.phoneNumberValidator()]),
    phoneNumber: new FormControl(''),
    roleId: new FormControl<number[]>([], Validators.required),
    rightIds: new FormControl<number[]>([])
  });

  rolesData: DataManager;
  rightsData: DataManager;
  errorMessage: string = '';
  private initialFormValue: any;

  constructor(
    private api: ApiService, 
    private toast: ToastMessageService,
    private globals: GlobalsService
  ) {}

  ngOnInit(): void {
    this.loadUserData();
    this.setRoles();
    this.setRights();
  }

  /**
   * Loads the user data into the form
   */
  private loadUserData(): void {
    if (this.user) {
      const phoneNumber = this.user.XrefPhoneUsers?.[0]?.Phone?.PhoneNumber || '';
      
      this.editUserForm.patchValue({
        userName: this.user.Username,
        name: this.user.Name,
        email: this.user.Email,
        title: this.user.Title,
        phoneNumber: phoneNumber,
        roleId: this.user.XrefUsersRoles?.map(r => r.RoleId ?? 0).filter(id => id !== 0) || [],
        rightIds: this.user.XrefUsersRights?.map(r => r.RightId ?? 0).filter(id => id !== 0) || []
      });
      
      // Store initial form value after loading
      this.initialFormValue = this.editUserForm.value;
    }
  }

  /**
   * Fetches available roles from the API
   */
  private setRoles(): void {
    this.rolesData = this.api.getOdata(APIEndpoints.Roles);
  }

  /**
   * Fetches available rights from the API
   */
  private setRights(): void {
    this.rightsData = this.api.getOdata(APIEndpoints.Rights);
  }

  /**
   * Handles escape key press to close form
   */
  @HostListener('document:keydown.escape')
  handleEscapeKey(): void {
    this.cancel();
    this.closeForm.emit(false);
  }

  /**
   * Resets form and clears error messages
   */
  cancel(): void {
    this.loadUserData(); // Reset to original user data
    this.errorMessage = '';
    this.closeForm.emit(false);
  }

  /**
   * Handles form submission and user updates
   */
  async onSubmit(): Promise<void> {
    var hasChanges = false;
    if (!this.editUserForm.valid) {
      this.markFormGroupTouched(this.editUserForm);
      return;
    }

    // Check if form has changes
    if (JSON.stringify(this.initialFormValue) !== JSON.stringify(this.editUserForm.value)) {
      hasChanges = true;
    }

    if (hasChanges) {
      try {
        const userData = this.prepareUserData();
        await this.updateUser(userData);
        this.showSuccessMessage(userData.Name ?? '');
        this.closeForm.emit(true);  // Only emit once here
      } catch (error) {
          this.handleError(error);
      }
    }else {
      this.closeForm.emit(false);
    }
  }
  
  private prepareUserData(): UserUpdateDTO {
    return {
      Id: this.user.Id,
      Name: this.editUserForm.value.name,
      Email: this.editUserForm.value.email,
      Title: this.editUserForm.value.title,
      XrefPhoneUsers: this.user.XrefPhoneUsers?.map(xref => ({
        Id: xref.Id,
        UserId: xref.UserId,
        PhoneId: xref.PhoneId,
        Phone: xref.Phone ? {
          Id: xref.Phone.Id,
          PhoneNumber: xref.Phone.PhoneNumber
        } : undefined
      })) || [],
      XrefUsersRoles: this.getRoleIds().map(roleId => ({ RoleId: roleId })),
      XrefUsersRights: this.getRightIds().map(rightId => ({ RightId: rightId }))
    };
  }
  
  private async updateUser(userData: UserUpdateDTO): Promise<void> {
    const response = await this.api.basicPatch(`${APIEndpoints.Users}/${this.user.Id}`, userData) as Response;
    if (!response.ok) {
      throw new Error('Failed to update user');
    }
  }
  
  private async parseResponse(response: Response, context: string): Promise<any> {
    try {
      return await response.json();
    } catch (error) {
      throw new Error(`Failed to parse ${context} response: ${response.statusText}`);
    }
  }
  
  private showSuccessMessage(userName: string): void {
    this.toast.showSuccess(`User ${userName} has been updated successfully.`);
  }
  
  private handleError(error: any): void {
    this.errorMessage = (error as Error).message || 'Failed to update user. Please try again.';
    this.toast.showError(this.errorMessage);
  }

  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.editUserForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) return 'This field is required';
      if (control.errors['email']) return 'Invalid email format';
      if (control.errors['invalidPhone']) return 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) return this.errorMessage;
    }
    return '';
  }

  private getRoleIds(): number[] {
    const roleIds = Array.isArray(this.editUserForm.value.roleId) 
      ? this.editUserForm.value.roleId 
      : [this.editUserForm.value.roleId];
    return roleIds.map(Number);
  }

  private getRightIds(): number[] {
    const rightIds = Array.isArray(this.editUserForm.value.rightIds)
      ? this.editUserForm.value.rightIds
      : [this.editUserForm.value.rightIds];
    return rightIds.map(Number);
  }
}