<div id="accounting">

    <ejs-tab id="_ejs-component-accountingTab" [items]="tabItems" [enablePersistence]="true">

        <e-tabitems>
            <e-tabitem 
                *ngFor="let tab of tabItems; let i = index"
                [header]="tab" 
                [id]="tab.id" 
                [tabIndex]="i">
                <ng-template #headerTemplate>
                    <div class="tab-header">
                        <fa-icon [icon]="tab.faIcon" />
                        <label>{{tab.header?.text}}</label>
                    </div>
                </ng-template>

                <ng-template #content let-data>
                    <div class="tab-contents">
                        <ng-container [ngSwitch]="tab.id">
                            <invoices-grid #invoicesGrid *ngSwitchCase="'invoices'" />
                            <deposits-grid #depositsGrid *ngSwitchCase="'deposits'" />
                        </ng-container>
                    </div>
                </ng-template>
            </e-tabitem>
        </e-tabitems>

    </ejs-tab>
</div>