<div id="users-grid">

    <grid-template #usersGrid [settings]="usersGridSettings" [name]="'Users'" [useRoundedEdges]="true" />

    <!-- create dropdown form for editing user roles -->
    <ng-template #XrefUsersRolesEditTemplate let-data>
        <label class="custom-template-label">Role</label>
        <ejs-multiselect #rolesMS
            [showDropDownIcon]="true"
            [fields]="{ text: 'RoleName', value: 'Id' }"
            [dataSource]="rolesData"
            (created)="rolesMSCreated(rolesMS, data.XrefUsersRoles)"
            (select)="rolesMSChanged($event)">
        </ejs-multiselect>
    </ng-template>

    <!-- Create dropdown form for editing user roles -->
    <ng-template #XrefUsersRightsEditTemplate let-data>
        <label class="custom-template-label">Rights</label>
        <ejs-multiselect #rightsMS
            [showDropDownIcon]="true"
            [fields]="{ text: 'FriendlyName', value: 'Id' }"
            [dataSource]="rightsData"
            (created)="rightsMSCreated(rightsMS, data.XrefUsersRights)"
            (select)="rightsMSChanged($event)">
        </ejs-multiselect>
    </ng-template>

    <div class="dialog-container" *ngIf="showEditUserDialog">
        <ejs-dialog #editUserDialog
            [visible]="showEditUserDialog"
            [header]="'Edit User'"
            [isModal]="true"
            [showCloseIcon]="true"
            [closeOnEscape]="true"
            [width]="800"
            (close)="showEditUserDialog = false">
            <edit-user *ngIf="selectedUser" 
                      [user]="selectedUser" 
                      [grid]="usersGrid.grid" 
                      (closeForm)="handleCloseForm($event)">
            </edit-user>
        </ejs-dialog>
    </div>

    <!-- Modal popup for adding new users -->
    <div class="dialog-container" *ngIf="showAddUserDialog">
        <ejs-dialog
            #addUserDialog
            [visible]="showAddUserDialog"
            [header]="'Add New User'"
            [isModal]="true"
            [width]="400"
            (close)="showAddUserDialog = false">
        <add-user [grid]="usersGrid.grid" (closeForm)="handleCloseForm()"></add-user>
    </ejs-dialog>
    </div>
    
    
</div>