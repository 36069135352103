import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from '@components/tasks/tasks.component';
import { FileHubService } from '@services/file-hub/file-hub.service';

@Component({
  selector: 'tasks-tab',
  standalone: true,
  imports: [
    CommonModule,
    TasksComponent
  ],
  template: `
    <app-tasks 
      entityType="CaseFile" 
      [entityId]="fileHub.caseFile?.Id">
    </app-tasks>
  `
})
export class TasksTabComponent {
  constructor(public fileHub: FileHubService) {}
}
