// Angular
import { Component, Renderer2, Input, AfterViewInit, ViewEncapsulation, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

// Intenal
import * as BoxModel from '@models/components/box-ui.model';
import { HeadService } from '@services/head/head.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';

declare let Box: any;

@Component({
  selector: 'box-ui',
  standalone: true,
  imports: [
    CommonModule,
    LoadingModule
  ],
  templateUrl: './box-ui.component.html',
  styleUrl: './box-ui.component.scss',
  providers: [HeadService],
  encapsulation: ViewEncapsulation.None
})
export class BoxUiComponent implements AfterViewInit {

  constructor(
    private renderer: Renderer2,
    private headService: HeadService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { }

  @Input() folderId?: string;
  @Input() componentData: BoxModel.BoxComponentInterface = {
    boxCdnJS: '',
    boxCdnCss: '',
    name: BoxModel.BoxComponentsType.ContentExplorer
  };
  loadingBoxUI: WritableSignal<boolean> = signal(true);

  ngAfterViewInit(): void {
    if (this.componentData.name) {
      this.watchForBoxComponent();
      this.loadJs(this.componentData.boxCdnJS);
      this.loadCss(this.componentData.boxCdnCss);
    }
  }

  private loadCss(href: string): void {
    if (href === '' || this.headService.isStylesheetLoaded(href)) return
    const styleElement = this.headService.loadStylesheetLink(this.renderer, href);

    styleElement.onerror = () => {
      console.warn(`Could not load ${this.componentData.name} Stylesheet!`);
    }
  }

  private loadJs(src: string): void {
    if (src === '') return
    if (this.headService.isScriptLoaded(src)) {
      this.initializeComponent()
      return
    }
    const scriptElement = this.headService.loadJsScript(this.renderer, src);

    scriptElement.onload = () => {
      this.initializeComponent()
    }

    scriptElement.onerror = () => {
      console.warn(`Could not load ${this.componentData.name} Script!`);
    }
  }

  /**
   * ~~ NOTICE ~~
   *  Connection can be tested temporarily by creating a developer token 
   *  here -> https://app.box.com/developers/console/app/2268127/configuration
   * 
   *  Copy generated token above and paste into BoxDeveloperToken value 
   *  in both of the files found in @environments/
   */
  private initializeComponent(): void {
    const boxComponentInstance = new Box[this.componentData.name]();
    const logo = document.body.classList.contains('e-dark-mode') ? 'assets/logos/Varent-Logo-RGB_White.png' : 'assets/logos/Varent-Logo-RGB_Mint.png';
    const folder = this.folderId ?? '276364091104';


    this.api.basicFetch(`api/box/token/picker?boxFolderId=${folder}`, false).then((res) => {
      if (res.token) {
        this.initializeComponentHelper(boxComponentInstance, folder, logo, res.token);
      } else {
        const errmsg = `${res.status} Error: Unable to fetch Box token.<br><br>${res.url}`;
        this.toast.showError(errmsg);
      }
    })
  }

  private initializeComponentHelper(instance: any, folder: string, logo: string, token: any): void {
    instance.show(folder, token, {
      container: `#${this.componentData.name.toLowerCase()}`,
      logoUrl: logo,
      showAnnotations: true,
      showDownload: true,
    });
  }

  watchForBoxComponent(): void {
    const boxUi = document.getElementById(`${this.componentData.name.toLowerCase()}`);
    
    if (boxUi) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if ((mutation.target as HTMLElement).id === `${this.componentData.name.toLowerCase()}`) {
            this.loadingBoxUI.set(false);
          }
        });
      });

      observer.observe(boxUi, {
        childList: true, // Watch for added/removed child elements
        subtree: true,   // Watch the entire subtree of the body
      });

    }
  }
}