<div>
    <form class="add-balance-statement-form" [formGroup]="formGroup">
        <div>
            <div class="balance-statement-row balance-statement-container">
                <app-dropdown-multi [hidden]="isGeneratingStatement" id="pandl" name="pandls" placeholder="P&L"
                    [endpoint]="PandLs" [floatLabelType]="floatLabelType" [form]="formGroup">
                </app-dropdown-multi>
                @if(isGeneratingStatement){
                <div class="loading-container">
                    <h1 class="loading-text">Generating Balance In Progress</h1>
                    <div class="spacer"></div>
                    <div class="spinner"></div>
                </div>
                }
                @else {
                <b>Final Balance Due</b>
                <ejs-checkbox #rememberCheck [(checked)]="final"></ejs-checkbox>
                <button ejs-button class="e-primary e-flat" (click)="GenerateBalanceStatement()">Generate</button>
                }
            </div>
            <div style="height: 20px;"></div>
            <app-balance-due-grid #BalanceDueGrid [caseFileId]="caseFileId" [endpoint]="PandLs"></app-balance-due-grid>
        </div>
    </form>
</div>