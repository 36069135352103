<div #card id="card" class="card d-flex flex-column {{cssClass}}" [ngClass]="{ 'pad-bottom' : !footerString && !footerContentWrapper }">

    <header id="card-header" class="header d-flex justify-content-between align-items-center">
        <div *ngIf="cardSettings.cardTitle">
            <h3>{{cardSettings.cardTitle}}</h3>
        </div>
        <div *ngIf="!cardSettings.cardTitle">
            <h3>Card Settings</h3>
        </div>
        <div class="settings-menu">
            <button ejs-button cssClass="e-flat" iconCss="e-icons e-open-link" (click)="expandCard($event)"></button>
            <ejs-menu [items]="cardSettings.cardSettingsMenu ? cardSettings.cardSettingsMenu : defaultSettings.cardSettingsMenu" (select)="settingsMenuClick($event)"></ejs-menu>
        </div>
    </header>

    <!-- calendar card -->
    <main id="card-content" class="content" style="flex-grow: 1;">
        <div *ngIf="cardSettings.gridSettings && cardSettings.scheduleSettings && cardSettings.showCalendarView">
            <div [hidden]="cardSettings.showCalendarView()">
                <grid-template #cardGrid [settings]="cardSettings.gridSettings" [useRoundedEdges]="false" [hideExports]="true"></grid-template>
                <div *ngIf="gridOverlay" class="overlay"></div>
            </div>
            
            <div [hidden]="!cardSettings.showCalendarView()">
                <ejs-schedule
                    [currentView]="cardSettings.scheduleSettings.currentView"
                    [selectedDate]="cardSettings.scheduleSettings.selectedDate"
                    [readonly]="true"
                    [eventSettings]="cardSettings.scheduleSettings.eventSettings"
                    [views]="cardSettings.scheduleSettings.views"
                    [height]="cardSettings.scheduleSettings.height"
                    >
                </ejs-schedule>
            </div>
        </div>

        <!-- default card -->
        <div #defaultCard *ngIf="cardSettings.gridSettings && !cardSettings.scheduleSettings">
            <grid-template #cardGrid [settings]="cardSettings.gridSettings" [useRoundedEdges]="false" [hideExports]="true"></grid-template>
            <div *ngIf="gridOverlay" class="overlay"></div>
        </div>
    </main>
    
    <footer *ngIf="footerContentWrapper || footerString" id="card-footer" class="footer">
        <div *ngIf="footerString" [innerHTML]="footerString"></div>
        <ng-container *ngTemplateOutlet="footerContentWrapper; context: { $implicit: footerContent }"></ng-container>
    </footer>
</div>