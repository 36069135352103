<div *ngIf="showColumn()">
<div id="open-file-menu" class="file-overlay" (click)="toggleLeftColumn()"></div>

<section id="open-file-menu" class="left-column hamburger-mode" [ngClass]="{ 'visible': showColumn()  }">
	<div class="close-button e-icons e-close" (click)="toggleLeftColumn()"></div>

	<div class="list-container">
		<input #textbox class="e-input" type="text" id="textbox" placeholder="Filter"
			title="Type in a name or case number" (keyup)="onkeyup()" />

		<ejs-listview id='list' #casefilelist [dataSource]='openFiles' [headerTitle]="getListHeaderTitle()"
			cssClass='e-list-template' [showHeader]='true' sortOrder='Ascending' (select)="onSelect()">
			<ng-template #template let-data="">
				<div [id]="data.id" class="e-list-item e-card" (click)="onClick($event)">
					<div [id]="data.id" class="e-list-wrapper e-list-multi-line">
						<span class="e-list-item-header">{{data.name}}</span>
						<span class="e-list-content">{{data.id}}</span>
					</div>
					<span class='e-icons e-circle-close' (click)="deleteItem($event)"></span>
				</div>
			</ng-template>
		</ejs-listview>

	</div>
	<div class="blur"></div>
</section>