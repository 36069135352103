<ng-template *ngIf="isLoading(); else surgicalTab">
  <div>Loading...</div>
</ng-template>

<ng-template #surgicalTab>

  <div id="surgical-tab">

    <tab-card [heading]="'Surgical Progresion'">
      <div class="progress-container" *ngIf="segments.length">
          <div *ngFor="let segment of segments; let index = index"
            class="progress-segment"
            [class.active]="selectedSegments[index]"
            (click)="onSegmentClick(index)">
            <label>{{ segment.Label }}</label>
          </div>
      </div>
    </tab-card>
    <div class="grid-container">
      <surgeries-grid (surgerySelected)="onSurgerySelect($event)"></surgeries-grid>
  </div>

    <!-- Surgery Progression Dialog -->
    <ng-container *ngIf="!isLoading()">
      <ejs-dialog #progressionDialog
        [header]="'Update Surgery Progression'"
        [visible]="isDialogVisible"
        [buttons]="dialogButtons"
        [showCloseIcon]="true"
        [isModal]="true"
        [width]="'35%'"
        (overlayClick)="progressionDialog.hide()"
        (close)="progressionDialog.hide()">
        <div class="dialog-content">{{ dialogBody }}</div>
      </ejs-dialog>
    </ng-container>

    <!-- Confirm Undo Dialog -->
    <ng-container *ngIf="!isLoading()">
      <ejs-dialog #undoDialog
        [header]="'Confirm Undo'"
        [visible]="confirmUndoDialogVisible"
        [width]="'400px'"
        [buttons]="confirmUndoButtons"
        [showCloseIcon]="true"
        [isModal]="true"
        [width]="'35%'"
        (overlayClick)="undoDialog.hide()"
        (close)="undoDialog.hide()">
        <div class="dialog-content">{{ confirmUndoDialogBody }}</div>
      </ejs-dialog>
    </ng-container>
  </div>
    
</ng-template>