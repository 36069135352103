// create an array of 5 surgery objects, with fields id, surgery tyoe, provider. sugeon, surgery date, surgery cose estimate, missing invoices, invoices confirmed, and confirmed by

export const surgeries = [
  {
    id: 1,
    surgeryType: 'Knee Replacement',
    provider: 'Dr. Smith',
    surgeon: 'Dr. Smith',
    surgeryDate: '2021-01-01',
    surgeryCostEstimate: 10000,
    missingInvoices: 'yes',
    invoicesConfirmed: 'no',
    confirmedBy: ''
  },
  {
    id: 2,
    surgeryType: 'Hip Replacement',
    provider: 'Dr. Smith',
    surgeon: 'Dr. Smith',
    surgeryDate: '2021-01-01',
    surgeryCostEstimate: 10000,
    missingInvoices: 'yes',
    invoicesConfirmed: 'no',
    confirmedBy: ''
  },
  {
    id: 3,
    surgeryType: 'Shoulder Replacement',
    provider: 'Dr. Smith',
    surgeon: 'Dr. Smith',
    surgeryDate: '2021-01-01',
    surgeryCostEstimate: 10000,
    missingInvoices: 'yes',
    invoicesConfirmed: 'no',
    confirmedBy: ''
  },
  {
    id: 4,
    surgeryType: 'Elbow Replacement',
    provider: 'Dr. Smith',
    surgeon: 'Dr. Smith',
    surgeryDate: '2021-01-01',
    surgeryCostEstimate: 10000,
    missingInvoices: 'yes',
    invoicesConfirmed: 'no',
    confirmedBy: ''
  },
  {
    id: 5,
    surgeryType: 'Ankle Replacement',
    provider: 'Dr. Smith',
    surgeon: 'Dr. Smith',
    surgeryDate: '2021-01-01',
    surgeryCostEstimate: 10000,
    missingInvoices: 'yes',
    invoicesConfirmed: 'no',
    confirmedBy: ''
  }
];

/*
export const detailDataSource = [
  {
    providerType: 'Facility',
    provider: 'Memorial Hospital',
    dateOfService: '2024-03-15',
    sumOfInvoices: 12500.00
  },
  {
    providerType: 'Professional', 
    provider: 'Dr. Smith',
    dateOfService: '2024-03-15',
    sumOfInvoices: 4800.00
  },
  {
    providerType: 'Anesthesia',
    provider: 'Capital Anesthesia Group',
    dateOfService: '2024-03-15', 
    sumOfInvoices: 2100.00
  },
  {
    providerType: 'Surgical Assist',
    provider: 'Dr. Johnson',
    dateOfService: '2024-03-15',
    sumOfInvoices: 1200.00
  },
  {
    providerType: 'Neuromonitoring',
    provider: 'Neuro Care Services',
    dateOfService: '2024-03-15',
    sumOfInvoices: 3400.00
  }
];
*/
interface DetailData {
  surgeryId: number;  // Add this to link to parent surgery
  providerType: string;
  provider: string;
  dateOfService: string;
  sumOfInvoices: number;
}

export const detailDataSource: DetailData[] = [
  {
    surgeryId: 1,
    providerType: 'Facility',
    provider: 'City Hospital',
    dateOfService: '12/15/2020',
    sumOfInvoices: 5000
  },
  {
    surgeryId: 1,
    providerType: 'Professional',
    provider: 'Dr. Smith',
    dateOfService: '12/31/2020',
    sumOfInvoices: 2000
  },
  {
    surgeryId: 2,
    providerType: 'Facility',
    provider: 'County Medical Center',
    dateOfService: '11/20/2020',
    sumOfInvoices: 6000
  },
  {
    surgeryId: 2,
    providerType: 'Professional',
    provider: 'Dr. Smith',
    dateOfService: '12/31/2020',
    sumOfInvoices: 2500
  },
  {
    surgeryId: 3,
    providerType: 'Facility',
    provider: 'General Hospital',
    dateOfService: '10/05/2020',
    sumOfInvoices: 7000
  },
  {
    surgeryId: 4,
    providerType: 'Facility',
    provider: 'Regional Medical Center',
    dateOfService: '12/31/2020',
    sumOfInvoices: 8000
  },
  {
    surgeryId: 5,
    providerType: 'Facility',
    provider: 'Community Hospital',
    dateOfService: '12/31/2020',
    sumOfInvoices: 9000
  }
];