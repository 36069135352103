import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
  GridModule, 
  GridComponent,
  PageService, 
  SortService, 
  ToolbarService,
  FilterService,
  GroupService
} from '@syncfusion/ej2-angular-grids';
import { ApiService } from '@services/api/api.service';
import { Query, DataManager, Predicate } from '@syncfusion/ej2-data';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TaskFormComponent } from './task-form/task-form.component';
import { Task } from '@models/task.model';

@Component({
  selector: 'app-tasks',
  standalone: true,
  imports: [
    CommonModule,
    GridModule,
    DialogModule,
    ButtonModule,
    TaskFormComponent
  ],
  providers: [
    ApiService,
    PageService,
    SortService,
    ToolbarService,
    FilterService,
    GroupService
  ],
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  @Input() entityType?: string;
  @Input() entityId?: number;
  @ViewChild('grid') public grid!: GridComponent;
  
  public settings: any = {
    allowPaging: true,
    pageSettings: { pageSize: 10 },
    allowSorting: true,
    allowFiltering: true,
    filterSettings: { type: 'Excel' },
    toolbar: ['Search'],
    sortSettings: {
      columns: [
        { field: 'DueDate', direction: 'Ascending' }
      ]
    },
    columns: [
      { field: 'Id', isPrimaryKey: true, visible: false },
      { field: 'Name', headerText: 'Name', width: 200 },
      { field: 'Description', headerText: 'Description', width: 300 },
      { field: 'taskType.Description', headerText: 'Type', width: 120 },
      { field: 'priority.Description', headerText: 'Priority', width: 100 },
      { field: 'DueDate', headerText: 'Due Date', width: 120, type: 'date', format: 'MM/dd/yyyy' },
      { field: 'assignee.Name', headerText: 'Assignee', width: 150 },
      { field: 'creator.Name', headerText: 'Created By', width: 150 },
      { field: 'CreatedAt', headerText: 'Created', width: 120, type: 'date', format: 'MM/dd/yyyy' },
      { field: 'completer.Name', headerText: 'Completed By', width: 150 },
      { field: 'CompletedAt', headerText: 'Completed', width: 120, type: 'date', format: 'MM/dd/yyyy' }
    ]
  };
  public dataManager!: DataManager;
  public showAddDialog = false;
  public showEditDialog = false;
  public selectedTask: Task | null = null;

  constructor(private api: ApiService) {}

  async ngOnInit() {
    this.dataManager = await this.api.getOdata('/Tasks');
    
    const query = new Query()
      .expand(['taskType', 'priority', 'assignee', 'creator', 'completer']);

    if (this.entityType && this.entityId) {
      query.where(new Predicate('EntityType', 'equal', this.entityType)
        .and('EntityId', 'equal', this.entityId));
    }
    
    this.settings = {
      ...this.settings,
      dataSource: this.dataManager,
      query
    };
  }

  onAddClick(): void {
    this.selectedTask = null;
    this.showAddDialog = true;
  }

  onEditClick(task: Task): void {
    this.selectedTask = task;
    this.showEditDialog = true;
  }

  onTaskSaved(): void {
    this.grid.refresh();
    this.showAddDialog = false;
    this.showEditDialog = false;
    this.selectedTask = null;
  }

  onDialogClose(): void {
    this.showAddDialog = false;
    this.showEditDialog = false;
    this.selectedTask = null;
  }
} 