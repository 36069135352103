// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPhone, faTableList } from '@fortawesome/free-solid-svg-icons';

// Models
import { Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Type & Interface setup
type PhoneFormControls = {
  [K in keyof Phone]: FormControl<Phone[K] | null>;
};

export type FormResult = {
  status: 'success' | 'warning' | 'error';
  message: string;
} | undefined;

@Component({
  selector: 'phone-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule
  ],
  templateUrl: './phone-number-form.component.html',
  styleUrl: './phone-number-form.component.scss'
})
export class PhoneFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 

     effect((onCleanup) => {
      const { currentValue } = this.formState();
      if (currentValue) this.phoneForm.patchValue(currentValue, { emitEvent: false });
      this.loadingForm = false;

      // Cleanup previous subscriptions
      onCleanup(() => this.phoneForm.reset());
    });
  }

  // Decorator variables
  @Input() phone!: Signal<Phone | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayFields: string[] = ['PhoneNumber', 'PhoneType'];
  @Input() displayButtons: boolean = false;
  @Output() formResult: EventEmitter<FormResult> = new EventEmitter<FormResult>();  

  // Form state
  protected readonly formState = computed(() => {
    const currentPhone = this.phone();
    return {
      isValid: this.phoneForm.valid,
      hasChanges: currentPhone !== this.phoneForm.value,
      currentValue: currentPhone
    };
  });

  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.phoneForm.controls).forEach(key => {
      const control = this.phoneForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  phoneHTMLElement: Element | null = null;
  phoneTypes: any;
  phoneTypesQuery: Query;
  phoneForm = new FormGroup<PhoneFormControls>({
    'Id': new FormControl(undefined),
    'PhoneNumber': new FormControl(undefined, [Validators.required]),
    'PhoneType': new FormControl(undefined)
  });
  phoneIcons = {
    phoneNumber: faPhone,
    phoneType: faTableList
  }

  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions'
  };

  ngOnInit() {
    this.fetchPhoneTypes();
  }

  ngAfterViewInit() {
    this.phoneHTMLElement = document.querySelector('phone-form');
    this.updateWidth(this.phoneHTMLElement?.clientWidth || window.innerWidth);
    setTimeout(() => this.watchInputElements(), 500);
  }

  // On form submit
  async onSubmit() {
    let submitResults: any;
    if (this.phoneForm.invalid) throw new Error('Form is invalid');

    const submitType = this.submitType ? this.submitType : this.phoneForm.get('Id')?.value ? 'PATCH' : 'POST';
    const endpoint = this.phoneForm.get('Id')?.value ? `${APIEndpoints.Phones}/${this.phoneForm.get('Id')?.value}` : `${APIEndpoints.Phones}`;

    // Check for valid form first
    if (this.phoneForm.invalid) {
      submitResults = 'error';
      this.loadingForm = false;
      this.formResult.emit(submitResults);
      return submitResults;
    }

    // Check if form has changes
    if (this.phoneForm.pristine) {
      submitResults = 'no-change';
      this.loadingForm = false;
      this.formResult.emit(submitResults);
      return submitResults;
    }
    console.log('PHONE FORM', this.phoneForm.value);
    console.log('PHONE SUBMIT TYPE', submitType);
    return await this.formCRUD.submitForm(this.phoneForm, `odata${endpoint}`, submitType).then((res) => {
      if (res instanceof Error) throw new Error('Failed to create phone');
      return res;
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
    }  else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.phoneHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.phoneForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['pattern']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  private fetchPhoneTypes(): void {
    this.api.basicFetch(APIEndpoints.PhoneTypes).then(
      (response: any) => {
        const data = response.value; 
        this.phoneTypes = data.map((item: any) => ({
          id: item.Id,
          description: item.Description
        }));
      }
    ).catch(
      (error) => {
        console.error('Error fetching phone types', error);
      }
    );
  }
}