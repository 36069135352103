import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { BACKENDURL } from '@environments/environment';
import { NetworkStatus } from '@app/shared/models/components/provider-map.model';
import { Query } from '@syncfusion/ej2-data';
import { ApiService } from '@services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  private baseUrl = BACKENDURL;

  constructor(private api: ApiService) { }

  getNetworkStatuses(): Observable<NetworkStatus[]> {
    const endpoint = '/ProviderPriorityStatuses';
    const query = new Query();
    return from(this.api.getOdata(endpoint).executeQuery(query)).pipe(
      map(response => response as unknown as NetworkStatus[])
    );
  }
} 