import { inject, Injectable } from '@angular/core';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { ApiService } from '@root/src/app/shared/services/api/api.service';

@Injectable({
  providedIn: 'root'
})

// this service is used to set and get the endpoint for the special instructions
// EX: both law firm and providers have a special instructions tab, 
// so we need to set the endpoint for when we click the save button

export class SpecialInstructionsService {
  private api: ApiService;

  constructor() {
    this.api = inject(ApiService);
  }


  saveInstructions(id: number, payload: any, entityType: APIEndpoints) {
    return this.api.basicPatch(entityType + '(' + id + ')',  payload);
  }
  
}
