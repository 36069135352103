<div id="home-tab" class="tab-content">
    
    <div class="row">

        <!-- Recent File Activity -->
        <div class="col col-12 col-lg-6">
            <tab-card [heading]="'Recent File Activity'" [loadingContent]="loadingHomeTabData">
                <ng-container *ngIf="!recentActivityGrid; else activityGrid">
                    <loading-overlay [loading]="loadingHomeTabData" />
                </ng-container>
                <ng-template #activityGrid>
                    <div class="summary grid">
                        <grid-template #recentActivity [settings]="recentActivityGrid" />
                    </div>
                </ng-template>
            </tab-card>
        </div>

        <!-- Summaries -->
        <div class="col-12 col-lg-6">

            <div class="row">

                <!-- Counts Summaries -->
                <div class="col-12">
                    
                    <div class="row">

                        <div class="col col-12 col-lg-4">
                            <tab-card [heading]="'Work Items Count'" [loadingContent]="loadingHomeTabData">
                                <div class="summary row">
                                    <span class="col-12 col-sm-6"><label>Active: </label>{{workItemsCountData ? workItemsCountData.Active : ''}}</span>
                                    <span class="col-12 col-sm-6"><label>Total: </label>{{workItemsCountData ? workItemsCountData.Total : ''}}</span>
                                </div>
                            </tab-card>
                        </div>

                        <div class="col col-12 col-lg-4">
                            <tab-card [heading]="'Invoices Count'" [loadingContent]="loadingHomeTabData">
                                <div class="summary row">
                                    <span class="col-12 col-sm-6"><label>Providers: </label>{{invoicesCountData ? invoicesCountData.Providers : ''}}</span>
                                    <span class="col-12 col-sm-6"><label>Total: </label>{{invoicesCountData ? invoicesCountData.Total : ''}}</span>
                                </div>
                            </tab-card>
                        </div>

                        <div class="col col-12 col-lg-4">
                            <tab-card [heading]="'Appts Count'" [loadingContent]="loadingHomeTabData">
                                <div class="summary row">
                                    <span class="col-12 col-sm-6"><label>Active: </label>{{appointmentsCountData ? appointmentsCountData.Active : ''}}</span>
                                    <span class="col-12 col-sm-6"><label>Total: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                                </div>
                            </tab-card>
                        </div>

                    </div>

                </div>

                <!-- Financial Summaries -->
                <div class="col col-12">
                    <tab-card [heading]="'Financial Summary'" [loadingContent]="loadingHomeTabData">
                        <div class="summary financial row">
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Days Open: </label>{{appointmentsCountData ? appointmentsCountData.Active : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Total COGS: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Total Billed: </label>{{appointmentsCountData ? appointmentsCountData.Active : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Reductions: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>ROIC: </label>{{appointmentsCountData ? appointmentsCountData.Active : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Deposits: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                        </div>
                    </tab-card>
                </div>

                <!-- Invoice Summaries -->
                <div class="col col-12">
                    <tab-card [heading]="'Invoice Summary'" [loadingContent]="loadingHomeTabData">
                        <div class="summary grid">
                            <ng-container *ngIf="invoiceSummaryGrid">
                                <grid-template #invoiceSummary [settings]="invoiceSummaryGrid" />
                            </ng-container>
                        </div>
                    </tab-card>
                </div>
            </div>
        </div>
    </div>
</div>