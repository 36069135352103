//Angular
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

// 3rd Party
import { AccordionModule } from '@syncfusion/ej2-angular-navigations'
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import * as BoxModel from '@models/components/box-ui.model';
import { BoxUiComponent } from '@features/box/box-ui/box-ui.component';
import { ApiService } from '@services/api/api.service';

@Component({
  selector: 'app-view-balance-statement',
  standalone: true,
  imports: [AccordionModule,
    CheckBoxAllModule,
    MultiSelectAllModule,
    ReactiveFormsModule,
    BoxUiComponent,],
  templateUrl: './view-balance-statement.component.html',
  styleUrl: './view-balance-statement.component.scss'
})
export class ViewBalanceStatementComponent {
  constructor(
    private api: ApiService
  ) { }

  @Input() caseFileId: number;

  //Gets data to view the box folder
  componentData: BoxModel.BoxComponentInterface = {
    boxCdnJS: 'https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/explorer.js',
    boxCdnCss: 'https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/explorer.css',
    name: BoxModel.BoxComponentsType.ContentExplorer
  };

  ngOnInit(): void {

  }
}
