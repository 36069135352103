<div id="modalities">
    <ng-container *ngIf="schedulerSignals.loading.ModalityTypes() || schedulerSignals.loading.Modalities(); else modalitiesList">
        <loading-overlay [loading]="schedulerSignals.loading.ModalityTypes"></loading-overlay>
    </ng-container>

    <ng-template #modalitiesList>
        <div *ngFor="let modalityType of modalityTypes(); index as i;">
            <div class="category {{modalityType.Description}}" [style.backgroundColor]="modalityColors[modalityType.Id ?? 0]">
                <ejs-checkbox #modalityCheckbox
                    label="{{modalityType.Description}}" 
                    value="{{modalityType.Id}}"
                    [checked]="true"
                    (change)="onModalityTypeChange($event, modalityType)">
                </ejs-checkbox>
            </div>
        </div>
    </ng-template>
</div>