// Angular
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CellSaveEventArgs } from '@syncfusion/ej2-angular-treegrid';
import { GridModel, RowSelectEventArgs, CommandClickEventArgs, EditEventArgs} from '@syncfusion/ej2-angular-grids';
import { DropDownList, DropDownListModule, MultiSelect, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';

// Models
import { User, Role, Right, XrefUsersRole, XrefUsersRight } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AddUserComponent } from '@components/forms/user/add/add-user.component';
import { EditUserComponent } from '@components/forms/user/edit/edit-user.component';

@Component({
  selector: 'users-grid',
  standalone: true,
  imports: [
    CommonModule,
    DropDownListModule,
    MultiSelectModule, 
    GridTemplateModule,
    DialogModule,
    AddUserComponent,
    EditUserComponent
  ],
  templateUrl: './users-grid.component.html',
  styleUrls: ['./users-grid.component.scss']
})
export class UsersGridComponent implements OnInit {
  
  constructor(
    private api: ApiService,
    private router: Router,
    private globals: GlobalsService,
    private toastMessage: ToastMessageService
  ) { }

  // Grid variables
  @ViewChild('usersGrid') usersGrid: GridTemplateComponent;
  @ViewChild('XrefUsersRolesEditTemplate', { static: true }) XrefUsersRolesEditTemplate!: string;
  @ViewChild('XrefUsersRightsEditTemplate', { static: true }) XrefUsersRightsEditTemplate!: string;
  @ViewChild('addUserDialog') addUserDialog: DialogComponent;
  usersGridSettings: GridModel;
  gridCurrentRow: RowSelectEventArgs;
  isAdmin: boolean = false;
  showAddUserDialog: boolean = false;
  showEditUserDialog: boolean = false;
  
  // Initialize user roles variables
  @ViewChild('rolesDD') rolesDD: DropDownList;
  rolesData: DataManager = this.api.getOdata(APIEndpoints.Roles);

  // Initialize user rights variables
  @ViewChild('rightsMS') rightsMS: MultiSelect;
  rightsData: DataManager = this.api.getOdata(APIEndpoints.Rights);
  rightsMSValues: any[] = [];

  // Add dialog template in the component
  @ViewChild('editUserDialog') editUserDialog: any;
  selectedUser: User | null = null;

  ngOnInit(): void {
    this.usersGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Users),
      query: new Query().expand('XrefUsersRoles($expand=Role),XrefUsersRights($expand=Right),XrefPhoneUsers($expand=Phone)'),
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false, showInColumnChooser: false },
        { field: 'Username', allowEditing: false },
        { field: 'Name', allowEditing: false },
        { field: 'Email', allowEditing: false },
        { field: 'Title' },
        { field: 'XrefUsersRoles', headerText: 'Role', allowFiltering: false, allowSorting: false, editTemplate: this.XrefUsersRolesEditTemplate, 
          valueAccessor: (field: string, data: Object): string[] => this.roleValueAccessor(field, data) },
        { field: 'XrefUsersRights', headerText: 'Assigned Rights', allowFiltering: false, allowSorting: false, editTemplate: this.XrefUsersRightsEditTemplate, 
          valueAccessor: (field: string, data: Object): string[] => this.rightsValueAccessor(field, data) },
        { type: 'commands', headerText: 'Actions', allowSorting: false, visible: this.globals.isResponsive ? false : true }
      ],
      toolbar: ['Add User'],
      editSettings: { 
        allowEditing: false
      },
      actionComplete: ($event: any) => this.onActionComplete($event),
      toolbarClick: ($event: any) => this.onToolbarClick($event),
      cellSave: ($event: CellSaveEventArgs) => this.usersCellSave($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    };
  }

  // Update display of roles by retrieving the RoleName from the data
  roleValueAccessor(field: string, data: object): string[] {
    var returnedData: string[] = [];

    // Check if data[field] is defined and is an array
    if (Array.isArray((data as any)[field])) {
      returnedData = (data as any)[field].map(
        (XrefUsersRoles: { Role: { RoleName: string } }) => {
          return XrefUsersRoles.Role.RoleName;
        }
      );
    } else {
      // Handle the case where data[field] is not an array
      returnedData = [(data as any)[field]?.Role?.RoleName || ''];
    }

    return returnedData;
  }

  // Update display of roles by retrieving an array of FriendlyName from the data
  rightsValueAccessor(field: string, data: any): any {
    var returnedData: string[] = [];

    // Check if data[field] is defined and is an array
    if (Array.isArray((data as any)[field])) {
      returnedData = (data as any)[field].map(
        (XrefUsersRights: { Right: { FriendlyName: string } }) => {
          return XrefUsersRights.Right.FriendlyName;
        }
      );
    } else {
      // Handle the case where data[field] is not an array
      returnedData = [(data as any)[field]?.Right?.FriendlyName || ''];
    }

    return returnedData;
  }

  onCreated() {
    this.usersGrid.grid.toolbar = this.usersGrid.settings.toolbar as any[];
    this.usersGrid.insertToolbarItem({ 
        text: 'Add User', 
        tooltipText: 'Add User', 
        prefixIcon: 'e-icons e-add', 
        align: 'Right',
        cssClass: 'e-primary'
    });
  }

  onActionComplete(args: EditEventArgs) {
    
    if (args.requestType === 'beginEdit') {
      const dialog = (args as any).dialog;
      dialog.header = `Editing ${(args.rowData as any)?.Username}`;
    }
  }

  onToolbarClick(args: any) {

    if (args.item.text === 'Add User') {
      args.cancel = true;
      this.showAddUserDialog = true;
      if (this.showAddUserDialog) this.addUserDialog.show();
    }
  }

  // Add logic for navigating to user profile
  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const userId: number | undefined = (commandClickArgs?.rowData as User).Id;
    const commandTitle = commandClickArgs.commandColumn?.title;

    switch (commandTitle) {
      case 'View':
        if (userId) this.router.navigate([`${this.router.url}/${userId}`]);
        else this.toastMessage.showError('User ID is not defined');
        break;
      case 'Edit':
        this.selectedUser = commandClickArgs.rowData as User;
        this.showEditUserDialog = true;
        break;
      default:
        break;
    }
  }

  usersCellSave(args: any) {
    
    // Update form values to be valid format for DB XrefUsersRoles column
    if (args.columnName === 'XrefUsersRights') {
      
      if (this.rightsMSValues.length > 0) {        
        args.value = this.rightsMSValues.map(right => {
          return { RightId: right.Id, Right: right };
        });

        // Reset global variable
        this.rightsMSValues = [];
      }
    }
  }

  // Occurs when editing user rights
  rightsMSCreated(component?: MultiSelect, data?: XrefUsersRight[]) {

    // Set values for MultiSelect Component if data exists
    if (component && data && data.length > 0) {
      component.value = data?.map(item => item.RightId as number);
    }
  }

  // Capture changes made to user's role
  rolesDDChanged(args: any): void {

    if (args.isInteracted) {
      var selectedRows = this.usersGrid.grid?.getSelectedRowIndexes();

      if (this.usersGrid.grid?.editSettings.mode === 'Batch') {

        if (selectedRows && selectedRows.length === 1) {
          var currentCell = this.usersGrid.grid?.getCellFromIndex(selectedRows[0], this.usersGrid.grid?.getColumnIndexByField('XrefUsersRoles'));
          this.usersGrid.grid.updateCell(selectedRows[0], 'XrefUsersRoles', args.value);
          currentCell.innerHTML = args.itemData.RoleName;
        }
      }
    }
  }

  // Capture changes made to user's assigned rights
  rightsMSChanged(args: any): void {

    if (args.itemData) {
      this.rightsMSValues.push(args.itemData as Right);
      this.rightsMSValues = this.removeDuplicatesInArray(this.rightsMSValues);
    }
  }

  // Ensures no duplicates are saved to database
  removeDuplicatesInArray(arr: any[]) {
    const uniqueIds = new Set();

    return arr.filter(item => {
      if (!uniqueIds.has(item.RightId)) {
        uniqueIds.add(item.RightId);
        return true;
      }
      return false;
    });
  }

  rolesMSCreated(multiSelect: any, userRoles: any[]): void {
    if (!userRoles) return;
    const selectedRoles = userRoles.map(ur => ur.RoleId);
    multiSelect.value = selectedRoles;
  }

  rolesMSChanged(args: any): void {
    console.log('args: ', args);
  }

  handleCloseForm(needsRefresh: boolean = false): void {
    this.showAddUserDialog = false;
    this.showEditUserDialog = false;
    if (needsRefresh) {
      setTimeout(() => {
        this.refreshGrid();
      }, 100);
    }
  }

  refreshGrid(): void {
    if (this.usersGrid?.grid) {
      this.usersGrid.grid.refresh();
    }
  }
}
