"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrashedFilesManager = exports.DeleteTrashedFileByIdHeaders = exports.GetTrashedFileByIdHeaders = exports.RestoreFileFromTrashHeaders = exports.DeleteTrashedFileByIdOptionals = exports.GetTrashedFileByIdOptionals = exports.RestoreFileFromTrashOptionals = void 0;
exports.serializeRestoreFileFromTrashRequestBodyParentField = serializeRestoreFileFromTrashRequestBodyParentField;
exports.deserializeRestoreFileFromTrashRequestBodyParentField = deserializeRestoreFileFromTrashRequestBodyParentField;
exports.serializeRestoreFileFromTrashRequestBody = serializeRestoreFileFromTrashRequestBody;
exports.deserializeRestoreFileFromTrashRequestBody = deserializeRestoreFileFromTrashRequestBody;
const trashFileRestored_generated_js_1 = require("../schemas/trashFileRestored.generated.js");
const trashFile_generated_js_1 = require("../schemas/trashFile.generated.js");
const errors_js_1 = require("../box/errors.js");
const network_generated_js_1 = require("../networking/network.generated.js");
const fetchOptions_generated_js_1 = require("../networking/fetchOptions.generated.js");
const utils_js_1 = require("../internal/utils.js");
const utils_js_2 = require("../internal/utils.js");
const json_js_1 = require("../serialization/json.js");
const json_js_2 = require("../serialization/json.js");
class RestoreFileFromTrashOptionals {
  constructor(fields) {
    this.requestBody = {};
    this.queryParams = {};
    this.headers = new RestoreFileFromTrashHeaders({});
    this.cancellationToken = void 0;
    if (fields.requestBody !== undefined) {
      this.requestBody = fields.requestBody;
    }
    if (fields.queryParams !== undefined) {
      this.queryParams = fields.queryParams;
    }
    if (fields.headers !== undefined) {
      this.headers = fields.headers;
    }
    if (fields.cancellationToken !== undefined) {
      this.cancellationToken = fields.cancellationToken;
    }
  }
}
exports.RestoreFileFromTrashOptionals = RestoreFileFromTrashOptionals;
class GetTrashedFileByIdOptionals {
  constructor(fields) {
    this.queryParams = {};
    this.headers = new GetTrashedFileByIdHeaders({});
    this.cancellationToken = void 0;
    if (fields.queryParams !== undefined) {
      this.queryParams = fields.queryParams;
    }
    if (fields.headers !== undefined) {
      this.headers = fields.headers;
    }
    if (fields.cancellationToken !== undefined) {
      this.cancellationToken = fields.cancellationToken;
    }
  }
}
exports.GetTrashedFileByIdOptionals = GetTrashedFileByIdOptionals;
class DeleteTrashedFileByIdOptionals {
  constructor(fields) {
    this.headers = new DeleteTrashedFileByIdHeaders({});
    this.cancellationToken = void 0;
    if (fields.headers !== undefined) {
      this.headers = fields.headers;
    }
    if (fields.cancellationToken !== undefined) {
      this.cancellationToken = fields.cancellationToken;
    }
  }
}
exports.DeleteTrashedFileByIdOptionals = DeleteTrashedFileByIdOptionals;
class RestoreFileFromTrashHeaders {
  constructor(fields) {
    /**
     * Extra headers that will be included in the HTTP request. */
    this.extraHeaders = {};
    if (fields.extraHeaders !== undefined) {
      this.extraHeaders = fields.extraHeaders;
    }
  }
}
exports.RestoreFileFromTrashHeaders = RestoreFileFromTrashHeaders;
class GetTrashedFileByIdHeaders {
  constructor(fields) {
    /**
     * Extra headers that will be included in the HTTP request. */
    this.extraHeaders = {};
    if (fields.extraHeaders !== undefined) {
      this.extraHeaders = fields.extraHeaders;
    }
  }
}
exports.GetTrashedFileByIdHeaders = GetTrashedFileByIdHeaders;
class DeleteTrashedFileByIdHeaders {
  constructor(fields) {
    /**
     * Extra headers that will be included in the HTTP request. */
    this.extraHeaders = {};
    if (fields.extraHeaders !== undefined) {
      this.extraHeaders = fields.extraHeaders;
    }
  }
}
exports.DeleteTrashedFileByIdHeaders = DeleteTrashedFileByIdHeaders;
class TrashedFilesManager {
  constructor(fields) {
    this.networkSession = new network_generated_js_1.NetworkSession({});
    if (fields.auth !== undefined) {
      this.auth = fields.auth;
    }
    if (fields.networkSession !== undefined) {
      this.networkSession = fields.networkSession;
    }
  }
  /**
     * Restores a file that has been moved to the trash.
     *
     * An optional new parent ID can be provided to restore the file to in case the
     * original folder has been deleted.
     * @param {string} fileId The unique identifier that represents a file.
    
    The ID for any file can be determined
    by visiting a file in the web application
    and copying the ID from the URL. For example,
    for the URL `https://*.app.box.com/files/123`
    the `file_id` is `123`.
    Example: "12345"
     * @param {RestoreFileFromTrashOptionalsInput} optionalsInput
     * @returns {Promise<TrashFileRestored>}
     */
  restoreFileFromTrash(fileId_1) {
    return __awaiter(this, arguments, void 0, function* (fileId, optionalsInput = {}) {
      const optionals = new RestoreFileFromTrashOptionals({
        requestBody: optionalsInput.requestBody,
        queryParams: optionalsInput.queryParams,
        headers: optionalsInput.headers,
        cancellationToken: optionalsInput.cancellationToken
      });
      const requestBody = optionals.requestBody;
      const queryParams = optionals.queryParams;
      const headers = optionals.headers;
      const cancellationToken = optionals.cancellationToken;
      const queryParamsMap = (0, utils_js_1.prepareParams)({
        ['fields']: queryParams.fields ? queryParams.fields.map(utils_js_2.toString).join(',') : undefined
      });
      const headersMap = (0, utils_js_1.prepareParams)(Object.assign({}, headers.extraHeaders));
      const response = yield this.networkSession.networkClient.fetch(new fetchOptions_generated_js_1.FetchOptions({
        url: ''.concat(this.networkSession.baseUrls.baseUrl, '/2.0/files/', (0, utils_js_2.toString)(fileId)),
        method: 'POST',
        params: queryParamsMap,
        headers: headersMap,
        data: serializeRestoreFileFromTrashRequestBody(requestBody),
        contentType: 'application/json',
        responseFormat: 'json',
        auth: this.auth,
        networkSession: this.networkSession,
        cancellationToken: cancellationToken
      }));
      return Object.assign(Object.assign({}, (0, trashFileRestored_generated_js_1.deserializeTrashFileRestored)(response.data)), {
        rawData: response.data
      });
    });
  }
  /**
     * Retrieves a file that has been moved to the trash.
     *
     * Please note that only if the file itself has been moved to the
     * trash can it be retrieved with this API call. If instead one of
     * its parent folders was moved to the trash, only that folder
     * can be inspected using the
     * [`GET /folders/:id/trash`](e://get_folders_id_trash) API.
     *
     * To list all items that have been moved to the trash, please
     * use the [`GET /folders/trash/items`](e://get-folders-trash-items/)
     * API.
     * @param {string} fileId The unique identifier that represents a file.
    
    The ID for any file can be determined
    by visiting a file in the web application
    and copying the ID from the URL. For example,
    for the URL `https://*.app.box.com/files/123`
    the `file_id` is `123`.
    Example: "12345"
     * @param {GetTrashedFileByIdOptionalsInput} optionalsInput
     * @returns {Promise<TrashFile>}
     */
  getTrashedFileById(fileId_1) {
    return __awaiter(this, arguments, void 0, function* (fileId, optionalsInput = {}) {
      const optionals = new GetTrashedFileByIdOptionals({
        queryParams: optionalsInput.queryParams,
        headers: optionalsInput.headers,
        cancellationToken: optionalsInput.cancellationToken
      });
      const queryParams = optionals.queryParams;
      const headers = optionals.headers;
      const cancellationToken = optionals.cancellationToken;
      const queryParamsMap = (0, utils_js_1.prepareParams)({
        ['fields']: queryParams.fields ? queryParams.fields.map(utils_js_2.toString).join(',') : undefined
      });
      const headersMap = (0, utils_js_1.prepareParams)(Object.assign({}, headers.extraHeaders));
      const response = yield this.networkSession.networkClient.fetch(new fetchOptions_generated_js_1.FetchOptions({
        url: ''.concat(this.networkSession.baseUrls.baseUrl, '/2.0/files/', (0, utils_js_2.toString)(fileId), '/trash'),
        method: 'GET',
        params: queryParamsMap,
        headers: headersMap,
        responseFormat: 'json',
        auth: this.auth,
        networkSession: this.networkSession,
        cancellationToken: cancellationToken
      }));
      return Object.assign(Object.assign({}, (0, trashFile_generated_js_1.deserializeTrashFile)(response.data)), {
        rawData: response.data
      });
    });
  }
  /**
     * Permanently deletes a file that is in the trash.
     * This action cannot be undone.
     * @param {string} fileId The unique identifier that represents a file.
    
    The ID for any file can be determined
    by visiting a file in the web application
    and copying the ID from the URL. For example,
    for the URL `https://*.app.box.com/files/123`
    the `file_id` is `123`.
    Example: "12345"
     * @param {DeleteTrashedFileByIdOptionalsInput} optionalsInput
     * @returns {Promise<undefined>}
     */
  deleteTrashedFileById(fileId_1) {
    return __awaiter(this, arguments, void 0, function* (fileId, optionalsInput = {}) {
      const optionals = new DeleteTrashedFileByIdOptionals({
        headers: optionalsInput.headers,
        cancellationToken: optionalsInput.cancellationToken
      });
      const headers = optionals.headers;
      const cancellationToken = optionals.cancellationToken;
      const headersMap = (0, utils_js_1.prepareParams)(Object.assign({}, headers.extraHeaders));
      const response = yield this.networkSession.networkClient.fetch(new fetchOptions_generated_js_1.FetchOptions({
        url: ''.concat(this.networkSession.baseUrls.baseUrl, '/2.0/files/', (0, utils_js_2.toString)(fileId), '/trash'),
        method: 'DELETE',
        headers: headersMap,
        responseFormat: 'no_content',
        auth: this.auth,
        networkSession: this.networkSession,
        cancellationToken: cancellationToken
      }));
      return void 0;
    });
  }
}
exports.TrashedFilesManager = TrashedFilesManager;
function serializeRestoreFileFromTrashRequestBodyParentField(val) {
  return {
    ['id']: val.id
  };
}
function deserializeRestoreFileFromTrashRequestBodyParentField(val) {
  if (!(0, json_js_2.sdIsMap)(val)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting a map for "RestoreFileFromTrashRequestBodyParentField"'
    });
  }
  if (!(val.id == void 0) && !(0, json_js_1.sdIsString)(val.id)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "id" of type "RestoreFileFromTrashRequestBodyParentField"'
    });
  }
  const id = val.id == void 0 ? void 0 : val.id;
  return {
    id: id
  };
}
function serializeRestoreFileFromTrashRequestBody(val) {
  return {
    ['name']: val.name,
    ['parent']: val.parent == void 0 ? val.parent : serializeRestoreFileFromTrashRequestBodyParentField(val.parent)
  };
}
function deserializeRestoreFileFromTrashRequestBody(val) {
  if (!(0, json_js_2.sdIsMap)(val)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting a map for "RestoreFileFromTrashRequestBody"'
    });
  }
  if (!(val.name == void 0) && !(0, json_js_1.sdIsString)(val.name)) {
    throw new errors_js_1.BoxSdkError({
      message: 'Expecting string for "name" of type "RestoreFileFromTrashRequestBody"'
    });
  }
  const name = val.name == void 0 ? void 0 : val.name;
  const parent = val.parent == void 0 ? void 0 : deserializeRestoreFileFromTrashRequestBodyParentField(val.parent);
  return {
    name: name,
    parent: parent
  };
}
