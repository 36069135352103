<div id="law-firms-grid">

    <div *ngIf="loadingData()">
        <loading-overlay [loading]="loadingData" />
    </div>
    <div *ngIf="!loadingData()">
        <grid-template #lawFirmsGrid [settings]="lawFirms" [name]="'Law Firms'" [useRoundedEdges]="true" />
    </div>

    <ng-template #nameTemplate let-data>
        <a class="link" (click)="onLawFirmClick(data)">
            <span>{{data.Name}}</span>
        </a>
    </ng-template>

    <div class="dialog-container" *ngIf="viewLawFirmAlert">
        <ejs-dialog id="view-law-firm-dialog" #viewLawFirmDialog 
            header="View Law Firm"    
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="viewLawFirmAlert"
            (close)="viewLawFirmAlert = false">
            <p>Viewing account details for a particular entity is currently under construction.</p>
        </ejs-dialog>
    </div>

    <!-- Audit Logs Modal -->
    <ejs-dialog #logsDialog [visible]="logsDialogVisibility" 
        [header]="'Law Firm Logs'" 
        [buttons]="logsDialogButtons"
        [target]="'body'"
        [isModal]="true"
        [showCloseIcon]="true"
        [width]="'60%'"
        [height]="'60vh'"
        (beforeOpen)="beforeOpening($event)">
        <ng-template #content>
            <div class="dialog-content logs-dialog-content">
                <app-audit-logs 
                    [logs]="auditLogs"
                    [loading]="auditLogsLoading">
                </app-audit-logs>
            </div>
        </ng-template>
    </ejs-dialog>
</div>

<ejs-dialog id="edit-entity-dialog" #editDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="editDialogVisibility"
    [header]="'Edit ' + [lawFirmSignal()?.Name]"
    (beforeOpen)="beforeOpening($event)"
    (close)="closeDialog()">
    <law-firm-form 
    [lawFirm]="lawFirmSignal"
    [isLawFirm]="true"
    [submitType]="'PATCH'"
    (formCancelled)="editDialogVisibility = false"
    (formSubmitted)="editDialogVisibility = false; this.lawFirmsGrid.refresh();"
  ></law-firm-form>

</ejs-dialog>
<ejs-dialog id="add-entity-dialog" #addDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="addLawFirmDialogVisibility"
    [header]="'Add Law Firm'"
    (beforeOpen)="beforeOpening($event)"
    (close)="closeDialog()">
    <law-firm-form 
    [isLawFirm]="true"
    [submitType]="'POST'"
    (formCancelled)="addLawFirmDialogVisibility = false"
    (formSubmitted)="addLawFirmDialogVisibility = false; this.lawFirmsGrid.refresh();"

  ></law-firm-form>
</ejs-dialog>

<!-- Bulk Edit Law Firms Component -->
<app-bulk-edit-lawfirms #bulkEditLawFirmsComponent></app-bulk-edit-lawfirms>