// Angular
import { 
  Injectable, 
  signal } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// 3rd Party
import { 
  CognitoUserPool,
  CognitoUserSession, 
  CognitoUser,
  AuthenticationDetails,
  IAuthenticationCallback,
  ICognitoUserSessionData, 
  ICognitoUserPoolData,
  ICognitoUserData } from 'amazon-cognito-identity-js';
//import { CognitoMFASettings } from '@aws-amplify/auth/dist/esm/providers/cognito/utils/clients/CognitoIdentityProvider/types';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { environment } from '@environments/environment.development';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

interface ExtendedIAuthenticationCallback extends IAuthenticationCallback {
  associateSecretCode: (secretCode: string) => void;
}

@Injectable({
  providedIn: 'root'
})

export class CognitoService {
  constructor(
    private router: Router,
    private toast: ToastMessageService,
    private globals: GlobalsService,
    private user: UserPreferencesService
  ) { }

  public poolData: ICognitoUserPoolData = {
    UserPoolId: environment.cognitoUserPoolId,
    ClientId: environment.cognitoAppClientId,
  }
  private sessionCredentials: ICognitoUserSessionData;
  public userPool = new CognitoUserPool(this.poolData);
  public cognitoUser: CognitoUser | null = this.userPool.getCurrentUser();
  public usernameQuery: string;
  
  /**
   * Helper Functions
   */

  public getCognitoUser(): CognitoUser | undefined {
    const cognitoUserPool = new CognitoUserPool(this.poolData);
    const cognitoUser = cognitoUserPool.getCurrentUser();
    
    if (cognitoUser) {
      return cognitoUser;
    } else {
      let returnData = {
        message: 'Error, no cognitoUser Found.',
        cognitoUserPool: cognitoUserPool,
        cognitoUser: cognitoUser
      }
      console.log(returnData);
      return undefined;
    }
  }

  public getUserIdToken(): string {
    const cognitoUser = this.getCognitoUser();
    let token = '';

    if(cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        if(err) {
          console.log(err);
          return err.message;
        } else {
          token = session.getIdToken().getJwtToken();
          return token;
        }
      });

      return token;

    } else {
      console.log('No user found')
      return 'No user found';
    }
  }

  public async resendConfirmationCode(user: string): Promise<any> {
    const username = this.globals.removeEmailFromString(user);

    const userData = {
      Username: user,
      Pool: this.userPool,
    }
    const cognitoUser = new CognitoUser(userData);

    if (cognitoUser) {
      return new Promise(async (resolve, reject) => {
        return await cognitoUser.resendConfirmationCode((err: any, result: any) => {
          if(err) {
            reject(err);
            console.log(err);
            return err;
          } else {
            resolve(result);
            console.log('Success: ', result);
            return result;
          }
        });
      });
    } else {
      return Promise.reject(cognitoUser);
    }
  }

  public resendConfirmation(email: string, password: string) {
    const userData = {
      Username: email,
      Pool: this.userPool,
    };
    const authDetails = new AuthenticationDetails({
      Username: email, Password: password
    });
    const cognitoUser = new CognitoUser(userData);

    console.log(cognitoUser);
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log(result);
      },
      onFailure: (err) => {
        console.log(err);
        cognitoUser.resendConfirmationCode((err: any, result: any) => {
          if(err) {
            console.log(err);
            return err;
          } else {
            console.log('Success: ', result);
            return result;
          }
        });
      }
    })
  }

  public async getUserSessionData(): Promise<CognitoUserSession | undefined> {
    const cognitoUser = this.getCognitoUser();

    return new Promise<CognitoUserSession | undefined>((resolve, reject) => {
      cognitoUser?.getSession((err: any, session: CognitoUserSession) => {
        if (!err) {
          resolve(session);
        } else {
          console.log(err);
          reject(err);
        }
      });
    });
  };

  public async getUserMFAOptions(): Promise<any | undefined> {
    const cognitoUser = this.getCognitoUser();
    const toasterService = this.toast;

    let returnObj: any = {}

    if (cognitoUser) {
      return new Promise((resolve, reject) => {
        return cognitoUser.getSession(function(err: any, session: any) {
          if (!err) {
            return cognitoUser.getMFAOptions(async (err, mfaOptions) => {
              if (!err) { 
                if (mfaOptions !== undefined) {
                  returnObj.message = 'getMFAOptions Success! mfaOptions shown in result.';
                  returnObj.mfaOptions = mfaOptions;
                  await returnObj;
                  resolve(mfaOptions as any);
                } else {
                  returnObj.message = 'No value found for getMFAOptions.';
                  returnObj.mfaOptions = mfaOptions;
                  await returnObj;
                  console.log(returnObj);
                  resolve(mfaOptions as any);
                }
              } else {
                returnObj.message = 'getMFAOptions FAIL. Error shown in result.';
                returnObj.mfaOptions = err;
                await returnObj;
                console.log(returnObj);
                reject(err);
                toasterService.showError(err.message);
              }
            });
          }
        })
      })
    } else {
      return Promise.reject(undefined);
    }
  }

  public async logCognitoUserData(user: string | undefined, password: string) {
    let username: string;
    let cognitoUser: CognitoUser | undefined;
    let userData: ICognitoUserData;
    let authDetails: AuthenticationDetails;

    if (user === undefined) {
      cognitoUser = this.getCognitoUser();
      userData = {
        Username: cognitoUser?.getUsername() as string,
        Pool: this.userPool
      }
    } else {
      username = this.globals.removeEmailFromString(user as string);
      userData = {
        Username: username,
        Pool: this.userPool
      }
      cognitoUser = new CognitoUser(userData);
    }

    if (cognitoUser) {
      username = await this.globals.removeEmailFromString(user as string);
      console.log(cognitoUser);
      authDetails = new AuthenticationDetails({
        Username: username, Password: password
      })
      const getMFA = this.getUserMFAOptions();
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async function(result) {
          await getMFA;
          console.log(result);
          cognitoUser?.getUserData((err, data: any) => {
            if (err) {
              console.log(err);
              return;
            }
            console.log(data);
          });
        },
      
        onFailure: function(err) {
          alert(err.message || JSON.stringify(err));
        }
      })
    } else {
      console.log('No cognito user found. ', 'cognitoUser = ' + cognitoUser);
    }
  }

  public rememberDevicePlaceholder() {
    this.cognitoUser?.setDeviceStatusRemembered({
	    onSuccess: function (result) {
        console.log('call result: ' + result);
	    },

	    onFailure: function(err) {
        alert(err);
	    }
    });

    this.cognitoUser?.setDeviceStatusNotRemembered({
      onSuccess: function (result) {
        console.log('call result: ' + result);
      },

      onFailure: function(err) {
        alert(err);
      }
    });
  }

  /**
   * User Sign In
   */
  public async signIn(email: string, password: string): Promise<any> {
    const username = this.globals.removeEmailFromString(email);
    var sessionUserAttributes;

    var PromiseResult = {
      message: '',
      result: {}
    }

    let userData = {
      Username: username,
      Pool: this.userPool,
    }
    
    let authDetails = new AuthenticationDetails({
      Username: email, Password: password
    });

    let cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          console.log(result);
          let router = this.router;
          if (!result.getIdToken().payload['email_verified']) {
            result.getIdToken().payload['email_verified'] = true;
            console.log('Email not verified for ' + cognitoUser.getUsername() + ': ', result.getIdToken().payload['email_verified']);
            console.log('Email not verified for ' + cognitoUser.getUsername() + ': ', result.getIdToken().payload['']);
            alert('User email not verified. Close this window to verify.');
            return cognitoUser.getAttributeVerificationCode('email', {
              onSuccess: function(verifyEmailResult) {
                console.log('call result: ' + verifyEmailResult);
                alert('Update successful. You may now close this window.');
                resolve(PromiseResult);
                router.navigate(['/dashboard']);
                return result;
              },
              onFailure: function(err) {
                delete result.getIdToken().payload['email_verified'];
                alert(err.message || JSON.stringify(err));
                window.location.reload();
                reject(PromiseResult);
                return result;
              },
              inputVerificationCode: function() {
                var verificationCode = prompt('Please input verification code: ', '');
                cognitoUser.verifyAttribute('email', (verificationCode as string), this);
              },
            });
          } else {
            console.log('access token + ' + result.getIdToken().getJwtToken());
            localStorage.setItem('username', username);
            localStorage.setItem('isLoggedIn', 'true');
            this.sessionCredentials = {IdToken: result.getIdToken(), AccessToken: result.getAccessToken()}
            this.cognitoUser = this.userPool.getCurrentUser();
            PromiseResult.message = 'Login Successful';
            PromiseResult.result = result;
            resolve(result);
            this.router.navigate(['/dashboard']);
            return result;
          }
        },
        onFailure: (err) => {
          this.toast.showError(err.message);
          this.cognitoUser = null;
          console.log(err.message);
          PromiseResult.message = 'Login Failed';
          PromiseResult.result = err;
          reject(err);
          return err.message;
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.log(userAttributes);
          PromiseResult.message = 'Login Failed';
          PromiseResult.result = 'newPasswordRequired';
          sessionUserAttributes = userAttributes;
          reject(PromiseResult);
          return PromiseResult;
        },
        
        mfaSetup: (challengeName, challengeParameters) => {
          cognitoUser.associateSoftwareToken({
            associateSecretCode: (secretCode) => {
              var challengeAnswer = prompt('Please input the TOTP code.', '');
              cognitoUser.verifySoftwareToken((challengeAnswer as string), 'My TOTP device', {
                onSuccess: (result) => {
                  console.log(result);
                  return result;
                },
                onFailure: (err) => {
                  console.log(err);
                  return err;
                }
              });
            },
            onFailure: (err) => {
              console.log(err);
              return err;
            }
          });
        },
      
        selectMFAType: (challengeName, challengeParameters) => {
          var mfaType = prompt('Please select the MFA method.', ''); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
          cognitoUser.sendMFASelectionAnswer((mfaType as string), {
            onSuccess: (result) => {
              console.log(result);
            },
            onFailure: (err) => {
              console.log(err);
            },
          });
        },
      
        totpRequired: (secretCode) => {
          var challengeAnswer = prompt('Please input the TOTP code.', '');
          cognitoUser.sendMFACode((challengeAnswer as string), {
            onSuccess: (result) => {
              console.log(result);
            },
            onFailure: (err) => {
              console.log(err);
            }
          }, 'SOFTWARE_TOKEN_MFA');
        },
      
        mfaRequired: (codeDeliveryDetails) => {
          console.log(codeDeliveryDetails);
          var verificationCode = prompt('Please input verification code', '');
          cognitoUser.sendMFACode((verificationCode as string), {
            onSuccess: (result) => {
              console.log(result);
              console.log('access token + ' + result.getIdToken().getJwtToken());
              localStorage.setItem('username', username);
              localStorage.setItem('isLoggedIn', 'true');
              this.sessionCredentials = {IdToken: result.getIdToken(), AccessToken: result.getAccessToken()}
              this.router.navigate(['/dashboard']);
              return result;
            },
            onFailure: (err) => {
              console.log(err);
              alert(err.message);
              return err;
            }
          });
        }
      });
    });
  }

  async replaceTemporaryPassword(user: string, oldPassword: string, newPassword: string): Promise<any> {
    const username = this.globals.removeEmailFromString(user);
    
    var PromiseResult = {
      message: '',
      success: false,
      result: {}
    }

    let userData = {
      Username: username,
      Pool: this.userPool
    }
    
    let authDetails = new AuthenticationDetails({
      Username: username, Password: oldPassword
    })

    let cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolve(result);
          return result;
        },
        onFailure: (err) => {
          reject(err);
          return err;
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
            onSuccess: (result) => {
              console.log(result);
              console.log('User Attributes', userAttributes);
              console.log('Required Attributes', requiredAttributes);
              console.log('access token + ' + result.getIdToken().getJwtToken());
              localStorage.setItem('isLoggedIn', 'true');
              
              this.sessionCredentials = {IdToken: result.getIdToken(), AccessToken: result.getAccessToken()}
              this.cognitoUser = this.userPool.getCurrentUser();
              PromiseResult.message = 'Update successful';
              PromiseResult.success = true;
              PromiseResult.result = result;
              console.log(PromiseResult);
              this.router.navigate(['/dashboard']);
            },
            onFailure: (err) => {
              this.toast.showError(err.message);
              PromiseResult.message = 'Update failed.';
              PromiseResult.result = err;
              alert(err);
              console.log(err);
              reject(PromiseResult);
            }
          });
        }
      });
    });
  }
  
  /**
   * Remember device - prevents mfa authentication if device remembered
   */
  totpLogin(username: string, password: string): void {
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);

    const userData = {
      Username: username,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession) => {
        console.log('Authentication successful:', session);
        // this.router.navigate(['/dashboard']);
      },
      onFailure: (error: any) => {
        console.error('Authentication failed:', error);
        this.toast.showError(error.message || 'Authentication failed');
      },
      mfaRequired: (challengeName: string, challengeParameters: any) => {
        console.log('MFA required:', challengeName, challengeParameters);
        // Assume the MFA method is TOTP (Time-based One-Time Password)
        this.setupTOTPMFA(cognitoUser);
      },
      totpRequired: function(secretCode) {
        console.log('--- totpRequired()');
        console.log('\t ' + secretCode)
        var challengeAnswer = prompt('totpRequired: Please input the TOTP code.', '');
        cognitoUser.sendMFACode((challengeAnswer as string), this, 'SOFTWARE_TOKEN_MFA');
      },
    });
  }

  setupTOTPMFA(cognitoUser: CognitoUser): void {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: (secretCode: string) => {
        console.log('Secret code:', secretCode);
        // Here you would typically display the secret code to the user for them to set up their TOTP app
        // For demonstration purposes, let's assume the user enters the TOTP code manually
        console.log('secret code: ', secretCode);
        const totpCode = prompt('Please enter the TOTP code from your authenticator app:');
        if (totpCode) {
          this.verifyTOTP(cognitoUser, totpCode);
        }
      },
      onFailure: (error: any) => {
        console.error('Error associating software token:', error);
        this.toast.showError(error.message || 'Error associating software token');
      }
    });
  }

  verifyTOTP(cognitoUser: CognitoUser, totpCode: string): void {
    cognitoUser.verifySoftwareToken(totpCode, 'My TOTP Device', {
      onSuccess: (session: CognitoUserSession) => {
        console.log('TOTP verification successful:', session);
        // Complete the MFA setup
        cognitoUser.sendMFACode(totpCode, {
          onSuccess: (session: CognitoUserSession) => {
            console.log('MFA setup completed:', session);
            this.router.navigate(['/dashboard']);
          },
          onFailure: (error: any) => {
            console.error('Error completing MFA setup:', error);
            this.toast.showError(error.message || 'Error completing MFA setup');
          }
        });
      },
      onFailure: (error: any) => {
        console.error('TOTP verification failed:', error);
        this.toast.showError(error.message || 'TOTP verification failed');
      }
    });
  }

  /**
   * User forgot password flow
   */
  public async userForgotPassword(user: string, newPassword: string): Promise<any> {
    const username = this.globals.removeEmailFromString(user);

    var PromiseResult = {
      message: '',
      result: {}
    };      

    let userData = {
      Username: username,
      Pool: this.userPool
    };

    let cognitoUser = new CognitoUser(userData);
    let errorService = this.toast;
    let router = this.router;

    return new Promise((resolve, reject) => {
      console.log('Starting Forgot Password Promise.', cognitoUser);
      cognitoUser.forgotPassword({
        onSuccess: function(data) {
          PromiseResult.message = 'CodeDeliveryData from forgotPassword successful';
          PromiseResult.result = data;
          console.log(PromiseResult);
          resolve(PromiseResult);
          return PromiseResult;
        },
        onFailure: function(err) {
          console.log('CodeDeliveryData failure: ' + err);
          console.log(err.message || JSON.stringify(err));
          PromiseResult.result = err.message;
          reject(PromiseResult);
          return PromiseResult;
        },
        // Optional automatic callback
        inputVerificationCode: function(data) {
          console.log('Data request: ' + JSON.stringify(data));
          var verificationCode = prompt('Input verification code sent to ' + cognitoUser.getUsername() + ': ', '');
          cognitoUser.getAttributeVerificationCode('email', {
            onSuccess: function(verifyEmailResult) {
              console.log('call result: ' + verifyEmailResult);
              alert('Update successful. You may now close this window.');
              PromiseResult.message = 'Verified Email!';
              PromiseResult.result = data;
              console.log(PromiseResult);
            },
            onFailure: function(err) {
              PromiseResult.message = err.message;
              PromiseResult.result = err;
              console.log(PromiseResult);
            },
            inputVerificationCode: function() {
              cognitoUser.verifyAttribute('email', (verificationCode as string), this);
            },
          });
          cognitoUser.confirmPassword((verificationCode as string), newPassword, {
            onSuccess() {
              PromiseResult.message = 'Password confirmed!';
              PromiseResult.result = data;
              console.log(PromiseResult);
              resolve(PromiseResult);
              return PromiseResult;
            },
            onFailure(err) {
              console.log('PASSWORD FAIL');
              console.log('Error confirming password', err);
              PromiseResult.message = 'Error confirming password';
              PromiseResult.result = err;
              reject(PromiseResult);
              return PromiseResult;
            },
          });
        },
      });
    })
  }

  /**
   * Change user password
   */
  public changeUserPassword(oldPassword: string, newPassword: string): Promise<any> {
    const cognitoUser = this.getCognitoUser();

    if (cognitoUser) {
      let authDetails = new AuthenticationDetails({
        Username: (cognitoUser.getUsername() as string), Password: oldPassword
      });
      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: (authSuccess) => {
            console.log(authSuccess);
            cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
              if (!err) {
                console.log('Password changed result: ' + result);
                alert('Password updated! You may now close this window.\n' + 'Call result: ' + result);
                resolve(result);
                window.location.reload();
              } else {
                console.log(err);
                reject(err);
                alert(err.message);
              }
            });
          },
          onFailure: (authFail) => {
            console.log(authFail);
            alert(authFail);
          }
        })
      })
    } else {
      return Promise.reject(cognitoUser);
    }
  }

  /**
   * Remember device - prevents mfa authentication if device remembered
   */
  public async rememberDevice(user: string, password: string): Promise<any> {

  }

  /**
   * Enable user MFA
   */
  public enableUserSMSMFA(user: string, password: string) {
    const username = this.globals.removeEmailFromString(user);

    let userData = {
      Username: username,
      Pool: this.userPool,
    }
    
    let authDetails = new AuthenticationDetails({
      Username: username, Password: password
    });

    let cognitoUser = new CognitoUser(userData);

    let smsMfaSettings = {
      PreferredMfa: true,
      Enabled: true,
    };

    let totpMfaSettings = {
      PreferredMfa: false,
      Enabled: true,
    };
    
    cognitoUser?.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log(result);
        cognitoUser?.setUserMfaPreference(smsMfaSettings, totpMfaSettings, function(err, result) {
          if (err) {
            alert(err.message || JSON.stringify(err));
          } else {
            console.log('call result ' + result);
            alert(result);
          }
        });
      },
      onFailure: (err) => {
        console.log(err);
      }
    });
  }

  /**
   * User Logout
   */

  public async signOut() {

    if(this.cognitoUser != null) {
      this.cognitoUser.signOut();
      console.log("User signed out successfully");
    }

    this.router.navigate(['/']);
  }
}
