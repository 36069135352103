// Angular
import { Component, ViewEncapsulation, ViewChild, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';

// 3rd Party
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { IconDefinition, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';

export interface SidebarState {
  type: string;
  position: string;
  isOpen: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HeaderContent {

  constructor(
    private router: Router,
    private globals: GlobalsService,
    private user: UserPreferencesService
  ) { }

  @ViewChild('sidebar') sidebar: SidebarComponent;
  @ViewChild('togglebtn') togglebtn?: ButtonComponent;
  logo = this.user.appLogo;
  layoutMode: WritableSignal<string> = signal('');
  darkMode: any = this.user.darkMode;
  isDesktop: boolean = this.globals.isDesktop;
  sideBarIcon: WritableSignal<string> = signal('');
  type: string = 'Push';
  width: string = '250px';
  dockWidth: string = '72px';
  toggleSideBarIcon: IconDefinition;
  sidebarState: any;
  sidebarId: string;
  appContentClass: string = 'sidebar-open';

  ngOnInit(): void {
    this.user.appLayout$.subscribe((layoutMode) => {
      this.layoutMode.set(layoutMode);
    });
  }

  ngAfterViewInit(): void {
    if (this.sidebar?.element?.id) {
      const sidebarSettings = localStorage.getItem(`sidebar${this.sidebar.element.id}`) ?? '';
      this.sidebarState = sidebarSettings ? JSON.parse(sidebarSettings) : { isOpen: true };
    } else {
      this.sidebarState = { isOpen: true };
    }
  }

  onCreated() {
    if (this.sidebar?.element) {
      this.sidebar.element.style.visibility = '';
    }
    this.setToggleSidebarIcon();
    this.toggleAppContentClass();
  }

  goHome(): void {
    this.router.navigate(['/dashboard']);
  }

  setToggleSidebarIcon() {
    this.toggleSideBarIcon = this.sidebar?.isOpen ? faAnglesLeft : faAnglesRight;
  }

  toggleSidebar(args: any) {
    if (!this.sidebar) return;
    
    this.sidebar.toggle();
    this.toggleAppContentClass();
    this.setToggleSidebarIcon();
    this.sidebarState.isOpen = this.sidebar.isOpen;
    
    if (this.sidebar.element?.id) {
      localStorage.setItem(`sidebar${this.sidebar.element.id}`, JSON.stringify(this.sidebarState));
    }
    
    this.logo.set(this.user.getLogo({ sidebar: this.sidebar, layoutMode: this.layoutMode() }));
  }

  toggleAppContentClass() {
    if (!this.sidebar) return;
    
    const appContent = document.querySelector('app-content');
    appContent?.classList.remove('sidebar-closed');
    appContent?.classList.remove('sidebar-open');
    this.appContentClass = this.sidebar.isOpen === true ? 'sidebar-open' : 'sidebar-closed';
    appContent?.classList.add(this.appContentClass);
  }
}
